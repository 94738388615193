import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {LanguageRouteDataService} from "./language-route-data.service";
import {isValidLanguageCode} from "./language-utils";
import {URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";

@Injectable()
export class LanguageRouteGuard implements CanActivate {
  //private urlRegex = new RegExp('(?<lang>[a-z]{2})\\/(?:admin)?\\/?(?<slug>[\\s\\w-]*)', 'g')

  constructor(
    private router: Router,
    private languageService: LanguageRouteDataService,
    @Inject(URL_SETTINGS_TOKEN) private urlSettings: UrlSettings
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const segmentWithCode = route.url?.[0]?.path;

    if(!isValidLanguageCode(segmentWithCode)){
      const lang = this.languageService.currentLanguage.toLowerCase();
      const isAdmin = window.location.pathname.includes('admin');
      const adminPrefix = isAdmin ? '/admin' : '';
      const currentRouteUrl = route.url.join('/');
      const newValidURl = `${adminPrefix}/${lang}/${currentRouteUrl}`

      // console.log('will navigate to', newValidURl);
      const {queryParams} = route;
      await this.router.navigate([newValidURl], {queryParams});
      return false;
    } else {
      // console.log(segmentWithCode,'is a valid code');
    }
    return true;
  }
}
