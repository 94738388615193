import { Pipe, PipeTransform } from '@angular/core';
import {WidgetEvent} from "@echo-nx/shared/common";

@Pipe({
  name: 'mouseEventTypeGuard'
})
export class MouseEventTypeGuardPipe implements PipeTransform {

  transform(value: any): WidgetEvent<MouseEvent> | null {
    const isMouseEvent = (value as WidgetEvent<MouseEvent>)?.eventName === 'right-click'
      && (value as WidgetEvent<MouseEvent>)?.data?.x !== undefined
      && (value as WidgetEvent<MouseEvent>)?.data?.y !== undefined;

    if (isMouseEvent) {
      return value as WidgetEvent<MouseEvent>;
    }

    return null;
  }

}
