import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LanguageRouteDataService} from "./language-route-data.service";
import {LanguageRouteGuard} from "./language-route-guard";
import {LanguageProviderService} from "@echo-nx/shared/ng/data-access";

@NgModule({
  imports: [CommonModule],
  providers: [
    LanguageProviderService,
    LanguageRouteDataService,
    LanguageRouteGuard
  ]
})
export class SharedNgFeatureLanguageModule {}
