<div class="flex flex-row flex-grow w-full items-center justify-center">
  <div class="text-2xl font-black flex-grow pr-1" [ngClass]="[primaryColor | prefix:'text']">
    {{title}}
  </div>
  {{date}}
</div>

<div [innerHTML]="content" class="text-black"></div>

<img *ngIf="image" class="w-100" [src]="image.url" [alt]="image.description">

<ng-container *ngIf="videos?.length > 0">
  <div class="text-xl font-bold mt-2"
       [ngClass]="[primaryColor | prefix:'text']">
    Video / animace
  </div>
  <div class="grid grid-cols-2 gap-2 auto-rows-max">
    <ng-container *ngFor="let video of videos;let i = index">
      <div plyr
           *ngIf="video.type === 'YouTubeVideo'"
           [plyrOptions]="plyrOptions"
           [plyrTitle]="'Video #'+i"
           [plyrPlaysInline]="true"
           [plyrSources]="[{src: video.url, provider: 'youtube'}]"></div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="images?.length > 0">
  <div class="text-xl font-bold mt-2"
       [ngClass]="[primaryColor | prefix:'text']">
    Fotogalerie
  </div>
  <div class="grid grid-cols-4 gap-2" echoNxLightboxGalleryGroup>
    <echo-nx-medium-item
      *ngFor="let image of images; index as i"
      [index]="i"
      [medium]="image"></echo-nx-medium-item>
  </div>
</ng-container>


