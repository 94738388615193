// navigation collection
import {ICategorizedEntity, IPublishableOwnedEntity} from "../i-owned-entity";
import {IBaseOwner} from "../i-base-owner";
import {IKioskScreen} from "../structural";
import {IMultilanguage} from "../base-document-types";


// nested document
export interface INavigationItem<S extends IKioskScreen | string = any, O extends IBaseOwner | string = any> extends IPublishableOwnedEntity<O>, IMultilanguage, ICategorizedEntity {
  screen?: S; // ref
  externalLink?: string;
  title?: string;
  subtitle?: string;
  tooltip?: string;
  icon?: string;
  children?: INavigationItem<S, O>[];

  //this little shit determines visibility inside navigation - negative to isHidden
  isActive?: boolean;
}
