import { IMarlenkaOwner } from './owner';
import {IMedium, IOwnedEntity} from "@echo-nx/shared/common";

export type CssAlignment = "center" | "left" | "right";

export interface IMVoucherType extends IOwnedEntity<IMarlenkaOwner | string>{
  name?: string;
  bgMedium?: IMedium<IMarlenkaOwner> | string,
  voucherPreview?: IMedium<IMarlenkaOwner> | string,
  contentPosition?: {
    top: string; //amount and unit
    left: string; //amount and unit
  }
  maxWidth?: string; //amount and unit
  logoAlignment?: CssAlignment;
  title?: {
    color: string;
    fontSize: string; //amount and unit
    textAlignment: CssAlignment;
  }
  text?: {
    color: string;
    fontSize: string; //amount and unit
    textAlignment: CssAlignment;
  }
  signature?: {
    color: string;
    fontSize: string; //amount and unit
    textAlignment: CssAlignment;
  }
}

interface VoucherTypeTemplateData {
    title:{
      content: string;
    }
    text: {
      content: string;
    }
}

export type VoucherTypeTemplateProps = IMVoucherType & VoucherTypeTemplateData;
