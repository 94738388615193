import gql from "graphql-tag";

export const deleteDepartment = gql`
  mutation deleteDepartment($input: [String!]!){
    response: deleteDepartment(ids: $input){
      userMessage
      description
      error
    }
  }
`;
