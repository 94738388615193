import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonThemeAComponent} from './button-theme-a.component';
import {PrefixModule} from "../../prefix/prefix.module";

@NgModule({
  declarations: [
    ButtonThemeAComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ButtonThemeAComponent
  ]
})
export class ButtonThemeAModule {
}
