import {IOwnedEntity} from "./i-owned-entity";
import { IBaseOwner } from './i-base-owner';

export interface IHistory<T extends IBaseOwner | string = any> extends IOwnedEntity<T>{

  operationName: string;

  description?: string;

  // Marlenka chce mít verze souborů, tzn každá historie bude ukazovat na předchozí dokument, který je teď softDeletnutý
  previousDocumentId?: string;
}
