import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafeHtmlPipe} from "./safe-html.pipe";
import {SafeUrlPipe} from "./safe-url.pipe";
import {SafeStylePipe} from "./safe-style.pipe";




@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    SafeUrlPipe,
    SafeStylePipe
  ]
})
export class SanitizerModule { }
