import gql from 'graphql-tag';

export const readAllNavigations = gql`
  query ReadAllNavigations($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllMNavigation(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
    total
      page
      take
      hasMore
      items{
        _id
        name
        page{
          _id
          name
        }
        pages{
          _id
          name
          title
          anchors{
            link
            title
          }
        }
      }
    }
  }
`;
