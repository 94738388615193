import {IMarlenkaOwner} from "../owner";
import {MarlenkaPageIdentifier} from "../../enums";
import {IBasePageSettings, ICategory, IGallery, IMedium} from "@echo-nx/shared/common";

export interface IMPageSettings<T = any> extends IBasePageSettings<IMarlenkaOwner | string> {
  title: string; // frontend title in navigation
  icon?: string; // icon in navigation, because our designer likes to be consistent
  identifier: MarlenkaPageIdentifier;
  subnavItemService?: any; //fixme type dis
  anchors?: PageAnchor[];
  texts?: T;
  logo?: IMedium<any> | string | any;
  storeGallery?: IGallery | string;
  cafeGallery?: IGallery | string;
  excursionGallery?: IGallery | string;

  newsArticleCategory?: ICategory | string;
  reviewsArticleCategory?: ICategory | string;

  careerArticleCategory?: ICategory | string;
  awardsArticleCategory?: ICategory | string;
  videosArticleCategory?: ICategory | string;
  certificatesArticleCategory?: ICategory | string;
}

export interface PageAnchor {
  name: string; // to display in CMS when creating navigation
  link: string; // front scroll to anchor
  title: string; // frontend navigation text

}

// maybe not only
export interface CommonPageTextItem {
  name: string
  title: string;
  subtitle?: string;
}

export interface CommonPageButtonItem {
  name: string;
  readMore: string;
  readLess: string;
}

export interface CommonImagePageTextItem extends CommonPageTextItem {
  backgroundImg: IMedium<IMarlenkaOwner> | string;
  link: string;
}
