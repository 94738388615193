import {Directive, TemplateRef} from '@angular/core';


@Directive({
  selector: '[echoNxTableExpandedItem]',
})
export class ExpandedItemDirective {

  constructor(public templateRef: TemplateRef<any>) {
  }
}
