import {gql} from 'apollo-angular';

export const deleteLanguage = gql`
  mutation deleteLanguage($input: [String!]!, $forceDelete: Boolean){
    response: deleteLanguage(ids: $input, forceDelete: $forceDelete){
      userMessage
      description
      error
    }
  }
`;
