import gql from 'graphql-tag';

export const readSelectedVTScene = gql`
  query ReadSelectedVTScene($ids: [String!]!) {
    response: readSelectedVirtualTourScene(
      ids: $ids
    ) {
      _id
      name
      description
      initialPosition{
        pitch
        yaw
        fov
      }
      points{
        title
        scene
        content
        description
        icon
        location{
          pitch
          yaw
          fov
        }
        orientation{
          pitch
          yaw
          fov
        }
      }
      prefix
      createdAt
      updatedAt
    }
  }
`;
