import {Component, Input, OnInit} from '@angular/core';
import {EchoNxCmsStatusCellProps, StatusDefinition} from "./status-cell.interface";

@Component({
  selector: 'echo-nx-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss']
})
export class StatusCellComponent implements OnInit {


  @Input()
  public text!: string;

  @Input()
  public settings!: EchoNxCmsStatusCellProps;

  @Input()
  public entity: any;

  public currentDefinition?: StatusDefinition;

  ngOnInit(): void {
    if (!this.settings) {
      console.error('CANNOT WORK WITHOUT SETTINGS');
    } else {
      this.currentDefinition = this.settings.displayFunction(this.text, this.entity);
    }
  }

}
