import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeaterFieldComponent } from './repeater-field.component';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {PortalModule} from "@angular/cdk/portal";
import {
  DynamicComponentPipeModule,
  DynamicComponentRegistryService,

} from "@echo-nx/shared/ng/feature/common";
import {TransformForRepeaterPortalPipe} from "./transform-for-repeater.pipe";
import {ReactiveFormsModule} from "@angular/forms";
import { RepeaterComponentTreeDirective } from './repeater-component-tree.directive';



@NgModule({
  declarations: [
    RepeaterFieldComponent,
    TransformForRepeaterPortalPipe,
    RepeaterComponentTreeDirective
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        PortalModule,
        DynamicComponentPipeModule,
        ReactiveFormsModule,
    ]
})
export class RepeaterFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputRepeaterFieldComponent', RepeaterFieldComponent);
  }
}
