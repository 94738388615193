import {Component, Inject, Injector, NgZone, Optional, ViewEncapsulation} from '@angular/core';
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {DefineIcon, FroalaEditorClazz, FroalaOptions, RegisterCommand} from "froala-editor";
import {defaultFroalaOptions} from "../wysiwyg.constants";
import {take,} from "rxjs/operators";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/cs.js';
import {BASE_MEDIUM_SERVICE_TOKEN} from "@echo-nx/shared/ng/data-access";
import {BaseFormFieldComponent} from "../../base-form-field.component";
import {IWysiwygFieldSettings} from "./i-wysiwyg-field.settings";
import {FormGroupDirective} from "@angular/forms";
import {IMedium} from "@echo-nx/shared/common";
import {DataCardDialogComponent, IDataCardDialogSettings} from "../../../../../data-card";

@Component({
  selector: 'echo-nx-wysiwyg-field',
  templateUrl: './wysiwyg-field.component.html',
  styleUrls: ['./wysiwyg-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WysiwygFieldComponent extends BaseFormFieldComponent<IWysiwygFieldSettings> {
  private mediaDialogRef?: MatDialogRef<DataCardDialogComponent, Record<string, any>[]>;
  public editor!: FroalaEditorClazz & any;
  private editorInstance = null;

  froalaOptions: Partial<FroalaOptions> = {
    ...defaultFroalaOptions
  };

  textChanged(value: string) {
    // console.log('textValue Changed',value);
    this.formControl.setValue(value);
  }

  constructor(
    @Optional() @Inject(DYNAMIC_COMPONENT_INPUT) entityDefinition: any,
    public dialog: MatDialog,
    public zone: NgZone,
    formGroupDirective: FormGroupDirective,
    injector: Injector
  ) {
    super(entityDefinition, formGroupDirective, injector);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctx = this;

    this.froalaOptions = {
      ...defaultFroalaOptions,
      autofocus: false,
      events: {
        'contentChanged': () => {
          this.textChanged(this.editor.html.get());
        },
        initialized: function () {
          ctx.disableFroala(this);
          ctx.getInitialValue();
        },
        focus: function () {
          ctx.disableFroala(this);
        },
        click: function () {
          ctx.disableFroala(this);
        },
        blur: function () {
          ctx.disableFroala(this);
        },
      },
    }
  }

  private disableFroala(editor: any) {
    if (this.disabled) {
      //WTF WHY DO I HAVE TO DO THIS MUCH
      editor.toolbar.disable();
      editor.edit.disableDesign();
      editor.events.disableBlur();
      editor.edit.off();

    }
  }

  private getInitialValue() {
    //set froala with entity value
    /*this.entity?.pipe(takeUntil(this.isDestroyed$)).subscribe(
      ent => {
        this.editor.html.set(ent[this.settings.formControlName]);
      }
    )*/
  }

  async initializeFroala(initControls: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    DefineIcon('openPicker', {NAME: 'info', SVG_KEY: "insertImage"});
    RegisterCommand('openPicker', {
      title: 'Vybrat médium',
      type: 'button',
      focus: false,
      undo: true,
      callback: function (iconName, clickedValue) {
        // The current context is the editor instance.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component.zone.run(() => component.openMediaDialog(this))
        return true;
      },
    });

    initControls.initialize();
    this.editor = initControls.getEditor();
  };


  private openMediaDialog(editor: FroalaEditorClazz) {
    const dialogConfig = this.getDialogConfig();
    this.mediaDialogRef = this.dialog.open(DataCardDialogComponent, dialogConfig);

    // after close clear the ref and get the result
    this.mediaDialogRef.afterClosed().pipe(
      take(1)
    ).subscribe((res) => {
      if (res && res.length > 0) {
        const {url, name, _id} = (res as IMedium[])[0];
        editor.image.insert(url ?? '', true, {'name': name, 'id': _id}, null, {});
      }
      this.mediaDialogRef = undefined
    });
  }

  private getDialogConfig(): MatDialogConfig<IDataCardDialogSettings> {
    const {mediaDialogSettings} = this.settings;
    const dialogConfig = new MatDialogConfig<IDataCardDialogSettings>();
    dialogConfig.data = {
      // use provided one or default
      ...mediaDialogSettings ?? {
        title: 'Média',
        entityServiceData: {
          token: BASE_MEDIUM_SERVICE_TOKEN
        },
        selectOnItemClick: true,
        viewType: 'grid'
      }
    };
    dialogConfig.width = '85%';
    // dialogConfig.maxWidth = '95vw';
    // dialogConfig.maxHeight = '95vh';
    // dialogConfig.height = '100%';
    // dialogConfig.width = '100%';
    return dialogConfig;
  }
}

