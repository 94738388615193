import {Component, Injector} from '@angular/core';
import {MarlenkaBasePageComponent} from '..';
import {Observable, of, switchMap} from 'rxjs';
import {ArticleService} from '../../services/article.service';
import {filter, map, takeUntil} from 'rxjs/operators';
import {CareerPageTexts, MarlenkaPageIdentifier} from "@echo-nx/marlenka/common";
import {IArticle, ICategory} from "@echo-nx/shared/common";

@Component({
  selector: 'marlenka-career-page',
  templateUrl: './career-page.component.html',
  styleUrls: ['./career-page.component.scss']
})
export class CareerPageComponent extends MarlenkaBasePageComponent<CareerPageTexts> {

  public careers$: Observable<IArticle[]>;

  constructor(injector: Injector, private blogService: ArticleService) {
    super(injector);

    this.careers$ = this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      map(lang => lang.toLowerCase()),
      switchMap(lang => this.pageSettings$.pipe(
        map(({careerArticleCategory}) => (careerArticleCategory as ICategory)?._id),
        switchMap(categoryId => categoryId ? this.blogService.fetchArticlesInCategory(categoryId, 10, lang) : of([]))
      ))
    )
  }

}
