import {Injectable, InjectionToken} from "@angular/core";
import {
  CommonEntityTypes,
  FetchAllArgs,  IBaseRole,
  ICategory,
  IEntityService, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {readAllRoles, readSelectedRole} from "../queries";
import {addRole} from "../mutations";

@Injectable()
export class BaseRoleService implements IEntityService<IBaseRole> {

  constructor(protected apollo: Apollo) {
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any): any {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IBaseRole>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IBaseRole>>>({
      query: readAllRoles,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<IBaseRole[]> {
    return this.apollo.query<IPKApolloResult<IBaseRole[]>>({
      query: readSelectedRole,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IBaseRole> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    )
  }

  save(entities: IBaseRole[]): Observable<MutationResult<IPKApolloResult<IBaseRole[]>>> {
    return this.apollo.mutate<IPKApolloResult<IBaseRole[]>>({
      mutation: addRole,
      variables: {input: entities}
    });
  }

  getType(): string {
    return CommonEntityTypes.Role;
  }

}
// CMS_BASE_AUTH_SERVICE_TOKEN
export const BASE_ROLES_SERVICE_TOKEN = new InjectionToken<IEntityService<IBaseRole>>('BASE_ROLES_SERVICE_TOKEN');

