import {IPrivilege} from "./i-role";

export type CmsLayout =
  | 'empty'
  // ##### Horizontal
  | 'material'
  | 'modern'
  // #### Vertical
  | 'classic'
  | 'compact'
  | 'dense';

// Types
export type CmsScheme = 'auto' | 'dark' | 'light';
export type CmsTheme = 'default' | string;
export type CmsScreens = { [key: string]: string };
export type CmsAvailableThemes = { id: string; name: string }[];

export interface CmsAppConfig
{
  screens: CmsScreens;
  layout: CmsLayout;
  scheme: CmsScheme;
  themes: CmsAvailableThemes;
  theme: CmsTheme;
}

export const defaultConfig: CmsAppConfig = {
  layout : 'classic',
  scheme : 'auto',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px'
  },
  theme  : 'theme-default',
  themes : [
    {
      id  : 'theme-default',
      name: 'Default'
    },
    {
      id  : 'theme-brand',
      name: 'Brand'
    },
    {
      id  : 'theme-teal',
      name: 'Teal'
    },
    {
      id  : 'theme-rose',
      name: 'Rose'
    },
    {
      id  : 'theme-purple',
      name: 'Purple'
    },
    {
      id  : 'theme-amber',
      name: 'Amber'
    }
  ]
};
