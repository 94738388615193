import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRippleModule} from '@angular/material/core';
import {ReservationInfoComponent} from './reservation-info/reservation-info.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';

import {SectionHeaderComponent} from './section-header/section-header.component';
import {CalendarLegendComponent} from './calendar-legend/calendar-legend.component';
import {FilterComponent} from './filter/filter.component';
import {CalendarComponent} from './calendar/calendar.component';
import {CodeInputComponent} from './code-input/code-input.component';

import {SharedNgFeatureScannerModule} from "@echo-nx/shared/ng/feature/scanner";
import {SharedNgFeatureLoadingModule} from "@echo-nx/shared/ng/feature/loading";
import {SharedNgReservationFeatureModule} from "@echo-nx/shared/ng/feature/reservation";
import {SharedNgUiGenericModule} from "@echo-nx/shared/ng/ui/generic";
import {MarlenkaWysiwygViewComponent} from "./wysiwyg/marlenka-wysiwyg-view/marlenka-wysiwyg-view.component";
import {FroalaViewModule} from "angular-froala-wysiwyg";


@NgModule({
  declarations: [
    CalendarComponent,
    FilterComponent,
    SectionHeaderComponent,
    CalendarLegendComponent,
    CodeInputComponent,
    ReservationInfoComponent,
    MarlenkaWysiwygViewComponent
  ],
  exports: [
    CalendarComponent,
    FilterComponent,
    SectionHeaderComponent,
    CalendarLegendComponent,
    CodeInputComponent,
    ReservationInfoComponent,
    MarlenkaWysiwygViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatListModule,
    MatButtonModule,

    MatButtonModule,
    MatIconModule,
    SharedNgUiGenericModule,
    SharedNgReservationFeatureModule,
    SharedNgFeatureLoadingModule,
    SharedNgFeatureScannerModule,
    FormsModule,
    MatIconModule,
    FroalaViewModule,
  ]
})
export class MarlenkaUiModule {
}
