export const convertChoicesToResult = (choices: {event:string, alwaysOn?:boolean}[]) =>{
  return choices.reduce((acc,curr)=>{
    acc[curr.event] = !!curr.alwaysOn;
    return acc;
  },{});
}
/*
* Result is smth like
* {adStorage: true, performanceStorage: false}
* todo type dis
* */
export const setAll = (result, allowed) => {
  return Object.keys(result).reduce((acc,curr)=>{
    acc[curr] = allowed;
    return acc;
  },{})
}
