<div class="grid-cell-wrapper" *ngFor="let productType of productTypes">
  <div class="product-type">
    <img class="image" [src]="productType.image.url" />
    <div class="content">
      <div class="title">
        {{ productType.name }}
      </div>
      <div class="price">
        {{ productType.commodityType.price }} Kč
      </div>
      <!--<div class="description">
        {{ productType.description | multilang: mrdService.currentLanguage }}
      </div>-->
    </div>
  </div>

  <div class="borders">
    <echo-nx-counter
      [value]="productType | itemCount: reservationService.selectedProducts"
      (add)="addProduct(productType)"
      (remove)="removeProduct(productType)"
      panelClass="marlenka-counter"
    >
    </echo-nx-counter>
  </div>
</div>
