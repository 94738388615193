import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  addMPartnerStore,
  deletePartnerStore,
  IMarlenkaOwner,
  IMPartnerStore, MarlenkaEntityTypes, readAllMPartnerStore, readMPartnerStoreLanguageGroups,
  readSelectedMPartnerStore
} from "@echo-nx/marlenka/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";


@Injectable()
export class BasePartnerStoreService implements IEntityService<IMPartnerStore>{

  constructor(private apollo: Apollo) {}

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMPartnerStore>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMPartnerStore>>>({
        query: readAllMPartnerStore,
        variables: args
      }).pipe(
        map(result => result.data.response)
      );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMPartnerStore>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMPartnerStore>>>({
      query: readMPartnerStoreLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMPartnerStore[]> {
    return this.apollo.query<IPKApolloResult<IMPartnerStore[]>>({
      query: readSelectedMPartnerStore,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IMPartnerStore> {
    return this.apollo.query<IPKApolloResult<IMPartnerStore[]>>({
      query: readSelectedMPartnerStore,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deletePartnerStore,
      variables: {input: ids}
    });
  }

  save(partnerStore: IMPartnerStore[]): Observable<MutationResult<IPKApolloResult<IMPartnerStore[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMPartnerStore[]>>({
      mutation: addMPartnerStore,
      variables: {input: partnerStore}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(language?:string): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type:  this.getType(), // Distribution is correct, its shared for Distrib and PartnerStore
        language
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Distribution ;
  }
}
export const MPARTNER_STORE_SERVICE_TOKEN = new InjectionToken<IEntityService<IMPartnerStore>>('MPARTNER_STORE_SERVICE_TOKEN');
