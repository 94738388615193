import {BaseWidgetSettings} from './base-settings.type';
import {IBaseItemDetailData, IBaseItemDetailDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";

export type ItemDetailWidgetSettings = {
  variant?: ComponentVariant;
  itemDetailDesign?: IBaseItemDetailDesign;
  itemDetailData?: IBaseItemDetailData;
  mapping?: string; //Partial<Record<keyof IBaseItemDetailSettings, string>>;
  disableDocumentViewer?: boolean;
} & BaseWidgetSettings;

