import gql from "graphql-tag";

export const deleteAuth = gql`
  mutation deleteAuths($input: [String!]!, $forceDelete: Boolean){
    response: deleteAuths(ids: $input,forceDelete: $forceDelete){
      userMessage
      description
      error
    }
  }
`;
