import {ICategorizedEntity, IOwnedEntity, IPublishableOwnedEntity} from "./i-owned-entity";
import {IBaseOwner} from "./i-base-owner";
import { MongoID } from './base-document-types';
import {IMedium} from "./i-medium";

/***
 * Contact is master class for PO, FOP and FON
 * It can be assigned to authClass... it can be used in
 * Reservations, Invoice, Form, Payments etc...
 ***/
export interface IOwnedContact<T extends IBaseOwner | string = any> extends IPublishableOwnedEntity<T>, IContact, ICategorizedEntity {
}

export interface IOwnedCompanyContact<T extends IBaseOwner | string> extends IOwnedEntity<T>, ICompanyContact {
}

export type UnionContact<T extends IBaseOwner | string> = IOwnedContact<T> & IOwnedCompanyContact<T>

interface BaseContact extends MongoID {
  name?: string;
  isMain?: boolean;
  priority?: number;
}

export interface IContact extends BaseContact{
  title?: string;
  firstName?: string;
  lastName?: string;
  profession?: string;
  birthday?: Date;
  birthnumber?: string;
  identityCardNumber?: string;
  address?: IAddress;
  telephones?: string[];
  emails?: string[];
  websites?: string[];
  avatar?: IMedium<any> | string;
}

export interface ICompanyContact extends BaseContact {
  companyName?: string;
  ico?: string; // can start with zero (05149860)
  dic?: string; // starts with country code
  dataBox?: string;
  address?: IAddress;
  bankInfo?: IBankInfo;
  tradeRegisterText?: string; //Společnost je zapsána v obchodním rejstříku u Krajského soudu v Ostravě, oddíl C, vložka 55084.
  contacts?: IContact[];
}

export interface IAddress {
  street?: string;
  province?: string;
  zipcode?: string;
  city?: string;
  state?: string;
}

export interface IBankInfo {
  bankName?: string;
  bankNumber?: string;
  bankCode?: string;
  swift?: string;
  iban?: string;
}
