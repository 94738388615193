import {
  AfterViewInit,
  Component,
  Inject, Injector,
  OnInit,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {IDateTimeFieldSettings} from "./i-date-time-field.settings";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {getFirstError} from "../../../utils/form-utils";
import {FormGroupDirective} from "@angular/forms";
import {MatDatetimepickerType} from "@mat-datetimepicker/core/datetimepicker/datetimepicker-type";


@Component({
  selector: 'echo-nx-date-time-field',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateTimeFieldComponent extends BaseFormFieldComponent<IDateTimeFieldSettings> implements OnInit, AfterViewInit {
  public firstError ?: string;
  public type!: MatDatetimepickerType;
  public nazev?: string;

  public renderAfterValueSet = false;

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IDateTimeFieldSettings>,
              formGroupDirective: FormGroupDirective,
              injector: Injector
              ) {
    super(def,formGroupDirective, injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    // potrebujeme subscribe na kazdou dalsi zmenu aby fungovaly nase validatory
    this.formControl?.valueChanges.subscribe((value) => {
      console.log('new value',value)
      this.firstError = getFirstError(this.settings.formControlName, this.formGroup);
    });
    const { type,nazev } = this.settings;
    this.type = type ?? "datetime";
    this.nazev = typeof nazev === "function" ? nazev(this.settings, this.formGroup) : nazev;
    this.firstError = getFirstError(this.settings.formControlName, this.formGroup);

    // Transform string to Date when initializing
    if(this.formControl?.value){
      this.formControl?.setValue(new Date(this.formControl?.value));
    }
    this.renderAfterValueSet = true;
  }

  ngAfterViewInit(): void {
    //tohle tu mozna byt nemusi a staci v subscribe
    this.firstError = getFirstError(this.settings.formControlName, this.formGroup);
  }

}
