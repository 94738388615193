import gql from 'graphql-tag';

export const readAllVTScenes = gql`
  query ReadAllVTScenes($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllVirtualTourScene(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        description
        prefix
        initialPosition{
          pitch
          yaw
          fov
        }
        faceSize
        points{
          title
          scene
          content
          description
          icon
          location{
            pitch
            yaw
            fov
          }
          orientation{
            pitch
            yaw
            fov
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
