import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenFieldComponent } from './hidden-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";



@NgModule({
  declarations: [
    HiddenFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class HiddenFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkHiddenFieldComponent', HiddenFieldComponent,);
  }
}
