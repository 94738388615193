import {Component, Input, OnInit} from '@angular/core';
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {IMDialogCommodity, IMProductType, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-product-type-grid',
  templateUrl: './product-type-grid.component.html',
  styleUrls: ['./product-type-grid.component.scss']
})
export class ProductTypeGridComponent implements OnInit {
  @Input() productTypes!: IMProductType<IMDialogCommodity>[];
  @Input() cellSize = '15rem';


  constructor(public reservationService: ReservationService, public mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
  }

  ngOnInit() {
    // set styles
    document.documentElement.style.setProperty('--cell-size', this.cellSize);
  }

  addProduct(productType: IMProductType<IMDialogCommodity>) {
    this.reservationService.addProduct(productType)
  }

  removeProduct(productType: IMProductType<IMDialogCommodity>) {
    this.reservationService.removeProduct(productType);
  }

}


