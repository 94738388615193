import {Directive, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {EntityFormService} from "../../entity-form";
import {GroupComponentTreeDirective} from "../group-field/group-component-tree.directive";

@Directive({
  selector: '[echoNxRepeaterComponentTree]'
})
export class RepeaterComponentTreeDirective implements OnInit {

  @Input()
  public entityFormService!: EntityFormService;

  @Input()
  public repeaterControlName!: string;

  protected componentTree: Record<string, any>[] = [];

  constructor(@Optional() @SkipSelf() private gctDirective: GroupComponentTreeDirective) {

  }

  ngOnInit() {
    if (!this.gctDirective){
      this.entityFormService.componentTree[this.repeaterControlName] = [];
      this.componentTree = this.entityFormService.componentTree[this.repeaterControlName];
    } else {
      this.gctDirective.componentTree[this.repeaterControlName] = [];
      this.componentTree = this.gctDirective.componentTree[this.repeaterControlName];
    }
  }

  public addComponentAtIndex(name: string, idx: number, portal: any) {
    if (this.componentTree[idx]) {
      this.componentTree[idx] = {
        ...this.componentTree[idx],
        [name]: portal
      };
    } else {
      this.componentTree.push({[name]: portal})
    }
  }


  public removeComponentAtIndex(idx: number){
    this.componentTree.splice(idx,1);
    console.log('ct',this.componentTree);
  }

  public pushGroup(idx: number, group: any) {
    if (this.componentTree[idx]) {
      return;
    }
    this.componentTree.push(group);
  }

}
