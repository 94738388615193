import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JsonFieldComponent} from './json-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    JsonFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatIconModule,
        FlexModule,
        MatButtonModule,
        TranslateModule,
    ]
})
export class JsonFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputJsonFieldComponent', JsonFieldComponent);
  }
}
