/**
 *
 * @param quantity - počet prvků
 * @param terms - array o třech prvcích reprezentující správné skloňování pro 1,2,5 prvků př. [pes, psi, psů]
 * @param prependQuantity - true pokud chceš aby funkce vrátila číslo + text, false vrátí pouze text
 */
export const pluralize = (quantity: number, terms: string[], prependQuantity = true) => {
  const absQuantity = Math.abs(quantity);

  let quantifiedString;
  if (absQuantity == 1)                           quantifiedString = terms[0];
  else if (absQuantity < 5 && absQuantity > 0)    quantifiedString = terms[1];
  else                                            quantifiedString = terms[2];

  return prependQuantity ?
    `${quantity} ${quantifiedString}` :
    quantifiedString;
};
