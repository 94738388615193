import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ITransaction, PaymentInfo} from "@echo-nx/shared/common";
import {
  getChildrenAndAdultCount,
  getReservationState, IMDialogCommodity, IMEvent,
  IMProductType,
  IMReservation,
  IMTicketType
} from "@echo-nx/marlenka/common";


@Component({
  selector: 'marlenka-reservation-info',
  templateUrl: './reservation-info.component.html',
  styleUrls: ['./reservation-info.component.scss']
})
export class ReservationInfoComponent implements OnChanges {

  @Input()
  public reservation!: IMReservation<any, any, IMEvent>;

  public noChildrenInReservation = 0;
  public noAdultsInReservation = 0;
  public noClaimedTickets = 0;
  public reservationState?: string;


  ngOnChanges(changes: SimpleChanges): void {
    // when reservation changes, recalculate its items
    const reservation: IMReservation<IMTicketType<IMDialogCommodity>, IMProductType<IMDialogCommodity>> = changes.reservation.currentValue;
    if (reservation) {
      const [noChildren, noAdults] = getChildrenAndAdultCount(reservation);
      this.noChildrenInReservation = noChildren;
      this.noAdultsInReservation = noAdults;
      this.reservationState = getReservationState(reservation.transactions as ITransaction<PaymentInfo>[]);
      this.noClaimedTickets = reservation.tickets?.reduce((acc, curr) => {
        if (curr.claimedAt) {
          acc += 1;
        }
        return acc;
      }, 0) ?? 0;
    }
  }

}
