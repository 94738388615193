import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform<T>(arr: T[], key: keyof T | any, order: 'asc' | 'desc' = 'desc'): unknown {
    const o = order === 'desc' ? 1 : -1;
    // infinity in case priority is undefined, this will make it prioritize the ones with defined priority
    return arr.sort((a: any, b: any) => ((a[key] ?? Infinity) > (b[key] ?? Infinity)) ? o : -o);
  }

}
