<ng-container *ngIf="buttons$ | async as buttons">
  <div class="p-2 flex gap-2 flex-shrink font-medium"
       [ngClass]="[bgColor | prefix:'bg', textSize | prefix:'text']">
    <button class="button"
            [disabled]="activeIndex === 0"
            [ngClass]="activeIndex === 0 ? [disabledColorful.color | prefix:'text', disabledColorful.bgColor | prefix:'bg'] :
                                      [normalColorful.color | prefix:'text', normalColorful.bgColor | prefix:'bg']"
            (click)="previousClicked()">
      <i class="fas fa-chevron-left fa-fw"></i>
    </button>


    <button class="button"
            *ngFor="let button of buttons"
            (click)="pageClicked(button.index)"
            [disabled]="button.isDisabled || button.isActive"
            [ngClass]="button.isDisabled ? ([disabledColorful.color | prefix:'text', disabledColorful.bgColor | prefix:'bg']) : (button.isActive ?
     [activeColorful.color | prefix:'text', activeColorful.bgColor | prefix:'bg'] :
     [normalColorful.color | prefix:'text', normalColorful.bgColor | prefix:'bg'])">
      {{button.label}}
    </button>


    <button class="button" *ngIf="buttons"
            [disabled]="!hasNext()"
            [ngClass]="!hasNext() ? [disabledColorful.color | prefix:'text', disabledColorful.bgColor | prefix:'bg'] :
                                      [normalColorful.color | prefix:'text', normalColorful.bgColor | prefix:'bg']"
            (click)="nextClicked()">
      <i class="fas fa-chevron-right fa-fw"></i>
    </button>

  </div>

</ng-container>
