import {IColumnDefinition} from "../ui/table/i-column-definition";
import {IGridDefinition} from "../ui/grid/i-grid-definition";
import {IEntityDefinition} from "../../forms";

// renamed from ICmsEntityService - now belongs to data-card library, which takes care of displaying entity in a card.
export interface IDataEntityService<T = any> {

  // to spawn filter form accordingly
  getFilterEntityDefinition(): IEntityDefinition[];

  transformFilterValue?(value: any): string | undefined;

  // for display of TABLE
  getColumnDefinition(...args: any): IColumnDefinition[] | undefined;

  // for display of LIST todo Interface with Definition for default display + data to spawn component via PORTAL
  getListItemDefinition(...args: any): [] | undefined;

  // for display of GRID
  getGridItemDefinition(...args: any): IGridDefinition | undefined;
}
