export type MarlenkaPaymentType = 'reservation' | 'voucher';

export interface ILocalPaymentInitResult extends ILocalPaymentResult {
  // code: LocalPaymentResultCode | number;
  doklad_no?: number;
}


export interface ILocalPaymentResult {
  code: LocalPaymentResultCode | number;
  message?: string;
}


export enum LocalPaymentResultCode {
  PAYMENT_CANCELED = 1,
  SUCCESS = 0,
  NO_CHANGE = -1,
  ERROR = -100
}
