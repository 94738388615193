import gql from 'graphql-tag';

export const deleteEventLanguage = gql`
  mutation deleteEventLanguage($input: [String!]!) {
    response: deleteEventLanguage(ids: $input) {
      userMessage
      description
      error
    }
  }
`;
