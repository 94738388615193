import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import {
  FetchAllArgs, IApiIntegration,
  IEntityService, IKiosk,
  IMutationResponse, IPaginatedResponse, IPKApolloResult,
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {readAllIntegrations,  readSelectedIntegrations} from "../queries";
import {addIntegration} from "../mutations";

@Injectable()
export class BaseIntegrationsService implements IEntityService<IApiIntegration> {
  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IApiIntegration>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IApiIntegration>>>({
      query: readAllIntegrations,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IApiIntegration[]> {
    return this.apollo.query<IPKApolloResult<IApiIntegration[]>>({
      query: readSelectedIntegrations,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IApiIntegration> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0] || null)
    );
  }

  getType(): string {
    return '';
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(category: IApiIntegration[]): Observable<MutationResult<IPKApolloResult<IApiIntegration[]>>> {
    return this.apollo.mutate<IPKApolloResult<IApiIntegration[]>>({
      mutation: addIntegration,
      variables: {input: category}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<any> {
    throw Error('No categories in Category.');
  }

}

export const BASE_INTEGRATION_SERVICE_TOKEN = new InjectionToken<IEntityService<IApiIntegration>>('BASE_INTEGRATION_SERVICE_TOKEN');
