import {Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import * as moment from 'moment';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {BasePageSettingsService, ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {CommonPageTextItem, CouponValidationResult, CreateReservationPageTexts, IDiscountCoupon, MarlenkaPageIdentifier, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {TableField} from "@echo-nx/shared/ng/feature/common";
import {CodeInputComponent} from "@echo-nx/marlenka/ng/ui";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-coupon-dialog',
  templateUrl: './coupon-dialog.component.html',
  styleUrls: ['./coupon-dialog.component.scss']
})
export class CouponDialogComponent implements OnInit, OnDestroy {
  public pageTexts$: Observable<CommonPageTextItem | undefined>;
  private readonly isDestroyed$ = new Subject<boolean>();

  public readonly error = {
    hasError: false,
    message: ''
  };

  @ViewChild('codeInputComponent')
  private codeInputComponent!: CodeInputComponent;

  public loadingCounter = 0;
  public readonly couponTableFields: TableField<IDiscountCoupon>[] = [
    {
      key: 'code',
      label: 'Kód voucheru'
    },
    {
      key: 'name',
      label: 'Typ voucheru',
      formatter: item => item.name ?? ''
    },
    {
      key: 'expires_at',
      label: 'Platnost voucheru',
      formatter: item => moment(item.expires_at).format('DD.MM.YYYY')
    },
    {
      key: 'price',
      label: 'Cena',
      formatter: item => `${item.price} Kč`
    },
    {
      key: 'delete-cell',
      label: 'Odstranit'
    }
  ];
  public couponsTotal$!: Observable<number>;

  @Input()
  public reservationService!: ReservationService;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { reservationService: ReservationService },
    private httpClient: HttpClient,
    private mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    private pageSettingService: BasePageSettingsService
    ) {
    if(data) {
      this.reservationService = data.reservationService;
    }

    this.pageTexts$ = this.mrdService.currentLanguage$.pipe(switchMap((language) =>
      this.pageSettingService.getPageSettingsByIdentifier<CreateReservationPageTexts>(
        MarlenkaPageIdentifier.CREATE_RESERVATIONANDVOUCHER,
        language.toLowerCase()
      ).pipe(map((pageSettings) => pageSettings.texts?.voucherBannerText))
    ));
  }

  ngOnInit(): void {
    this.couponsTotal$ = this.reservationService.selectedCoupons$
      .pipe(
        takeUntil(this.isDestroyed$),
        map(coupons => coupons.reduce((sum: number, coupon: IDiscountCoupon) => {
            sum += coupon.price;
            return sum;
          }, 0)
        )
      );
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  public async couponChange(value: string) {
    if (value.length < 1) {
      return;
    }

    this.loadingCounter += 1;
    this.error.hasError = false;

    const trimmedValue = value.trim()

    // if coupon already exists, throw error and return;
    if (this.reservationService.selectedCoupons.some(c => c.code === trimmedValue)) {
      this.error.hasError = true;
      this.error.message = 'Tento kupón je již uplatněn.';
      this.loadingCounter -= 1;
      return;
    }

    // validate coupon via api
    const code = encodeURIComponent(trimmedValue);
    const result = await firstValueFrom(this.httpClient.get<CouponValidationResult>(`/api/coupon/validate/${this.mrdService.currentLanguage}/${code}`,{params:{
      eventStart: this.reservationService.selectedEvent?.start as unknown as string //todo investigate wrong types -  graphql is probably serializing
    }}));

    // stop loading
    this.loadingCounter -= 1;

    // return result
    const {error, data, message} = result;
    if (!error && data) {

      this.reservationService.selectedCoupons = [
        ...this.reservationService.selectedCoupons,
        data
      ];
      console.log('selected Coupon');
      this.codeInputComponent.clearInput();
    } else {
      this.error.hasError = true;
      this.error.message = message ?? '';
    }

  }

  public removeCoupon(coupon: IDiscountCoupon) {
    this.reservationService.selectedCoupons = this.reservationService.selectedCoupons.filter(c => c !== coupon);
  }
}
