import { Component } from '@angular/core';


@Component({
  selector: 'echo-nx-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent {}


