import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Apollo} from "apollo-angular";
import {BaseProductTypeService} from "./base-product-type.service";
import {IMProductType} from "@echo-nx/marlenka/common";

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService extends BaseProductTypeService {

  private _productTypes: BehaviorSubject<IMProductType[]>;
  public readonly productTypes$: Observable<IMProductType[]>;

  set productTypes(productTypes: IMProductType[]) {
    this._productTypes.next(productTypes);
  }

  get productTypes() {
    return this._productTypes.getValue();
  }

  constructor(apollo: Apollo,) {
    super(apollo);
    this._productTypes = new BehaviorSubject<IMProductType[]>([]);
    this.productTypes$ = this._productTypes.asObservable();
  }
}
