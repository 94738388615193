import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateTimeBarVariantBComponent} from './date-time-bar-variant-b.component';
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    DateTimeBarVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    DateTimeBarVariantBComponent
  ]
})
export class DateTimeBarVariantBModule {
}
