import {Component, HostBinding, Input} from '@angular/core';
import {IBaseButtonData, IBaseButtonDesign, IEpxColorful, IEpxIcon} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-button-theme-b',
  templateUrl: './button-theme-b.component.html',
  styleUrls: ['./button-theme-b.component.scss']
})
export class ButtonThemeBComponent implements IBaseButtonDesign, IBaseButtonData {

  @Input()
  fontSize;

  @Input()
  bgColor;

  @Input()
  secondaryColor;

  @Input()
  accentColor;

  @Input()
  textColor;

  @Input()
  dense = false;

  @Input()
  prominent = false;

  @Input()
  outlined = false;

  @Input()
  orientation;

  @Input()
  hoverable;

  @Input()
  description?: string;

  @Input()
  descriptionColorful?: IEpxColorful;

  @Input()
  title !: string;

  @Input()
  startIcon ?: IEpxIcon;

  @Input()
  endIcon ?: IEpxIcon;

  @Input()
  fluid;

  @Input()
  panelClass?: string;


  @HostBinding('class') get classList(): any {
    return {
      "flex-1": this.fluid
    };
  }

  @Input()
  set design({
               textColor,
               bgColor,
               startIcon,
               endIcon,
               fluid,
               descriptionColorful,
               prominent,
               orientation,
               outlined,
               dense,
               secondaryColor,
               fontSize,
               accentColor,
             }: IBaseButtonDesign) {
    this.fluid = fluid;
    this.textColor = textColor;
    this.bgColor = bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.descriptionColorful = descriptionColorful;
    this.prominent = prominent;
    this.orientation = orientation ?? 'row';
    this.dense = dense;
    this.outlined = outlined;
    this.secondaryColor = secondaryColor;
    this.fontSize = fontSize;
    this.accentColor = accentColor;
  }


  @Input()
  set data({
             title,
             description,
           }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }

}
