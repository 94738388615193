
export interface ISliderDesign {
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;

  hideIndicators?: boolean;
  hideIndicatorButtons?: boolean;
}

export interface ISliderData {
  title?: string,
  subtitle?: string,
  description?: string,
  imgUrl?: string,
}
