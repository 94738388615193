import gql from 'graphql-tag';

export const checkForDialogInit = gql`
  query checkForDialogInit($noPokladny: Int!, $reservationId: String!) {
    response: checkForDialogInit(
      noPokladny: $noPokladny,
      reservationId: $reservationId
    ){
      code
      doklad_no
      message
    }
  }
`;


export const checkForPayment = gql`
  query checkForPayment($noPokladny: Int!, $reservationId: String!) {
    response: checkForPayment(
      noPokladny: $noPokladny,
      reservationId: $reservationId
    ){
      code
      message
    }
  }
`;
