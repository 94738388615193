import {CommonImagePageTextItem, CommonPageTextItem} from "./marlenka-page-settings";
import {IGallery, IMedium} from "@echo-nx/shared/common";
import {IMarlenkaOwner} from "../owner";

export interface ExcursionPageTexts {
  menuBanner: ExcursionBannerText;
  infoJumbotron: InfoJumbotronText;
  contentJumbotron: InfoJumbotronText;
  tourProgramJumbotron: InfoJumbotronText;
  // visitorCentrumJumbotron: InfoJumbotronText;
  virtualTourJumbotron: InfoJumbotronText;
  presentationJumbotron: InfoJumbotronText;
  //tastingJumbotron: InfoJumbotronText;
  //productionInspectionJumbotron: InfoJumbotronText;
  //purchaseInspectionJumbotron: InfoJumbotronText;
  reservationJumbotron: ExcursionContentJumbotronText;
  stepper: CommonPageTextItem;
  virtualTour: CommonPageTextItem
}

export interface ExcursionBannerText {
  main: CommonImagePageTextItem;
  info: CommonImagePageTextItem;
  reservation: CommonImagePageTextItem;
  virtualTour: CommonImagePageTextItem;
}

export interface InfoJumbotronText {
  name: string;
  title: string;
  content: string;
  medium?: IMedium<IMarlenkaOwner>;
  gallery?: IGallery<IMarlenkaOwner>;
}

export interface ExcursionContentJumbotronText extends InfoJumbotronText{
  enterReservationSystem: CommonPageTextItem;
}
