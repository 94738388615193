import {IOwnedEntity} from "./i-owned-entity";
import {IBaseOwner} from "./i-base-owner";
import {IMeta} from "./i-meta";

export interface ISensorData<TMetrics = any, O extends IBaseOwner = any, TMeta = any> extends IOwnedEntity<O>, IMeta<TMeta>{
  stationName: string;
  stationLocationName: string;
  stationType: 'speed-meter' | 'air-quality' | 'weather' | string;
  metrics: TMetrics;
}
