import { Pipe, PipeTransform } from '@angular/core';
import {stripPathSegments} from "@echo-nx/shared/common";


@Pipe({
  name: 'stripUrl',
  pure: false
})
export class StripUrlPipe implements PipeTransform {
  transform(value: string, lastIdx: number): any {
    return stripPathSegments(value, lastIdx);
  }
}
