import {Pipe, PipeTransform} from '@angular/core';
import {IMItemType, IMReservedItem} from "@echo-nx/marlenka/common";

@Pipe({
  name: 'itemCount'
})
export class ItemCountPipe implements PipeTransform {

  transform(itemType: IMItemType, items: IMReservedItem<IMItemType>[]): number {
    return items.reduce((count, item) => {
      if (item.itemType.languageGroupId === itemType.languageGroupId) {
        count++;
      }
      return count;
    }, 0);
  }

}
