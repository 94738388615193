import { Directive, ElementRef } from '@angular/core';
import {ScrollableService} from "../services";


/*
 * Mark element as scrollable and provide service
 * renamed from pkScrollable
 */
@Directive({
  selector: '[echoNxScrollable]',
  providers: [
    {
      provide: ScrollableService,
    },
  ],
})
export class ScrollableDirective {
  constructor(
    private elementref: ElementRef<HTMLElement>,
    private scrollableService: ScrollableService
  ) {
    this.scrollableService.setScrollable(elementref, true);
  }
}
