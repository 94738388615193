import gql from 'graphql-tag';

export const readSelectedMAllergens = gql`
  query ReadSelectedMAllergens($ids: [String!]!) {
    response: readSelectedAllergens(
      ids: $ids,

    ) {
      _id
      text
      number
      icon
      language
      languageGroupId
    }
  }
`;
