<div class="p-2 max-w-lg bg-white flex flex-col space-y-4">

  <!-- image, categories -->
  <div class="relative flex" [ngClass]="[featuredImage ? 'h-44' : 'h-8']">
    <!-- image   -->
    <img *ngIf="featuredImage" class="object-cover w-full h-full" [src]="featuredImage?.thumbnailUrl ?? featuredImage?.url"/>

    <!-- categories -->
    <div class="flex flex-col items-start space-y-2"
         [class.absolute]="!!featuredImage">
      <div *ngFor="let cat of categories"
           class="py-2 px-4 flex items-center justify-center text-white space-x-2"
           [ngStyle]="{backgroundColor: cat.color}">
        <i *ngIf="cat.icon" class="fa-fw" [ngClass]="[cat?.icon]"></i>
        <span class="font-medium text-base">{{cat.name}}</span>
      </div>
    </div>
  </div>


  <!-- date, title, subtitle -->
  <div class="flex flex-col space-y-2" [ngClass]="[primaryColor | prefix:'text']">
    <span class="text-sm font-light">{{publishedAt}}</span>
    <div class="text-lg font-black" >{{title}}</div>
    <div class="text-slate-900 font-light">{{subtitle}}</div>
  </div>

  <!-- link -->
  <div class="flex items-center space-x-2 text-md cursor-pointer"
       *ngIf="_id"
       [ngClass]="[primaryColor | prefix:'text']"
       (click)="detailClicked.emit(this._id)">
    <i class="fa-fw fas fa-arrow-circle-right"></i>
    <span class="hover:underline">Číst více</span>
  </div>
</div>
