import { Component, Input } from '@angular/core';


@Component({
  selector: 'echo-nx-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent {
  @Input()
  readonly flipSides = false;
}

