import {Component, Input, OnInit, Optional, Self, SkipSelf} from '@angular/core';
import {IBaseOwner, IMedium, MediumType} from "@echo-nx/shared/common";
import {LightboxGroupService} from "../../services/lightbox-group.service";

@Component({
  selector: 'echo-nx-medium-item',
  templateUrl: './medium-item.component.html',
  styleUrls: ['./medium-item.component.scss'],
  providers: [LightboxGroupService]
})
export class MediumItemComponent implements OnInit {
  @Input()
  public medium?: IMedium<IBaseOwner>;

  @Input()
  public index!: number;

  @Input()
  public cover = false;


  public groupService!: LightboxGroupService;

  constructor(
    @Self() private myGroupService: LightboxGroupService,
    @SkipSelf() @Optional() private parentGroupService: LightboxGroupService) {
    // how to reverse DI flow + prefer parent over self
    this.groupService = parentGroupService ?? myGroupService;
  }

  ngOnInit(): void {
    const {url, thumbnailUrl, type, } = this.medium ?? {};

    // https://github.com/MurhafSousli/ngx-gallery/wiki/Mixed-Content-Usage
    // https://ngx-gallery.netlify.app/#/advanced
    // https://stackblitz.com/edit/ngx-gallery-xe4tu6?file=src/app/home/home.component.html
    // https://ngx-gallery.netlify.app/#/lightbox

    if (type === MediumType.Image) {
      this.groupService.galleryRef.addImage({
        src: url,
        thumb: thumbnailUrl ?? url
      });
    } else if (type === MediumType.Video) {
      this.groupService.galleryRef.addVideo({
        src: url,
        thumb: thumbnailUrl ?? url
      });
    } else if (type === MediumType.YouTubeVideo) {
      this.groupService.galleryRef.addYoutube({
        src: url,
        thumb: thumbnailUrl ?? url
      });
    }

  }

}
