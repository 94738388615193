import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationVariantCComponent } from './pagination-variant-c.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    PaginationVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    PaginationVariantCComponent
  ]
})
export class PaginationVariantCModule { }
