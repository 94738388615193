import gql from 'graphql-tag';

export const readSelectedSms = gql`
  query ReadSelectedSms($ids: [String!]!) {
    response: readSelectedSms(
      ids: $ids
    ) {
      _id
      text
      date_of_sending
      date_of_payment
      count
      receivers
    }
  }
`;
