import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemCardVariantCComponent} from "./item-card-variant-c.component";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    ItemCardVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ItemCardVariantCComponent
  ]
})
export class ItemCardVariantCModule {
}
