export enum CommonEntityTypes {
  Article = 'Article',
  Media = 'Media',
  Language = 'Language',
  Category = 'Category',
  Gallery = 'Gallery',
  Owner = 'Owner',
  User = 'User',
  Auth = 'Auth',
  Role = 'Role',
  Logs = 'Logs',
  VirtualTour = 'VirtualTour',
  POIItem = 'POIItem',
  Theme = 'Theme',
  Transaction = 'Transaction',
  Message = 'Message',
  WeatherData = 'WeatherData',
  SensorData = 'SensorData',
  Event = 'Event',
  Contact = 'Contact',
}

export enum _EkioskEntityTypes {
  Kiosk = 'Kiosk',
  Screen = 'Screen',
  Timeline = 'KioskTimeline',
  Navigation = 'Navigation',
}

export type EkioskEntityTypesType = _EkioskEntityTypes | CommonEntityTypes;

export const EkioskEntityTypes = {
  ..._EkioskEntityTypes,
  ...CommonEntityTypes,
};
