import {Component, Input, OnInit} from '@angular/core';
import {IBaseButtonData, IBaseButtonDesign, IEpxColorful, IEpxIcon} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-button-variant-e',
  templateUrl: './button-variant-e.component.html',
  styleUrls: ['./button-variant-e.component.scss']
})
export class ButtonVariantEComponent implements OnInit, IBaseButtonData, IBaseButtonDesign {

  @Input()
  accentColor?: string;

  @Input()
  descriptionColorful?: IEpxColorful;

  @Input()
  description?: string;

  @Input()
  endIcon?: IEpxIcon;

  @Input()
  bgColor;

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  textColor?: string;

  @Input()
  title!: string;

  @Input()
  fluid?

  @Input()
  set design({
               textColor,
               bgColor,
               startIcon,
               endIcon,
               fluid
             }: IBaseButtonDesign) {
    this.textColor = textColor ?? this.textColor;
    this.bgColor = bgColor ?? this.bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.fluid = fluid;
  }

  @Input()
  set data({
             title,
             description,
           }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }

  ngOnInit(): void {
    return;
  }

}
