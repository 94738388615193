import {Component, Input} from '@angular/core';

@Component({
  selector: 'marlenka-step-summary',
  templateUrl: './step-summary.component.html',
  styleUrls: ['./step-summary.component.scss']
})
export class StepSummaryComponent {
  @Input() top = true;
  @Input() bottom = true;
  @Input() autoMarginTop = false;
}
