import {BehaviorSubject, firstValueFrom, Observable, ReplaySubject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {LanguageProviderService} from '@echo-nx/shared/ng/data-access';
import {BaseRouteData} from "@echo-nx/shared/common";
import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {isValidLanguageCode} from "./language-utils";

@Injectable()
export class LanguageRouteDataService<T extends BaseRouteData = BaseRouteData> {
  protected _routeData: ReplaySubject<T>;
  public routeData$: Observable<T>;

  protected _currentLanguage: BehaviorSubject<string>;
  public currentLanguage$: Observable<string>;

  get currentLanguage(): string {
    return this._currentLanguage.getValue();
  }

  set currentLanguage(lang: string) {
    console.log('setting new language',lang);
    // replace current route language to new language. eg cs/about -> :lang/about
    const urlSegments = this._router.url.split('/');
    urlSegments.shift();
    const newUrl = urlSegments
      .reduce((acc: string[], curr, index) => {
        if (index === 0) {
          acc.push(lang.toLowerCase());
        } else {
          acc.push(curr);
        }
        return acc;
      }, []).join('/');
    // navigate to new Url and set currentLang
    this._router.navigateByUrl(newUrl).then(() => {
      this.document.documentElement.lang = lang;
      localStorage.setItem('lang', lang);
      this._currentLanguage.next(lang);
    });
  }

  constructor(
    protected languageProvider: LanguageProviderService,
    protected _route: ActivatedRoute,
    protected _router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    const userLocale = getUsersLocale();
    const defaultLocale = this.getDefaultLocale(userLocale);
    this._currentLanguage = new BehaviorSubject<string>(getLanguageFromLocale(defaultLocale));
    this.currentLanguage$ = this._currentLanguage.asObservable();

    this._routeData = new ReplaySubject<T>(1);
    this.routeData$ = this._routeData.asObservable();

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this._route.firstChild?.snapshot.data) {
          this._routeData.next(this._route.firstChild.snapshot.data as T);
        }
      });

    // if language in route is not defined by language provider, reroute to system locale
    firstValueFrom(this.languageProvider
      .fetchAll())
      .then((langs) => {
        const correctLang = langs?.items?.some(
          (l) => l.code === this.currentLanguage
        );
        if (!correctLang) {
          // this.currentLanguage = getLanguageFromLocale(userLocale);
          this.currentLanguage = 'cs';
        }
      });
  }

  private getDefaultLocale(userLocale: string): string {
    // default locale, from path or from user agent
    const startUrl = window.location.pathname.split('/');
    startUrl.shift();
    const routeLocale = isValidLanguageCode(startUrl[0].toLowerCase()) ? startUrl[0].toLowerCase() : 'cs';

    // path > storage > os locale
    return (
      routeLocale ||
      localStorage.getItem('lang') ||
      getLanguageFromLocale(userLocale)
    );
  }
}

const getLanguageFromLocale = (locale: string)=> {
  return locale.split('-')[0];
}

const getUsersLocale = (defaultValue: string = 'cs'): string => {
  if (typeof window === undefined || typeof window.navigator === undefined) {
    return defaultValue;
  }
  const wn = window.navigator as any;
  let lang = wn.languages ? wn.languages[0] : defaultValue;
  lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
  return lang;
};
