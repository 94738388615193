export interface IEnvironmentS3Settings {
  s3bucket: string,
  s3apikey: string,
  s3secret: string,
}

export interface IEnvironmentDBSettings {
  dbPw?: string,
  dbUsername?: string,
  dbName: string,
  dropDbOnBuild: boolean;
}

export interface IEnvironmentAPISettings {
  protocol: "http" | "https",
  apiUrl?: string,
  graphqlSuffix?: string,

  /**
   * Only in API apps
   */
  apiPort?: number,

  /**
   * Only in API apps
   */
  jwtSecret?: string,

  ssoLoginUrl?: string; // where do you login to SSO -> cms login path basically
}

export interface IEnvironmentKioskSettings {
  wssProtocol : "wss" | 'ws',
  //wssUrl?: string,
  wssSuffix: string,

  // since we are in kiosk settings, this should implicitly be true, but u never know
  useKioskUUIDInPath?: boolean
}


export interface IEnvironment {
  production: boolean,
  kioskSettings?: IEnvironmentKioskSettings,
  apiSettings?: IEnvironmentAPISettings,
  dbSettings?: IEnvironmentDBSettings,
  s3Settings?: IEnvironmentS3Settings
  cmsBaseUrl?: string;
}
