import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {INavigationItem} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-nav-dropdown-item',
  templateUrl: './nav-dropdown-item.component.html',
  styleUrls: ['./nav-dropdown-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavDropdownItemComponent implements OnInit {

  @Output()
  public itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public item!: INavigationItem;

  @Input()
  public offset = false;

  public activeIndex = -1;

  @HostBinding('class')
  get panelClass(): any {
    return {
      'flex': !this.offset,
    };
  }

  ngOnInit(): void {
    return;
  }

}
