
export type CookieStorageType = typeof cookieStorageArray[number];

export const cookieStorageArray = ['ad_storage','analytics_storage','functionality_storage','personalization_storage','security_storage'] as const;

export type Cookie = {
  [key in CookieStorageType]?: boolean;
}




// https://github.com/klaxit/cookie-consent/blob/master/src/cookie.js
// https://github.com/stevermeister/ngx-cookie-service/blob/master/projects/ngx-cookie-service/src/lib/cookie.service.ts
