import gql from 'graphql-tag';

export const readSelectedNoticeBoard = gql`
  query ReadSelectedNoticeBoard($ids: [String!]!) {
    response: readSelectedNoticeBoard(
      ids: $ids
    ) {
      _id
      title
      content
      slug
      summary
      categories {
        _id
        name
      }
      attachments{
        _id
        url
        thumbnailUrl
        hash
        mime
        type
      }
      featuredImage {
        _id
        name
        url
        thumbnailUrl
      }
      owner{
        _id
        name
      }
      createdAt
    }
  }
`;
