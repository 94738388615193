import {
  Component, ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {FullscreenOverlayContainer, Overlay, OverlayConfig, OverlayContainer, OverlayRef} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";
import {echoNxDebounce} from "@echo-nx/shared/common";
import {QRCode} from "jsqr";


@Component({
  selector: 'marlenka-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}]
})
export class CodeInputComponent implements OnInit {
  @ViewChild('qrCodeOverlay')
  private qrCodePortal!: TemplateRef<any>;

  @Input()
  public color = '#A37D2C';

  @Input()
  public codeMaxLength = 20;

  @Input()
  public isLoading!: boolean;

  @Input()
  public spinnerSize = 40;

  @ViewChild('inputElement')
  private inputElement!: ElementRef<HTMLInputElement>;

  @Input()
  @HostBinding('class')
  public panelClass!: string;

  @Input()
  public value = '';

  @Output()
  public valueChanged = new EventEmitter<string>();

  private overlayRef?: OverlayRef;
  private overlayConfig = new OverlayConfig({
    hasBackdrop: true,
    positionStrategy: this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically()
  })

  private readonly _debouncer!: (...args: any[]) => Promise<ReturnType<any> | null> | null;

  constructor(private overlay: Overlay, private _viewContainerRef: ViewContainerRef) {
    this._debouncer = echoNxDebounce(null, 750);
  }

  ngOnInit(): void {
    document.documentElement.style.setProperty('--color', this.color);
  }

  public async emitInput(event: Event) {
    const {value} = event.target as HTMLInputElement;

    this._debouncer()?.then(() => {
      this.valueChanged.emit(value);
    })
  }

  public openQrCodeScannerDialog() {
    this.overlayRef = this.overlay.create(this.overlayConfig);
    this.overlayRef.attach(new TemplatePortal(
      this.qrCodePortal,
      this._viewContainerRef
    ));
  }

  public closeQrCodeScannerDialog() {
    this.overlayRef?.detach();
  }

  public onQrCodeDetected(code: QRCode) {
    this.value = code.data;
    this.closeQrCodeScannerDialog();
    this._debouncer()?.then(() => {
      this.valueChanged.emit(code.data);
    })
  }

  public clearInput() {
    this.value = '';
  }

  public focusInput() {
    this.inputElement.nativeElement.focus();
  }

}
