import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GenericSliderComponent} from './generic-slider.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    GenericSliderComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    PrefixModule
  ],
  exports: [
    GenericSliderComponent
  ]
})
export class GenericSliderModule {
}
