<div class="contact" *ngIf="footerTexts$ | async as texts">
    <div class="company-name" *ngIf="contact.companyName">
        <div class="name">{{ contact.companyName }}</div>
        <div>{{ contact.firstName }} {{ contact.lastName }}</div>
    </div>
    <div class="name" *ngIf="!contact.companyName">{{ contact.firstName }} {{ contact.lastName }}</div>
    <!-- address -->
    <div *ngIf="contact.address" [innerHTML]="contact.address | address"></div>

    <!-- company stuff -->
    <div *ngIf="contact.ico">
        <div class="fx-row row">
            <strong>{{texts.ico.title}}</strong><span>: {{ contact.ico }}</span> <span *ngIf="contact.dic">, <strong>{{ texts.dic.title }}</strong></span
            ><span *ngIf="contact.dic">: {{ contact.dic }}</span>
        </div>

        <div class="fx-row row" *ngIf="contact.dataBox">
            <strong>{{texts.dataBox.title }}: </strong><span>: {{ contact.dataBox }}</span>
        </div>

        <br *ngIf="contact.bankInfo.bankName" />
        <div class="fx-row row" *ngIf="contact.bankInfo.bankName">
            <strong>{{texts.bank.title}}: </strong><span>{{ contact.bankInfo.bankName }}, {{ texts.accountNo.title }}: {{ contact.bankInfo.bankNumber }}/{{ contact.bankInfo.bankCode }}</span>
        </div>

        <div class="fx-row row" *ngIf="contact.bankInfo?.iban">
            <div>
                <strong>{{ texts.swift.title }}</strong><span>: {{ contact.bankInfo.swift }}</span>
            </div>
            <div>
                <strong>{{ texts.iban.title }}</strong><span>: {{ contact.bankInfo.iban }}</span>
            </div>
        </div>
    </div>

    <!-- emails -->
    <div *ngIf="contact.emails?.length > 0">
        <span *ngFor="let mail of contact.emails; let last = last">
            <strong *ngIf="mail?.length > 0">{{texts.email.title}}:</strong>
            <a href="mailto:{{ mail }}"> {{ mail }}</a>
            <span *ngIf="!last"><br /></span>
        </span>
    </div>

    <!-- telephones -->
    <div *ngIf="contact.telephones?.length > 0">
        <span *ngFor="let tel of contact.telephones; let last = last">
            <strong *ngIf="tel?.length > 0">{{ texts.tel.title }}:</strong>
            <a href="tel:{{ tel }}"> {{ tel }}</a>
            <span *ngIf="!last"><br /></span>
        </span>
    </div>

    <!-- web -->
    <div *ngIf="contact.websites?.length > 0">
        <span *ngFor="let web of contact.websites; let last = last">
            <strong *ngIf="web?.length > 0">{{ texts.web.title }}:</strong>
            <a target="_blank" href="{{ web | prefixHttp }}"> {{ web }}</a>
            <span *ngIf="!last"><br /></span>
        </span>
    </div>

    <div *ngIf="contact.tradeRegisterText" style="margin-top: 1rem;">
        {{ contact.tradeRegisterText }}
    </div>

    <hr />
</div>
