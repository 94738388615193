/*export interface EpxWSMessage extends MessageEvent{
  data: string | EpxWSMessagePayload
}*/

export interface EpxWSMessagePayload<T = any> {
  cmd: EpxWSCommands | string;
  type: EpxWSMessageType;
  data?: T;
}

export enum EpxWSMessageType{
  CMD = "CMD", //
  LOG = "LOG", //JUST FOR DASHBOARD UPDATE
  ACCEPT = "ACCEPT", //MSG Delivered
  SUCCESS = "SUCCESS", //CMD Successful
  ERROR = "ERROR" //CMD Failed
}

export enum EpxWSCommands{
  //logging
  LogOffline = "LOG-OFFLINE",
  LogOnline = "LOG-ONLINE",
  LogTimeout = "LOG-TIMEOUT",
  LogNavigate = "LOG-NAV",
  LogAllForAdmin = "LOG-ALL-ADMIN", // send entire current status for admin dashboard

  //REG
  RegisterAsManager = "REG-MNGR", // python or twa manager
  RegisterAsAdmin = "REG-ADMIN", // admin web
  RegisterAsWeb = "REG-WEB",    // classic web

  //WEB COMMANDS
  WebSynchronizeCmd = "WEB-SYNC",
  WebReinitializeCmd = "WEB-REINITDB",
  WebUpgradeAppCmd = "WEB-UPGRADE",
  WebNavigate = "WEB-NAVIGATE",
  GetCurrentRoute = "WEB-GET-ROUTE",
  ClickWidget = "WEB-CLICK-WIDGET",

  //PYTHON CMDS
  TakeScreenshot = "PY-TAKE-SCREENSHOT",
  Reboot = "PY-REBOOT",
  Shutdown = "PY-SHUTDOWN",
  GetWakeupTime = "PY-GET-WAKETIME",
  SetWakeupTime = "PY-SET-WAKETIME",
  RefreshBrowser = "PY-REFRESH-BROWSER",
  ConnectVPN = "PY-VPN-CONNECT",
  ClearStore = "PY-CLEAR-STORE",
  RestartApp = "PY-RESTART-APP",
}
