<div class="timeline-article" [ngClass]="{reverse: flipSides}">
    <div class="left">
        <ng-content select="[left]"></ng-content>
    </div>

    <div class="circle"></div>

    <div class="right">
        <ng-content select="[right]"></ng-content>
    </div>

</div>