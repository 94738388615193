import {BaseWidgetSettings} from "./base-settings.type";
import {ContentfulSettings} from "./contentful-settings.type";
import {IEpxIcon} from "../../structural";



export type NavigationWidgetSettings = {
  navigationType: 'side' | 'hamburger';
  /**
   * If you want to render only given subtree.
   */
  subtreeId?: string;
  sideNavigationSettings?: SideNavigationSettings;
} & BaseWidgetSettings & ContentfulSettings;

export interface SideNavigationSettings {
  hideTitle?: boolean;
  startIcon?: IEpxIcon;
  titleColor?: string;
  textColor?: string;
  hoverColor?: string;
  dividerColor?: string;
  divideSize?: 'small' | 'medium' | 'large';
}
