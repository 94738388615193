import {Component, Injector} from '@angular/core';
import {MarlenkaBasePageComponent} from "../marlenka-base-page/marlenka-base-page.component";
import {ArticlesPageTexts, IMarlenkaOwner, MarlenkaPageIdentifier} from "@echo-nx/marlenka/common";
import {combineLatest, Observable, of} from "rxjs";
import {IArticle, ICategory, IMedium, IPaginatedResponse} from "@echo-nx/shared/common";
import {ActivatedRoute} from "@angular/router";
import {ArticleService} from "../../services/article.service";
import {NavigationService} from "../../services/navigation.service";
import {filter, map, switchMap, takeUntil} from "rxjs/operators";

@Component({
  selector: 'marlenka-app-articles-reviews-page',
  templateUrl: './articles-reviews-page.component.html',
  styleUrls: ['./articles-reviews-page.component.scss']
})
export class ArticlesReviewsPageComponent extends MarlenkaBasePageComponent<ArticlesPageTexts> {

  public articles$: Observable<IPaginatedResponse<IArticle>>;
  public pageNumber$: Observable<number>;
  public take = 10;

  constructor(injector: Injector, private activatedRoute: ActivatedRoute, private blogService: ArticleService, private navigationService: NavigationService) {
    super(injector);
    this.pageNumber$ = activatedRoute.queryParams.pipe(
      takeUntil(this.isDestroyed$),
      map(params => parseInt(params.page as string, 10) || 1)
    );


    this.articles$ = combineLatest([this.pageNumber$, this.mrdService.currentLanguage$]).pipe(
      takeUntil(this.isDestroyed$),
      switchMap(([pageNumber, language]) => {
        return this.pageSettingsService.getPageSettingsByIdentifier(MarlenkaPageIdentifier.HOMEPAGE, language).pipe(
          map(({reviewsArticleCategory}) => (reviewsArticleCategory as ICategory)?._id),
          filter(categoryId => !!categoryId),
          switchMap(categoryId => this.blogService.fetchAll({
            filter: JSON.stringify({categories: {$in: [categoryId]}}),
            page: pageNumber - 1,
            take: this.take,
            sortDirection: 'DESC',
            sortColumn: 'publishedAt'
          }) )
        )
      })
    );
  }

}
