//ARTICLE = warehouse item, its just generic type

import {IBaseOwner, IOwnedEntity} from "@echo-nx/shared/common";

export interface IMDialogPricetable<T extends IBaseOwner | string = any> extends IOwnedEntity<T>{
  articleCode: string;
  currency: string;
  price: number;
  status: number;
  validFrom: Date;
  validTo: Date;
}

