import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  IMarlenkaOwner,
  IMReservationVoucher,
  MarlenkaEntityTypes,
  readAllReservations,
  readSelectedReservations
} from "@echo-nx/marlenka/common";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";

@Injectable()
export class BaseVoucherService implements IEntityService<IMReservationVoucher>{

  constructor(protected apollo: Apollo,) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMReservationVoucher>> {
    const variables = {...args};
    const internalFilters = {event: {"$exists": false}};
    if(args?.filter){
      variables.filter = JSON.stringify({
        $and: [
          JSON.parse(args.filter),
          internalFilters
        ]
      });
    } else {
      variables.filter = JSON.stringify(internalFilters);
    }
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMReservationVoucher>>>({
      query: readAllReservations,
      variables
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[], includeDeleted = false): Observable<IMReservationVoucher[]> {
    return this.apollo.query<IPKApolloResult<IMReservationVoucher[]>>({
      query: readSelectedReservations,
      variables: {
        ids: ids,
        includeDeleted
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string, includeDeleted = false): Observable<IMReservationVoucher> {
    return this.apollo.query<IPKApolloResult<IMReservationVoucher[]>>({
      query: readSelectedReservations,
      variables: {
        ids: [id],
        includeDeleted,
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(reservations: IMReservationVoucher<string, string>[]): Observable<MutationResult<IPKApolloResult<IMReservationVoucher[]>>> {
    throw Error('NOT IMPLEMENTED :(');

    /*return this.apollo.mutate<IPKApolloResult<IMReservationVoucher[]>>({
      mutation: addReservation,
      variables: {input: reservations}
    });*/
  }

  executeMutation<T extends IMReservationVoucher[] | IMReservationVoucher>(mutation: any, vars: any) {
    return this.apollo.mutate<IPKApolloResult<T>>({
      mutation: mutation,
      variables: vars
    });
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Reservations;
  }

}

export const MVOUCHER_SERVICE_TOKEN = new InjectionToken<IEntityService<IMReservationVoucher>>('MVOUCHER_SERVICE_TOKEN');
