// widget can be contentful and therefore needs a definitions about the content
import {FetchAllArgs} from "../../i-fetch-all-args";


export type ContentfulSettings = {
  content: ContentSettings; // deprecate in favor of fetchArgs
  mapping: string;
}

export type ContentSettings = {
  storeName?: string;
  fetchArgs?: FetchAllArgs;
}
