import gql from 'graphql-tag';

export const readSelectedPointsOfInterest = gql`
  query ReadSelectedPointsOfInterest($ids: [String!]!) {
    response: readSelectedPOIItem(
      ids: $ids
    ) {
      _id
      name
      content
      description
      categories {
        _id
        name

      }
      location{
        lat
        lng
      }

      media{
        _id
        url
        thumbnailUrl
        hash
        mime
        type
      }
      featuredImage {
        _id
        name
        url
        thumbnailUrl
      }
      owner{
        _id
        name
      }
      createdAt
    }
  }
`;
