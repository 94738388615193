<ng-container *ngIf="(pageSettings$ | async)?.texts as pageTexts">
  <marlenka-jumbotron
    [title]="pageTexts.jumbotron.title"
    [subtitle]="
      pageTexts.jumbotron.subtitle
    "
    [topWave]="true"
    [dividerWave]="true"
    background="#FFF6E6"
    id="career-jumbotron"
  >
    <div class="wrapper container small">
      <div
        class="fx-row"
        *ngFor="let career of careers$ | async; let i = index"
        [class.alternate-cols]="i % 2"
      >
        <div class="col">
          <img
            [src]="career.featuredImage.url"
            [alt]="career.featuredImage.description"
          />
        </div>
        <div class="col">
          <time>
            <span>
              {{ career.createdAt | date: 'dd. MM. yyy | HH:mm' }}
            </span>
          </time>
          <h3>{{ career.title }}</h3>
          <div
            class="career-content"
            [innerHTML]="career.content"
          ></div>
        </div>
      </div>
    </div>
  </marlenka-jumbotron>
</ng-container>
