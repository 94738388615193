import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {IAuthResponse, IAuthToken, urlBase64Decode} from "@echo-nx/shared/common";
import {UrlSettings} from "../utils";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class BaseAuthService<T = IAuthToken> {
  private _loginErrorMessage: Subject<string>;
  public loginErrorMessage$: Observable<string>;

  private _token: BehaviorSubject<T | null>;
  public token$: Observable<T | null>;

  protected tokenKey = 'token';
  protected refreshTokenKey = 'refresh_token';

  constructor(protected http: HttpClient, protected urlSettings: UrlSettings) {
    // console.log('BASE AUTH SERVICE CREATED');
    // maybe we are just refreshing! seek the default data
    const defaultData = this.getTokenData();

    this._token = new BehaviorSubject(defaultData);
    this.token$ = this._token.asObservable();

    this._loginErrorMessage = new Subject();
    this.loginErrorMessage$ = this._loginErrorMessage.asObservable();
  }

  protected set token(token: T | null) {
    this._token.next(token);
  }

  protected set loginErrorMessage(message: string) {
    this._loginErrorMessage.next(message);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  protected async processAuthResponse(response?: IAuthResponse): Promise<T> {
    if (response?.success && response?.data) {
      const {token, refresh_token} = response.data;
      // console.log('AUTH TOKEN', token);
      const decodedToken = this.createSessionAndDecode(token, refresh_token);

      // call the hookerino
      await this.onAuthResponseSuccess(decodedToken, token);
      return decodedToken;
    } else {
      const errorMessage = response?.message ?? 'Neznámá chyba, odpoveď je prázná.';
      this.loginErrorMessage = errorMessage;
      throw new Error(errorMessage);
    }
  }

  protected async onAuthResponseSuccess(decodedToken: T, token: string) {//abstract
  }

  public tryToRefresh(token: string, refresh_token: string) {
    console.log('try to refresh NOT IMPLEMENTED');
  }

  refresh(token: string, refresh_token: string) {
    return this.http.post<{ data?: { refresh: IAuthResponse } }>(this.urlSettings.fullGraphqlPath, {
      operationName: 'Refresh',
      query: 'query Refresh($token: String!, $refresh_token: String!){refresh(token: $token, refresh_token: $refresh_token) {success message data {token refresh_token}}}',
      variables: {token, refresh_token}
    })
      .toPromise()
      .then(res => res?.data?.refresh)
      .then(data => {
        if (data) {
          this.processAuthResponse(data);
        } else {
          console.log('Refresh query did not yield any refresh data', data);
        }
        return data;
      });
  }

  isLoggedIn() {
    return !this.isTokenExpired();
  }

  hasValidRefreshToken() {
    const refreshToken = this.getRefreshToken();

    return refreshToken ? !this.isTokenExpired(refreshToken) : !this.isTokenExpired();
  }

  public decodeToken(token: string | null = this.getToken()): T | null {
    if (token == null || token === '') {
      return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('Špatný formát JWT');
    }

    const decoded = urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Nečitelný token');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string | null = this.getToken()): Date | null {
    const decoded = this.decodeToken(token);

    // decoded.hasOwnProperty('exp')
    if (!decoded || !Object.keys(decoded).find(k => k === 'exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds((decoded as any).exp);

    return date;
  }

  public isTokenExpired(token: string | null = this.getToken(), offsetSeconds?: number): boolean {
    if (token == null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    //console.log('Expires at', date);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }


  public getTokenData(token?: any): T | null {
    if (!token) {
      token = this.getToken();
    }
    return this.decodeToken(token);
  }

  createSessionAndDecode(token: string, refresh_token?: string) {
    // set to storage
    localStorage.setItem(this.tokenKey, token);
    if (refresh_token) {
      localStorage.setItem(this.refreshTokenKey, refresh_token);
    }

    // decode and return
    const decoded = this.decodeToken(token);
    if (decoded) {
      // console.log('setting decoded token', decoded);
      this.token = decoded;
    } else {
      throw new Error(`Decoded token was null ${decoded}`);
    }
    return decoded;
  }

  getSession() {
    return {
      token: localStorage.getItem(this.tokenKey),
      refresh_token: localStorage.getItem(this.refreshTokenKey)
    };
  }

  async logout(): Promise<void> {
    // console.log("Cleaning session", localStorage);
    this.token = null;
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshTokenKey);

    // todo clear refresh token from DB
  }
}
