import {Injectable, InjectionToken} from "@angular/core";
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {IMarlenkaOwner, MarlenkaEntityTypes, readAllRoles} from "@echo-nx/marlenka/common";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
  IRole
} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";



@Injectable()
export class BaseRoleService implements IEntityService<IRole<IMarlenkaOwner>> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IRole<IMarlenkaOwner>>> {
    console.log(args);
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IRole<IMarlenkaOwner>>>>({
      query: readAllRoles,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
    /*return this.apollo.query<IPKApolloResult<IRole<IOwner>[]>>({
      query: readSelectedMAllergens,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )*/
  }

  fetchSingle(id: string): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
    /*return this.apollo.query<IPKApolloResult<IRole<IOwner>>>({
      query: readSelectedMAllergens,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )*/
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(allergen: IRole<IMarlenkaOwner>[]): Observable<MutationResult<IPKApolloResult<IRole<IMarlenkaOwner>[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Allergens;
  }

}

export const MROLE_SERVICE_TOKEN = new InjectionToken<IEntityService<IRole<IMarlenkaOwner>>>('MROLE_SERVICE_TOKEN');
