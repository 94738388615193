<span *ngIf="currentDefinition">
    <span class='icon' *ngIf="currentDefinition.iconName; else circle">
        <mat-icon
          matListIcon
          [svgIcon]="currentDefinition.iconName"
          [style.color]="currentDefinition.color">
        </mat-icon>
    </span>
</span>

<div>{{currentDefinition.textToShow}}</div>

<ng-template #circle>
  <span class="circle-status" [style.background]="currentDefinition.color"></span>
</ng-template>
