import {ActivatedRoute, Data, Params, UrlSegment} from "@angular/router";

export const getReturnUrl = (urlSegments: UrlSegment[] | string[]): string => {
  // dont mess with the original array
  const urls = [...urlSegments];

  // remove last element
  urls.splice(urlSegments.length - 1, 1)

  // return the path or root if empty
  return urls.join('/') || '/';
}


export const getAllRouteData = <T extends Data = any>(activatedRoute: ActivatedRoute): T => {
  let route = activatedRoute;
  while (route.parent) {
    route = route.parent;
  }

  let data = activatedRoute.snapshot?.data ?? {};
  // Traverse over the state tree to find the most nested data.
  while (route.firstChild) {
    const child = route.firstChild;
    // The idea is that the children merge with parent data.
    // For example parent can specify tabs for all children, but children can change actions
    data = {
      ...data,
      ...child.snapshot?.data ?? {}
    }

    //traverse deeper
    route = route.firstChild;
  }
  return data as T;
}

export const getAllRouteParams = <T extends Params = any>(activatedRoute: ActivatedRoute): T => {
  let route = activatedRoute;
  while (route.parent) {
    route = route.parent;
  }

  let params = activatedRoute.snapshot?.params ?? {};
  // Traverse over the state tree to find the most nested data.
  while (route.firstChild) {
    const child = route.firstChild;
    // The idea is that the children merge with parent data.
    // For example parent can specify tabs for all children, but children can change actions
    params = {
      ...params,
      ...child.snapshot?.params ?? {}
    }

    //traverse deeper
    route = route.firstChild;
  }
  return params as T;

}
