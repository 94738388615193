import {Component, Inject, Injector, OnInit, Optional} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {ICheckboxFieldSettings} from "./i-checkbox-field.settings";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss']
})
export class CheckboxFieldComponent extends BaseFormFieldComponent<ICheckboxFieldSettings> implements OnInit{

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<ICheckboxFieldSettings>,formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
/*    const {defaultValue} = this.settings;
    this.formControl.setValue(defaultValue);*/
  }
}
