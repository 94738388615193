import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {IMNavigation, IMSubnavigationItem, MarlenkaRouteData, readMainNavigation} from "@echo-nx/marlenka/common";
import {IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {BaseNavigationService} from "@echo-nx/marlenka/ng/data-access";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Injectable({
  providedIn: 'root'
})
export class NavigationService extends BaseNavigationService {

  private _subnavigationItems: BehaviorSubject<IMSubnavigationItem[] | undefined> = new BehaviorSubject<IMSubnavigationItem[] | undefined>(undefined);
  public subnavigationItems$: Observable<IMSubnavigationItem[] | undefined> = this._subnavigationItems.asObservable();

  constructor(apollo: Apollo, protected mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
    super(apollo, mrdService);
  }

  public fetchMainNavigation(): Observable<IMNavigation> {
    return this.mrdService.currentLanguage$.pipe(switchMap((language) => this.apollo.query<IPKApolloResult<IPaginatedResponse<IMNavigation>>>({
      query: readMainNavigation,
      variables: {
        filter: JSON.stringify({language, "page": null})
      }
    }).pipe(
      tap(res=> console.log('main Nav', res)),
      map(res => res.data.response.items[0])
    )));
  }

  public setSubnavigationItems(items: IMSubnavigationItem[] | undefined) {
    this._subnavigationItems.next(items);
  }
}
