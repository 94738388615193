<div class="flex flex-1 p-4"
     [class.space-x-4]="!isVertical"
     [class.space-y-4]="isVertical"
     [ngClass]="[baseOrientation ? ('flex-' + baseOrientation) : '', primaryColor | prefix: 'bg']">

  <!-- IMAGE< DATE-->
  <div *ngIf="image"
       [ngClass]="[isVertical ? 'h-64' : 'basis-1/3']">
    <!-- image   -->
    <img class="object-cover w-full h-full" [src]="image.thumbnailUrl ?? image.url"/>

  </div>


  <!-- CONTENT -->
  <div class="flex flex-col space-y-4" [ngClass]="[!isVertical && image ? 'basis-2/3': '']">
    <!-- categories -->
    <div *ngIf="categories" class="flex text-center space-y-2 space-x-0 sm:space-y-0 sm:space-x-2 flex-col sm:flex-row">
      <div class="flex"
           *ngFor="let cat of categories">
        <div class="flex p-2 justify-center items-center text-white" [ngClass]="[cat.color | prefix:'bg']">
          <i *ngIf="cat.icon" class="fa-fw" [ngClass]="[cat?.icon]"></i>
        </div>
        <span class="px-4 py-1 bg-slate-200 font-medium text-base"
              [ngClass]="[cat.color | prefix:'text']">{{cat.name}}</span>
      </div>

    </div>

    <!-- date, title, attributes -->
    <div class="flex flex-col space-y-2 px-4 py-2 border-l-8 items-start"
         [ngClass]="[secondaryColor | prefix: 'border', secondaryColor | prefix: 'text']">
      <!-- date -->
      <div class="font-medium text-sm"
           *ngIf="date"
           [ngClass]="[dateText?.bgColor | prefix:'bg', dateText?.color | prefix:'text']">
        {{date}}
      </div>

      <!-- title -->
      <div class="text-2xl font-medium font-display"
           [ngClass]="[titleText?.color | prefix:'text']">
        {{title}}
      </div>

      <!-- attributes -->
      <div *ngIf="attributes"
           [class.flex-col]="isVertical"
           [class.space-y-2]="isVertical"
           [class.flex-row]="!isVertical"
           [class.space-x-4]="!isVertical"
           class="flex">
        <div class="space-x-2 flex items-center font-base text-sm font-medium"
             *ngFor="let attribute of attributes">
          <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
          <span>{{attribute.text}}</span>
          <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
        </div>
      </div>
    </div>

    <!-- perex -->
    <div *ngIf="perex" class="font-base" [innerHTML]="perex"></div>

    <!-- footer -->
    <div [ngClass]="footerText?.bgColor | prefix: 'bg'" class="mt-3 space-x-2 flex items-center text-gray-700"
         *ngIf="footer">
      <i [ngClass]="[footerText?.icon ?? '', footerText?.color | prefix: 'text']"></i>
      <span class="font font-medium text-sm underline">{{footer}}</span>
    </div>
  </div>
</div>
