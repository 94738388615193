import gql from 'graphql-tag';

export const readAllThemes = gql`
  query readAllThemes($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllTheme(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        name
        createdAt
        owner{
          _id
          name
        }
      }
    }
  }
`;
