import {
  addMAllergens,
  deleteAllergen,
  IMAllergens,
  IMarlenkaOwner,
  MarlenkaEntityTypes, readAllergensLanguageGroups, readAllMAllergens, readSelectedMAllergens
} from "@echo-nx/marlenka/common";
import {Injectable, InjectionToken} from "@angular/core";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {Apollo, MutationResult} from "apollo-angular";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";

@Injectable()
export class BaseAllergensService implements IEntityService<IMAllergens> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMAllergens>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMAllergens>>>({
      query: readAllMAllergens,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMAllergens>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMAllergens>>>({
      query: readAllergensLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMAllergens[]> {
    return this.apollo.query<IPKApolloResult<IMAllergens[]>>({
      query: readSelectedMAllergens,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IMAllergens> {
    return this.fetchSelected([id]).pipe(map((items) => items[0]));
  }

  getType(): string {
    return MarlenkaEntityTypes.Allergens;
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteAllergen,
      variables: {input: ids}
    });
  }

  save(allergen: IMAllergens[]): Observable<MutationResult<IPKApolloResult<IMAllergens[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMAllergens[]>>({
      mutation: addMAllergens,
      variables: {input: allergen}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(language: string): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        language,
        type: MarlenkaEntityTypes.Allergens,
      }
    }).pipe(
      map(result => result.data.response)
    )
  }
}

export const MALLERGENS_SERVICE_TOKEN = new InjectionToken<IEntityService<IMAllergens>>('MALLERGENS_SERVICE_TOKEN');
