import {IMarlenkaOwner, IMOwnerSettings, readOwnerSettings, readOwnerSettingsFrontend} from "@echo-nx/marlenka/common";
import {Injectable, InjectionToken} from "@angular/core";
import {Apollo, MutationResult} from "apollo-angular";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";


@Injectable()
export class BaseOwnerService implements IEntityService<IMarlenkaOwner> {

  constructor(protected apollo: Apollo,) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMarlenkaOwner>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchSelected(ids: string[]): Observable<IMarlenkaOwner[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchSingle(id: string): Observable<IMarlenkaOwner> {
    throw Error('NOT IMPLEMENTED :(');
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(events: IMarlenkaOwner[] | IMOwnerSettings[]): Observable<MutationResult<IPKApolloResult<IMarlenkaOwner[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return of([]);
  }

  fetchSettings(): Observable<IMOwnerSettings> {
    return this.apollo.query<IPKApolloResult<IMOwnerSettings>>({
      query: readOwnerSettings
    }).pipe(
      map(res => res.data.response)
    )
  }

  fetchSettingsForFrontend(): Observable<IMOwnerSettings>{
    return this.apollo.query<IPKApolloResult<IMOwnerSettings>>({
      query: readOwnerSettingsFrontend
    }).pipe(
      map(res => res.data.response)
    )
  }

  getType(): string {
    return '';
  }

}

export const MOWNER_SERVICE_TOKEN = new InjectionToken<IEntityService<IMarlenkaOwner>>('MOWNER_SERVICE_TOKEN');
