<img src="/assets/gf-logo.svg" alt="Gluten Free" />

<div class="text">
  <div class="wrapper">
    <div class="title">Potřebujete připravit bezlepkovou ochutnávku?</div>
    <div class="subtitle">Bezlepková příprava je v ceně vstupného.</div>
    <mat-radio-group
      aria-labelledby="gluten-free-choice"
      [value]="reservationService.requiresGlutenFree"
      (change)="glutenFreeChange($event)"
    >
      <mat-radio-button [value]="false">Ne</mat-radio-button>
      <mat-radio-button [value]="true">Ano</mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="control">
  <div *ngIf="reservationService.requiresGlutenFree" class="wrapper">
    <div class="title">Kolik bezlepkových ochutnávek máme připravit?</div>
    <echo-nx-counter
      [value]="reservationService.glutenFreeCount"
      (add)="addGFPeople()"
      (remove)="removeGFPeople()"
    ></echo-nx-counter>
  </div>
</div>
