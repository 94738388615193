import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseButtonData, IBaseButtonDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";
import {WithBadge} from "./with-badge.type";


export type ButtonWidgetSettings<D extends IBaseButtonDesign = IBaseButtonDesign> = {
  variant?: ComponentVariant;
  buttonData?: IBaseButtonData;
  buttonDesign?: D;
  targetWidget?: string; //  napr. pokud chceme badge s aktivnich filtru, musime targetovat spravny filtr
} & BaseWidgetSettings & WithBadge;
