import { AfterViewInit, Component, ElementRef, Inject, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';
import * as Marzipano from 'marzipano';
import { Viewer, Scene } from 'marzipano';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { VirtualTourPointDetailComponent } from '../../components/virtual-tour/virtual-tour-point-detail/virtual-tour-point-detail.component';
import {IVTScene} from "@echo-nx/shared/common";
import {BASE_VIRTUAL_TOUR_SCENE_SERVICE_TOKEN, BaseVirtualTourSceneService} from "@echo-nx/shared/ng/data-access";



@Component({
  selector: 'marlenka-virtual-tour-page',
  templateUrl: './virtual-tour-page.component.html',
  styleUrls: ['./virtual-tour-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VirtualTourPageComponent implements AfterViewInit {

  @ViewChild('pano') pano: ElementRef;

  public scenes: SceneContainer[] = [];
  marzipanoViewer: Viewer;
  public currentSceneIdx = 0;
  public loading = true;

  private marlenkaBucketUrl = 'https://marlenka.s3.eu-central-1.amazonaws.com/tour';

  get currentSceneContainer(): SceneContainer {
    return this.scenes[this.currentSceneIdx];
  }

  constructor(
    @Inject(BASE_VIRTUAL_TOUR_SCENE_SERVICE_TOKEN) protected service: BaseVirtualTourSceneService,
    protected renderer: Renderer2,
    private matDialog: MatDialog
  ) {
  }

  linear(val) { return val; }

  opacity = (ease) => {
    ease = ease || this.linear;
    return (val, newScene) => {
      val = ease(val);
      newScene.layer().setEffects({ opacity: val });
    };
  };

  initMarzipanoViewer() {
    const panoElement = this.pano.nativeElement;
    const viewerOpts = {
      controls: {
        mouseViewMode: 'drag'
      }
    };

    this.marzipanoViewer = new Marzipano.Viewer(panoElement, viewerOpts);
  }

  createMarzipanoPointsInCurrentScene(mScene: Scene, dbScene: IVTScene){
    const hotspots = mScene.hotspotContainer().listHotspots();
    for(const hotspot of hotspots){
      mScene.hotspotContainer().removeHotspot(hotspot);
    }
    // Parse all hotspots of each scene
    for (const dbPoint of dbScene.points) {
      // hotspot creation
      // create icon wrapper
      const hotspot = this.renderer.createElement('div');
      this.renderer.addClass(hotspot, 'hotspot');

      // create item with icon
      const item = this.renderer.createElement('div');
      this.renderer.addClass(item, 'item');
      this.renderer.appendChild(hotspot, item);

      const matIcon = this.renderer.createElement('mat-icon');
      this.renderer.appendChild(matIcon, this.renderer.createText(dbPoint.icon));
      this.renderer.addClass(matIcon, 'mat-icon');
      this.renderer.addClass(matIcon, 'material-icons');
      this.renderer.appendChild(item, matIcon);

      // create tooltip
      const tooltipWrapper = this.renderer.createElement('span');
      this.renderer.addClass(tooltipWrapper, 'marlenka-tooltip-wrapper');
      this.renderer.appendChild(item, tooltipWrapper);

      const tooltip = this.renderer.createElement('span');
      this.renderer.addClass(tooltip, 'marlenka-tooltip');
      this.renderer.appendChild(tooltip, this.renderer.createText(dbPoint.title));
      this.renderer.appendChild(tooltipWrapper, tooltip);

      /* Absolute positioning read from DB */
      const hotspotPosition = { yaw: dbPoint.location.yaw, pitch: dbPoint.location.pitch };

      /* On Click */
      if (dbPoint.scene) {
        // navigate to another scene
        hotspot.addEventListener('click', () => {
          const target = this.scenes.findIndex((scene) => scene.dbScene._id === dbPoint.scene);
          if (target >= 0) {
            this.currentSceneIdx = target;
            console.log('should orientate',dbPoint.orientation, dbPoint);
            this.setCurrentScene(dbPoint.orientation);
          } else {
            console.error('Unable to find target scene', dbPoint.scene);
          }
        });
      } else {
        // Show dialog with content
        hotspot.addEventListener('click', () => {
          // todo open dialog with content;
          this.matDialog.open(VirtualTourPointDetailComponent, { data: dbPoint });

        });
      }

      /* Create Hotspot */
      mScene.hotspotContainer().createHotspot(hotspot, hotspotPosition);
    }
  }


  async createMarzipanoScenesFromApi(geometry: any) {
    const allScenes = await this.service.fetchAll().pipe(map(res => res.items)).toPromise();
    this.pano.nativeElement.addEventListener('click', (e) => {
      console.log('Clicked Point',this.marzipanoViewer.view().screenToCoordinates({x : e.clientX, y: e.clientY}));
      console.log('current ZOOM', this.marzipanoViewer.view().fov());
    });
    // Parse all available scenes
    for (const dbScene of allScenes) {
      const limiter = Marzipano.RectilinearView.limit.traditional(dbScene.faceSize, 100*Math.PI/180, 120*Math.PI/180);
      const view = new Marzipano.RectilinearView(dbScene.initialPosition, limiter);
      const mScene: Scene = this.marzipanoViewer.createScene({
        source: Marzipano.ImageUrlSource.fromString(
          this.marlenkaBucketUrl + '/' + dbScene.prefix + '/{z}/{f}/{y}/{x}.jpg',
          { cubeMapPreviewUrl: this.marlenkaBucketUrl  + '/' + dbScene.prefix + '/preview.jpg' }),
        geometry,
        view,
        pinFirstLevel: true
      });

      this.createMarzipanoPointsInCurrentScene(mScene,dbScene);

      this.scenes.push({
        dbScene,
        mScene
      });
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.initMarzipanoViewer();

    const geometry = new Marzipano.CubeGeometry([
      {
        tileSize: 256,
        size: 256,
        fallbackOnly: true
      },
      {
        tileSize: 512,
        size: 512
      },
      {
        tileSize: 512,
        size: 1024
      },
      {
        tileSize: 512,
        size: 2048
      },
      {
        tileSize: 512,
        size: 4096
      }
    ]);
    await this.createMarzipanoScenesFromApi(geometry);

    if (this.scenes.length) {
      this.setCurrentScene();
    }
  }

  public nextScene() {
    this.currentSceneIdx = mod(this.currentSceneIdx + 1, this.scenes.length);
    this.setCurrentScene();
  }

  public previousScene() {
    this.currentSceneIdx = mod(this.currentSceneIdx - 1, this.scenes.length);
    this.setCurrentScene();
  }

  public onSceneSelected(event?: MatSelectChange) {
    // ngModel takes care of currentSceneIdx
    console.log(event);
    this.setCurrentScene();
  }

  private setCurrentScene(orientation?) {
    //this.loading = true;
    if(orientation) {
      const view = this.scenes[this.currentSceneIdx]?.mScene.view();
      view.setYaw(orientation.yaw);
      view.setPitch(orientation.pitch);
      view.setFov(orientation.fov);
    }
    this.scenes[this.currentSceneIdx]?.mScene.switchTo({
        transitionDuration: 750},
      () => {
        this.loading = false;
        //this.createMarzipanoPointsInCurrentScene(this.scenes[this.currentSceneIdx]?.mScene,this.scenes[this.currentSceneIdx]?.dbScene);
      } );
  }
}

interface SceneContainer {
  mScene: Scene;
  dbScene: IVTScene;
}

const mod = (n: number, m: number) => ((n % m) + m) % m;

