export enum SupportedPayGates {
  CSOB = "CSOB",
  ComGate = "ComGate"
}

export enum ComGateState {
  PENDING = "Založena",
  PAID = "Zaplacena",
  CANCELLED = "Zrušena",
  AUTHORIZED = "Předautorizována"
}

export enum CMSTransactionState {
  TransactionInitiated = "K zaplacení",
  TransactionError = "Neúspěšná platba", // jakýkoliv error, zamítnuto - stále je k zaplacení
  TransactionSuccessful = "Úspěšná platba", // úspěšně dokončeno
  TransactionCanceled = "Zrušená platba", // vráceno / komplet zrušeno - není k zaplacení
  TransactionTimeout = "Platba vypršela",
  TransactionReversed = "Platba vrácena",
  TransactionNoGateway = "Bez platební brány"
}

//https://github.com/csob/paymentgateway/wiki/Vol%C3%A1n%C3%AD-rozhran%C3%AD-eAPI#%C4%8C%C3%ADseln%C3%ADk-n%C3%A1vratov%C3%BDch-k%C3%B3d%C5%AF-
export enum CSOBReturnCode {
  "OK" = 0,
  "Missing parameter" = 100,
  "Invalid parameter" = 110,
  "Merchant blocked" = 120,
  "Session expired" = 130,
  "Payment not found" = 140,
  "Payment not in valid state" = 150,
  "Payment method disabled" = 160,
  "Payment method unavailable" = 170,
  "Operation not allowed" = 180,
  "Payment method error" = 190,
  "EET Rejected" = 500,
  "Internal error" = 900
}

export enum CSOBTransactionState {
  "Platba založena" = 1,
  "Platba probíhá" = 2,
  "Platba zrušena" = 3,
  "Platba potvrzena" = 4,
  "Platba odvolána" = 5,
  "Platba zamítnuta" = 6,
  "Čeká na zúčtování" = 7,
  "Platba zúčtována" = 8,
  "Zpracování vrácení" = 9,
  "Platba vrácena" = 10
}

