<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup">
  <mat-label>{{ settings.nazev }}</mat-label>
  <input
    matInput
    [matAutocomplete]="auto"
    [formControlName]="settings.formControlName"
    [required]="settings.povinnost"
    type="text"
  >

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="settings.displayWith">
    <mat-option *ngFor="let item of filteredData$ | async" [value]="item">
      <span>{{ item[labelKey] }}</span>
    </mat-option>
  </mat-autocomplete>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <mat-error *ngIf="formGroup.get(settings.formControlName)?.invalid">Toto pole je povinné!</mat-error>
</mat-form-field>
