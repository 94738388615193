import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { BoxSides } from '@echo-nx/shared/common';

@Directive({
  selector: '[echoNxPaddingDefinition]',
})
export class PaddingDefinitionDirective implements AfterViewInit {
  @Input() paddingDefinition!: BoxSides<string>;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.paddingDefinition) {
      if (
        this.paddingDefinition.bottom &&
        this.paddingDefinition.left &&
        this.paddingDefinition.right &&
        this.paddingDefinition.top
      ) {
        this.el.nativeElement.style.padding =
          this.paddingDefinition.top +
          ' ' +
          this.paddingDefinition.right +
          ' ' +
          this.paddingDefinition.bottom +
          ' ' +
          this.paddingDefinition.left;
      } else {
        this.el.nativeElement.style.paddingTop = this.paddingDefinition.top;
        this.el.nativeElement.style.paddingLeft = this.paddingDefinition.left;
        this.el.nativeElement.style.paddingRight = this.paddingDefinition.right;
        this.el.nativeElement.style.paddingBottom =
          this.paddingDefinition.bottom;
      }
    }
  }
}
