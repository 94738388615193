import gql from 'graphql-tag';

export const readAllContacts = gql`
  query ReadAllContacts($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllContact(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore

      items{
        _id
        title
        name
        emails
        telephones
        profession
        avatar{
          url
          type
          mime
        }
        firstName
        lastName
        owner{
          _id
          name
        }
        createdAt
      }
    }
  }
`
