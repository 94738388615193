import {Pipe, PipeTransform} from '@angular/core';
import {marlenkaRoutes} from "../modules/routing/routing.module";
import {MarlenkaPageIdentifier, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";

@Pipe({
  name: 'marlenkaRouterLink',
  pure: false
})
export class MarlenkaRouterLinkPipe implements PipeTransform {


  constructor(private mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
  }

  transform(identifier: MarlenkaPageIdentifier): string {
    // console.log('looking for', identifier, 'current lang', this.mrdService.currentLanguage);
    const result = marlenkaRoutes.find(route => route?.data?.identifier === identifier).path || '';
    return result.replace(new RegExp('^:lang'), this.mrdService.currentLanguage).toLowerCase();
  }

}
