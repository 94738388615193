import gql from 'graphql-tag';
import { LAYOUT_FRAGMENT } from '../fragments';

export const readAllScreenTemplatesFull = gql`

  query ReadAllScreenTemplatesFull($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllScreenTemplate(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items {
        _id
        title
        description
        zones {
          background {
            color
          }
          display
          flexDirection
          justifyContent
          alignItems
          justifySelf
          alignSelf
          gridColumnStart
          gridColumnEnd
          gridRowStart
          gridRowEnd
          layouts {
            ... LayoutUnionFragment
          }
        }
      }
    }
  }
  ${LAYOUT_FRAGMENT}
`;
