import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QrScannerComponent} from "./qr-scanner/qr-scanner.component";
import {WebcamScannerComponent} from "./webcam-scanner/webcam-scanner.component";

@NgModule({
  imports: [CommonModule],
  declarations: [
    WebcamScannerComponent,
    QrScannerComponent
  ],
  exports: [
    QrScannerComponent
  ]
})
export class SharedNgFeatureScannerModule {}
