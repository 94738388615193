import {MongoID} from "./base-document-types";
import {ICategory} from "./i-category";
import {IOwnedEntity} from "./i-owned-entity";
import {CMSTransactionState, ComGateState, CSOBReturnCode, CSOBTransactionState} from "../utils";
import { IBaseOwner } from './i-base-owner';



export interface IPaymentOrder<Owner extends IBaseOwner | string, Auth> extends IOwnedEntity<Owner> {
  category: ICategory<Owner> | string;
  price: number; // v halirich
  status: string; // equals to last Transaction status... but better to have it here
  revokedAt?: Date;
  paidAt?: Date;
  dueDate: Date;
  variableSymbol: string;
  payer: Auth | string;
  author: Auth | string;
  transactions: ITransaction<PaymentInfo>[];
  paymentItems: IPaymentItem[];
}

export interface IPaymentItem {
  name: string;
  amount: number;
  price: number;
}

// Platba prevodem - VS, Castka, cas, cislo odchoziho uctu
// platba na pokladne - Castka, cas, cislo pokladny, cislo dokladu
// platba na pokladne terminal - castka, cas, cislo terminalu, cislo dokladu
// platba comgate -
// platba csob -

export type PaymentInfo = CSOBPaymentInfo | ComGatePaymentInfo | TransferPaymentInfo | ManualPaymentInfo;

export interface ITransaction<T extends PaymentInfo, O extends IBaseOwner = any> extends IOwnedEntity<O> {
  status: CMSTransactionState;
  price?: number; // v halirich
  variableSymbol: string; // cislo dokladu, cislo transakce
  paymentMethod?: "CSOB" | "ComGate" | "Transfer" | "Terminal" | "Cash" | "SkippedPayment" | "OnSitePayment" | string; // DISKRIMINATOR PRO PAYGATESTATUS
  paymentInfo?: T;
  finishedAt?: Date;
}

export interface CSOBPaymentInfo {
  status: CSOBTransactionState | number;
  payId: string;
  redirectUrl?: string;
  authCode?: string;
  returnCode?: CSOBReturnCode | number;
}

export interface ComGatePaymentInfo {
  status: ComGateState;
  transId: string;
  redirectUrl?: string;
}

export interface TransferPaymentInfo {
  sourceBankCode: string;
  targetBankCode: string;
}

export interface ManualPaymentInfo {
  placeDesignation: string;
  authorId: string;
  //EET bullshit?
}

// FRONT END STUFF: todo move to separate file
export interface MApiResponse<Data = undefined> {
  code: MApiResultCode;
  message: string;
  data?: Data;
}
export enum MApiResultCode {
  ERROR,
  SUCCESS,
}

export interface PaymentInitData {
  redirectUrl: string;
}
