import {IEntityDefinition} from "../../interfaces";
import {
  IGroupFieldSettings,
  IJsonFieldSettings,
  ISelectFieldSettings,
  ITextFieldSettings
} from "../../modules/form-fields";
import {of} from "rxjs";

export const getItemListWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          // DATA
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Obsah',
              formControlName: 'content',
              groupDefinition: [
                // storename
                {
                  name: 'PkSelectFieldComponent',
                  settings: {
                    nazev: 'Typ obsahu',
                    formControlName: 'storeName',
                    transformFn: arg => typeof arg === "string" ? arg : arg?._id,
                  } as ISelectFieldSettings,
                  data: of([
                    {
                      displayValue: 'Akce',
                      _id: 'events'
                    },
                    {
                      displayValue: 'Články',
                      _id: 'articles'
                    },
                    {
                      displayValue: 'Úřední deska',
                      _id: 'noticeBoards'
                    },
                    {
                      displayValue: 'Galerie',
                      _id: 'galleries'
                    }
                  ]),
                  validators: [],
                  cols: 12,
                },
                // fetch args
                {
                  name: 'PkInputGroupFieldComponent',
                  settings: {
                    nazev: 'Parametry',
                    formControlName: 'fetchArgs',
                    withoutCard: true,
                    groupDefinition: [
                      {
                        name: 'PkInputTextFieldComponent',
                        settings: {
                          nazev: 'Stránka',
                          formControlName: 'page',
                          type: 'number'
                        } as ITextFieldSettings,
                        data: null,
                        validators: [],
                        cols: 12,
                      },
                      {
                        name: 'PkInputTextFieldComponent',
                        settings: {
                          nazev: 'Počet položek',
                          formControlName: 'take',
                          type: 'number'
                        } as ITextFieldSettings,
                        data: null,
                        validators: [],
                        cols: 12,
                      },
                      {
                        name: 'PkSelectFieldComponent',
                        settings: {
                          nazev: 'Podle čeho řadit',
                          formControlName: 'sortColumn',
                          transformFn: arg => typeof arg === "string" ? arg : arg?._id,
                        } as ISelectFieldSettings,
                        data: of([
                          {
                            displayValue: 'Data publikace',
                            _id: 'publishedAt'
                          },
                          {
                            displayValue: 'Data začátku',
                            _id: 'startingAt'
                          },
                          {
                            displayValue: 'Data vytvoření',
                            _id: 'createdAt'
                          },
                          {
                            displayValue: 'Výchozí řazení',
                            _id: '_id'
                          }
                        ]),
                        validators: [],
                        cols: 12,
                      },
                      {
                        name: 'PkSelectFieldComponent',
                        settings: {
                          nazev: 'Řazení',
                          formControlName: 'sortDirection',
                          transformFn: arg => typeof arg === "string" ? arg : arg?._id,
                        } as ISelectFieldSettings,
                        data: of([
                          {
                            displayValue: 'Sestupně',
                            _id: 'DESC'
                          },
                          {
                            displayValue: 'Vzestupně',
                            _id: 'ASC'
                          }
                        ]),
                        validators: [],
                        cols: 12,
                      },
                    ]
                  } as IGroupFieldSettings,
                  data: null,
                  validators: [],
                  cols: 12,
                },
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
          {
            name: 'PkInputJsonFieldComponent',
            settings: {
              nazev: 'Filtr',
              formControlName: 'initialFilter'
            } as IJsonFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
          {
            name: 'PkInputJsonFieldComponent',
            settings: {
              nazev: 'Mapping',
              formControlName: 'mapping',
            } as IJsonFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          }
          // DESIGN
          /*{
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Design',
              formControlName: 'itemListDesign',
              withoutCard: true,
              groupDefinition: [
                // todo
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },*/
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
  ]
}
