import gql from 'graphql-tag';

export const readAllMedia = gql`
  query ReadAllMedia($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllMedia(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        url
        thumbnailUrl
        name
        description
        mime
        originalFileName
        categories {
          _id
          name
        }
        type
        createdAt
        updatedAt
      }
    }
  }
`;
