import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import {
  addEventTopic,
  deleteEventTopic,
  IMarlenkaOwner,
  IMEventTopic,
  MarlenkaEntityTypes,
  readAllEventTopics,
  readSelectedEventTopics,
} from '@echo-nx/marlenka/common';
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BaseEventTopicService implements IEntityService<IMEventTopic> {
  constructor(protected apollo: Apollo) {}

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMEventTopic>> {
    return this.apollo
      .query<IPKApolloResult<IPaginatedResponse<IMEventTopic>>>({
        query: readAllEventTopics,
        variables: args,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSelected(ids: string[]): Observable<IMEventTopic[]> {
    return this.apollo
      .query<IPKApolloResult<IMEventTopic[]>>({
        query: readSelectedEventTopics,
        variables: {
          ids: ids,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  fetchSingle(id: string): Observable<IMEventTopic> {
    return this.apollo
      .query<IPKApolloResult<IMEventTopic[]>>({
        query: readSelectedEventTopics,
        variables: {
          ids: [id],
        },
      })
      .pipe(map((res) => res.data.response[0] || null));
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteEventTopic,
      variables: { input: ids },
    });
  }

  save(
    events: IMEventTopic[]
  ): Observable<MutationResult<IPKApolloResult<IMEventTopic[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMEventTopic[]>>({
      mutation: addEventTopic,
      variables: { input: events },
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return MarlenkaEntityTypes.EventTopic;
  }
}

export const MEVENT_TOPIC_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IMEventTopic>
>('MEVENT_TOPIC_SERVICE_TOKEN');
