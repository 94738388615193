import {Component, HostBinding, Input, OnChanges, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subject, switchMap, timer} from "rxjs";
import {startWith, takeUntil} from "rxjs/operators";
import {IBusDepartureData, IBusDepartureDesign, IEpxFluid} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-bus-departure',
  templateUrl: './bus-departure.component.html',
  styleUrls: ['./bus-departure.component.scss']
})
export class BusDepartureComponent implements IBusDepartureData, IBusDepartureDesign, OnChanges, OnDestroy, OnInit {


  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any
  {
    return {
      'dark': this.isDark
    };
  }

  @Input()
  stopId: string;

  @Input()
  mode: 'Departures' | 'Arrivals';

  @Input()
  maxLines: number;

  @Input()
  showOngoing: boolean;

  @Input()
  modifyHeader = true;

  @Input()
  endpoint = 'http://localhost:4205/bus-departure';

  @Input()
  lineBg: string;

  @Input()
  oddLineBg: string;

  @Input()
  primaryColor: string;

  @Input()
  stationName: string;

  @Input()
  bgColor: string;

  @Input()
  fluid: IEpxFluid;

  @Input()
  panelClass: string;

  @Input()
  isDark = true;


  /* Setters for Smart Component */
  @Input()
  set design({
               maxLines,
               bgColor,
               isDark,
               lineBg,
               oddLineBg,
               primaryColor,
               showOngoing,
               modifyHeader
             }: IBusDepartureDesign) {
    this.maxLines = maxLines;
    this.bgColor = bgColor;
    this.isDark = isDark || true;
    this.lineBg = lineBg;
    this.oddLineBg = oddLineBg;
    this.primaryColor = primaryColor;
    this.showOngoing = showOngoing;
    this.modifyHeader = modifyHeader;
  }

  @Input()
  set data({
            mode,
            stopId,
            endpoint,
            stationName
          }: IBusDepartureData) {
    this.mode = mode;
    this.stopId = stopId;
    this.stationName = stationName;
    this.endpoint = endpoint;
  }


  public table$: Observable<Document>;
  public syncTime: string;

  private isDestroyed$ = new Subject<void>();
  private inputChanged$ = new Subject<void>();

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  ngOnInit(): void {
    this.table$ = this.inputChanged$.pipe(
      startWith(null),
      takeUntil(this.isDestroyed$),
      switchMap(() => timer(0, 1000 * 60).pipe(
          takeUntil(this.isDestroyed$),
          switchMap(async () =>
            fetch(`${this.endpoint}?${new URLSearchParams({
              stopId: this.stopId,
              mode: this.mode,
            })}`, {
              method: 'GET',
            })
              .then((response) => response.text())
              .then((txt) => {
                const date = new Date();
                this.syncTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
                return this.parseHtml(txt);
              })
          )
        )
      )
    );

  }

  ngOnChanges(): void {
    this.inputChanged$.next();
  }


  private parseHtml(txtHtml: string): Document {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(txtHtml, 'text/html');

    //remove NONE elements
    const noneEls = htmlDoc.getElementsByClassName('NONE')
    while (noneEls.length > 0) {
      noneEls[0].parentNode.removeChild(noneEls[0]);
    }

    //remove notIDS elements
    const notIDSEls = htmlDoc.getElementsByClassName('notIDS')
    while (notIDSEls.length > 0) {
      notIDSEls[0].parentNode.removeChild(notIDSEls[0]);
    }

    /*  //stylize odd lines
      const oddEls = htmlDoc.getElementsByClassName('odd')
      for(let i = 0; i < oddEls.length; i++){
        oddEls[i].classList.add('bg-primary-50')
      }

      //stylize even lines
      const evenEls = htmlDoc.getElementsByClassName('even')
      for(let i = 0; i < evenEls.length; i++){
        evenEls[i].classList.add('bg-primary-100')
      }*/

    if (this.showOngoing) {
      // modify linkMap (jedná se o tlačítko pro zobrazení gps souřadnice)... možná lepší skrýt
      const linkMaps = htmlDoc.getElementsByClassName('linkMap')
      for (let i = 0; i < linkMaps.length; i++) {
        linkMaps[i].setAttribute('title', 'Na cestě');
        linkMaps[i].classList.add('ongoing');
      }
    }

    // Rename Table Header and Change DOM to TH
    if (this.modifyHeader) {
      const hd = htmlDoc.getElementsByClassName('hd')[0];
      const newChildren = [];

      for (let i = 0; i < hd.children.length; i++) {
        const item = hd.children.item(i);
        const th = document.createElement('th');

        th.classList.value = item.classList.value;

        //could RENAME via index, but what if they reorder or smth - using their classes
        if (th.classList.contains('connection')) {
          th.textContent = 'Linka'
        } else if (th.classList.contains('datetime')) {
          th.textContent = 'Prav. odj.'
        } else if (th.classList.contains('platform')) {
          th.textContent = 'Stanoviště'
        } else if (th.classList.contains('delay')) {
          th.textContent = 'Zpoždění (min.)'
        } else {
          th.textContent = 'Cíl'
        }
        newChildren.push(th);
      }
      while (hd.children.length > 0) {
        hd.removeChild(hd.children[0]);
      }
      hd.append(...newChildren);
    }


    //Limit max lines
    if (this.maxLines) {
      const lines = htmlDoc.getElementsByTagName('tr');
      for (let i = lines.length - 1; i >= 0; i--) {
        if (i + 1 > this.maxLines + 1) {
          lines[i].parentNode.removeChild(lines[i]);
        }
      }
    }

    return htmlDoc;
  }

}
