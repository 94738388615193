import {IMarlenkaOwner} from "./owner";
import {IMAllergens} from "./allergen";
import {IMBoxSize} from "./box-size";
import {IMPackage} from "./package";
import {IMPallet} from "./pallet";
import {IMTempCondition} from "./tempCondition";
import { IMPartnerStore } from './partner-store';
import {
  ICategorizedEntity,
  ICategory,
  IMedium, IMultilanguage,
  IOwnedEntity,
  PublishableTimestamp
} from "@echo-nx/shared/common";

export interface IMProduct<C extends ICategory<IMarlenkaOwner> | string = ICategory<IMarlenkaOwner> | string> extends IOwnedEntity<IMarlenkaOwner | string>, ICategorizedEntity<C>, PublishableTimestamp, IMultilanguage {
  name: string;
  priority?: number;
  media: IMedium<IMarlenkaOwner> | string;
  description: string;
  allergens: IMAllergens[] | string[];
  weight: number; //grams
  boxSize: IMBoxSize;
  ean: string;
  package: IMPackage;
  pallet: IMPallet[];
  tempCondition: IMTempCondition[];
  attachments?: IMedium<IMarlenkaOwner>[] | string[];
  stores?: IMPartnerStore[] | string[];
}
