import gql from 'graphql-tag';

export const readAllCategories = gql`
  query ReadAllCategories($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean) {
    response: readAllCategory(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,

    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        type
        language
        languageGroupId
        publishedAt
        description
        featuredImage {
          _id
          name
          description
          mime
          type
          url
        }
        owner{
          _id
          name
        }
        color
        icon
        priority
        isSystemic
        createdAt
      }
    }
  }
`;
