import gql from 'graphql-tag';

export const readSelectedTicketTypes = gql`
  query ReadSelectedTicketTypes($ids: [String!]!) {
    response: readSelectedTicketTypes(
      ids: $ids
    ) {
      _id
      language
      languageGroupId
      description
      icon
      name
      noAdults
      noChildren
      commodityType {
        _id
        price
        articleName
      }
      publishedAt
      categories {
        _id
        name
      }
      externalIdentifiers
    }
  }
`;
