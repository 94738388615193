import {BaseWidgetSettings} from "./base-settings.type";
import {
  ChartType
} from 'ng-apexcharts';


export type ChartWidgetSettings = {
  chartData: ChartData,
  chartDesign: ChartDesign,
} & BaseWidgetSettings;

export interface ChartDesign {
  chartType: ChartType;
  primaryColor: string;
  panelClass: string,
}

export interface ChartData {
  title: string;
  datasets: ChartDataset[];
}

export interface ChartDataset {
  store: string;
  take: number;
  filter: string;
  /** x and y values please **/
  mapping: string;
  title: string;
  label: string;
}
