<nav class="flex h-12 sm:h-20 justify-between font-body" [ngClass]="[bgColor | prefix: 'bg']">

  <!-- LOGO -->
  <div
    class="flex items-center justify-end basis-auto sm:basis-1/4 rounded-br-2xl mb-1 sm:mb-0  p-2 sm:p-4 order-2 sm:order-1"
    [ngClass]="[primaryColor | prefix: 'bg']">
    <img class="hidden h-full sm:block" [src]="fullLogo?.url ?? smallLogo?.url"/>
    <img class="h-full block sm:hidden" [src]="smallLogo?.url ?? fullLogo?.url"/>
  </div>

  <!-- NAV ITEMS -->
  <div class="flex flex-1 items-center items-center basis-auto sm:basis-1/2 p-2 sm:p-4 order-1 sm:order-2">
    <div class="hidden sm:flex">
      <echo-nx-nav-dropdown-item (itemClicked)="itemClicked.next($event)" [item]="navigation"></echo-nx-nav-dropdown-item>
    </div>

    <div class="flex sm:hidden relative cursor-pointer"
         style="width:2.25rem; height: 2.25rem"
         (click)="toggleSideNav()">
      <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
            [ngClass]="{'rotate-45': sideMenuActive,' -translate-y-2': !sideMenuActive }"></span>
        <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
              [ngClass]="{'opacity-0': sideMenuActive } "></span>
        <span class="block absolute h-1 w-8 bg-primary-500 transform transition duration-250 ease-in-out"
              [ngClass]="{'-rotate-45': sideMenuActive, ' translate-y-2': !sideMenuActive}"></span>
      </div>
    </div>
  </div>

  <!-- SEARCH -->
  <div class="flex items-center justify-start basis-auto sm:basis-1/4 p-2 sm:p-4 order-3">
    <form (submit)="onSearchSubmit($event,searchInput.value)" class="hidden sm:block relative">

      <input #searchInput
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-primary-500 focus:border-primary-500 p-2.5 mr-4"
             placeholder="Vyhledat..." type="text"/>
      <button type="submit"
              class="absolute top-0 bottom-0 right-0 bg-primary-500 text-white font-medium rounded-r-lg text-sm p-2.5 text-center inline-flex items-center mr-2">
        <i class="fas fa-search"></i></button>

    </form>
    <div class="flex sm:hidden">
      SER
    </div>
  </div>
</nav>
