import {animate, style, transition, trigger} from "@angular/animations";

export const SlideInOutAnimation = [
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({width: 0}),
          animate('175ms ease-out', style({width: '*'}))
        ]
      ),
      transition(
        ':leave',
        [
          style({width: '*'}),
          animate('175ms ease-in', style({width: 0}))
        ]
      )
    ]
  )
]
