import gql from 'graphql-tag';

export const readSelectedProductTypes = gql`
  query ReadSelectedProductTypes($ids: [String!]!) {
    response: readSelectedProductTypes(
      ids: $ids
    ) {
      _id
      language
      languageGroupId
      description
      image{
        _id
        url
        description
        type
        categories {
          _id
          name
        }
      }
      publishedAt
      name
      commodityType {
        _id
        price
        articleName
      }
    }
  }
`;
