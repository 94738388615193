import {BaseWidgetSettings} from "./base-settings.type";
import {ComponentVariant} from "./component-variants";
import {ISliderData, ISliderDesign} from "../../structural";
import {ContentfulSettings} from "./contentful-settings.type";

export type SliderWidgetSettings = {
  //currently this does nothing
  variant?: ComponentVariant;

  mediaKey?: string;

  sliderData?: ISliderData[];
  sliderDesign?: ISliderDesign;
} & BaseWidgetSettings & ContentfulSettings;
