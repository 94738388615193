<table
    class="w-full"
    mat-table
    [dataSource]="data"
    matSort
    multiTemplateDataRows
    [matSortActive]="defaultSort?.active"
    [matSortDirection]="defaultSort?.direction"
  >
    <!-- CELLS -->
    <ng-container
      *ngFor="let definition of columnDefinition; index as i"
      [ngSwitch]="definition.type"
      [matColumnDef]="definition.key.join('.')"
    >

    <!-- SELECT COLUMN -->
    <ng-container *ngSwitchCase="'select'">

      <!-- MASTER CHECKBOX CELL -->
      <th mat-header-cell *matHeaderCellDef
          [ngStyle]="{width:definition.width||''}"
          [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']">
        <mat-checkbox
          #masterCheckbox
          *ngIf="canSelectMultiple"
          (click)="toggledMasterSelect.emit()"
          color="primary"
          [checked]="selectionModel.hasValue() && isAllSelected()"
          [indeterminate]="selectionModel.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <!-- ITEM CHECKBOX CELL -->
      <td mat-cell *matCellDef="let row"
          [ngClass]="['column-' + definition.align]">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation(); toggledSelect.emit(row)"
          [checked]="selectionModel.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- HEADER CELLS -->
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
      [ngStyle]="{width:definition.width||''}"
      [disabled]="!definition.sortable"
      [class.sm:invisible]="definition.fxHide?.sm || false"
      [class.md:invisible]="definition.fxHide?.sm || false"
      [class.lg:invisible]="definition.fxHide?.sm || false">
      {{ definition.title }}
    </th>

    <!-- DATA CELLS -->
    <td
      mat-cell *matCellDef="let item"
      [ngClass]="['column-' + definition.align]"
      [class.sm:invisible]="definition.fxHide?.sm || false"
      [class.md:invisible]="definition.fxHide?.sm || false"
      [class.lg:invisible]="definition.fxHide?.sm || false">
      <!-- STRING COLUMN -->
      <ng-container *ngSwitchCase="'string'">
        <p class="text-truncate">
          {{ item | resolveKey:definition.key:definition.nullValue }}
        </p>
      </ng-container>
      <!-- DATE COLUMN -->
      <ng-container *ngSwitchCase="'date'">
        <p class="text-truncate">
          {{ item | resolveKey:definition.key:definition.nullValue | date: definition.settings?.format ?? 'dd.MM.yyyy HH:mm' }}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'format'">
        <p class="text-truncate">
          {{ item | resolveKey:definition.key:definition.nullValue | format:definition.settings:item }}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'status'">
        <echo-nx-status-cell
          [settings]="definition.settings"
          [text]="item | resolveKey:definition.key:definition.nullValue"
          [entity]="item"
        >
        </echo-nx-status-cell>
      </ng-container>
      <ng-container *ngSwitchCase="'filter'">
        <p class="text-truncate">
          {{item | resolveKey:definition.key:definition.nullValue | echoNxFilter:definition.settings}}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'map'">
        <p class="text-truncate">
          {{ item | resolveKey:definition.key:definition.nullValue | pluck:definition.settings }}
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'template'">
        <ng-template [ngTemplateOutlet]="getCellTemplate(definition.key)" [ngTemplateOutletContext]="{$implicit: item}">
        </ng-template>
      </ng-container>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail" *ngIf="isExpandable">
    <td mat-cell *matCellDef="let item" [attr.colspan]="columnDefinition.length" class="border-0">
      <div class="flex overflow-hidden"
           [@detailExpand]="item === expandedItem ? 'expanded' : 'collapsed'">
        <ng-template [ngTemplateOutlet]="getTemplate()" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
      </div>
    </td>
  </ng-container>

  <!-- HEADER ROW -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <!-- DATA ROW -->
  <!-- [class.selected]="item._id == clickedRow?._id" -->
  <tr mat-row
      *matRowDef="let item; columns: displayedColumns"
      class="hover:bg-accent-100 dark:hover:bg-accent-700"
      (click)="onRowClicked(item)"
  ></tr>

  <!-- expanded row -->
  <ng-container *ngIf="isExpandable">
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"  class="h-0"></tr>
  </ng-container>
</table>

