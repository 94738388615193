import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataCardDialogComponent} from './data-card-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {MediaUploadModule} from "@echo-nx/shared/ng/feature/media-upload";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {DataCardModule} from "../../data-card.module";


@NgModule({
  declarations: [
    DataCardDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    DataCardModule,
    MatTabsModule,
    MediaUploadModule,
    MatIconModule,
    MatRippleModule
  ]
})
export class DataCardDialogModule {
}
