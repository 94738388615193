import {Inject, Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {BehaviorSubject, Observable, OperatorFunction} from "rxjs";
import {filter, map, switchMap} from "rxjs/operators";
import {IMarlenkaOwner} from "@echo-nx/marlenka/common";
import {ICategory, IMedium, MediumType} from "@echo-nx/shared/common";
import {BaseMediumService} from "@echo-nx/shared/ng/data-access";
import {HttpClient} from "@angular/common/http";
import {URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";


@Injectable({
  providedIn: 'root'
})
export class MediumService extends BaseMediumService {

  private _voucherBackgrounds: BehaviorSubject<IMedium<IMarlenkaOwner>[]>;
  public readonly voucherBackgrounds$: Observable<IMedium<IMarlenkaOwner>[]>;

  get voucherBackgrounds() {
    return this._voucherBackgrounds.getValue();
  }

  set voucherBackgrounds(media: IMedium<IMarlenkaOwner>[]) {
    this._voucherBackgrounds.next(media);
  }

  constructor(apollo: Apollo, http: HttpClient, @Inject(URL_SETTINGS_TOKEN) urlSettings: UrlSettings) {
    super(apollo, http, urlSettings);
    this._voucherBackgrounds = new BehaviorSubject<IMedium<IMarlenkaOwner>[]>([]);
    this.voucherBackgrounds$ = this._voucherBackgrounds.asObservable();
  }

  public fetchVoucherBackgrounds(): Observable<IMedium<IMarlenkaOwner>[]> {
    return this.fetchCategories().pipe(
      map(res => res.find(category => category.name === 'Vouchery')),
      filter(cat => cat !== undefined) as OperatorFunction<ICategory<any> | undefined, any>,
      switchMap(({_id}) => {
        return this.fetchAll({
          filter: JSON.stringify({categories: {$in: [_id]}})
        }).pipe(
          map(res => res.items.filter(item => item.type === MediumType.Image))
        )
      })
    )
  }


}
