import gql from 'graphql-tag';

export const WIDGET_FRAGMENTS = gql`
  fragment WidgetsUnionFragment on WidgetUnionObject {
    ... DropzoneWidgetFragment
    ... ImageWidgetFragment
    ... DatetimeBarWidgetFragment
    ... ButtonWidgetFragment
    ... WebWidgetFragment
    ... ScreenNameWidgetFragment
    ... FilterWidgetFragment
    ... MapWidgetFragment
    ... ItemListWidgetFragment
    ... ItemDetailWidgetFragment
    ... TextWidgetFragment
    ... PaginationWidgetFragment
    ... CheckboxWidgetFragment
    ... WeatherWidgetFragment
    ... SliderWidgetFragment
    ... NavigationWidgetFragment
    ... BreadcrumbsWidgetFragment
    ... HtmlViewerWidgetFragment
    ... LanguagePickerWidgetFragment
    ... ChartWidgetFragment
    ... SearchResultWidgetFragment
    ... NavbarWidgetFragment
    ... DatePickerWidgetFragment
  }

  fragment ItemDetailWidgetFragment on ItemDetailWidgetObject {
    name
    to
    event{
      eventName
      name
      purpose
      uniqueName
    }
    settings {
      mapping
      disableDocumentViewer
      variant
      itemDetailData{
        attachments{
          name
          url
          mime
          type
        }
        categories{
          name
        }
        title
        content
        date
        image{
          name
          url
          mime
          type
        }
      }
      itemDetailDesign{
        secondaryColor
        primaryColor
        contentColorful{
          bgColor
          color
        }
        dateText {
          icon
          color
          bgColor
        }
        titleText{
          color
          bgColor
        }
      }
    }
  }

  fragment ItemListWidgetFragment on ItemListWidgetObject {
    name
    to
    event{
      eventName
      name
      purpose
      uniqueName
    }
    settings {
      mapping
      itemTo
      isPhotoCard
      emptyText
      emptyIcon
      withoutScroll
      background{
        attachment
        clip
        origin
        position
        repeat
        size
        color
        image{
          name
          url
          mime
          type
        }
      }
      paginated
      variant
      itemsClass
      uniqueName
      initialFilter
      itemListDesign{
        accentColor
        primaryColor
        secondaryColor
        rounded
        titleText {
          color
          bgColor
        }
        horizontalBreakpoint
        baseOrientation
        breakOrientation
        dateText {
          color
          bgColor
        }
        footerText {
          color
          icon
        }
        prominent
        fluid
      }
      content{
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
          includeNotPublished
        }
      }
    }
  }

  fragment SearchResultWidgetFragment on SearchResultWidgetObject {
    name
    to
    event{
      eventName
      name
      purpose
      uniqueName
    }
    settings {
      emptyIcon
      variant
      itemsClass
      uniqueName
      itemListDesign{
        accentColor
        primaryColor
        secondaryColor
        rounded
        titleText {
          color
          bgColor
        }
        horizontalBreakpoint
        baseOrientation
        breakOrientation
        dateText {
          color
          bgColor
        }
        footerText {
          color
          icon
        }
        prominent
        fluid
      }
    }
  }

  fragment DropzoneWidgetFragment on DropzoneWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
    }
  }

  fragment WebWidgetFragment on WebsiteWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
      url
      fluid
      width
      height
      panelClass
    }
  }

  fragment ButtonWidgetFragment on ButtonWidgetObject {
    name
    to
    event{
      eventName
      purpose
      name
      uniqueName
      data
    }
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      justifySelf
      uniqueName
      dropAble
      variant
      buttonData {
        title
        description
      }
      buttonDesign {
        bgColor
        secondaryColor
        accentColor
        textColor
        prominent
        dense
        outlined
        orientation
        fluid
        panelClass
        startIcon {
          icon
          color
          bgColor
          offset
        }
        endIcon {
          icon
          color
          bgColor
          offset
        }
        descriptionColorful {
          color
          bgColor
        }
        fontSize
      }
      targetWidget
      matBadgeDesign {
        matBadgePosition
        matBadgeSize
      }
      matBadgeData {
        title
      }
      dynamicBadgeData {
        prefix
        suffix
        settings {
          filterTemplate
        }
      }
    }
  }

  fragment CheckboxWidgetFragment on CheckboxWidgetObject {
    name
    to
    event{
      eventName
      purpose
      name
      uniqueName
    }
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
      variant
      targetWidget
      filterTemplate
      checkboxData {
        title
      }
      checkboxDesign {
        primaryColor
        accentColor
        secondaryColor
        fluid
        panelClass
        itemsClass
      }
      hideHeader
      isRadio
      buttonDesign {
        bgColor
        accentColor
        textColor
        prominent
        dense
        outlined
        orientation
        fluid
        panelClass
        startIcon {
          icon
          color
          bgColor
          offset
        }
        endIcon {
          icon
          color
          bgColor
          offset
        }
        descriptionColorful {
          color
          bgColor
        }
      }
      content {
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
        }
      }
      mapping
      values {
        displayValue
        value
        title
        description
      }
    }
  }

  fragment DatetimeBarWidgetFragment on DateTimeBarWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
      variant
      dateTimeBarDesign {
        accentColor
        primaryColor
        secondaryColor
        textColor
        fluid
      }
      dateTimeBarData {
        title
      }
    }
  }

  fragment TextWidgetFragment on TextWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
      variant
      justifySelf
      textDesign {
        primaryColor
        secondaryColor
        fontSize
        fontWeight
        panelClass
        fontFamilyType
        endIcon {
          icon
          color
          bgColor
          offset
        }
        startIcon {
          icon
          color
          bgColor
          offset
        }
      }
      textData {
        title
      }
    }
  }

  fragment ImageWidgetFragment on ImageWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      objectFit
      maxWidthPx
      uniqueName
      dropAble
      url
      panelClass
      image{
        _id
        url
        mime
        type
      }
    }
  }

  fragment LanguagePickerWidgetFragment on LanguagePickerWidgetObject {
    name
    to
    settings {
      showFlags
      primaryColor
      secondaryColor

    }
  }

  fragment ScreenNameWidgetFragment on ScreenNameWidgetObject {
    name
    to
    settings {
      size
      text
      color
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
    }
  }

  fragment PaginationWidgetFragment on PaginationWidgetObject {
    name
    to
    settings {
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
      variant
      targetWidget
      paginationDesign {
        breakpoints{
          big
          compact
        }
        activeColorful {
          bgColor
          color
        }
        normalColorful{
          bgColor
          color
        }
        disabledColorful{
          bgColor
          color
        }
        bgColor
        textSize
      }
    }
  }

  fragment FilterWidgetFragment on FilterWidgetObject {
    name
    to
    settings {
      text
      keyName
      variant
      position
      top
      right
      bottom
      left
      values{
        displayValue
        value
      }
      filterDesign{
        bgColor
        prominent
        dense
        outlined
        orientation
      }
      widgetName
      type
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
    }
  }

  fragment MapWidgetFragment on MapWidgetObject {
    name
    to
    settings {
      isWeb
      content{
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
          includeNotPublished
        }
        storeName
      }
      mapViewOptions {
        bounds{
          east
          north
          south
          west
        }
        debounceBoundaryEventMs
        defaultCenter {
          lat
          lng
        }
        defaultPointOptions {
          color
          labelIcon
          marker
        }
        kmlOverlayUrl
        mapId
        mapPointMapping
      }
      mapInfoOptions{
        mapping
        variant
        to
        content{
          id
          storeName
        }
      }
      gridRowStart
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      uniqueName
      dropAble
    }
  }

  fragment WeatherWidgetFragment on WeatherWidgetObject {
    name
    to
    event{
      eventName
      purpose
      name
      uniqueName
      data
    }
    settings {
      gridRowStart
      content{
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
        }
      }
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      justifySelf
      uniqueName
      dropAble
      variant
      mapping
      weatherData {
        title
        description
        suffix
        title
        value
      }
      weatherDesign {
        primaryColor
        secondaryColor
        accentColor
        textColor
        startIcon {
          icon
          color
          bgColor
        }
        fluid
        panelClass
      }
    }
  }

  fragment SliderWidgetFragment on SliderWidgetObject {
    name
    to
    settings {
      gridRowStart
      content{
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
        }
      }
      gridRowEnd
      gridColumnStart
      gridColumnEnd
      justifySelf
      uniqueName
      dropAble
      variant
      mapping
      mediaKey
      sliderData {
        title
        subtitle
        description
        imgUrl
      }
      sliderDesign {
        primaryColor
        secondaryColor
        textColor
        onPrimaryColor
      }
    }
  }

  fragment NavbarWidgetFragment on NavbarWidgetObject {
    name
    to
    settings {
      navbarDesign {
        primaryColor
        textColor
        bgColor
      }
      variant
      uniqueName
      navbarData {
        searchRoute
        fullLogo {
          _id
          name
          type
          url
        }
        smallLogo {
          _id
          name
          type
          url
        }
      }
    }
  }

  fragment NavigationWidgetFragment on NavigationWidgetObject {
    name
    to
    settings {
      uniqueName
      navigationType
      subtreeId
      content {
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
        }
      }
      mapping
      sideNavigationSettings {
        divideSize
        dividerColor
        hideTitle
        startIcon{
          icon
          color
          bgColor
        }
        titleColor
        textColor
      }
    }
  }

  fragment BreadcrumbsWidgetFragment on BreadcrumbsWidgetObject {
    name
    to
    settings {
      content {
        storeName
        fetchArgs {
          search
          page
          take
          sortColumn
          sortDirection
          filter
        }
      }
      mapping
    }
  }

  fragment HtmlViewerWidgetFragment on HtmlViewerWidgetObject {
    name
    to
    settings {
      html
    }
  }

  fragment ChartWidgetFragment on ChartWidgetObject {
    name
    to
    event{
      eventName
      purpose
      name
      uniqueName
      data
    }
    settings {
      panelClass
      chartDesign
      chartData
    }
  }

  fragment DatePickerWidgetFragment on DatePickerWidgetObject {
    name
    to
    settings {
      uniqueName
      variant
      targetWidget
      filterTemplate
      datePickerData {
        placeholder
      }
      calendarDesign {
        accentColor
        activeColor
        bgColor
        fluid
        secondaryColor
      }
    }
  }
`;
