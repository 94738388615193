<marlenka-jumbotron
  title="404"
  subtitle="Je nám to líto, ale Vámi požadovaná stránka nebyla nalezena. Pokud si myslíte, že se jedná o chybu, kontaktujte podporu."
  [withDivider]="true"
  [topWave]="true"
  background="#FFF6E6"
  id="products"
>
  <button
    class="button primary"
    mat-stroked-button
    [routerLink]="'/'"
  >
    <span class="text">Zpět na hlavní stránku</span>
    <mat-icon>home</mat-icon>
  </button>
</marlenka-jumbotron>
