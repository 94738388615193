import gql from 'graphql-tag';

export const readAllTransactions = gql`
  query ReadAllTransactions($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllTransaction(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        variableSymbol
        price
        status
        createdAt
        updatedAt
      }
    }
  }
`;
