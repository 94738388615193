import { Component, OnInit } from '@angular/core';
import {BaseItemDetailComponent} from "../base-item-detail.component";

@Component({
  selector: 'echo-nx-item-detail-variant-c',
  templateUrl: './item-detail-variant-c.component.html',
  styleUrls: ['./item-detail-variant-c.component.scss']
})
export class ItemDetailVariantCComponent extends BaseItemDetailComponent {



}
