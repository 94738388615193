import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

/**
 * renamed from swipe?
 */
@Directive({
  selector: '[echoNxSwipe]',
})
export class EchoNxSwipeDirective {
  defaultTouch = { x: 0, y: 0, time: 0 };

  @Output()
  public swiped: EventEmitter<SwipeDirection> = new EventEmitter<SwipeDirection>();

  @Input()
  public timeThreshold = 500;

  @Input()
  public distanceThreshold = 60;

  @HostListener('touchstart', ['$event'])
  //@HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event: TouchEvent) {
    const touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x;
      const deltaY = touch.pageY - this.defaultTouch.y;
      const deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < this.timeThreshold) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > this.distanceThreshold) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.doSwipeRight();
          } else {
            this.doSwipeLeft();
          }
        }

        if (Math.abs(deltaY) > this.distanceThreshold) {
          // delta y is at least 60 pixels
          if (deltaY > 0) {
            this.doSwipeDown();
          } else {
            this.doSwipeUp();
          }
        }
      }
    }
  }

  doSwipeLeft() {
    //console.log('swipe left', event);
    this.swiped.emit('left');
  }

  doSwipeRight() {
    //console.log('swipe right', event);
    this.swiped.emit('right');
  }

  doSwipeUp() {
    //console.log('swipe up', event);
    this.swiped.emit('up');
  }

  doSwipeDown() {
    //console.log('swipe down', event);
    this.swiped.emit('down');
  }
}

export type SwipeDirection = 'up' | 'down' | 'left' | 'right';
