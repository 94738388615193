export interface IMeta<M> {
  meta?: IMetaData<M>;
}

export interface IMetaData<M> {
  integrationMeta?: M,
  seedMeta?: ISeedMeta,
  migrationMeta?: IMigrationMeta[]
}


export interface ISeedMeta {
  id: string;
  seedAt: Date;
}

export interface IMigrationMeta {
  id: string;
  info: any;
}
