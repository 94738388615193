import {Injectable, InjectionToken} from "@angular/core";
import {
  EkioskEntityTypes,
  FetchAllArgs,
  ICategory,
  IEntityService,
  IKioskScreen, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {
  readAllCategories,
  readAllCategoriesOfEntity,
  readAllScreens,
  readAllScreensForSynchronizing,
  readSelectedScreens
} from "../queries";
import {addScreen} from '../mutations'
import {map} from "rxjs/operators";
import {mapGqlScreens} from "../utils";

@Injectable()
export class BaseScreenService implements IEntityService<IKioskScreen> {

  constructor(protected apollo: Apollo) {
  }

  getType(): string {
    return EkioskEntityTypes.Screen;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, variables: any) {
    return this.apollo.mutate({
      mutation,
      variables
    });
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IKioskScreen>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IKioskScreen>>>({
      query: readAllScreens,
      variables: args
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchCategories(): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType()
        }),
        includeNotPublished: false
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }

  fetchSelected(ids: string[]): Observable<IKioskScreen[]> {
    return this.apollo.query<IPKApolloResult<IKioskScreen[]>>({
      query: readSelectedScreens,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IKioskScreen> {
    return this.fetchSelected([id]).pipe(
      map(res => mapGqlScreens(res[0]))
    )
  }

  public fetchAllForSync(args?: FetchAllArgs): Observable<IPaginatedResponse<IKioskScreen>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IKioskScreen>>>({
      query: readAllScreensForSynchronizing,
      variables: args
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  public fetchSingleForSync(screenIdOrSlug: string, isSlug = true, language: string = 'cs'): Observable<IKioskScreen | undefined> {
    const filter = isSlug ? {slug: screenIdOrSlug, language} : {_id: screenIdOrSlug, language};

    return this.fetchAllForSync({
      filter: JSON.stringify(filter)
    }).pipe(
      map(({items}) => items?.[0])
    )
  }

  save(entities: IKioskScreen[]): Observable<MutationResult<IPKApolloResult<IKioskScreen[]>>> {
    return this.apollo.mutate<IPKApolloResult<IKioskScreen[]>>({
      mutation: addScreen,
      variables: {input: entities}
    });
  }

}

export const BASE_KIOSK_SCREEN_SERVICE_TOKEN = new InjectionToken<IEntityService<IKioskScreen>>('BASE_KIOSK_SCREEN_SERVICE_TOKEN');
