import {IPublishableOwnedEntity} from "./i-owned-entity";
import {IMedium} from "./i-medium";
import { IBaseOwner } from './i-base-owner';
import {IMeta} from "./i-meta";
import {IMultilanguage} from "./base-document-types";

export interface ICategory<T extends IBaseOwner | string = any, M = any> extends IMeta<M>, IPublishableOwnedEntity<T>, IMultilanguage {
  name: string;
  type: string; // entityType
  description?: string;
  group?: string;
  color: string;
  isSystemic?: boolean; //systemic categories cannot be deleted (documents, pictures, videos)
  featuredImage?: IMedium<T> | string;
  priority?: number;
  icon?: string;
}
