import {Component, Input, HostBinding, OnInit, ElementRef, ChangeDetectorRef, ViewChild} from '@angular/core';
import {CdkStepper, StepperSelectionEvent} from '@angular/cdk/stepper';
import {Subject} from 'rxjs';
import {Directionality} from '@angular/cdk/bidi';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Location} from '@angular/common';
import {ScrollableService} from "@echo-nx/shared/ng/feature/common";
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'echo-nx-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: StepperComponent}]
})
export class StepperComponent extends CdkStepper implements OnInit {


  constructor(private location: Location,
              private currentRoute: ActivatedRoute,
              private router: Router,
              dir: Directionality,
              changeDetectorRef: ChangeDetectorRef,
              elementRef: ElementRef<HTMLElement>,
              private scrollable: ScrollableService,
              private matSnackbar: MatSnackBar) {
    super(dir, changeDetectorRef, elementRef);
  }

  private _stepChanged = new Subject<[number, number]>();
  public stepChanged$ = this._stepChanged.asObservable();

  private _error = new Subject<{type: 'nextError', description?: string}>();
  public error$ = this._error.asObservable();

  @Input() activeBackgroundColor = '#A37D2C';
  @Input() activeColor = '#FFFFFF';
  @Input() showSnackbarOnNextError = true;

  @ViewChild('navigationBar')
  navigationBar!: ElementRef;

  @Input()
  @HostBinding('class')
  public panelClass!: string;

  ngOnInit(): void {
    document.documentElement.style.setProperty('--active-background-color', this.activeBackgroundColor);
    document.documentElement.style.setProperty('--active-color', this.activeColor);

    this.router.navigate([], {
      queryParams: {step: this.selectedIndex},
      replaceUrl: true,
      queryParamsHandling: 'merge'
    });

    //subscribe to stepChanged$
    this.stepChanged$.pipe(
      takeUntil(this._destroyed)
    ).subscribe(
      async ([prev, curr]) => {
        console.log(prev, curr);
        //we want forward
        if (prev < curr) {
          this.location.getState();
          this.scrollable.scrollToTop();
          await this.router.navigate([], {queryParams: {step: curr}});
        } else if (prev > curr) {
          //we want back
          this.scrollable.scrollToTop();
          await this.router.navigate([], {
            queryParams: {step: curr},
            replaceUrl: true,
            queryParamsHandling: 'merge'
          });
        } else if (this.showSnackbarOnNextError) {
          this._error.next({type: 'nextError'})
        }
      }
    );

    //subscribe to router Event
    this.router.events.pipe(
      takeUntil(this._destroyed),
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    )
      .subscribe(
        (event: NavigationEnd) => {
          if (event?.url) {
            const tree = this.router.parseUrl(event.url);
            //check if we are still on currentPage

            if (tree.queryParams && tree.queryParams.step) {
              const targetIdx = Number(tree.queryParams.step);
              if (targetIdx !== this.selectedIndex) {
                this.onClick(targetIdx);
              }
            }
          }

        }
      );

  }

  onClick(index: number): void {
    if(!this.isDisabled(index)) {
      this._stepChanged.next([this.selectedIndex, index]);
      this.selectedIndex = index;
    }
  }


  next(): void {
    const prevStep = this.selectedIndex;
    super.next();
    this._stepChanged.next([prevStep, this.selectedIndex]);
  }

// if there exists a previous step that is incomplete, then this step should be disabled
  public isDisabled(idx: number) {
    if (idx === 0) {
      return false;
    }

    for (let i = idx - 1; i >= 0; i--) {
      if (!this.steps.toArray()[i].completed) {
        return true;
      }
    }
    return false;
  }

}
