<div class="button" mat-ripple (click)="openQrCodeScannerDialog()">
  <mat-icon color="accent">qr_code_scanner</mat-icon>
</div>

<input #inputElement type="text" placeholder="KOD-VOUCHERU" [maxLength]="codeMaxLength" [(ngModel)]="value" (input)="emitInput($event)">

<div class="appendix">
  <mat-spinner *ngIf="isLoading; else clear" [diameter]="spinnerSize"></mat-spinner>
  <ng-template #clear>
    <button style="margin-right: 1.5rem" *ngIf="value.length > 0" mat-icon-button
            (click)="value = ''; valueChanged.emit(''); inputElement.focus()">
      <mat-icon>close</mat-icon>
    </button>
  </ng-template>
</div>


<!-- QR OVERLAY -->
<!--this is not tailwind exclusive component -tw classes has been stripped to minimum -->
<ng-template #qrCodeOverlay>
  <div class="bg-card card">
    <div class="header">
      <div>QR Snímač</div>
      <button
        mat-icon-button
        (click)="closeQrCodeScannerDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <p class="my-2">Naskenujte QR kód vstupenky.</p>
    <echo-nx-qr-scanner (qrCodeDetected)="onQrCodeDetected($event)"></echo-nx-qr-scanner>
  </div>
</ng-template>
