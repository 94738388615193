import {Pipe, PipeTransform} from '@angular/core';
import {IMedium, MediumType} from "@echo-nx/shared/common";


@Pipe({
  name: 'isImage'
})
export class IsImageMediumPipe implements PipeTransform {

  transform(value: IMedium<any>): boolean {
    console.log('is image??', value);
    return value.type === MediumType.Image;
  }

}
