// specifies how to display self in the Grid.
export type GridItemSettings = {
  gridColumnStart?: number;
  gridColumnEnd?: number;
  gridRowStart?: number;
  gridRowEnd?: number;
  gridArea?: string;
  alignSelf?: string;
  justifySelf?: string;
}
