import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'echo-nx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input()
  public title!: string;

  @Input()
  public cardClass?: string[] | string;

  ngOnInit(): void {
    return;
  }

}
