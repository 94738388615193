import gql from 'graphql-tag';
import {
  LAYOUT_FRAGMENT
} from '../fragments';

export const readSelectedScreens = gql`
  query ReadSelectedScreens($ids: [String!]){
    response: readSelectedScreen(ids: $ids){
      _id
      name
      owner{
        _id
      }
      slug
      categories{
        _id
        name
      }
      background{
        color
        image{
          _id
          type
          url
          thumbnailUrl
          mime
        }
        size
        position
        origin
        repeat
        clip
        attachment
      }
      zones{
        background {
          color
          image{
            url
          }
        }
        display
        flexDirection
        justifyContent
        alignItems
        justifySelf
        alignSelf
        gridColumnStart
        gridColumnEnd
        gridRowStart
        gridRowEnd
        layouts{
          ... LayoutUnionFragment
        }
      }
    }
  }
  ${LAYOUT_FRAGMENT}
`;
