import {Injectable, InjectionToken} from "@angular/core";
import {
  CommonEntityTypes,
  FetchAllArgs, IAuthApiResponse, IBaseAuth, IBaseOwner,
  ICategory,
  IEntityService, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {readAllAuths} from "../queries/contact";
import {map} from "rxjs/operators";
import {addContactAndAuth} from "../mutations";

@Injectable()
export class BaseAuthsService implements IEntityService<IBaseAuth<IBaseOwner>> {

  constructor(protected apollo: Apollo) {
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any): any {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IBaseAuth<IBaseOwner>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IBaseAuth<IBaseOwner>>>>({
      query: readAllAuths,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSingleByUserId(user: string): Observable<IBaseAuth<IBaseOwner>> {
    return this.fetchAll({
      filter: JSON.stringify({ user })
    }).pipe(
      map(result => result.items?.[0] as any)
    );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<IBaseAuth<IBaseOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchSingle(id: string): Observable<IBaseAuth<IBaseOwner>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return CommonEntityTypes.Auth;
  }

  save(entities: IBaseAuth<IBaseOwner>[]): Observable<MutationResult<IPKApolloResult<IBaseAuth<IBaseOwner>[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  saveWithUser(entities: any[]): Observable<IAuthApiResponse<any>> {
    return this.apollo.mutate<IPKApolloResult<IAuthApiResponse<any>>>({
      mutation: addContactAndAuth,
      variables: { input: entities }
    }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((res: any) => res.data!.response)
    );
  }


}
// CMS_BASE_AUTH_SERVICE_TOKEN
export const BASE_AUTHS_SERVICE_TOKEN = new InjectionToken<IEntityService<IBaseAuth<IBaseOwner>>>('BASE_AUTHS_SERVICE_TOKEN');

