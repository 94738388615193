import {BaseWidgetSettings} from './base-settings.type';
import {IBasePaginationData, IBasePaginationDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";

export type PaginationWidgetSettings = {
  variant?: ComponentVariant;
  paginationDesign?: IBasePaginationDesign;
  paginationData?: IBasePaginationData;
  targetWidget: string;
} & BaseWidgetSettings;

