<div
  [style.background]="background"
  [style.backgroundSize]="'cover'"
  [style.backgroundPosition]="
    backgroundPosition ? 'center ' + backgroundPosition : 'center'
  "
  [style.minHeight]="minHeight"
  [class.topWave]="topWave"
  [class]="wrapClass"
  class="jumbotron-wrap"
>
  <!-- #TODO: add justify-content prop plus top-padding that we can have content on top and then move it by padding-top -->
  <div *ngIf="topArrowFragment" class="topArrow">
    <a [routerLink]="[]" [fragment]="topArrowFragment"><img src="/assets/arrow.svg" /></a>
  </div>

  <h1
    [style.color]="textColor"
    [ngClass]="{ 'with-divider': withDivider && !dividerWave }"
    class="title title-font"
  >
    {{ title }}
  </h1>
  <div *ngIf="withDivider && dividerWave" class="wave-divider"></div>
    <marlenka-wysiwyg-view
            [style.textAlign]="'center'"
            [style.color]="textColor"
            class="subtitle paragraph-font content-paragraph"
            *ngIf="subtitle"
            [html]="subtitle">
    </marlenka-wysiwyg-view>
  <ng-content></ng-content>
  <div *ngIf="bottomArrowFragment" class="bottomArrow">
    <a [routerLink]="[]" [fragment]="bottomArrowFragment"
      ><img src="/assets/arrow.svg"
    /></a>
  </div>
</div>
