import {ProductsStepComponent} from "../marlenka-stepper/steps/products-step/products-step.component";
import {SummaryStepComponent} from "../marlenka-stepper/steps/summary-step/summary-step.component";
import {Component, EventEmitter, Injector, OnInit, Output, ViewChild} from "@angular/core";
import {MarlenkaStepperComponent} from "../marlenka-stepper/marlenka-stepper.component";
import {getStepperTitleFor, initiateMarlenkaPayment, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {BaseLoadingService} from "@echo-nx/shared/ng/feature/loading";
import {MatDialog} from "@angular/material/dialog";
import {TicketsStepComponent} from "../marlenka-stepper/steps/tickets-step/tickets-step.component";
import {ComponentPortal} from "@angular/cdk/portal";
import {combineLatest, of, Subject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {CustomizeVoucherStepComponent} from "../marlenka-stepper/steps/customize-voucher-step/customize-voucher-step.component";
import {ContactStepComponent} from "../marlenka-stepper/steps/contact-step/contact-step.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {createPortalInjector} from "@echo-nx/shared/ng/feature/common";
import {
  IPKStep,
  PRODUCT_STEP_COMPONENT_INPUT,
  ProductStepComponentInput,
} from "../utils";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {SnackbarComponent, SnackbarData} from "@echo-nx/shared/ng/feature/snackbar";


@Component({
  selector: 'marlenka-voucher-stepper',
  templateUrl: './voucher-stepper.component.html',
  styleUrls: ['./voucher-stepper.component.scss'],
  providers: [
    ReservationService
  ]
})
export class VoucherStepperComponent implements OnInit {

  @ViewChild('marlenkaStepper', {static: true})
  public marlenkaStepper!: MarlenkaStepperComponent;

  @Output()
  public backClicked = new EventEmitter<number>();

  public reservationId?: string;

  public readonly isDestroyed$ = new Subject<boolean>();

  constructor(
    public reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private loadingService: BaseLoadingService,
    private mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    private injector: Injector,
  ) {
  }

  public reservationSteps!: IPKStep[];

  ngOnInit(): void {
    this.reservationSteps = this.createVoucherSteps();

    this.marlenkaStepper.stepper.error$.pipe(
      takeUntil(this.isDestroyed$),
      filter((v) => v.type === 'nextError')
    ).subscribe(() => this.reservationService.onNextFailed.next());

  }

  private createVoucherSteps(): IPKStep[] {
    const ticketsOrProductsCompletion$ = combineLatest([
      this.reservationService.selectedTickets$,
      this.reservationService.selectedProducts$
    ]).pipe(map(([tickets, products]) => tickets.length > 0 || products.length > 0));

    const ticketsOrProductsAndPersonCompletion$ = combineLatest([
      ticketsOrProductsCompletion$,
      this.reservationService.selectedPerson$
    ]).pipe(
      map(([someTicketsOrProducts, contact]) => someTicketsOrProducts && contact.formValid)
    );

    return [
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('tickets', lang))
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({tickets}) => tickets.count > 0 ? `${tickets.count} ks - ${tickets.cost} Kč` : '')
        ),
        nextButtonText: of('Pokračovat'),
        completed: of(true),
        optional: true,
        componentPortal:
          new ComponentPortal(TicketsStepComponent
        )
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('products', lang))
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({products}) => products.count > 0 ? `${products.count} ks - ${products.cost} Kč` : '')
        ),
        nextButtonText: of('Pokračovat'),
        completed: ticketsOrProductsCompletion$,
        optional: false,
        componentPortal: new ComponentPortal(ProductsStepComponent, null, createPortalInjector(
          PRODUCT_STEP_COMPONENT_INPUT,
          {
            hideGlutenFree: true
          } as ProductStepComponentInput,
          this.injector,))
      },
      {
        title: of('Dárkový poukaz'),
        nextButtonText: of('Pokračovat'),
        subtitle: this.reservationService.voucherCustomization$.pipe(
          map(vt => vt?.voucherTypeId ? 'Zvoleno' : '')
        ),
        completed: of(true),
        optional: true,
        componentPortal: new ComponentPortal(CustomizeVoucherStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('contact', lang))
        ),
        subtitle: this.reservationService.selectedPerson$.pipe(
          map(contact => contact.formData?.person.name || '')
        ),
        nextButtonText: of('Pokračovat'),
        completed: ticketsOrProductsAndPersonCompletion$,
        optional: false,
        componentPortal: new ComponentPortal(ContactStepComponent)
      },
      {
        title: this.mrdService.currentLanguage$.pipe(
          map(lang => getStepperTitleFor('summary', lang))
        ),
        nextButtonText: this.reservationService.totals$.pipe(
          map(({total}) => total.cost > 0 ? 'Zaplatit' : 'Dokončit')
        ),
        subtitle: this.reservationService.totals$.pipe(
          map(({tickets, total}) => tickets.count > 0 ? `${total.cost} Kč` : '')
        ),
        completed: ticketsOrProductsAndPersonCompletion$,
        optional: false,
        componentPortal: new ComponentPortal(SummaryStepComponent)
      },
    ]
  }

  public onVoucherCompleted() {
    if(this.loadingService.isLoading()){
      return;
    }
    const loadingId = this.loadingService.startLoading();
    //payment time
    this.reservationService.saveVoucher().toPromise()
      .then((res: any) => res)
      .then(({data}) => {
        const _id = data?.response[0]?._id;
        if (!_id) {
          throw Error(`VoucherID is missing. ${_id}`)
        }
        this.reservationId = _id;
        return initiateMarlenkaPayment(_id, "voucher");
      })
      .catch(err => {
        console.log('failed to save voucher prior to payment', err);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: {
            text: err.message || 'Rezervaci se nepodařilo vytvořit, zkuste to prosím později.'
          } as SnackbarData,
          panelClass: 'custom-snackbar',
          verticalPosition: 'top',
          horizontalPosition: 'end'
        });
      })
      .finally(() => {
        this.loadingService.stopLoading(loadingId);
      })
  }
}
