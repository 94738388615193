import {IBaseOwner} from "./i-base-owner";
import {IOwnedEntity} from "./i-owned-entity";



export interface IWeatherData<O extends IBaseOwner = any> extends IOwnedEntity<O>{
  stationName: string;
  stationLocationName: string;

  humidity: number; //vlhkost v procentech
  pressure: number; // tlak v hPa

  exposure?: number; //akt. slunecni osvit W/m2
  exposureIdeal?: number; //ocekav. slunecni osvit W/m2

  windSpeed: number; //v m/s
  windDirection?: number; //ve stupnich
  windGust?: number; // narazovy vitr v m/s

  precipitation: number; //v mm

  temperatureApparent?: number; // pocitova tepl. v stupnich celsia
  temperature: number; //v stupnich celsia
  dewPoint?: number; // rosny bod v stupnich celsia

  sunrise?: number; //in minutes
  sunset?: number; //in minutes

  moonphase?: number; //1-8 || 1 nov, 5 uplnek, 8 ubyvajici srpek
  isday?: number; // 0/1
  bio?: number;
  fog?: number //0-4 || 0no fog, 1 maybe fog, 2 probably fog, 3 maybe cold fog, 4 probably cold fog
}
