import gql from 'graphql-tag';

export const readSelectedEventTopics = gql`
  query ReadSelectedEventTopics($ids: [String!]!) {
    response: readSelectedEventTopic(ids: $ids) {
      _id
      name
      nameShort
      warningTitle
      warningContent
    }
  }
`;
