import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextComponent} from "./text.component";
import {PrefixModule} from "../prefix/prefix.module";



@NgModule({
  declarations: [TextComponent],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [TextComponent]
})
export class TextModule {
}
