import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrefixModule} from "../../prefix/prefix.module";
import {ItemCardVariantAComponent} from "./item-card-variant-a.component";
import {ResizeObserverModule} from "@echo-nx/shared/ng/feature/responsive";
import {SanitizerModule} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    ItemCardVariantAComponent
  ],
  imports: [
    CommonModule,
    ResizeObserverModule,
    PrefixModule,
    SanitizerModule
  ],
  exports:[
    ItemCardVariantAComponent
  ]
})
export class ItemCardVariantAModule {
}
