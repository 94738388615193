<div class="flex flex-col">

  <!-- next | current | prev -->
  <div class="flex justify-between items-center p-2" [ngClass]="[secondaryColor | prefix:'bg']">
    <!-- previous month -->
    <button (click)="previousMonth()" class="px-4 py-2 text-white">
      <i class="fas fa-chevron-left fa-fw"></i>
    </button>

    <!-- current month and year -->
    <span class="text-white">{{ currentMonth | monthName:language }} {{ currentMonth.getFullYear() }}</span>

    <!-- next month-->
    <button (click)="nextMonth()" class="px-4 py-2 text-white" >
      <i class="fas fa-chevron-right fa-fw"></i>
    </button>
  </div>


  <!-- days of the week -->
  <div class="grid grid-cols-7 gap-2 text-white p-2" [ngClass]="[accentColor | prefix:'bg']">
    <div *ngFor="let day of days"
         class="flex items-center justify-center px-4 py-2">
      {{ day }}
    </div>
  </div>

  <!-- days of the month -->
  <div class="grid grid-cols-7 gap-2 p-2" [ngClass]="[bgColor | prefix:'bg']">
    <div *ngFor="let i of currentMonth | daysOfMonth; first as isFirst"
         class="flex items-center justify-center px-4 py-2 cursor-pointer"
         [ngClass]="[isSelected(i) ? (activeColor | prefix:'bg') : '']"
         [class.text-white]="isSelected(i)"
         (click)="selectDay(i)"
         [style.grid-column]="isFirst ? (currentMonth | firstDayOfMonth) : ''">
      {{i + 1}}
    </div>
  </div>
</div>
