import {IInjectableServiceData} from "@echo-nx/shared/ng/feature/common";
import {DataViewType, IDataEntityService} from "../../interfaces";
import {IEntityService} from "@echo-nx/shared/common";
import {IColumnDefinition} from "../../ui/table/i-column-definition";
import {IGridDefinition} from "../../ui/grid/i-grid-definition";

export interface IDataCardDialogSettings {
  // mimicks @Inputs of DataCard
  filterServiceData?: IInjectableServiceData;
  title: string;
  // if set to true, the option to upload new media will be available in the dialog
  isMedia?: boolean;
  entityServiceData: IInjectableServiceData<IDataEntityService & IEntityService>;
  hideSearch?: boolean;
  hideFilters?: boolean;
  filterDefinition?: any[];
  viewType?: DataViewType;
  isMultiple?: boolean;
  selectOnItemClick?: boolean;
  initiallySelectedValues?: Record<string, any>[];
  tableItemDefinition?: IColumnDefinition[];
  gridItemDefinition?: IGridDefinition;
  // todo add table,grid and item definitions as optional in case of overrides
}
