import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MonitorComponent} from "./monitor.component";
import {SharedNgFeatureCommonModule} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    MonitorComponent
  ],
  imports: [
    CommonModule,
    SharedNgFeatureCommonModule,
  ],
  exports: [
    MonitorComponent
  ]
})
export class MonitorModule {
}
