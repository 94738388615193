<ng-container *ngIf="(reservationService.selectedCoupons$ | async) as coupons">


  <div class="warning" *ngIf="coupons?.length>0 && data?.showWarning">
    <span>V tomto kroku si můžete <b> DOKOUPIT </b> dodatečné vstupenky.
    Poukazy uplatňujte v předchozím kroku.
    Počítadla níže zobrazují pouze dokupované vstupenky.
    </span>
  </div>
</ng-container>
<ng-container *ngFor="let category of (categorizedTickets$ | async)">
  <marlenka-section-header
    [name]="category.name"
    [description]="category.description"
    class="padded-header"
  >
  </marlenka-section-header>

  <marlenka-ticket-type-grid [ticketTypes]="category.tickets"></marlenka-ticket-type-grid>

</ng-container>


<!-- step summary -->
<marlenka-step-summary *ngIf="(reservationService.totals$ | async) as totals">
  <!-- top row -->
  <div top-row-additional>
    Celková cena za vstupenky
  </div>

  <div top-row-main>
    <div>
      <strong>{{totals.tickets.cost}}</strong> Kč
    </div>
  </div>

  <!-- bottom row -->
  <div bottom-row-additional>
    Celková cena za vstupenky a vouchery
  </div>
  <div bottom-row-main>
    <div>
      <strong>{{ totals.coupons.cost + totals.tickets.cost }}</strong> Kč
    </div>
  </div>
</marlenka-step-summary>
