import {Injectable, InjectionToken} from "@angular/core";
import {
  EkioskEntityTypes,
  FetchAllArgs,
  ICategory,
  IEntityService,
  IKiosk, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {readAllKiosks, readSelectedKiosks} from "../queries";
import {addKiosk} from '../mutations'
import {map} from "rxjs/operators";

@Injectable()
export class BaseKioskService implements IEntityService<IKiosk> {

  constructor(protected apollo: Apollo) {}

  getType(): string {
    return EkioskEntityTypes.Kiosk;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, variables: any) {
    return this.apollo.mutate({
      mutation,
      variables
    });
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IKiosk>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IKiosk>>>({
      query: readAllKiosks,
      variables: args
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<IKiosk[]> {
    return this.apollo.query<IPKApolloResult<IKiosk[]>>({
      query: readSelectedKiosks,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IKiosk> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    )
  }

  save(entities: IKiosk[]): Observable<MutationResult<IPKApolloResult<IKiosk[]>>> {
    return this.apollo.mutate<IPKApolloResult<IKiosk[]>>({
      mutation: addKiosk,
      variables: {input: entities}
    });
  }

}

export const BASE_KIOSK_SERVICE_TOKEN = new InjectionToken<IEntityService<IKiosk>>('BASE_KIOSK_SERVICE_TOKEN');
