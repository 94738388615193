export interface IFileMetada {
  name: string;
  mime: string;
  category?: string;
  description?: string;
}


export interface S3ReturnObject {
  ETag: string;
  Location: string;
  Bucket: string;
  Key: string;
}
