import {Component, OnInit} from '@angular/core';
import {BaseNavBarComponent} from "../base-nav-bar.component";

@Component({
  selector: 'echo-nx-navbar-variant-c',
  templateUrl: './navbar-variant-c.component.html',
  styleUrls: ['./navbar-variant-c.component.scss']
})
export class NavbarVariantCComponent extends BaseNavBarComponent {

}
