<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup">
  <mat-label>{{ label }}</mat-label>

  <input
    *ngIf="!settings.isTextArea; else textArea"
    matInput
    [type]="settings.type"
    [required]="settings.povinnost"
    [readonly]="settings.isReadonly"
    [formControlName]="settings.formControlName"
  />

  <ng-template #textArea>
      <textarea
        matInput
        [type]="settings.type"
        [readonly]="settings.isReadonly"
        [required]="settings.povinnost"
        [rows]="settings.textAreaRows ?? 1"
        [cols]="settings.textAreaCols ?? 1"
        [formControlName]="settings.formControlName"
      ></textarea>
  </ng-template>

  <!-- HINTS -->
  <mat-hint align="end"
            *ngIf="settings.maxLength && settings.maxLength > 0">{{formControl.value?.length ? formControl.value?.length : 0}}
    /{{settings.maxLength}}</mat-hint>
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon" [innerHTML]="settings.napoveda.text"></mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
  </mat-icon>

  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>

  <!-- PASSWORD VISIBILITY -->
  <button *ngIf="isPassword" mat-icon-button matSuffix (click)="hideText()" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="isHidden">
    <mat-icon>{{isHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>

  <!-- PASSWORD GENERATOR -->
  <button *ngIf="settings.type === 'newpassword'" matTooltip="Vygenerovat náhodné heslo" mat-icon-button matSuffix
          (click)="genPassword()"
          [attr.aria-label]="'Generate password'">
    <mat-icon>vpn_key</mat-icon>
  </button>

</mat-form-field>

