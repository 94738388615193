import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {

  transform(month: Date, language: string): string {
    return month.toLocaleString(language, {month: 'long'});
  }

}
