import gql from 'graphql-tag';

export const readSelectedProduct = gql`
  query ReadSelectedProduct($ids: [String!]!) {
    response: readSelectedProducts(
      ids: $ids
    ) {
      _id
      name
      description
      language
      languageGroupId
      allergens {
        _id
        text
        number
      }
      weight
      boxSize {
        height
        width
        depth
      }
      ean
      package {
        amountOfCakes
        weight
        ean
        boxSize {
          height
          width
          depth
        }
      }
      pallet {
        height
        amountOfCakes
        amountOfLayers
        amountOfPackagesPerLayer
      }
      tempCondition {
        amountOfDays
        units
        temperatureFrom
        temperatureTo
      }
      owner {
        _id
      }
      categories {
        _id
        name
      }
      priority
      attachments {
        _id
        url
        mime
        name
        type
      }
      media {
        _id
        url
        mime
        name
        type
        mime
        name
      }
      stores {
        _id
        name
      }
      publishedAt
    }
  }
`;
