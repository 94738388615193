<div class="container medium">
  <a
    class="product"
    *ngFor="let product of products$ | async"
    [routerLink]="
      '/' +
      mrdService.currentLanguage.toLowerCase() +
      '/products/detail/' +
      product.languageGroupId
    "
  >
    <figure class="image">
      <img [src]="product.media.url" [alt]="product.media.description" />
    </figure>
    <h4 class="title">
      {{ product.name }}
    </h4>
  </a>
</div>
