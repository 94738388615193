import {Injectable, InjectionToken} from '@angular/core';
import {
  addTimelines,
  deleteTimelines,
  IMarlenkaOwner,
  IMTimeline, MarlenkaEntityTypes, readAllTimelines, readSelectedTimelines, readTimelinesLanguageGroups,
} from "@echo-nx/marlenka/common";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult,
} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {Apollo, MutationResult} from "apollo-angular";
import {map, Observable} from "rxjs";



@Injectable()
export class MarlenkaTimelineService implements IEntityService<IMTimeline> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMTimeline>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMTimeline>>>({
      query: readAllTimelines,
      variables: args
    }).pipe(
      map(result => {
        return result.data.response;
      })
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMTimeline>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMTimeline>>>({
      query: readTimelinesLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMTimeline[]> {
    return this.apollo.query<IPKApolloResult<IMTimeline[]>>({
      query: readSelectedTimelines,
      variables: {
        ids: [ids]
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IMTimeline> {
    return this.apollo.query<IPKApolloResult<IMTimeline[]>>({
      query: readSelectedTimelines,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0])
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteTimelines,
      variables: { input: ids }
    });
  }

  save(timelines: IMTimeline[]): Observable<MutationResult<IPKApolloResult<IMTimeline[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMTimeline[]>>({
      mutation: addTimelines,
      variables: { input: timelines }
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Timeline;
  }

}

export const MTIMELINE_SERVICE_TOKEN = new InjectionToken<IEntityService<IMTimeline>>('MTIMELINE_SERVICE_TOKEN');
