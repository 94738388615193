import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'daysOfMonth'
})
export class DaysOfMonthPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): number[] {
    const numberOfDays = moment(value).daysInMonth();
    return Array.from(Array(numberOfDays).keys())
  }

}
