import gql from 'graphql-tag';

export const readMPartnerStoreLanguageGroups = gql`
  query ReadMPartnerStoreLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readPartnerStoreLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          name
          url
          language
          languageGroupId
          media {
            _id
            url
            description
          }
          owner {
            _id
          }
        }
      }
  }
}
`;
