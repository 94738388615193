import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'prefixHttp'
})
export class PrefixHttpPipe implements PipeTransform {
  transform(value: string, useHttps: boolean = false) {
    if(!value.startsWith('http')){
      return useHttps ? `https://${value}` : `http://${value}`;
    }
    return value;
  }

}
