import { Component, ElementRef, Inject, OnInit, Optional } from '@angular/core';
import { CalendarDay, CalendarDaySettings } from '../calendar.types';
import { DYNAMIC_COMPONENT_INPUT } from '@echo-nx/shared/ng/feature/common';

@Component({
  selector: 'echo-nx-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.scss'],
})
export class CalendarDayComponent {
  constructor(
    @Optional()
    @Inject(DYNAMIC_COMPONENT_INPUT)
    public calendarDay: CalendarDay<CalendarDaySettings>,
    private elementRef: ElementRef<HTMLElement>
  ) {
    const { panelClass } = this.calendarDay.settings;
    if (panelClass) {
      this.elementRef?.nativeElement?.classList.add(panelClass);
    }
  }
}
