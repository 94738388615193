import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { BoxSides } from '@echo-nx/shared/common';

/**
 * @Deprecated
 */
@Directive({
  selector: '[echoNxMarginDefinition]',
})
export class MarginDefinitionDirective implements AfterViewInit {
  @Input() marginDefinition!: BoxSides<string>;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.marginDefinition) {
      if (
        this.marginDefinition.bottom &&
        this.marginDefinition.left &&
        this.marginDefinition.right &&
        this.marginDefinition.top
      ) {
        this.el.nativeElement.style.margin =
          this.marginDefinition.top +
          ' ' +
          this.marginDefinition.right +
          ' ' +
          this.marginDefinition.bottom +
          ' ' +
          this.marginDefinition.left;
      } else {
        this.el.nativeElement.style.marginTop = this.marginDefinition.top;
        this.el.nativeElement.style.marginLeft = this.marginDefinition.left;
        this.el.nativeElement.style.marginRight = this.marginDefinition.right;
        this.el.nativeElement.style.marginBottom = this.marginDefinition.bottom;
      }
    }
  }
}
