import {IBaseFormFieldSettings} from "../modules/form-fields";
import {ValidatorFn} from "@angular/forms";
import {IInjectableEntityFormServiceData} from "@echo-nx/shared/ng/feature/common";

export interface IEntityDefinition<S extends IBaseFormFieldSettings = any> {
  name: EchoNxFormFieldName;
  settings: S;
  disabled?: boolean;
  data: any;
  validators: ValidatorFn[];
  cols: number;
  x?: number;
  y?: number;
  entityFormServiceData?: IInjectableEntityFormServiceData;
}

export type EchoNxFormFieldName =
  'PkInputTextFieldComponent' |
  'PkInputDateFieldComponent' |
  'PkHiddenFieldComponent' |
  'PkSelectFieldComponent' |
  'PkInputChipsFieldComponent' |
  'PkInputWysiwygComponent' |
  //'PkMultilanguageWysiwygComponent' |

  'PkInputAutocompleteComponent' |
  'PkIconFieldComponent' |
  'PkColorPickerFieldComponent' |
  'PkInputCheckboxFieldComponent' |
  'PkInputRepeaterFieldComponent' |
  'PkInputDateTimeFieldComponent' |
  'PkInputBoxFieldComponent' |
  // 'PkMultilanguageInputFieldComponent' |

  'PkInputFileFieldComponent' |
  'PkInputGroupFieldComponent' |
  'PkInputJsonFieldComponent' |
  'EntityPickerFieldComponent';

  // deprecated components:
  // 'PkFroalaWysiwygComponent' |

  // 'PkMultilanguageEntityFieldComponent' |
  // 'PkInputEntityFieldComponent' |
  // 'PkInputMediaFieldComponent' |
  // ^ REPLACED BY EntityPickerFieldComponent
