import {Pipe, PipeTransform} from "@angular/core";
import {EchoNxFilterPipeProps} from '../../types/echo-nx-filter-pipe.props';

@Pipe({
  name: 'echoNxFilter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], settings: EchoNxFilterPipeProps): string {
    if (value && value.length > 0) {
     return value.find(val => val[settings.keyToSearch] === settings.valueToSearch)[settings.keyToShow];
    } else {
      return '';
    }
  }
}
