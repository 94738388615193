import {IMarlenkaOwner} from './owner';
import {IMedium, IMultilanguage, IOwnedEntity} from "@echo-nx/shared/common";

// todo tohle by mohlo/melo byt shared
export interface IMTimeline<M extends IMedium<IMarlenkaOwner> | string = any> extends IOwnedEntity<IMarlenkaOwner | string>, IMultilanguage {
  year: number;
  text: string;
  featuredImage: M;
  attachment?: M[];
  gallery?: string; //todo should be Gallery
}
