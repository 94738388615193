import gql from 'graphql-tag';
import {
  LAYOUT_FRAGMENT,
} from "../fragments";

export const readAllScreensForSynchronizing = gql`
  query ReadAllScreens($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllScreen(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        name
        isDisabilityMode
        deletedAt
        owner{
          _id
        }
        slug
        to
        padding
        categories{
          _id
          name
        }
        theme{
          _id
        }
        background{
          color
          panelClass
          image{
            _id
            type
            url
            thumbnailUrl
            mime
          }
          size
          position
          origin
          repeat
          clip
          attachment
        }
        zones{
          background {
            color
            size
            position
            origin
            repeat
            clip
            attachment
            image{
              _id
              type
              url
              thumbnailUrl
              mime
            }
          }
          to
          display
          flexDirection
          justifyContent
          alignItems
          justifySelf
          alignSelf
          gridColumnStart
          gridColumnEnd
          gridRowStart
          gridRowEnd
          padding
          panelClass
          withContainer
          containerClass
          layouts{
            ... LayoutUnionFragment
          }
        }
      }
    }
  }
  ${LAYOUT_FRAGMENT}
`
