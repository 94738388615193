import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFieldComponent } from './date-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {CustomDateAdapter} from "./custom-date-adapter";
import {FlexModule} from "@angular/flex-layout";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    DateFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatIconModule,
        MatTooltipModule,
        MatNativeDateModule,
        FlexModule,
        TranslateModule
    ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CZ'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
  ]
})
export class DateFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputDateFieldComponent', DateFieldComponent);
  }
}
