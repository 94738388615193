import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { TableField } from '@echo-nx/shared/ng/feature/common';

@Component({
  selector: 'marlenka-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent {
  @Input() fields!: TableField[];
  @Input() items!: Observable<any[]>;
  @Output() remove = new EventEmitter();

  constructor() {}
}
