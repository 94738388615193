import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
  addDistributionPlace,
  deleteDistributionPlace,
  IMarlenkaOwner,
  IMDepartment,
  MarlenkaEntityTypes, readAllDistributions, readDistributionsLanguageGroups, readSelectedDistributionPlace,
} from "@echo-nx/marlenka/common";
import {readAllCategories, readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs, IBaseOwner,
  ICategory,
  ICompanyContact,
  IContact, IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse, IPKApolloResult
} from "@echo-nx/shared/common";

@Injectable()
export class BaseDistributionService implements IEntityService<IMDepartment<any>> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMDepartment<IContact[]>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMDepartment<IContact[]>>>>({
      query: readAllDistributions,
      variables: args,
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMDepartment>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMDepartment>>>({
      query: readDistributionsLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMDepartment<IContact[]>[]> {
    return this.apollo.query<IPKApolloResult<IMDepartment<IContact[]>[]>>({
      query: readSelectedDistributionPlace,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IMDepartment<IContact[] | ICompanyContact[]>> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteDistributionPlace,
      variables: {input: ids}
    });
  }

  save(distribution: IMDepartment[]): Observable<MutationResult<IPKApolloResult<IMDepartment[]>>> {
    //throw Error("Contact aren\'t done. Need to choose input component for it");
    return this.apollo.mutate<IPKApolloResult<IMDepartment[]>>({
      mutation: addDistributionPlace,
      variables: {input: distribution}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(language: string): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language,
        }),
        sortColumn: 'priority'
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Distribution;
  }
}

export const MDISTRIBUTION_SERVICE_TOKEN = new InjectionToken<IEntityService<IMDepartment>>('MDISTRIBUTION_SERVICE_TOKEN');
