import { CommonPageTextItem, CommonImagePageTextItem } from './marlenka-page-settings';

export interface HomepageTexts {
  menuBanner: HomepageMenuBannerText;
  articles: CommonPageTextItem;
  reviews: CommonPageTextItem;
  social: CommonPageTextItem;
}

export interface HomepageMenuBannerText {
  products: CommonImagePageTextItem;
  aboutUs: CommonImagePageTextItem;
  events: CommonImagePageTextItem;
  achievements: CommonImagePageTextItem;
  cakes: CommonImagePageTextItem;
  jobs: CommonImagePageTextItem;
}


