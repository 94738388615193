import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CommonEntityTypes, FetchAllArgs,
  IBaseOwner,
  ICategory, IEntityService, IMutationResponse, IPaginatedResponse, IPKApolloResult,
  IVTScene,
} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity, readAllVTScenes, readSelectedVTScene} from "../queries";
import {addVirtualTourScene} from "../mutations";


//todo pls make sum dummy types for marzipano dummy shit componentos
@Injectable()
export class BaseVirtualTourSceneService implements IEntityService<IVTScene> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IVTScene>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IVTScene>>>({
      query: readAllVTScenes,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IVTScene[]> {
    return this.apollo.query<IPKApolloResult<IVTScene[]>>({
      query: readSelectedVTScene,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IVTScene> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    );
  }

  getType(): string {
    return CommonEntityTypes.VirtualTour;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('Tato akce není aktuálně podporována');
  }

  save(scene: IVTScene[]): Observable<MutationResult<IPKApolloResult<IVTScene[]>>> {
    return this.apollo.mutate<IPKApolloResult<IVTScene[]>>({
      mutation: addVirtualTourScene,
      variables: { input: scene }
    });
  }

  executeMutation() {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<any> {
    return this.apollo.query<IPKApolloResult<ICategory<IBaseOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType(),
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

}

export const BASE_VIRTUAL_TOUR_SCENE_SERVICE_TOKEN = new InjectionToken<IEntityService<IVTScene>>('VIRTUAL_TOUR_SERVICE_TOKEN');
