<div *ngIf="top" class="top-row">
    <div class="additional-text" [class.auto-margin]="autoMarginTop">
        <ng-content select="[top-row-additional]"></ng-content>
    </div>
    <div class="main-text">
        <div class="divider"></div>
        <ng-content select="[top-row-main]"></ng-content>
    </div>
</div>
<div *ngIf="bottom" class="bottom-row">
    <div class="additional-text">
        <ng-content select="[bottom-row-additional]"></ng-content>
    </div>
    <div class="main-text">
        <div class="divider"></div>
        <ng-content select="[bottom-row-main]"></ng-content>
    </div>
</div>
