import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerFieldComponent } from './color-picker-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ColorChromeModule} from "ngx-color/chrome";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";



@NgModule({
  declarations: [
    ColorPickerFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        ColorChromeModule,
        FlexModule,
        MatButtonModule
    ]
})
export class ColorPickerFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkColorPickerFieldComponent', ColorPickerFieldComponent);
  }
}
