import {IOwnedEntity} from "./i-owned-entity";
import {IBaseOwner} from "./i-base-owner";

export interface MongoID {
  _id?: string;
}

export interface BaseTimestamps {
  createdAt?: Date;
  updatedAt?: Date;
}

export interface SoftDeleteTimestamp {
  deletedAt?: Date;
}

export interface PublishableTimestamp {
  publishedAt?: Date;
  unpublishedAt?: Date;
}

export interface BaseDocumentType extends BaseTimestamps, MongoID {
  previousDocuments?: string[];
  archivedAt?: Date;
}

export interface IMultilanguage {
  language: string,
  languageGroupId?: string
}

export interface BaseSoftDeletableDocumentType extends BaseDocumentType, SoftDeleteTimestamp {
}

export interface BasePublishableDocumentType extends BaseDocumentType, PublishableTimestamp{}
// export interface BasePublishableSoftDeletableDocumentType extends BaseSoftDeletableDocumentType, PublishableTimestamp {}
