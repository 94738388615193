import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {from, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {URL_SETTINGS_TOKEN} from "../generic-tokens";
import {BaseAuthService} from "../services";
import {UrlSettings} from "../utils";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: BaseAuthService,
    @Inject(URL_SETTINGS_TOKEN) private urlSettings: UrlSettings,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isLoggedIn()) {
      // get redirect route to auth login from provided settings or default to '/login'
      const loginRoute = this.urlSettings?.auth?.loginRedirect ?? '/login';

      // Not logged in, but has valid refresh token
      const token = this.authService.getToken();
      const refresh_token = this.authService.getRefreshToken();
      if (this.authService.hasValidRefreshToken() && token && refresh_token) {
        return from(this.authService.refresh(token, refresh_token))
          .pipe(
            // switchMap the result from original promise
            map((authToken) => {
              if (!authToken) {
                this.router.navigate([loginRoute], {queryParams: {returnUrl: state.url}});
                return false;
              }
              return true;
            }));
      } else {
        this.router.navigate([loginRoute], {queryParams: {returnUrl: state.url}});
        return false;
      }
    } else {
      return true;
    }
  }
}
