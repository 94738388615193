import {Component} from '@angular/core';
import {BaseItemDetailComponent} from "../base-item-detail.component";

@Component({
  selector: 'echo-nx-item-detail-variant-b',
  templateUrl: './item-detail-variant-b.component.html',
  styleUrls: ['./item-detail-variant-b.component.scss']
})
export class ItemDetailVariantBComponent extends BaseItemDetailComponent  {



}
