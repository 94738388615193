<ng-container *ngIf="buttons$ | async as buttons">
  <div class="p-2 flex gap-2 flex-shrink font-medium"
       [ngClass]="buttons?.length > 0 ? [bgColor | prefix:'bg',textSize | prefix:'text'] : []">
    <!--Leave this DIV here so we have some content that is needed for width calculation-->
    <button class="button"
            *ngIf="buttons?.length > 0"
            [disabled]="activeIndex === 0"
            [ngClass]="activeIndex === 0 ? [disabledColorful?.color | prefix:'text', disabledColorful?.bgColor | prefix:'bg'] :
                                      [normalColorful?.color | prefix:'text', normalColorful?.bgColor | prefix:'bg']"
            (click)="previousClicked()">
      <i class="fas fa-chevron-left fa-fw"></i>
    </button>

    <!-- PAGINATION INDIVIDUAL PAGE BUTTONS-->
    <button class="button"
            *ngFor="let button of buttons"
            (click)="pageClicked(button.index)"
            [disabled]="button.isDisabled || button.isActive"
            [ngClass]="button.isDisabled ? ([disabledColorful.color | prefix:'text', disabledColorful.bgColor | prefix:'bg']) : (button.isActive ?
     [activeColorful.color | prefix:'text', activeColorful.bgColor | prefix:'bg'] :
     [normalColorful.color | prefix:'text', normalColorful.bgColor | prefix:'bg'])">
      {{button.label}}
    </button>

    <!-- PAGINATION NEXT BUTTONS -->
    <button class="button"
            *ngIf="buttons?.length > 0"
            [disabled]="!hasNext()"
            [ngClass]="!hasNext() ? [disabledColorful?.color | prefix:'text', disabledColorful?.bgColor | prefix:'bg'] :
                                      [normalColorful?.color | prefix:'text', normalColorful?.bgColor | prefix:'bg']"
            (click)="nextClicked()">
      <i class="fas fa-chevron-right fa-fw"></i>
    </button>
  </div>

</ng-container>
