import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {NavigationService} from './services/navigation.service';
import {Observable, Subject, combineLatest, switchMap} from 'rxjs';
import {NavigationEnd, Router, RouterOutlet, Scroll} from '@angular/router';
import {filter, map, takeUntil} from 'rxjs/operators';
import {routerAnimations, SlideInOutAnimation} from './animations';
import {
  CommonpageTexts,
  IMNavigation,
  IMSubnavigationItem,
  MarlenkaFooterClass, MarlenkaPageIdentifier,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import {
  ScrollableDirective,
  ScrollableService,
} from '@echo-nx/shared/ng/feature/common';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {CookieConsentManager} from "@echo-nx/shared/ng/feature/cookies";
import packageJson from '../../../../../package.json';
import {PageSettingService} from "./services/page-setting.service";
import {GoogleTagManagerService} from "@echo-nx/shared/ng/feature/gtm";

@Component({
  selector: 'marlenka-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [...SlideInOutAnimation, routerAnimations],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  public version: string = packageJson.version;

  public logoSrc$!: Observable<string>;
  private isDestroyed$ = new Subject<boolean>();
  public navigation$: Observable<IMNavigation>;
  public subnavigation$: Observable<IMSubnavigationItem[] | undefined>;
  public hideFooter$: Observable<boolean>;
  public footerRows$: Observable<number>;

  public isMobileSidebarOpen = false;

  @ViewChild('mobileNav')
  private mobileNav!: ElementRef;

  @ViewChild(ScrollableDirective, {read: ElementRef})
  scrollableElement!: ElementRef;

  public footerClassList$: Observable<MarlenkaFooterClass>;

  public headerClassList$: Observable<string[]>;

  @HostListener('document:mousedown', ['$event'])
  public outsideClick(event: MouseEvent): void {
    if (!this.mobileNav?.nativeElement.contains(event.target)) {
      this.isMobileSidebarOpen = false;
    }
  }

  constructor(
    public navigationService: NavigationService,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    public scrollService: ScrollableService,
    private gtmService: GoogleTagManagerService,
    private cookieConsentManager: CookieConsentManager,
    private router: Router,
    private pageSettingService: PageSettingService,
  ) {
    document.documentElement.dataset.version = this.version;
    this.navigation$ = this.navigationService.fetchMainNavigation();
    this.subnavigation$ = this.navigationService.subnavigationItems$;

    this.hideFooter$ = this.mrdService.routeData$.pipe(
      takeUntil(this.isDestroyed$),
      map((rd) => rd.hideFooter ?? false)
    );

    this.footerRows$ = this.mrdService.routeData$.pipe(
      takeUntil(this.isDestroyed$),
      map((rd) => rd.footerRows ?? 2)
    );

    this.footerClassList$ = this.mrdService.routeData$.pipe(
      takeUntil(this.isDestroyed$),
      map((rd) => rd.footerClass ?? {})
    );

    this.headerClassList$ = this.mrdService.routeData$.pipe(
      takeUntil(this.isDestroyed$),
      map((rd) => rd.headerClass ?? [])
    );

    // anchour routing
    this.enableAnchorRouting();

    this.setupAnalytics();

    this.logoSrc$ = this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      switchMap(lang => this.pageSettingService.getPageSettingsByIdentifier<CommonpageTexts>(MarlenkaPageIdentifier.COMMON, lang)),
      map(({logo}) => logo?.url)
    );
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  private async setupAnalytics(): Promise<void> {
    await this.gtmService.addGtmToDom();

    this.router.events.pipe(
      takeUntil(this.isDestroyed$),
      filter(
        (event) => event instanceof NavigationEnd
      )
    ).subscribe((item: NavigationEnd) => {

      const gtmTag = {
        event: 'page_view',
        "page_url": item.url
      };
      this.gtmService.pushTag(gtmTag).catch(e => {
        console.error('gtm page push failed', e);
      });
    });
  }

  ngAfterViewInit(): void {
    this.scrollService.setScrollable(this.scrollableElement);
  }

  // todo remove this, scrollable should do this on its own
  private enableAnchorRouting(): void {
    this.router.events
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(
          (event) => event instanceof NavigationEnd || event instanceof Scroll
        )
      )
      .subscribe((event) => {
        const tree = this.router.parseUrl(this.router.url);
        const {fragment} = tree;
        if (fragment) {
          // we wait for the dom tree to build
          setTimeout(() => {
            this.scrollService.scrollToElement(
              document.querySelector<HTMLElement>(`#${fragment}`)
            );
          }, 125);
        } else if (!(event instanceof Scroll)) {
          setTimeout(() => {
            this.scrollService.scrollToTop();
          }, 125);
        }
        this.isMobileSidebarOpen = false; //close mobile menu after change, actually works wtf
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }
}
