import {gql} from 'apollo-angular';

export const deleteKioskTimeline = gql`
  mutation deleteKioskTimeline($input: [String!]!){
    response: deleteKioskTimeline(ids: $input){
      description
      error
      userMessage
    }
  }
`;
