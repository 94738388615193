import { Pipe, PipeTransform } from '@angular/core';
import {resolveKeyOrShowDefault} from "../../utils";

@Pipe({
  name: 'resolveKey'
})
export class ResolveKeyPipe implements PipeTransform {

  transform(item: Record<string, any>, key: string[], nullValue?: string) {
    return resolveKeyOrShowDefault(key, item, nullValue);
  }

}
