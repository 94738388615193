import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Button for horizontal scrollable directive (conditionally shows left and right button)
 */

@Directive({
  selector: '[echoNxHorizontalScrollable]',
  exportAs: 'echoNxHorizontalScrollable',
})
export class HorizontalButtonScrollableDirective {
  constructor(private elementRef: ElementRef) {}

  @Input() scrollUnit?: number;

  private get element() {
    return this.elementRef.nativeElement;
  }

  get isOverflow() {
    return this.element.scrollWidth > this.element.clientWidth;
  }

  scroll(direction: number) {
    this.element.scrollLeft += (this.scrollUnit ?? 100) * direction;
    console.log(this.element.scrollLeft);
  }

  get canScrollStart() {
    return this.element.scrollLeft > 0;
  }

  get canScrollEnd() {
    return (
      this.element.scrollLeft + this.element.clientWidth !=
      this.element.scrollWidth
    );
  }

  @HostListener('window:resize')
  onWindowResize() {} // required for update view when windows resized
}
