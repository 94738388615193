import gql from 'graphql-tag';

export const readAllEvents = gql`
  query ReadAllEvents(
    $search: String
    $page: Int
    $take: Int
    $sortColumn: String
    $sortDirection: String
    $filter: String
  ) {
    response: readAllEvent(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        availableCapacity
        start
        duration
        maximumCapacity
        categories {
          _id
          name
        }
        eventTopic {
          _id
          name
          nameShort
          warningTitle
          warningContent
        }
        eventLanguage {
          _id
          name
          nameShort
          warningTitle
          warningContent
        }
      }
    }
  }
`;
