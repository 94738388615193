<div class="wrapper">
  <ng-container *ngIf="isSuccessful; else unsuccessful">
    <marlenka-section-header
      id="reservation-header"
      name="Děkujeme Vám za vaši objednávku!"
    >
      <div content class="content fx-col">
        <p class="description">
          <span class="message">{{message}}</span>
          Na Vaší emailovou adresu Vám nyní zasíláme potvrzení Vaší rezervace, Vaše vstupenky/poukazy a fakturu.
          Změnu rezervovaného termínu můžete uskutečnit jednou, nejpozději však 3 dny před datem rezervované exkurze.
          <br>
          <br>
          Těšíme se na Vaší návštěvu.
        </p>

        <div class="buttons">
          <a class="button secondary" mat-stroked-button [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/create'">
            <mat-icon>shopping_cart</mat-icon>
            <span class="text">Vytvořit další rezervaci</span>
          </a>

          <a class="button primary" mat-stroked-button [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/home'">
            <mat-icon>home</mat-icon>
            <span class="text">Zpět na hlavní stránku</span>
          </a>
        </div>
      </div>

    </marlenka-section-header>
  </ng-container>
</div>


<ng-template #unsuccessful>
  <marlenka-section-header
    name="Vaši objednávku se nepodařilo zpracovat"
    [description]="message"
  >
    <div content class="content fx-col">
        <p class="description">
            Kliknutím na tlačidlo níže platbu zopakujte. Pokud potíže přetrvávají, můžete kontaktovat&nbsp;<a href="mailto:support@echopix.cz">podporu</a>.
        </p>
<!--        <p><button class="buttons" (click)="this.retryPayment()"><a href="button">Zopakovat platbu</a></button></p>-->

        <div class="buttons">
            <a [disabled]="actionTaken" class="button secondary" mat-stroked-button fragment="reservation" (click)="this.retryPayment()" >
                <mat-icon>cached</mat-icon>
                <span class="text">Zopakovat platbu</span>
            </a>
        </div>
      </div>

  </marlenka-section-header>
</ng-template>
