import {IEpxColorful} from "./i-base-attributes";
import {IMedium} from "../i-medium";
import {INavigationItem} from "../kiosk";


export interface IBaseNavbarData {
  smallLogo?: IMedium;
  fullLogo?: IMedium;
  navigation?: INavigationItem;
  searchRoute?: string;
}

export interface IBaseNavbarDesign {
  bgColor?: string;
  primaryColor?:string;
  secondaryColor?: string;

  textColor?: string;
  hoverColorful?: IEpxColorful;

}
