import gql from 'graphql-tag';

export const readAllVoucherTypes = gql`
  query ReadAllVoucherTypes($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeDeleted: Boolean) {
    response: readAllVoucherTypes(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
      includeDeleted: $includeDeleted
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        voucherPreview{
          _id
          name
          url
          mime
        }
        createdAt
      }
    }
  }
`;
