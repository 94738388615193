import gql from 'graphql-tag';

export const readAllReservations = gql`
  query ReadAllReservations($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeDeleted: Boolean) {
    response: readAllReservations(
      search: $search,
      take: $take,
      page: $page,
      sortColumn: $sortColumn,
      sortDirection: $sortDirection,
      filter: $filter,
      includeDeleted: $includeDeleted
    ) {
      total
      page
      take
      hasMore
      items {
        createdAt
        reservedTill
        _id
        glutenFreeCount
        canBeChangedByUser
        voucherType{
          _id
        }
        invoiceCode
        total
        uniqueCode
        contact {
          _id
          name
          surname
          email
          tel
          company {
            name
            ICO
            DIC
            street
            PSC
            city
            paysTaxes
            state
          }
        }
        event {
          _id
          start
        }
        products {
          _id
          code
          itemType {
            _id
            name
            commodityType {
              price
            }
          }
        }
        tickets{
          _id
          code
          claimedAt
          expiresAt
          usedAt
          itemType {
            _id
            name
            icon
            noAdults
            noChildren
            commodityType {
              price
            }
            categories {
              _id
              name
            }
          }
        }
        transactions{
          status
        }
      }
    }
  }
`;
