import {Component} from '@angular/core';
import {state, style, transition, trigger} from "@angular/animations";
import {
  fadeIn,
  fadeOut,
  scaleIn,
  scaleOut, slide,
} from "@echo-nx/shared/ng/feature/animations";
import {BaseSliderComponent} from "../base/base-slider.component";

@Component({
  selector: 'echo-nx-slider',
  templateUrl: './generic-slider.component.html',
  styleUrls: ['./generic-slider.component.scss'],
  animations: [
    trigger('sliderAnimator', [
      state("none", style({opacity: 0})),
      transition("* => fade", fadeIn),
      transition("fade => *", fadeOut),
      transition("* => slide", slide),
      transition("slide => *", slide),
      transition("* => scale", scaleIn),
      transition("scale => *", scaleOut),
    ]),
  ]
})
export class GenericSliderComponent extends BaseSliderComponent {


}
