<div [ngClass]="[bgColor ? (bgColor | prefix: 'bg') : 'dark:bg-gray-800 bg-white']" class="flex p-2 flex-col rounded-lg border shadow-md border-gray-700">
  <div class="flex w-full flex-1 flex-row justify-between items-center mb-2">
    <i [ngClass]="[primaryColor | prefix:'text']" class="flex text-2xl justify-center items-center p-2 w-12 h-12 rounded-full bg-gray-200 dark:text-gray-400 dark:bg-gray-700 fas fa-bus-alt"></i>
    <div class="flex-col flex text-center">
      <div class="text-2xl font-semibold tracking-tight dark:text-white" [ngClass]="[primaryColor | prefix:'text']">{{mode === 'Departures' ? 'ODJEZDY' : 'PŘÍJEZDY'}}</div>
      <div *ngIf="stationName" class="text-sm tracking-tight text-gray-900 dark:text-gray-300">{{stationName}}</div>
    </div>
    <div class="text-2xl font-semibold tracking-tight text-gray-900 dark:text-white text-right">{{syncTime}}</div>
  </div>

  <ng-container *ngIf="(table$ | async) as htmlDoc">
    <div class="w-full max-w-full flex flex-1 prose dark:prose-invert" [innerHTML]="htmlDoc.body.innerHTML"></div>
  </ng-container>
</div>

