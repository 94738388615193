// calendar days
export interface CalendarDay<T extends BaseCalendarDaySettings> {
  date: Date;
  name: 'CalendarDayComponent' | string;
  settings: T;
  disabled?: boolean;
}

// calendar day settings
export interface BaseCalendarDaySettings {
  backgroundColor: string;
  color: string;
}

export interface CalendarDaySettings extends BaseCalendarDaySettings {
  dayFontSize: string;
  text?: string;
  textFontSize?: string;
  panelClass?: string;
}
