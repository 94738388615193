import { BaseLayoutSettingsType } from './base-layout-settings.type';
import {FlexAlignment} from "./flex-layout-settings.type";

export type GridLayoutSettings = {
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridGap?: string;
  justifyContent?: FlexAlignment;
  alignItems?: FlexAlignment;
  panelClass?: string;
} & BaseLayoutSettingsType;
