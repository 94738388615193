import {
  IBaseItemListData,
  IBaseItemListDesign,
  ICategory, IEpxAttribute,
  IEpxColorful,
  IEpxIcon, IEpxOrientation,
  IMedium
} from "@echo-nx/shared/common";
import {AfterViewInit, Directive, HostBinding, Input, OnChanges, OnDestroy, ViewChild} from "@angular/core";
import {ResizeObserverDirective} from "@echo-nx/shared/ng/feature/responsive";
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Directive()
export class BaseItemCardComponent implements IBaseItemListDesign, IBaseItemListData, OnChanges, AfterViewInit, OnDestroy {

  private isDestroyed$: Subject<void> = new Subject<void>();

  @Input()
  accentColor?: string;

  @Input()
  categories: ICategory[];

  @Input()
  date: string;

  @Input()
  dateText: IEpxIcon;

  @Input()
  footer: string;

  @Input()
  footerText: IEpxIcon;

  @Input()
  image: IMedium;

  @Input()
  perex: string;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor?: string;

  @Input()
  prominent: boolean;

  @Input()
  titleText: IEpxColorful;

  @Input()
  title: string;

  @Input()
  attributes?: IEpxAttribute[];

  @Input()
  baseOrientation?: IEpxOrientation = 'row';

  /**
   *  number in pixels:
   *    if container is larger  = breakOrientation
   *    if container is smaller =  baseOrientation
   *  Do not set horizontalBreakpoint = baseOrientation everytime
   *  Set horizontalBreakpoint to 0   = breakOrientation all the time
   */
  @Input()
  horizontalBreakpoint?: number;

  @Input()
  breakOrientation?: IEpxOrientation = 'col';

  @Input()
  fluid;

  public isVertical: boolean;

  public breakpointSettings = {};

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      'flex': this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  @ViewChild(ResizeObserverDirective) resizeDirective: ResizeObserverDirective;

  @Input()
  set design({titleText, footerText, dateText, prominent, accentColor, primaryColor, baseOrientation, breakOrientation, horizontalBreakpoint, secondaryColor, fluid}: IBaseItemListDesign) {
    this.titleText = titleText;
    this.footerText = footerText;
    this.dateText = dateText;
    this.prominent = prominent;
    this.accentColor = accentColor;
    this.primaryColor = primaryColor;
    this.baseOrientation = baseOrientation;
    this.breakOrientation = breakOrientation;
    this.horizontalBreakpoint = horizontalBreakpoint;
    this.secondaryColor = secondaryColor;
    this.fluid = fluid;
  }

  @Input()
  set data({title, date, image, categories, perex, footer, attributes}: IBaseItemListData) {
    this.title = title;
    this.date = date;
    this.image = image;
    this.categories = categories;
    this.perex = perex;
    this.footer = footer;
    this.attributes = attributes;
  }

  ngAfterViewInit(): void {
    // this.isVertical = this.baseOrientation === 'col' || this.baseOrientation === 'col-reverse';
    this.resizeDirective?.activeBreakpoints$?.pipe(
      takeUntil(this.isDestroyed$),
      filter(() => this.baseOrientation?.length > 0)
    ).subscribe((activeBreakpoints) => {
      this.isVertical = activeBreakpoints.some(val => val?.includes('col')) || (activeBreakpoints?.length === 0 && this.baseOrientation?.includes('col'));
    })
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }



  ngOnChanges(): void {
    //for storybook only
    this.breakpointSettings = this.horizontalBreakpoint !== undefined ? {[this.breakOrientation ? 'break-orientation-'+this.breakOrientation : 'break-orientation-col'] : this.horizontalBreakpoint} : undefined
    this.isVertical = this.baseOrientation === 'col' || this.baseOrientation === 'col-reverse';
  }
}
