import gql from 'graphql-tag';

export const readAllLanguage = gql`
  query ReadAllLanguage($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean) {
    response: readAllLanguage(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        code
        publishedAt
      }
    }
  }
`;
