import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

//todo couldnt find any usage, pls delete

@Pipe({name: 'customCurrency', pure: false})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private cp: CurrencyPipe) {
  }

  transform(value: string, currency?: 'CZK' | 'EUR'): string {
    return this.cp.transform(parseFloat(value), currency, "Kč", undefined, 'cs-CZ') ?? '';
  }
}
