<!-- header with product grid and extra gluten info -->
<marlenka-section-header
  name="Doplňková nabídka"
  class="padded-header"
  description="V této sekci si můžete přidat ke vstupenkám ještě něco navíc. Můžete si zde třeba zvolit bezlepkovou ochutnávku nebo si vylepšit zážitek zakoupením ochutnávky originální Arménské brandy či poukazem na nákup v našem  obchodě."
>
  <!-- green box with GF info -->
  <marlenka-gluten-free-bar
    *ngIf="!data?.hideGlutenFree"
    class="gluten-free"
    extra-info
  ></marlenka-gluten-free-bar>

</marlenka-section-header>


<!-- product grid -->
<marlenka-product-type-grid
  [productTypes]="productTypes$ | async"
></marlenka-product-type-grid>

<!-- step summary -->
<marlenka-step-summary>
  <!-- top row -->
  <div top-row-additional>
    Celková cena za doplňkové služby
  </div>
  <div top-row-main>
    <div>
      <strong>{{ (reservationService.totals$ | async).products.cost }}</strong> Kč
    </div>
  </div>


  <!-- bottom row -->
  <div bottom-row-additional>
      Celková cena za vstupenky, vouchery a doplňkové služby
  </div>
  <div bottom-row-main>
    <div>
      <strong>{{ (reservationService.totals$ | async).total.cost }}</strong> Kč
    </div>
  </div>
</marlenka-step-summary>
