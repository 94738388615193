import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDetailVariantRsdComponent } from './item-detail-variant-rsd.component';
import {PrefixModule} from "../../prefix/prefix.module";
import {SharedNgFeatureLightboxGalleryModule} from "@echo-nx/shared/ng/feature/lightbox-gallery";
import {PlyrModule} from "@echo-nx/shared/ng/feature/plyr";



@NgModule({
  declarations: [
    ItemDetailVariantRsdComponent
  ],
  imports: [
    SharedNgFeatureLightboxGalleryModule,
    CommonModule,
    PrefixModule,
    PlyrModule
  ],
  exports: [
    ItemDetailVariantRsdComponent
  ]
})
export class ItemDetailVariantRsdModule { }
