import {InjectionToken} from '@angular/core';
import {UrlSettings} from "./utils";



export const DYNAMIC_COMPONENT_INPUT = new InjectionToken(
  'DYNAMIC_COMPONENT_INPUT'
);


export const URL_SETTINGS_TOKEN = new InjectionToken<UrlSettings>(
  'URL_SETTINGS_TOKEN'
);

export const AVAILABLE_ENTITY_TYPES = new InjectionToken<Record<string, string>>(
  'AVAILABLE_ENTITY_TYPES'
);
