import {IKioskTimeline} from "./timeline";
import {IRole} from "../i-role";
import {IKioskBrowserSettings} from "./kiosk";

export type KioskAuthRequestBody = {
  pwaUniqueId?: string;
  pythonUniqueId?: string;
}

export type KioskAuthToken = {
  _id: string; // ws-client-id (pwaUniqueId or pythonUniqueId or CMS auth _id)
  browserSettings: IKioskBrowserSettings;
  homescreenId: string;
  idleTimeout?: number;
  kioskId: string;
  macAddress?: string;
  ownerId: string;
  pin?: string;
  pwaUniqueId: string;
  pythonUniqueId?: string;
  role?: IRole<any>;
  timeline?: IKioskTimeline<any>;
  timelineTimeout?: number;
}
