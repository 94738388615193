import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { HomePageComponent, ReservationResultPageComponent } from '../../pages';
import { AboutPageComponent } from '../../pages/about-page/about-page.component';
import { ProductsPageComponent } from '../../pages/products-page/products-page.component';
import { DistributionPageComponent } from '../../pages/distribution-page/distribution-page.component';
import { ContactPageComponent } from '../../pages/contact-page/contact-page.component';
import { ExcursionPageComponent } from '../../pages/excursion-page/excursion-page.component';
import { DistributionDetailPageComponent } from '../../pages/distribution-page/distribution-detail-page/distribution-detail-page.component';
import { ArticlesPageComponent } from '../../pages/articles-page/articles-page.component';
import { ArticleDetailPageComponent } from '../../pages/articles-page/article-detail-page/article-detail-page.component';
import { CareerPageComponent } from '../../pages/career-page/career-page.component';
import { ProductDetailComponent } from '../../pages/products-page/product-detail/product-detail.component';
import { RedirectGuard } from './redirect.guard';
import { ReservationEditPageComponent } from '../../pages/reservation/reservation-edit-page/reservation-edit-page.component';
import { SelectPurchaseTypePageComponent } from '../../pages/reservation/reservation-create-page/select-purchase-type-page.component';
import { PageNotFoundComponent } from '../../pages/404/page-not-found.component';
import { ReservationStepperPageComponent } from '../../pages/reservation/reservation-create-page/reservation-stepper-page/reservation-stepper-page.component';
import { VoucherStepperPageComponent } from '../../pages/reservation/reservation-create-page/voucher-stepper-page/voucher-stepper-page.component';
import { CakesExclusivePageComponent } from '../../pages/cakes-exclusive-page/cakes-exclusive-page.component';
import { CakesExclusiveCategoryDetailComponent } from '../../pages/cakes-exclusive-page/cakes-exclusive-category-detail/cakes-exclusive-category-detail.component';
import { VirtualTourPageComponent } from '../../pages/virtual-tour-page/virtual-tour-page.component';
import {
  RouteScrollBehaviour,
  ScrollableOptionsRouteData,
} from '@echo-nx/shared/ng/feature/common';
import {
  MarlenkaPageIdentifier,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import { ArticlesReviewsPageComponent } from '../../pages/articles-reviews-page/articles-reviews-page.component';

interface MarlenkaRoute extends Route {
  data?: MarlenkaRouteData & ScrollableOptionsRouteData;
  //component?: ClassType<MarlenkaBasePageComponent<any>>
}

export const marlenkaRoutes: MarlenkaRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'cs/home',
  },
  {
    path: ':lang/home',
    component: HomePageComponent, //HomePageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.HOMEPAGE,
      scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
      animation: '1',
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
    },
  },
  {
    path: ':lang/about',
    component: AboutPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.ABOUTPAGE,
      scrollBehavior: RouteScrollBehaviour.KEEP_POSITION,
      animation: '2',
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/about/awards',
    component: ArticlesPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.AWARDS,
      animation: '4',
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
    },
  },
  {
    path: ':lang/about/awards/:id',
    component: ArticleDetailPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.AWARDS,
      animation: '3',
      hideFooter: true,
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/about/certificates/:id',
    component: ArticleDetailPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CERTIFICATES,
      animation: '333',
      hideFooter: true,
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/about/certificates',
    component: ArticlesPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CERTIFICATES,
      animation: '444',
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
    },
  },
  {
    path: ':lang/about/videos',
    component: ArticlesPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.VIDEOS,
      animation: '4',
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
    },
  },
  {
    path: ':lang/about/videos/:id',
    component: ArticleDetailPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.VIDEOS,
      animation: '3',
      hideFooter: true,
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/products',
    component: ProductsPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.PRODUCTS,
      animation: '4',
    },
  },
  {
    path: ':lang/products/:categoryId',
    component: ProductDetailComponent,
    data: {
      identifier: MarlenkaPageIdentifier.PRODUCTS_DETAIL,
      hideFooter: true,
      animation: '5',
    },
  },
  {
    path: ':lang/products/:categoryId/:productId',
    component: ProductDetailComponent,
    data: {
      identifier: MarlenkaPageIdentifier.PRODUCTS_DETAIL,
      hideFooter: true,
      animation: '6',
    },
  },
  {
    path: ':lang/cakes-exclusive',
    component: CakesExclusivePageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CAKES_EXCLUSIVE,
      animation: '2',
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/cakes-exclusive/:categoryId',
    component: CakesExclusiveCategoryDetailComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CAKES_EXCLUSIVE_DETAIL,
      animation: '5',
    },
  },
  {
    path: ':lang/home/articles',
    component: ArticlesPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.ARTICLES,
      animation: '7',
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/home/reviews',
    component: ArticlesReviewsPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.ARTICLES_REVIEWS,
      animation: '7',
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/home/articles/:id',
    component: ArticleDetailPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.ARTICLES,
      animation: '8',
      hideFooter: true,
    },
  },
  {
    path: ':lang/excursion',
    component: ExcursionPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.EXCURSION,
      animation: '9',
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/payment-result',
    component: ReservationResultPageComponent,
    data: {
      footerClass: {
        firstRow: ['withoutWave'],
      },
    },
  },
  {
    path: ':lang/create',
    component: SelectPurchaseTypePageComponent,
    data: {
      headerClass: ['darker'],
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
      animation: '10',
      identifier: MarlenkaPageIdentifier.CREATE,
    },
  },
  {
    path: ':lang/create/reservation',
    component: ReservationStepperPageComponent,
    data: {
      headerClass: ['darker'],
      footerClass: {
        firstRow: ['darker'],
      },
      animation: '10',
      identifier: MarlenkaPageIdentifier.CREATE_RESERVATIONANDVOUCHER,
      hideFooter: true,
    },
  },
  {
    path: ':lang/create/voucher',
    component: VoucherStepperPageComponent,
    data: {
      headerClass: ['darker'],
      footerClass: {
        firstRow: ['darker'],
      },
      animation: '10',
      identifier: MarlenkaPageIdentifier.CREATE_RESERVATIONANDVOUCHER,
      hideFooter: true,
    },
  },
  {
    path: ':lang/edit/reservation/:uuid',
    component: ReservationEditPageComponent,
    data: {
      headerClass: ['darker'],
      footerClass: {
        firstRow: ['darker'],
      },
    },
  },
  {
    path: ':lang/distribution',
    component: DistributionPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.DISTRIBUTION,
      animation: '11',
    },
  },
  {
    path: ':lang/distribution/:id',
    component: DistributionDetailPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.DISTRIBUTION,
      animation: '12',
      footerClass: {
        firstRow: ['withoutWave'],
        secondRow: ['secondBg'],
      },
    },
  },
  {
    path: ':lang/career',
    component: CareerPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CAREER,
      animation: '13',
    },
  },
  {
    path: ':lang/contact',
    component: ContactPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.CONTACT,
      animation: '14',
      // footerRows: 3,
    },
  },
  {
    path: ':lang/virtual-tour',
    component: VirtualTourPageComponent,
    data: {
      identifier: MarlenkaPageIdentifier.VIRTUAL_TOUR,
      animation: '15',
      hideFooter: true,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(marlenkaRoutes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled',
    }),
  ],
})
export class RoutingModule {}
