import gql from 'graphql-tag';

export const readAllContact = gql`
  query ReadAllContact($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllContact(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        firstName
        lastName
        telephones
        priority
        emails
        createdAt
        dataBox
        websites
        ico
        companyName
        dic
        address{
          city
          province
          state
          street
          zipcode
        }
        bankInfo{
          bankCode
          bankName
          bankNumber
          iban
          swift
        }
      }
    }
  }
`;
