import {Component, Injector, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {MarlenkaBasePageComponent} from "../../../marlenka-base-page/marlenka-base-page.component";
import {CreateReservationPageTexts} from "@echo-nx/marlenka/common";

@Component({
  selector: 'marlenka-reservation-stepper-page',
  templateUrl: './reservation-stepper-page.component.html',
  styleUrls: ['./reservation-stepper-page.component.scss']
})
export class ReservationStepperPageComponent  extends MarlenkaBasePageComponent<CreateReservationPageTexts> implements OnInit {

  constructor(private location: Location, injector: Injector){
    super(injector);
  }

  ngOnInit() {
    return;
  }

  onBackClicked(stepIndex: number){
    if (stepIndex === 0) {
      this.location.back();
    }
  }
}
