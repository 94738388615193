import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'echo-nx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

  @Input() public foregroundColor!: string;
  @Input() public currentPage!: number;
  @Input() public itemsPerPage!: number;
  @Input() public totalItems!: number;

  @Output() pageChanged = new EventEmitter<number>();

  public totalPages!: number;
  public showingFrom!: number;
  public showingTo!: number;

  constructor(/*colorUtilsService: ColorUtilsService, */private router: Router, private activatedRoute: ActivatedRoute) {

  }

  async changePage(newPageNumber: number) {
    await this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {page: newPageNumber}});
    this.pageChanged.emit(newPageNumber);
  }

  ngOnChanges(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.showingFrom = 1 + (this.currentPage - 1) * this.itemsPerPage;
    this.showingTo = this.currentPage * this.itemsPerPage > this.totalItems ? this.totalItems : this.currentPage * this.itemsPerPage
  }
}
