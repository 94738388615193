import {AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {IChipsFieldSettings} from "./i-chips-field.settings";
import {UntypedFormControl, FormGroupDirective} from "@angular/forms";
import {MatChipInputEvent, MatChipList} from "@angular/material/chips";
import {startWith, takeUntil} from "rxjs/operators";
import {getFirstError} from "../../../utils/form-utils";

@Component({
  selector: 'echo-nx-chips-field',
  templateUrl: './chips-field.component.html',
  styleUrls: ['./chips-field.component.scss']
})
export class ChipsFieldComponent extends BaseFormFieldComponent<IChipsFieldSettings> implements OnInit, OnDestroy, AfterViewInit {

  public firstError?: string;
  public innerData: InnerDataType[] = [];

  @ViewChild('chipList') chipList!: MatChipList;

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IChipsFieldSettings>,
              formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }

  ngAfterViewInit(): void {
    this.formControl?.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((_) => {
        this.checkForErrors();
      });
  }

  private checkForErrors() {
    this.firstError = getFirstError(this.settings.formControlName, this.formGroup);
    if (this.firstError && this.chipList) {
      this.chipList.errorState = true;
    } else if (this.chipList) {
      this.chipList.errorState = false;
    }
  }


  async addOwnFormControl(): Promise<void> {
    this.formControl = new UntypedFormControl([], this.validators);
    this.formGroup?.addControl(this.settings.formControlName, this.formControl);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value ?? []),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(val => {
          this.innerData = val.map((v: string, idx: number) => ({
            id: idx + 1,
            label: v.trim()
          }))
        }
      )
    this.checkForErrors();

  }

  public add(event: MatChipInputEvent): void {
    const {input, value} = event;

    // Add our fruit
    if ((value || '').trim()) {
      if (!this.formControl.value){
        this.formControl.setValue([value]);
      } else {
        this.formControl.value.push(value);
      }

      this.formControl.updateValueAndValidity();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: InnerDataType): void {
    const index = this.innerData.indexOf(item);
    if (index >= 0) {
      (this.formControl.value as []).splice(index, 1);
      this.formControl.updateValueAndValidity();
    }
  }

  override async ngOnDestroy(): Promise<void> {
    await super.ngOnDestroy();
    this.formControl.reset();
    this.formControl.updateValueAndValidity();
    this.innerData = [];
  }

}


interface InnerDataType {
  id: number,
  label: string
}
