import {IEntityDefinition} from "../../interfaces";
import {
  IGroupFieldSettings,
  ITextFieldSettings
} from "../../modules/form-fields";
import {endIconDefinition, routeToEntityDefinition, startIconDefinition} from "../entity-definition-helpers";


export const getTextWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    // SETTINGS
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          // DATA
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Data',
              formControlName: 'textData',
              withoutCard: true,
              groupDefinition: [
                {
                  name: 'PkInputTextFieldComponent',
                  settings: {
                    nazev: 'Text',
                    formControlName: 'title'
                  } as ITextFieldSettings,
                  data: null,
                  validators: [],
                  cols: 12,
                },
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },

          // DESIGN
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Design',
              formControlName: 'textDesign',
              withoutCard: true,
              groupDefinition: [
                startIconDefinition,
                endIconDefinition
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
    // TO
    routeToEntityDefinition,
  ]
}
