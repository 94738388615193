import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapViewComponent} from "./map-view/map-view.component";
import {GoogleMapsModule} from "@angular/google-maps";
import {MapComponent} from './map/map.component';
import {MapInfoThemeRsdComponent} from "./map-info/theme-rsd/map-info-theme-rsd.component";
import {PrefixModule} from "../prefix/prefix.module";
import {MapInfoVariantAComponent} from "./map-info/variant-a/map-info-variant-a.component";

@NgModule({
  declarations: [
    MapViewComponent,
    MapComponent,
    MapInfoThemeRsdComponent,
    MapInfoVariantAComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    PrefixModule
  ],
  exports: [
    MapComponent,
    MapInfoThemeRsdComponent,
    MapInfoVariantAComponent,
    MapViewComponent
  ]
})
export class MapModule {}
