import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ResizeObserverService} from "./resize-observer.service";
import {Observable, startWith, Subject, switchMap, takeUntil} from "rxjs";

@Directive({
  selector: '[echoNxResponsive]',
  exportAs: 'echoNxResponsive'
})
export class ResizeObserverDirective implements OnInit, OnChanges, OnDestroy {

  @Input() echoNxResponsive!: Record<string, number>;

  activeBreakpoints$!: Observable<string[]>;

  private isDestroyed$: Subject<void> = new Subject<void>();

  private breakpointSettings = new Subject<Record<string, number>>();

  constructor(private el: ElementRef, private resizeObserver: ResizeObserverService) {
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  ngOnInit() {
    this.activeBreakpoints$ = this.breakpointSettings.pipe(
      startWith(this.echoNxResponsive),
      takeUntil(this.isDestroyed$),
      switchMap((breakpoints) => {
        return this.resizeObserver.observeBreakpoints(this.el, {breakpoints});
      }));
    this.activeBreakpoints$.subscribe();
  }


  ngOnChanges(): void {
    if (this.echoNxResponsive) {
      this.breakpointSettings.next(this.echoNxResponsive);
    }
  }

}
