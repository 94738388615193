import gql from 'graphql-tag';

export const readMainNavigation = gql`
  query ReadMainNavigation($filter: String){
    response: readAllMNavigation(
      filter: $filter
    )
    {
      items {
        _id
        name
        pages{
          _id
          name
          icon
          identifier
          title
          anchors{
            link
            title
          }
        }
      }
    }
  }`
