import gql from 'graphql-tag';

export const readSelectedReservations = gql`
  query ReadSelectedReservations($ids: [String!]!, $includeDeleted: Boolean) {
    response: readSelectedReservations(
      ids: $ids,
      includeDeleted: $includeDeleted
    ) {
      _id
      glutenFreeCount
      uniqueCode
      uniqueId
      createdAt
      invoiceCode
      canBeChangedByUser
      title
      text
      voucherType {
        bgMedium {
          _id
        }
        contentPosition {
          top
          left
        }
        logoAlignment
        maxWidth
        text {
          color
          fontSize
          textAlignment
        }
        title {
          color
          fontSize
          textAlignment
        }
      }
      event{
        _id
        start
        duration
        availableCapacity
        language
        categories {
          _id
          name
        }
      }
      contact{
        name
        surname
        tel
        email
        note
        company{
          name
          ICO
          DIC
          street
          paysTaxes
          city
          PSC
          state
        }
      }
      tickets{
        _id
        code
        claimedAt
        usedAt
        expiresAt
        itemType{
          _id
          name
          icon
          noAdults
          noChildren
          commodityType{
            price
            articleName
            articleCode
          }
        }
      }
      products{
        _id
        code
        claimedAt
        usedAt
        expiresAt
        itemType{
          name
          image{
            _id
            url
            description
            type
            categories {
              name
            }
          }
          commodityType{
            price
            articleName
            articleCode
          }
        }
      }
      transactions{
        _id
        variableSymbol
        price
        status
        paymentMethod
        createdAt
        updatedAt
      }

    }
  }
`;
