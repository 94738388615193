import {Component} from '@angular/core';
import {BasePhotoCardComponent} from "../base-photo-card.component";

@Component({
  selector: 'echo-nx-photo-card-variant-a',
  templateUrl: './photo-card-variant-a.component.html',
  styleUrls: ['./photo-card-variant-a.component.scss']
})
export class PhotoCardVariantAComponent extends BasePhotoCardComponent {


}
