import {IEntityDefinition} from "../../interfaces";
import {IWysiwygFieldSettings} from "../../modules/form-fields";
import {UrlSettings} from "@echo-nx/shared/ng/feature/common";

export const getItemDetailWidgetEntityDefinition = (args: any): IEntityDefinition[] => {
  const [queryParams, urlSettings] = args;
  const {storeName, id} = queryParams as { storeName: string, id: string };

  // get cms redirect url
  const {cmsBaseUrl} = urlSettings as UrlSettings;
  const suffix = getCmsUrlFromStoreName(storeName);
  const cmsRedirectUrl = `${cmsBaseUrl}/${suffix}/update/${id}`;

  return [
    // DATA
    {
      name: 'PkInputTextFieldComponent',
      disabled: true,
      settings: {
        nazev: 'Editace v CMS',
        formControlName: 'html',
        napoveda: {
          text: `<a href="${cmsRedirectUrl}" target="_blank" class="text-primary-600 underline">Odkaz k editaci</a>`
        },
        defaultValue: 'Tento widget lze editovat pouze v prostředí CMS'
      } as IWysiwygFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
  ]
}

const getCmsUrlFromStoreName = (storeName: string): string => {
  switch (storeName){
    case 'noticeBoards':
      return 'general/notice-board';
    case 'articles':
      return 'general/articles';
    case 'galleries':
      return 'general/gallery';
    case 'events':
      return 'general/events';
    default:
      return '';
  }
}
