import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecordSelectionModel} from "../../utils/record-selection-model";
import {IGridDefinition} from "./i-grid-definition";

@Component({
  selector: 'echo-nx-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input()
  public gridDefinition!: IGridDefinition;

  @Input()
  public selectionModel!: RecordSelectionModel;

  @Input()
  public canSelectMultiple = false;

  @Input()
  public data!: Record<string, any>[];

  @Output()
  public itemClicked = new EventEmitter();

  @Output()
  public toggledSelect = new EventEmitter();

  ngOnInit(): void {
    return;
  }

}
