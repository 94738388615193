import gql from 'graphql-tag';

export const readSelectedMedia = gql`
  query ReadSelectedMedium($ids: [String!]!) {
    response: readSelectedMedia(
      ids: $ids
    ) {
      _id
      name
      type
      mime
      url
      thumbnailUrl
      description
      categories {
        _id
        name
      }
    }
  }
`;
