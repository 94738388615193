import {IOwnedEntity} from "./i-owned-entity";
import { IBaseOwner } from './i-base-owner';

export interface IToken<T extends IBaseOwner | string> extends IOwnedEntity<T> {
  target: string;
  purpose: string;
  expiresAt: Date;
  payload?: string;
  uuid: string;
}

export enum TokenPurpose {
  UNSUBSCRIBE_NEWSLETTER = 'UnsubscribeNewsletter',
  CONFIRM_NEWSLETTER = 'ConfirmNewsletter',
  RESET_PASSWORD = 'ResetPassword',
  CONFIRM_REGISTRATION = 'ConfirmRegistration',
}
