import {
  AfterViewInit,
  Component, ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output, QueryList, TemplateRef,
  ViewChild
} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {IColumnDefinition} from "./i-column-definition";
import {RecordSelectionModel} from "../../utils/record-selection-model";
import {MatCheckbox} from "@angular/material/checkbox";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExpandedItemDirective} from "./expanded-item.directive";
import {TableKeyDirective} from "@echo-nx/shared/ng/feature/common";

@Component({
  selector: 'echo-nx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('masterCheckbox')
  private masterCheckbox!: MatCheckbox;

  private isDestroyed$ = new Subject<boolean>();
  public displayedColumns: string[] = [];

  public expandedItem?: any;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @Input()
  public defaultSort?: Sort;

  @Input()
  public selectionModel!: RecordSelectionModel;

  @Input()
  public itemsTotal!: number;

  @Input()
  public canSelectMultiple = false;

  @Input()
  public isExpandable = false;

  @Input()
  public columnDefinition!: IColumnDefinition[];

  @Input()
  public data!: Record<string, any>[];

  @Output()
  public toggledMasterSelect = new EventEmitter();

  @Output()
  public toggledSelect = new EventEmitter();

  @Output()
  public rowClicked = new EventEmitter();

  @Output()
  public sortChanged = new EventEmitter<Sort>();

  @ContentChildren(ExpandedItemDirective) itemTemplates!: QueryList<ExpandedItemDirective>;
  @ContentChildren(TableKeyDirective) itemRowTemplates!: QueryList<TableKeyDirective>;

  ngOnInit(): void {
    this.displayedColumns = this.columnDefinition.map(col => col.key.join('.'));
  }

  ngAfterViewInit() {
    //apply default sort
    //this logically triggers something changed after view was checked...
    /*if(this.defaultSort){
      this.sort.sort({
        id: this.defaultSort.active,
        start: this.defaultSort.direction === "" ? "desc" : this.defaultSort.direction,
        disableClear: false})
    }*/

    //resend further changes
    this.sort.sortChange.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(res => this.sortChanged.emit(res));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  public isAllSelected(): boolean {
    return this.selectionModel.selected.length === this.itemsTotal;
  }

  public onRowClicked(item: any) {
    this.rowClicked.emit(item);
    if (this.isExpandable) {
      this.expandedItem = this.expandedItem === item ? null : item;
    }
  }

  public getTemplate(): TemplateRef<any> | undefined {
    return this.itemTemplates.get(0)?.templateRef;
  }

  public getCellTemplate(key: string[]): TemplateRef<any> | undefined {
      return this.itemRowTemplates.find(directive => directive.echoNxTableKey === key[0])?.templateRef as TemplateRef<any>;

  }

}
