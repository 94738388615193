export interface IRsdURL {
  URL: string;
}

export interface IRsdVideo extends IRsdURL {
  Type: string;
}

export interface IRsdOdkaz extends IRsdURL {
  Typ: string;
  Popis: string;
}
