import gql from 'graphql-tag';

export const readSelectedTransactions = gql`
  query ReadSelectedTransactions($ids: [String!]!) {
    response: readSelectedTransaction(
      ids: $ids
    ) {
      _id
      variableSymbol
      price
      status
      createdAt
      updatedAt
      }
    }
`;
