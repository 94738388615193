<ng-container class="rows" [formGroup]="formGroup">
  <mat-card *ngIf="!settings.withoutCard; else formFields" class="rounded-xl overflow-hidden">
    <div class="header bg-primary" [style.background]="color">
      <mat-card-title>{{settings.nazev}}</mat-card-title>
      <div class="actions">
        <button *ngIf="settings.disableable" mat-button (click)="toggleDisabled()">
          <mat-icon color="accent">{{disabled ? 'toggle_off' : 'toggle_on'}}</mat-icon>
        </button>
        <button *ngIf="settings.expandable" mat-button (click)="expanded = !expanded">
          <mat-icon color="accent">{{expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
        </button>
      </div>
    </div>

    <mat-card-content>
      <ng-container *ngTemplateOutlet="formFields"></ng-container>
    </mat-card-content>
  </mat-card>


  <ng-template #formFields>
    <div class="fx-row"
         [class.p-0]="settings.withoutCard"
         [style.display]="expanded ? '' : 'none'"
         [formGroup]="childFormGroup"

         echoNxGroupComponentTree
         [entityFormService]="entityFormService"
         [groupControlName]="settings.formControlName">
      <!-- policka -->
      <div
        *ngFor="let field of settings.groupDefinition; let fieldIndex = index"
        [ngClass]="field.name === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
        [style.grid-row-start]="field.y ? field.y + 1 : undefined"
        [style.grid-column-start]="field.x ? field.x + 1 : undefined"
        [style.grid-column-end]="field.x ? field.x + 1 + field.cols : undefined"
        [style.grid-column]="'span ' + field.cols">
        <ng-template
          (attached)="onPortalAttached(field.settings.formControlName, $event)"
          [cdkPortalOutlet]="field | dynamicComponent"
        >
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-container>
