import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {Observable} from "rxjs";
import {IMarlenkaOwner, IMDialogCommodity, readAllDialogCommodities} from "@echo-nx/marlenka/common";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {map} from "rxjs/operators";

@Injectable()
export class BaseDialogCommodityService implements IEntityService<IMDialogCommodity> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args: FetchAllArgs) {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMDialogCommodity>>>({
      query: readAllDialogCommodities,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMDialogCommodity[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchSingle(id: string): Observable<IMDialogCommodity> {
    throw Error('NOT IMPLEMENTED :(');

  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }



  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(commodities: IMDialogCommodity[]): Observable<MutationResult<IPKApolloResult<IMDialogCommodity[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return '';
  }

}

export const MDIALOG_COMMODITY_SERVICE_TOKEN = new InjectionToken<IEntityService<IMDialogCommodity>>('MDIALOG_COMMODITY_SERVICE_TOKEN');
