import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsFieldComponent } from './chips-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MAT_CHIPS_DEFAULT_OPTIONS, MatChipsDefaultOptions, MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    ChipsFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FlexModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule
    ],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      } as MatChipsDefaultOptions
    }
  ]
})
export class ChipsFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputChipsFieldComponent', ChipsFieldComponent);
  }
}
