import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CurrencyDirective,
  // ForegroundColorDirective,
  GapDefinitionDirective,
  HorizontalButtonScrollableDirective,
  LongPressDirective,
  // MacmsColorDirective,
  MarginDefinitionDirective,
  PaddingDefinitionDirective,
  EchoNxSwipeDirective,
  ResizeObserverDirective,
  ScrollableDirective,
  TableKeyDirective,
  DragAndDropDirective
} from './directives';
import {
  StripUrlPipe,
  TimesPipe,
  TruncatePipe,
  SortPipe,
  SplitPipe,
  PrefixHttpPipe,
  CustomCurrencyPipe,
  DetailUrlPipe,
  FileIconPipe,
  FileReaderPipe,
} from "./pipes";




@NgModule({
  imports: [CommonModule],
  declarations: [
    // todo add more and remove unused
    // Directives
    CurrencyDirective,
    EchoNxSwipeDirective,
    // ForegroundColorDirective,
    GapDefinitionDirective,
    HorizontalButtonScrollableDirective,
    LongPressDirective,
    // MacmsColorDirective,
    MarginDefinitionDirective,
    PaddingDefinitionDirective,
    ResizeObserverDirective,
    ScrollableDirective,
    TableKeyDirective,

    // todo add more and remove unused
    // Pipes

    CustomCurrencyPipe,
    DetailUrlPipe,
    PrefixHttpPipe,
    SortPipe,
    SplitPipe,
    StripUrlPipe,
    TimesPipe,
    TruncatePipe,
    DragAndDropDirective,
    FileIconPipe,
    FileReaderPipe
  ],
  exports: [
    // Directives
    CurrencyDirective,
    EchoNxSwipeDirective,
    // ForegroundColorDirective,
    GapDefinitionDirective,
    HorizontalButtonScrollableDirective,
    LongPressDirective,
    // MacmsColorDirective,
    MarginDefinitionDirective,
    PaddingDefinitionDirective,
    ResizeObserverDirective,
    ScrollableDirective,
    TableKeyDirective,
    DragAndDropDirective,

    // Pipes

    CustomCurrencyPipe,
    PrefixHttpPipe,
    SortPipe,
    SplitPipe,
    StripUrlPipe,
    TimesPipe,
    TruncatePipe,
    FileIconPipe,
    FileReaderPipe
  ]
})
export class SharedNgFeatureCommonModule {}
