import {WidgetName} from "../widget.type";
import {ComponentVariant} from "./component-variants";
import {ContentSettings} from "./contentful-settings.type";
import {BaseWidgetSettings} from "./base-settings.type";


export type RepeaterWidgetSettings<M extends RepeaterMapping | string = string, ComponentData = any, ComponentDesign = any> = {
  dynamicComponents: {
    content?: ContentSettings;
    mapping?: M;
  };
  staticComponents?: {
    data: ComponentData;
    design: ComponentDesign;
    componentName: WidgetName;
    variant: ComponentVariant;
  }[];
  append?: boolean; // append static components. default behavior is to prepend, i.e.: [...static components, ...dynamic components]
} & BaseWidgetSettings;

export type RepeaterMapping<Design = any, Data = any> = {
  design: Design;
  data: Data;
  componentName: WidgetName;
  variant: ComponentVariant;
}
