<mat-form-field
  appearance="outline"
  class="cursor-pointer"
  fxFlex
  [formGroup]="formGroup"
  (click)="fileInput.click()">

  <mat-label>{{ settings.nazev }}</mat-label>

  <input
    class="cursor-pointer"
    [value]="description"
    matInput
    readonly
  />

  <input
    hidden
    type="file"
    #fileInput
    [multiple]="settings.isMultiple"
    [required]="settings.povinnost"
    [readonly]="settings.isReadonly"
    (change)="onFileChange($event)"
  />

  <!-- HINTS -->
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon" [innerHTML]="settings.napoveda.text"></mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
  </mat-icon>
</mat-form-field>
