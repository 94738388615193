export interface IBaseDatetimeBarData {
  title?: string;
}

export interface IBaseDatetimeBarDesign {
  fluid?: "horizontal" | "vertical" | "both";
  primaryColor?:string;
  secondaryColor?:string;
  accentColor?: string;
  textColor?: string;
}
