import gql from 'graphql-tag';

// for frontend:
export const readPageSettingsLanguageGroups = gql`
  query ReadPageSettingsLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String,  $filter: String) {
    response: readPageSettingsLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items{
        _id
        docs {
          _id
          name
          identifier
          anchors{
            name
          }
          title
          language
          languageGroupId
        }
      }
    }
  }
`

