export interface IAuthResponse {
  success: boolean,
  message: string,
  data?: IAuthTokenGroup
}

export interface IAuthTokenGroup {
  token: string,
  refresh_token?: string,

  //either one of those
  refresh_expires_at?: Date, //output
  refresh_expires_in?: number, //input

  //and either one of these
  token_expires_at?: Date, //output
  token_expires_in?: number //input
}