import gql from 'graphql-tag';

export const readSelectedTimelines = gql`
  query ReadSelectedTimelines($ids: [String!]!) {
    response: readSelectedTimeline(
      ids: $ids
    ) {
      _id
      language
      languageGroupId
      year
      text
      featuredImage {
        _id
        type
        mime
        name
        url
      }
      attachment {
        type
        name
        mime
        _id
      }
      gallery
      owner {
        _id
      }
    }
  }
`;
