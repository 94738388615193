import { Pipe, PipeTransform } from "@angular/core";
import {isNumeric, pluralize} from "@echo-nx/shared/common";

@Pipe({name: 'pluralize'})
export class PluralizePipe implements PipeTransform {
  transform (input: string | number, plurals: string[]): any {
    if(typeof input === 'number'){
      return pluralize(input, plurals);
    }
    if(isNumeric(input)){
      return pluralize(parseInt(input), plurals);
    }
    return input;
  }
}
