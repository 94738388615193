import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SnackbarComponent} from "./lib/snackbar.component";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent]
})
export class SharedNgFeatureSnackbarModule {}
