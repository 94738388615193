import {Component} from '@angular/core';
import {BaseItemDetailComponent} from "../base-item-detail.component";
import {Options} from "plyr";

@Component({
  selector: 'echo-nx-item-detail-variant-rsd',
  templateUrl: './item-detail-variant-rsd.component.html',
  styleUrls: ['./item-detail-variant-rsd.component.scss']
})
export class ItemDetailVariantRsdComponent extends BaseItemDetailComponent {

  // todo move to base or gallery widget
  public plyrOptions: Options = {
    disableContextMenu: true,
    controls: ['play', 'play-large', 'fullscreen', 'progress', 'mute', 'volume'],
    youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }

  };

}
