import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  CommonEntityTypes,
  FetchAllArgs, ICategory, IEntityService,
  ILanguage,
  IPaginatedResponse, IPKApolloResult,
} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity, readAllLanguage, readSelectedLanguage} from "../queries";
import {addLanguage, deleteLanguage} from "../mutations";

@Injectable()
export class LanguageProviderService implements IEntityService<ILanguage> {

  // this bad boy should provide created languages in the Mongo
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<ILanguage>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ILanguage>>>({
      query: readAllLanguage,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<ILanguage[]> {
    return this.apollo.query<IPKApolloResult<ILanguage[]>>({
      query: readSelectedLanguage,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<ILanguage> {
    return this.apollo.query<IPKApolloResult<ILanguage[]>>({
      query: readSelectedLanguage,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null)
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteLanguage,
      variables: { input: ids, /*forceDelete: true*/ }
    });
  }

  save(langs: ILanguage[]): Observable<MutationResult<IPKApolloResult<ILanguage[]>>> {
    return this.apollo.mutate<IPKApolloResult<ILanguage[]>>({
      mutation: addLanguage,
      variables: { input: langs }
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  //there is no cats in langs
  fetchCategories(): Observable<ICategory<any>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<any>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return CommonEntityTypes.Language;
  }
}
export const BASE_LANGUAGES_SERVICE_TOKEN = new InjectionToken<IEntityService<ILanguage>>('BASE_LANGUAGES_SERVICE_TOKEN');
