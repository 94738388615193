import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import { map } from 'rxjs/operators';
import {
  addProduct, deleteProduct,
  IMarlenkaOwner,
  IMProduct,
  MarlenkaEntityTypes,
  readAllProduct,
  readSelectedProduct,
  readProductLanguageGroups
} from "@echo-nx/marlenka/common";
import {readAllCategories, readAllCategoriesOfEntity,} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs, IBaseOwner,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";

@Injectable()
export class BaseCakesService implements IEntityService<IMProduct> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMProduct<ICategory<IMarlenkaOwner>>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMProduct<ICategory<IMarlenkaOwner>>>>>({
      query: readAllProduct,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMProduct>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMProduct>>>({
      query: readProductLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMProduct<ICategory<IMarlenkaOwner>>[]> {
    return this.apollo.query<IPKApolloResult<IMProduct<ICategory<IMarlenkaOwner>>[]>>({
      query: readSelectedProduct,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IMProduct<ICategory<IMarlenkaOwner>>> {
    return this.apollo.query<IPKApolloResult<IMProduct<ICategory<IMarlenkaOwner>>[]>>({
      query: readSelectedProduct,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null)
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteProduct,
      variables: { input: ids }
    });
  }

  save(cake: IMProduct<string>[]): Observable<MutationResult<IPKApolloResult<IMProduct<string>[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMProduct<string>[]>>({
      mutation: addProduct,
      variables: { input: cake }
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(language: string): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language
        }),
        sortColumn: 'priority',
        sortDirection: 'DESC'
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Products;
  }


}

export const MPRODUCT_SERVICE_TOKEN = new InjectionToken<IEntityService<IMProduct>>('MCAKES_SERVICE_TOKEN');
