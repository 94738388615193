<marlenka-jumbotron
  *ngIf="reservation$ | async as reservation"
  title="Vaše rezervace"
  subtitle="Níže naleznete detaily Vaší rezervace. Váš termín si můžete sami jednou změnit na této stránce."
>

  <div class="reservation-wrapper" *ngIf="(summaryItems$ | async) as summaryItems">
    <div class="fx-col centered texts" *ngIf="reservation.event;else usevoucher">
      <ng-container
        *ngTemplateOutlet="showChangeReservationButton ? button : empty;context: {reservation:reservation}">
      </ng-container>
      <h2>Datum a čas rezervace</h2>
      <span class="subtitle">{{reservation.event.start | date:'dd.MM.yyyy HH:mm'}}</span>
    </div>
    <div class="fx-col centered texts">
      <h3>PDF ke stáhnutí</h3>
      <a
        *ngFor="let url of urls;let i = index"
        class="button primary"
        style="margin-bottom: 10px"
        mat-stroked-button
        [href]="url"
        target="_blank"
      >
        <span class="text">Stáhnout vstupenky / vouchery #{{i + 1}}</span>
        <mat-icon>download</mat-icon>
      </a>
      <a
        *ngIf="reservation?.voucherType"
        class="button primary"
        mat-stroked-button
        [href]="voucherUrl"
        target="_blank"
      >
        <span class="text">Stáhnout dárkový poukaz</span>
        <mat-icon>download</mat-icon>
      </a>
    </div>
    <echo-nx-table-item-list
      [fields]="summaryTableFields"
      [items]="summaryItems"
      [selectable]="false"
      [capitalizeBody]="true"
      [headerBackgroundColor]="null"
      [bodyBackgroundColor]="null"
      [dividerColor]="'#A37D2C'"
    >
      <ng-template echoNxTableKey="nazev" let-item>
        <div class="nazev-cell">
          <mat-icon class="red-icon">{{ item.icon }}</mat-icon>
          <div class="texts">
            <span>{{ item.name }}</span>
            <!--                        <span class="code" *ngIf="item.type ==='discount-coupon'">{{item.uniqueId}}</span>-->
          </div>
        </div>
      </ng-template>
    </echo-nx-table-item-list>

    <marlenka-step-summary [top]="false">
      <div bottom-row-additional>
        Celková cena rezervace
      </div>
      <div bottom-row-main>
        {{ reservation.total }} Kč
      </div>
    </marlenka-step-summary>

    <div class="fx-col centered event-change">
      <ng-container
        *ngTemplateOutlet="showChangeReservationButton ? button : message;context: {reservation:reservation}">
      </ng-container>
      <ng-container *ngIf="toggleReservationChange && !previousEvent">
        <h2 #scrollTarget>Zvolte nový termín</h2>
        <marlenka-event-step [numberOfPplInReservation]="numberOfPplInReservation"></marlenka-event-step>
        <div class="fx-row buttons">
          <button
            *ngIf="!isLoading"
            class="button primary"
            mat-stroked-button
            (click)="changeReservationDate(reservation)"
          >
            <span class="text">Změnit termín</span>
            <mat-icon>published_with_changes</mat-icon>
          </button>
          <div class="loading" *ngIf="isLoading">Probíhá požadavek na změnu termínu...</div>
        </div>
      </ng-container>

      <div class="result" *ngIf="previousEvent">
        Termín rezervace byl úspěšně změněn z {{previousEvent.start | date:'dd.MM.yyyy HH:mm'}}
        na {{reservationService.selectedEvent.start | date: 'dd.MM.yyyy HH:mm'}}.
        Informace o změně, včetně upravených vstupenek Vám byly zaslány na email. Kódy vstupenek zůstanou nezměněné.
      </div>
    </div>
  </div>

</marlenka-jumbotron>

<ng-template #message let-reservation="reservation">
  <span *ngIf="reservation.event && reservation.canBeChangedByUser" class="error fx-col centered texts">Tuto rezervaci již není možno změnit, jelikož uplynul možný termín ({{this.limitDaysBefore | pluralize:['den', 'dny', 'dní']}}
    před datem události).</span>
  <span *ngIf="reservation.event && !reservation.canBeChangedByUser" class="error fx-col centered texts">Změnit rezervaci samovolně lze pouze jednou. Pokud potřebujete opětovnou změnu, kontaktujte zákaznické centrum.</span>
</ng-template>

<ng-template #button let-reservation="reservation">
  <button mat-stroked-button class="button primary" *ngIf="!toggleReservationChange && !previousEvent"
          (click)="toggleChangeReservation(reservation)">
    <span class="text">Přeji si změnit termín rezervace</span>
  </button>
</ng-template>

<ng-template #usevoucher>
  <div class="fx-col centered texts">
    <button mat-stroked-button class="button primary" [routerLink]="
              '/' + mrdService.currentLanguage.toLowerCase() + '/create/reservation'
            ">
      <span class="text">Přejít do Rezervačního systému</span>
    </button>
  </div>
</ng-template>

<ng-template #empty>
  <!--just in case, dont know if I can leave it empty-->
  <span></span>
</ng-template>
