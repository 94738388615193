import gql from "graphql-tag";


export const addMAllergens = gql`
  mutation addMAllergens($input: [MAllergensInputObject!]!){
    response: addAllergens(inputAllergens: $input){
      _id
    }
  }
`;
