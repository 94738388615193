import {IBaseFormFieldSettings} from "../base-form-field.settings";


export interface ITextFieldSettings extends IBaseFormFieldSettings {
  formArray?: {
    name: string;
    index: string;
  };
  isTextArea: boolean;
  type: 'text' | 'number' | 'newpassword' | 'password' | 'time';
  maxLength?: number;
  textAreaRows?: number;
  textAreaCols?: number;
  isJson?: boolean;
}
