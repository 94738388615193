import {IMItemType, IMReservedItem} from "./common";
import {IMarlenkaOwner} from "./owner";
import {IMDialogCommodity} from "./dialog";
import {ICategorizedEntity, ICategory, IMultilanguage} from "@echo-nx/shared/common";


export interface IMTicketType <T extends IMDialogCommodity | string = IMDialogCommodity | string> extends IMItemType<T>, ICategorizedEntity<ICategory<IMarlenkaOwner> | string> {
  icon: string;
  noAdults: number;
  noChildren: number;
  externalIdentifiers?: string[]; // mapping from BeskydyCard/Slevomat -> DialogCommodity
}

export type IMReservedTicket<E extends IMTicketType | string = IMTicketType | string> = IMReservedItem<E>


