import gql from 'graphql-tag';

export const navigationItemFragment = gql`
  fragment NavigationItemFragment on NavigationItemObject {
    _id
    title
    subtitle
    icon
    tooltip
    screen {
      _id
      slug
    }
    isActive
    externalLink
  }
`
