<div class="month-indicator">
  <button mat-icon-button class="backward" (click)="previousMonth()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <div>
    {{ _months[currentMonth.getMonth()] }} {{ currentMonth.getFullYear() }}
  </div>
  <button mat-icon-button class="forward" (click)="nextMonth()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="day-of-week">
  <div *ngFor="let day of _days">
    {{ day }}
  </div>
</div>

<div class="date-grid">
  <ng-container *ngFor="let day of days; index as i; first as isFirst">
    <div
      mat-ripple
      class="day"
      (click)="
        !day.disabled
          ? selectDate(
              currentMonth.getFullYear(),
              currentMonth.getMonth(),
              i + 1
            )
          : null
      "
      [class.today]="isToday(i)"
      [class.selected]="isSelected(i)"
      [class.rounded]="rounded"
      [class.disabled]="day.disabled"
      [style.grid-column]="isFirst ? (currentMonth | firstDayOfMonth) : ''"
      [style.background-color]="day.settings.backgroundColor"
      [style.color]="isSelected(i) ? selectedColor : day.settings.color"
    >
      <ng-template [cdkPortalOutlet]="day | dynamicComponent"> </ng-template>
    </div>
  </ng-container>
</div>
