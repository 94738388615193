import {Directive, HostListener, OnInit} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[echoNxCurrency]',
})
export class CurrencyDirective implements OnInit{
  constructor(public model: NgControl) {
    this.onBlur();
  }

  private isFocused = false;

  ngOnInit() {
    this.model.valueAccessor?.writeValue(
      (this.model.value / 100).toLocaleString('cs-CZ', {
        minimumFractionDigits: 2,
      }) + ' Kč'
    );
  }

  @HostListener('ngModelChange')
  onChange() {
    if (!this.isFocused) {
      this.model.valueAccessor?.writeValue(
        (this.model.value / 100).toLocaleString('cs-CZ', {
          minimumFractionDigits: 2,
        }) + ' Kč'
      );
    }
  }

  @HostListener('blur')
  onBlur() {
    this.isFocused = false;
    this.onChange();
  }

  @HostListener('focus')
  onFocus() {
    this.isFocused = true;
    this.model.valueAccessor?.writeValue(
      parseFloat(this.model.value.toString().replace(' Kč', ''))
    );
  }
}
