import gql from 'graphql-tag';

// todo move to base common
export const readOwnerIntegrations = gql`
  query ReadOwnerIntegrations {
    response: readOwnerIntegrations{
      socialSites {
        instagram {
          short_lived_token
          long_lived_token
        }
      }
    }
  }
`;
