<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup" *ngIf="renderAfterValueSet">
  <mat-label>{{ settings.nazev }}</mat-label>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
  </mat-icon>
  <button mat-button *ngIf="formControl.value" matSuffix mat-icon-button
          aria-label="Clear"
          (click)="formControl.setValue(null); $event.stopPropagation()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-datetimepicker
    #datetimePicker
    class="picker"
    [type]="type"
    openOnFocus="true"
    timeInterval="5"
    [touchUi]="true"
  ></mat-datetimepicker>

  <input
    #datetimeInput
    autocomplete="off"
    matInput
    [formControlName]="settings.formControlName"
    [matDatetimepicker]="datetimePicker"
    [placeholder]="nazev"
    [required]="settings.povinnost"
    (click)="datetimePicker.open()"
  />

  <!-- <mat-error *ngIf="settings.form.get(settings.formControlName)?.errors"><div *ngIf="settings.form.get(settings.formControlName)?.errors.pkerror">{{settings.form.get(settings.formControlName)?.errors}}</div>Toto pole je povinné!</mat-error> -->
  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>
</mat-form-field>
