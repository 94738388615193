<div class="loader" *ngIf="loading">
  <h1>Načítám virtuální prohlídku</h1>
  <div class="spinner"></div>
  <div class="crosshair"></div>
</div>

<div #pano class="pano" id="pano">

</div>


<div class="controls-wrapper">
  <div class="controls">
    <div class="scene-title">{{currentSceneContainer?.dbScene.name}}</div>

    <mat-form-field appearance="outline">
      <mat-label>Scéna</mat-label>
      <mat-select [(ngModel)]="currentSceneIdx" (selectionChange)="onSceneSelected($event)">
        <mat-option *ngFor="let scene of scenes; let i = index" [value]="i">
          {{scene.dbScene.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-icon-button (click)="previousScene()">
      <mat-icon>navigate_before</mat-icon>
    </button>

    <button mat-icon-button (click)="nextScene()">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>
