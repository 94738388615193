import { GridItemSettings } from '../layout-setting';
import {IBackgroundObject} from "../../structural";

export type BaseWidgetSettings = {
  uniqueName?: string; // unique name PER SCREEN - na jedne obrazovce muze byt vice itemlistu. ty se mohou filtrovat pres filter widget a jsou s nim spojene pres FilterService diky tomuto jmenu
  dropAble?: boolean; // wether or not this widget acts as a dropzone - if yes, it will emit the dropped data to widgetEventService as is, so you can catch it and confirm the drop anywhere
  background?: IBackgroundObject;

  position?: 'absolute' | 'relative' | string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
} & GridItemSettings;
