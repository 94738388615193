import gql from 'graphql-tag';

export const readTimelinesLanguageGroups = gql`
  query ReadTimelinesLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readTimelineLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          year
          language
          languageGroupId
          createdAt
          text
          featuredImage {
            _id
            name
            mime
            url
            type
          }
          attachment {
            _id
          }
          gallery
          owner {
            _id
          }
        }
      }
    }
  }
`;
