import gql from "graphql-tag";

export const deleteTicketType = gql`
  mutation deleteTicketType($input: [String!]!){
    response: deleteTicketTypes(ids: $input){
      description
      error
      userMessage
    }
  }
`;
