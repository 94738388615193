import gql from 'graphql-tag';

//Smss
export const readAllSms = gql`
  query ReadAllSms($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllSms(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        text
        date_of_sending
        date_of_payment
        count
        receivers
      }
    }
  }
`;
