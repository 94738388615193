import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseButtonDesign} from "../../structural";

export type FilterSettings = {
  text: string;
  type: 'radio' | 'checkbox' | 'toggle' | string;
  widgetName: string; // widgetName = uniqueName widgetu, ktery na to ma reagovat
  keyName: string;
  values: FilterValueObject[];
  variant?: string;
  buttonDesign?: IBaseButtonDesign;
} & BaseWidgetSettings;

export type FilterValueObject = {
  displayValue: string;
  value: FilterValue;
}

export type FilterValue = string | number | Date;
