import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {createCustomSnackbar} from "@echo-nx/shared/ng/feature/snackbar";


@Component({
  selector: 'marlenka-event-step',
  templateUrl: './event-step.component.html',
  styleUrls: ['./event-step.component.scss']
})
export class EventStepComponent implements OnInit, OnDestroy {

  @Input()
  numberOfPplInReservation = 0;

  private isDestroyed$ = new Subject<void>();

  constructor(private reservationService: ReservationService, private matSnackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.reservationService.onNextFailed$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      createCustomSnackbar('Nejprve si vyberte datum i čas prohlídky',this.matSnackbar);
    })
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
  }

}
