import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  transform(fileType: string): string {
    console.log('filetype is', fileType);
    if (fileType.includes('image')) {
      return 'heroicons_outline:photograph';
    } else if (fileType.includes('document')) {
      return 'heroicons_outline:document';
    } else if (fileType.includes('pdf')) {
      return 'mat_outline:picture_as_pdf';
    } else if (fileType.includes('video')) {
      return 'mat_outline:movie';
    } else {
      return 'mat_outline:file_present';
    }

  }

}
