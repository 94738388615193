import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatRadioChange} from "@angular/material/radio";
import {debounceTime, skip, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {EventsService} from "@echo-nx/marlenka/ng/data-access";


@Component({
  selector: 'marlenka-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterComponent implements OnInit, OnDestroy {

  @Input()
  numberOfPplInReservation = 0;

  public readonly isDestroyed$ = new Subject<boolean>();

  constructor(public eventsService: EventsService) {
  }

  ngOnInit(): void {
    if (this.numberOfPplInReservation > 0) {
      this.eventsService.filter = {...this.eventsService.filter, people: this.numberOfPplInReservation};
    }
    this.eventsService.filter$
      .pipe(
        skip(1),
        takeUntil(this.isDestroyed$),
        debounceTime(175)
      )
      .subscribe(_ => {
        this.eventsService.days = this.eventsService.getDaysWithEvents(this.eventsService.selectedMonth, this.eventsService.events, this.eventsService.filter);
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }


  public languageChosen(event: MatRadioChange) {
    const {value} = event;
    this.eventsService.filter = {
      ...this.eventsService.filter,
      language: value
    }

  }

  public addPeople(): void {
    this.eventsService.filter = {
      ...this.eventsService.filter,
      people: this.eventsService.filter.people + 1
    }
  }

  public removePeople(): void {
    if (this.eventsService.filter.people <= 1) {
      return;
    }

    this.eventsService.filter = {
      ...this.eventsService.filter,
      people: this.eventsService.filter.people - 1
    }
  }

}
