import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MarlenkaBasePageComponent } from '..';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import {DistributionPageTexts, IMarlenkaOwner, IMSubnavigationItem} from "@echo-nx/marlenka/common";
import {BaseDistributionService} from "@echo-nx/marlenka/ng/data-access";
import {ICategory} from "@echo-nx/shared/common";
import {BaseCategoryService} from "@echo-nx/shared/ng/data-access";

@Component({
  selector: 'marlenka-distribution-page',
  templateUrl: './distribution-page.component.html',
  styleUrls: ['./distribution-page.component.scss']
})
export class DistributionPageComponent extends MarlenkaBasePageComponent<DistributionPageTexts> implements OnInit, OnDestroy {

  public distributionPlaces$: Observable<ICategory<IMarlenkaOwner>[]>;
  public onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.navigationService.setSubnavigationItems(undefined);
  }

  constructor(injector: Injector, private activeRoute: ActivatedRoute, private router: Router, private navigationService: NavigationService, private distributionService: BaseDistributionService) {
    super(injector);
  }

  ngOnInit(): void {
    this.distributionPlaces$ = this.mrdService.currentLanguage$.pipe(switchMap((lang)=>this.distributionService.fetchCategories(lang).pipe(
      takeUntil(this.onDestroy$),
      tap((categories) => {
        this.navigationService.setSubnavigationItems(
          categories.map((category => ({
            link: this.router.createUrlTree([`./${category.languageGroupId}`], { relativeTo: this.activeRoute }).toString(),
            title: category.name
          }) as IMSubnavigationItem))
        );
      })
    )));
  }
}
