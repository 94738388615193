export const ISO_3166_ALPHA_2 = {
  "AF":	"Afghánistán",
  "AX":	"Alandy",
  "AL":	"Albánie",
  "DZ":	"Alžírsko",
  "AS":	"Americká Samoa",
  "VI":	"Americké Panenské ostrovy",
  "AD":	"Andorra",
  "AO":	"Angola",
  "AI":	"Anguilla",
  "AQ":	"Antarktida",
  "AG":	"Antigua a Barbuda",
  "AR":	"Argentina",
  "AM":	"Arménie",
  "AW":	"Aruba",
  "AU":	"Austrálie",
  "AZ":	"Ázerbájdžán",
  "BS":	"Bahamy",
  "BH":	"Bahrajn",
  "BD":	"Bangladéš",
  "BB":	"Barbados",
  "BE":	"Belgie",
  "BZ":	"Belize",
  "BY":	"Bělorusko",
  "BJ":	"Benin",
  "BM":	"Bermudy",
  "BT":	"Bhútán",
  "BO":	"Bolívie",
  "BQ":	"Bonaire, Svatý Eustach a Saba",
  "BA":	"Bosna a Hercegovina",
  "BW":	"Botswana",
  "BV":	"Bouvetův ostrov",
  "BR":	"Brazílie",
  "IO":	"Britské indickooceánské území",
  "VG":	"Britské Panenské ostrovy",
  "BN":	"Brunej",
  "BG":	"Bulharsko",
  "BF":	"Burkina Faso",
  "BI":	"Burundi",
  "CK":	"Cookovy ostrovy",
  "CW":	"Curaçao",
  "TD":	"Čad",
  "ME":	"Černá Hora",
  "CZ":	"Česko",
  "CN":	"Čína",
  "DK":	"Dánsko",
  "CD":	"Konžská demokratická republika",
  "DM":	"Dominika",
  "DO":	"Dominikánská republika",
  "DJ":	"Džibutsko",
  "EG":	"Egypt",
  "EC":	"Ekvádor",
  "ER":	"Eritrea",
  "EE":	"Estonsko",
  "ET":	"Etiopie",
  "FO":	"Faerské ostrovy",
  "FK":	"Falklandy (Malvíny)",
  "FJ":	"Fidži",
  "PH":	"Filipíny",
  "FI":	"Finsko",
  "FR":	"Francie",
  "GF":	"Francouzská Guyana",
  "TF":	"Francouzská jižní a antarktická území",
  "PF":	"Francouzská Polynésie",
  "GA":	"Gabon",
  "GM":	"Gambie",
  "GH":	"Ghana",
  "GI":	"Gibraltar",
  "GD":	"Grenada",
  "GL":	"Grónsko",
  "GE":	"Gruzie",
  "GP":	"Guadeloupe",
  "GU":	"Guam",
  "GT":	"Guatemala",
  "GG":	"Guernsey",
  "GN":	"Guinea",
  "GW":	"Guinea-Bissau",
  "GY":	"Guyana",
  "HT":	"Haiti",
  "HM":	"Heardův ostrov a MacDonaldovy ostrovy",
  "HN":	"Honduras",
  "HK":	"Hongkong",
  "CL":	"Chile",
  "HR":	"Chorvatsko",
  "IN":	"Indie",
  "ID":	"Indonésie",
  "IQ":	"Irák",
  "IR":	"Írán",
  "IE":	"Irsko",
  "IS":	"Island",
  "IT":	"Itálie",
  "IL":	"Izrael",
  "JM":	"Jamajka",
  "JP":	"Japonsko",
  "YE":	"Jemen",
  "JE":	"Jersey",
  "ZA":	"Jižní Afrika",
  "GS":	"Jižní Georgie a Jižní Sandwichovy ostrovy",
  "SS":	"Jižní Súdán",
  "JO":	"Jordánsko",
  "KY":	"Kajmanské ostrovy",
  "KH":	"Kambodža",
  "CM":	"Kamerun",
  "CA":	"Kanada",
  "CV":	"Kapverdy",
  "QA":	"Katar",
  "KZ":	"Kazachstán",
  "KE":	"Keňa",
  "KI":	"Kiribati",
  "CC":	"Kokosové (Keelingovy) ostrovy",
  "CO":	"Kolumbie",
  "KM":	"Komory",
  "CG":	"Konžská republika",
  "KP":	"Korejská lidově demokratická republika",
  "KR":	"Korejská republika",
  "XK":	"Kosovo",
  "CR":	"Kostarika",
  "CU":	"Kuba",
  "KW":	"Kuvajt",
  "CY":	"Kypr",
  "KG":	"Kyrgyzstán",
  "LA":	"Laos",
  "LS":	"Lesotho",
  "LB":	"Libanon",
  "LR":	"Libérie",
  "LY":	"Libye",
  "LI":	"Lichtenštejnsko",
  "LT":	"Litva",
  "LV":	"Lotyšsko",
  "LU":	"Lucembursko",
  "MO":	"Macao",
  "MG":	"Madagaskar",
  "HU":	"Maďarsko",
  "MK":	"Severní Makedonie",
  "MY":	"Malajsie",
  "MW":	"Malawi",
  "MV":	"Maledivy",
  "ML":	"Mali",
  "MT":	"Malta",
  "IM":	"Man",
  "MA":	"Maroko",
  "MH":	"Marshallovy ostrovy",
  "MQ":	"Martinik",
  "MU":	"Mauricius",
  "MR":	"Mauritánie",
  "YT":	"Mayotte",
  "UM":	"Menší odlehlé ostrovy USA",
  "MX":	"Mexiko",
  "FM":	"Mikronésie",
  "MD":	"Moldavsko",
  "MC":	"Monako",
  "MN":	"Mongolsko",
  "MS":	"Montserrat",
  "MZ":	"Mosambik",
  "MM":	"Myanmar",
  "NA":	"Namibie",
  "NR":	"Nauru",
  "DE":	"Německo",
  "NP":	"Nepál",
  "NE":	"Niger",
  "NG":	"Nigérie",
  "NI":	"Nikaragua",
  "NU":	"Niue",
  "NL":	"Nizozemsko",
  "NF":	"Norfolk",
  "NO":	"Norsko",
  "NC":	"Nová Kaledonie",
  "NZ":	"Nový Zéland",
  "OM":	"Omán",
  "PK":	"Pákistán",
  "PW":	"Palau",
  "PS":	"Palestina",
  "PA":	"Panama",
  "PG":	"Papua Nová Guinea",
  "PY":	"Paraguay",
  "PE":	"Peru",
  "PN":	"Pitcairn",
  "CI":	"Pobřeží slonoviny",
  "PL":	"Polsko",
  "PR":	"Portoriko",
  "PT":	"Portugalsko",
  "AT":	"Rakousko",
  "RE":	"Réunion",
  "GQ":	"Rovníková Guinea",
  "RO":	"Rumunsko",
  "RU":	"Rusko",
  "RW":	"Rwanda",
  "GR":	"Řecko",
  "PM":	"Saint Pierre a Miquelon",
  "SV":	"Salvador",
  "WS":	"Samoa",
  "SM":	"San Marino",
  "SA":	"Saúdská Arábie",
  "SN":	"Senegal",
  "MP":	"Severní Mariany",
  "SC":	"Seychely",
  "SL":	"Sierra Leone",
  "SG":	"Singapur",
  "SK":	"Slovensko",
  "SI":	"Slovinsko",
  "SO":	"Somálsko",
  "AE":	"Spojené arabské emiráty",
  "US":	"Spojené státy",
  "RS":	"Srbsko",
  "CF":	"Středoafrická republika",
  "SD":	"Súdán",
  "SR":	"Surinam",
  "SH":	"Svatá Helena",
  "LC":	"Svatá Lucie",
  "BL":	"Svatý Bartoloměj",
  "KN":	"Svatý Kryštof a Nevis",
  "MF":	"Svatý Martin (FR)",
  "SX":	"Svatý Martin (NL)",
  "ST":	"Svatý Tomáš a Princův ostrov",
  "VC":	"Svatý Vincenc a Grenadiny",
  "SZ":	"Svazijsko",
  "SY":	"Sýrie",
  "SB":	"Šalomounovy ostrovy",
  "ES":	"Španělsko",
  "SJ":	"Špicberky a Jan Mayen",
  "LK":	"Šrí Lanka",
  "SE":	"Švédsko",
  "CH":	"Švýcarsko",
  "TJ":	"Tádžikistán",
  "TZ":	"Tanzanie",
  "TH":	"Thajsko",
  "TW":	"Tchaj-wan",
  "TG":	"Togo",
  "TK":	"Tokelau",
  "TO":	"Tonga",
  "TT":	"Trinidad a Tobago",
  "TN":	"Tunisko",
  "TR":	"Turecko",
  "TM":	"Turkmenistán",
  "TC":	"Turks a Caicos",
  "TV":	"Tuvalu",
  "UG":	"Uganda",
  "UA":	"Ukrajina",
  "UY":	"Uruguay",
  "UZ":	"Uzbekistán",
  "CX":	"Vánoční ostrov",
  "VU":	"Vanuatu",
  "VA":	"Vatikán",
  "GB":	"Velká Británie",
  "VE":	"Venezuela",
  "VN":	"Vietnam",
  "TL":	"Východní Timor",
  "WF":	"Wallis a Futuna",
  "ZM":	"Zambie",
  "EH":	"Západní Sahara",
  "ZW":	"Zimbabwe",
}
export const ISO_3166_N_3 = {
  "004":  "Afghánistán",
  "248":  "Alandy",
  "008":  "Albánie",
  "012":  "Alžírsko",
  "016":  "Americká Samoa",
  "850":  "Americké Panenské ostrovy",
  "020":  "Andorra",
  "024":  "Angola",
  "660":  "Anguilla",
  "010":  "Antarktida",
  "028":  "Antigua a Barbuda",
  "032":  "Argentina",
  "051":  "Arménie",
  "533":  "Aruba",
  "036":  "Austrálie",
  "031":  "Ázerbájdžán",
  "044":  "Bahamy",
  "048":  "Bahrajn",
  "050":  "Bangladéš",
  "052":  "Barbados",
  "056":  "Belgie",
  "084":  "Belize",
  "112":  "Bělorusko",
  "204":  "Benin",
  "060":  "Bermudy",
  "064":  "Bhútán",
  "068":  "Bolívie",
  "535":  "Bonaire, Svatý Eustach a Saba",
  "070":  "Bosna a Hercegovina",
  "072":  "Botswana",
  "074":  "Bouvetův ostrov",
  "076":  "Brazílie",
  "086":  "Britské indickooceánské území",
  "092":  "Britské Panenské ostrovy",
  "096":  "Brunej",
  "100":  "Bulharsko",
  "854":  "Burkina Faso",
  "108":  "Burundi",
  "184":  "Cookovy ostrovy",
  "531":  "Curaçao",
  "148":  "Čad",
  "499":  "Černá Hora",
  "203":  "Česko",
  "156":  "Čína",
  "208":  "Dánsko",
  "180":  "Konžská demokratická republika",
  "212":  "Dominika",
  "214":  "Dominikánská republika",
  "262":  "Džibutsko",
  "818":  "Egypt",
  "218":  "Ekvádor",
  "232":  "Eritrea",
  "233":  "Estonsko",
  "231":  "Etiopie",
  "234":  "Faerské ostrovy",
  "238":  "Falklandy(Malvíny)",
  "242":  "Fidži",
  "608":  "Filipíny",
  "246":  "Finsko",
  "250":  "Francie",
  "254":  "Francouzská Guyana",
  "260":  "Francouzská jižní a antarktická území",
  "258":  "Francouzská Polynésie",
  "266":  "Gabon",
  "270":  "Gambie",
  "288":  "Ghana",
  "292":  "Gibraltar",
  "308":  "Grenada",
  "304":  "Grónsko",
  "268":  "Gruzie",
  "312":  "Guadeloupe",
  "316":  "Guam",
  "320":  "Guatemala",
  "831":  "Guernsey",
  "324":  "Guinea",
  "624":  "Guinea - Bissau",
  "328":  "Guyana",
  "332":  "Haiti",
  "334":  "Heardův ostrov a MacDonaldovy ostrovy",
  "340":  "Honduras",
  "344":  "Hongkong",
  "152":  "Chile",
  "191":  "Chorvatsko",
  "356":  "Indie",
  "360":  "Indonésie",
  "368":  "Irák",
  "364":  "Írán",
  "372":  "Irsko",
  "352":  "Island",
  "380":  "Itálie",
  "376":  "Izrael",
  "388":  "Jamajka",
  "392":  "Japonsko",
  "887":  "Jemen",
  "832":  "Jersey",
  "710":  "Jižní Afrika",
  "239":  "Jižní Georgie a Jižní Sandwichovy ostrovy",
  "728":  "Jižní Súdán",
  "400":  "Jordánsko",
  "136":  "Kajmanské ostrovy",
  "116":  "Kambodža",
  "120":  "Kamerun",
  "124":  "Kanada",
  "132":  "Kapverdy",
  "634":  "Katar",
  "398":  "Kazachstán",
  "404":  "Keňa",
  "296":  "Kiribati",
  "166":  "Kokosové(Keelingovy) ostrovy",
  "170":  "Kolumbie",
  "174":  "Komory",
  "178":  "Konžská republika",
  "408":  "Korejská lidově demokratická republika",
  "410":  "Korejská republika",
  "095":  "Kosovo",
  "188":  "Kostarika",
  "192":  "Kuba",
  "414":  "Kuvajt",
  "196":  "Kypr",
  "417":  "Kyrgyzstán",
  "418":  "Laos",
  "426":  "Lesotho",
  "422":  "Libanon",
  "430":  "Libérie",
  "434":  "Libye",
  "438":  "Lichtenštejnsko",
  "440":  "Litva",
  "428":  "Lotyšsko",
  "442":  "Lucembursko",
  "446":  "Macao",
  "450":  "Madagaskar",
  "348":  "Maďarsko",
  "807":  "Severní Makedonie",
  "458":  "Malajsie",
  "454":  "Malawi",
  "462":  "Maledivy",
  "466":  "Mali",
  "470":  "Malta",
  "833":  "Man",
  "504":  "Maroko",
  "584":  "Marshallovy ostrovy",
  "474":  "Martinik",
  "480":  "Mauricius",
  "478":  "Mauritánie",
  "175":  "Mayotte",
  "581":  "Menší odlehlé ostrovy USA",
  "484":  "Mexiko",
  "583":  "Mikronésie",
  "498":  "Moldavsko",
  "492":  "Monako",
  "496":  "Mongolsko",
  "500":  "Montserrat",
  "508":  "Mosambik",
  "104":  "Myanmar",
  "516":  "Namibie",
  "520":  "Nauru",
  "276":  "Německo",
  "524":  "Nepál",
  "562":  "Niger",
  "566":  "Nigérie",
  "558":  "Nikaragua",
  "570":  "Niue",
  "528":  "Nizozemsko",
  "574":  "Norfolk",
  "578":  "Norsko",
  "540":  "Nová Kaledonie",
  "554":  "Nový Zéland",
  "512":  "Omán",
  "586":  "Pákistán",
  "585":  "Palau",
  "275":  "Palestina",
  "591":  "Panama",
  "598":  "Papua Nová Guinea",
  "600":  "Paraguay",
  "604":  "Peru",
  "612":  "Pitcairn",
  "384":  "Pobřeží slonoviny",
  "616":  "Polsko",
  "630":  "Portoriko",
  "620":  "Portugalsko",
  "040":  "Rakousko",
  "638":  "Réunion",
  "226":  "Rovníková Guinea",
  "642":  "Rumunsko",
  "643":  "Rusko",
  "646":  "Rwanda",
  "300":  "Řecko",
  "666":  "Saint Pierre a Miquelon",
  "222":  "Salvador",
  "882":  "Samoa",
  "674":  "San Marino",
  "682":  "Saúdská Arábie",
  "686":  "Senegal",
  "580":  "Severní Mariany",
  "690":  "Seychely",
  "694":  "Sierra Leone",
  "702":  "Singapur",
  "703":  "Slovensko",
  "705":  "Slovinsko",
  "706":  "Somálsko",
  "784":  "Spojené arabské emiráty",
  "840":  "Spojené státy",
  "688":  "Srbsko",
  "140":  "Středoafrická republika",
  "729":  "Súdán",
  "740":  "Surinam",
  "654":  "Svatá Helena",
  "662":  "Svatá Lucie",
  "652":  "Svatý Bartoloměj",
  "659":  "Svatý Kryštof a Nevis",
  "663":  "Svatý Martin(FR)",
  "534":  "Svatý Martin(NL)",
  "678":  "Svatý Tomáš a Princův ostrov",
  "670":  "Svatý Vincenc a Grenadiny",
  "748":  "Svazijsko",
  "760":  "Sýrie",
  "090":  "Šalomounovy ostrovy",
  "724":  "Španělsko",
  "744":  "Špicberky a Jan Mayen",
  "144":  "Šrí Lanka",
  "752":  "Švédsko",
  "756":  "Švýcarsko",
  "762":  "Tádžikistán",
  "834":  "Tanzanie",
  "764":  "Thajsko",
  "158":  "Tchaj - wan",
  "768":  "Togo",
  "772":  "Tokelau",
  "776":  "Tonga",
  "780":  "Trinidad a Tobago",
  "788":  "Tunisko",
  "792":  "Turecko",
  "795":  "Turkmenistán",
  "796":  "Turks a Caicos",
  "798":  "Tuvalu",
  "800":  "Uganda",
  "804":  "Ukrajina",
  "858":  "Uruguay",
  "860":  "Uzbekistán",
  "162":  "Vánoční ostrov",
  "548":  "Vanuatu",
  "336":  "Vatikán",
  "826":  "Velká Británie",
  "862":  "Venezuela",
  "704":  "Vietnam",
  "626":  "Východní Timor",
  "876":  "Wallis a Futuna",
  "894":  "Zambie",
  "732":  "Západní Sahara",
  "716":  "Zimbabwe",
}
