import {Component, Inject, Injector, OnDestroy, OnInit, Optional} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {IFileFieldSettings} from "./i-file-field.settings";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {concatFileNames} from "@echo-nx/shared/common";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss']
})
export class FileFieldComponent extends BaseFormFieldComponent<IFileFieldSettings> implements OnInit, OnDestroy {

  public description = '';

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IFileFieldSettings>,formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  override async ngOnDestroy(): Promise<void> {
    this.isDestroyed$.unsubscribe();
  }

  public onFileChange(event: Event) {
    const { target } = event;
    const { files } = target as HTMLInputElement;
    if (files && files?.length > 0) {
      this.description = concatFileNames(files);
      const {isMultiple} = this.settings;
      this.formControl.setValue(isMultiple ? files : files[0])
    }
  }

}
