<marlenka-section-header name="Filtr vhodných termínů" class="padded-header">
  <marlenka-filter [numberOfPplInReservation]="numberOfPplInReservation" extra-info></marlenka-filter>
  <marlenka-calendar-legend content></marlenka-calendar-legend>
</marlenka-section-header>

<div class="content">
  <marlenka-calendar class="calendar"></marlenka-calendar>

  <marlenka-event-list></marlenka-event-list>
</div>
