import gql from 'graphql-tag';

export const readAllTimelines = gql`
  query ReadAllTimelines($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllTimeline(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        language
        languageGroupId
        year
        createdAt
        text
        featuredImage {
          _id
          name
          mime
          url
          type
        }
        attachment {
          _id
        }
        gallery
        owner {
          _id
        }
      }
    }
  }
`;
