import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import {
  CommonEntityTypes, FetchAllArgs,
  ICategory, IEntityService,
  IMutationResponse, IPaginatedResponse, IPKApolloResult,
} from "@echo-nx/shared/common";
import {deleteCategory} from "../mutations/category/delete-category";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {readAllCategories, readSelectedCategory} from "../queries";
import {addCategory} from "../mutations";

@Injectable()
export class BaseCategoryService implements IEntityService<ICategory<any>> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<ICategory<any>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<any>>>>({
      query: readAllCategories,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<ICategory<any>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<any>[]>>({
      query: readSelectedCategory,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<ICategory<any>> {
    return this.apollo.query<IPKApolloResult<ICategory<any>[]>>({
      query: readSelectedCategory,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null)
    );
  }

  getType(): string {
    return CommonEntityTypes.Category;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    return this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
      mutation: deleteCategory,
      variables: { input: ids }
    });
  }

  save(category: ICategory<any>[]): Observable<MutationResult<IPKApolloResult<ICategory<any>[]>>> {
    return this.apollo.mutate<IPKApolloResult<ICategory<any>[]>>({
      mutation: addCategory,
      variables: { input: category }
    });
    // res.subscribe(res => {console.log(res)});
    // return res;
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<any> {
    throw Error('No categories in Category.');
  }

}

export const BASE_CATEGORY_SERVICE_TOKEN = new InjectionToken<IEntityService<ICategory<any>>>('BASE_CATEGORY_SERVICE_TOKEN');
