<!-- zde si pridavej komponenty ktere budes implementovat a testovat, at je vidis -->
<marlenka-menu-grid-banner [topWave]="true"></marlenka-menu-grid-banner>

<!--
<marlenka-jumbotron
  title="O nás"
  withDivider="true"
  subtitle="Příběh medových dortů MARLENKA® je úzce spjat se svým zakladatelem Gevorgem Avetisjanem,
který přišel z Arménie do Frýdku-Místku na přelomu tisíciletí. Z rodné země ho vyhnala válka a nestabilita,
a tak přemýšlel, jak se v Čechách uživí. Jako cizinec to neměl vůbec snadné. V nelehkých chvílích poprosil svou sestru,
aby mu pro radost upekla jejich oblíbený medový dort podle rodinného receptu. Kousek odnesl ochutnat
kamarádovi a majiteli kavárny a tak se započal příběh dnes již domácí marlenky."
  background="url(https://i.imgur.com/C6GCNGK.jpg)"
  textColor="#fff"
  [topWave]="true"
  minHeight="80vh"
>
</marlenka-jumbotron>


<marlenka-jumbotron
  title="Test"
  withDivider="false"
  background="#F3EFE6"
  [topWave]="true"
>
</marlenka-jumbotron>
-->

<div class="container">
  <marlenka-jumbotron title="Co je nového?" withDivider="true">
    <div class="article-grid">
      <marlenka-article-preview></marlenka-article-preview>
      <marlenka-article-preview></marlenka-article-preview>
      <marlenka-article-preview></marlenka-article-preview>
    </div>
  </marlenka-jumbotron>
</div>
<!--
<marlenka-jumbotron></marlenka-jumbotron>
<marlenka-article-header></marlenka-article-header>
-->

<marlenka-footer></marlenka-footer>
