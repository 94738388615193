import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  checkStatus,
  IMarlenkaOwner,
  MarlenkaEntityTypes,
  readAllTransactions,
  readSelectedTransactions, refundTransaction
} from "@echo-nx/marlenka/common";
import {
  CSOBPaymentInfo,
  FetchAllArgs,
  ICategory,
  IEntityService,
  IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult,
  ITransaction
} from "@echo-nx/shared/common";


@Injectable()
export class BaseTransactionService implements IEntityService<ITransaction<CSOBPaymentInfo>> {

  constructor(protected apollo: Apollo) {
  }

  refund(id: string): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    return this.apollo.mutate<IPKApolloResult<IMutationResponse>>(
      {
        mutation: refundTransaction,
        variables: {id}
      });
  }


  checkStatus(id: string): Observable<MutationResult<IPKApolloResult<ITransaction<CSOBPaymentInfo>>>> {
    return this.apollo.mutate<IPKApolloResult<ITransaction<CSOBPaymentInfo>>>(
      {
        mutation: checkStatus,
        variables: {id}
      });
  }

  fetchAll(args: FetchAllArgs): Observable<IPaginatedResponse<ITransaction<CSOBPaymentInfo>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ITransaction<CSOBPaymentInfo>>>>({
      query: readAllTransactions,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<ITransaction<CSOBPaymentInfo>[]> {
    return this.apollo.query<IPKApolloResult<ITransaction<CSOBPaymentInfo>[]>>({
      query: readSelectedTransactions,
      variables: {
        ids: ids
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSingle(id: string): Observable<ITransaction<CSOBPaymentInfo>> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0] || null),
    )
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }


  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(ticketTypes: ITransaction<CSOBPaymentInfo>[]): Observable<MutationResult<IPKApolloResult<ITransaction<CSOBPaymentInfo>[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return MarlenkaEntityTypes.Transaction;
  }
}

export const MTRANSACTION_SERVICE_TOKEN = new InjectionToken<IEntityService<ITransaction<CSOBPaymentInfo>>>('MTRANSACTION_SERVICE_TOKEN');
