import gql from 'graphql-tag';

export const readGalleryLanguageGroups = gql`
  query ReadAllGalleryLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readGalleryLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          name
          description
          slug
          color
          updatedAt
          owner {
            _id
            name
          }
          featuredImage {
            url
            thumbnailUrl
            type
          }
          createdAt
          publishedAt
          language
          languageGroupId
        }
      }
    }
  }
`;
