import gql from 'graphql-tag';

export const readAllCategoriesOfEntityNoImage = gql`
  query ReadAllCategoriesOfEntity($type: String!,$sortColumn: String, $sortDirection: String) {
    response: readAllCategoryOfType(
      type: $type,
      sortColumn: $sortColumn,
      sortDirection: $sortDirection
    ) {
      _id
      color
      isSystemic
      name
      publishedAt
      description
      type
      priority
    }
  }
`;
