import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IDataCardDialogSettings} from "./i-data-card-dialog.settings";
import {DataCardComponent} from "../../data-card.component";
import {UploadMediaComponent} from "@echo-nx/shared/ng/feature/media-upload";

@Component({
  selector: 'echo-nx-data-card-dialog',
  templateUrl: './data-card-dialog.component.html',
  styleUrls: ['./data-card-dialog.component.scss']
})
export class DataCardDialogComponent implements OnInit {
  /**
   * Always returns an array of selected entities, even if its a single entity.
   */



  @ViewChild(DataCardComponent)
  public dataCardComponent!: DataCardComponent;

  @ViewChild('uploadMediaComponent', {static: false})
  public uploadMediaComponent!: UploadMediaComponent;

  public uploadResult?: {error: boolean, message: string};

  constructor(@Inject(MAT_DIALOG_DATA) public settings: IDataCardDialogSettings,
              private dialogRef: MatDialogRef<DataCardDialogComponent>) {
  }

  ngOnInit(): void {
    console.log('Spawned data card dialog with settings:', this.settings);
    return;
  }

  public onConfirmClicked() {
    const {selection} = this.dataCardComponent;
    const {selected} = selection;
    this.dialogRef.close(selected);
  }

  public onCancelClicked() {
    this.dialogRef.close();
  }

  public uploadMedia(){
    this.uploadMediaComponent.uploadFiles().toPromise()
      .then((res) => {
        this.dataCardComponent.refetchData();
        const isAllSuccessful = res?.every(r => !r.error);

        this.uploadResult = {
          error: !isAllSuccessful,
          message: isAllSuccessful ? 'Všechna média byla úspěšně nahrána.' : 'Některá média se nepodařilo nahrát.',
        }
      });
  }

}
