import {MongoID} from "@echo-nx/shared/common";

export interface IMContactPersonForm {
  formValid: boolean;
  // should reflect the contactForm in ContactStep
  formData?: {
    person: IMReservedContact;
    isCompany: boolean;
    company: IMReservedCompanyContact;
  };
}

export interface IMReservedContact extends MongoID {
  name: string;
  surname: string;
  email: string;
  tel: string;
  gdpr: boolean;
  tos: boolean;
  note?: string;
  company?: IMReservedCompanyContact;
}

//fixme I need this JMENO_PARTNERA,ULICE,MESTO,PSC,STAT,ICO,DIC,PLATCE_DPH
export interface IMReservedCompanyContact {
  ICO: string;
  DIC?: string;
  name: string;
  city: string;
  PSC: string;
  street: string;
  state: string;
  paysTaxes: boolean;
}
