import {gql} from 'apollo-angular';

export const deleteMedia = gql`
  mutation deleteMedia($input: [String!]!, $forceDelete: Boolean){
    response: deleteMedia(ids: $input, forceDelete: $forceDelete){
      userMessage
      description
      error
    }
  }
`;
