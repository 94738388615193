<h2 mat-dialog-title>{{data.customizer.title}}</h2>

<mat-dialog-content>
 <div class="choice" *ngFor="let choice of data.customizer.choices">
   <mat-checkbox [(ngModel)]="formValues[choice.event]" [disabled]="choice.alwaysOn">{{choice.title}}</mat-checkbox>
   <div class="choice-information">{{choice.information}}</div>
 </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button
          class="button"
          color="accent"
          [mat-dialog-close]="allowAll()">{{data.buttons.allow}}
  </button>
  <button mat-stroked-button
          class="button primary"
          [mat-dialog-close]="formValues">{{data.buttons.confirm}}
  </button>
</mat-dialog-actions>
