import { /*BasePublishableSoftDeletableDocumentType*/ BaseSoftDeletableDocumentType, PublishableTimestamp } from './base-document-types';
import { IBaseOwner } from './i-base-owner';
import {ICategory} from "./i-category";


export interface IOwnedEntity<T extends IBaseOwner | string> extends BaseSoftDeletableDocumentType {
  owner?: T;
}

export interface ICategorizedEntity<C extends ICategory<any> | string = any> {
  categories?: C[]
}

export interface IPublishableOwnedEntity<T extends IBaseOwner | string> extends BaseSoftDeletableDocumentType, PublishableTimestamp {
  owner?: T;
}
