import {IMEvent} from "../interfaces";
import {isSameDate} from "@echo-nx/shared/common";

export const getTotalCapacity = (eventsInDay: IMEvent[]): number => eventsInDay.reduce((acc, curr): number => {
  acc += curr.availableCapacity
  return acc;
}, 0);

export const getTotalReservations = (eventsInDay: IMEvent[]): number => eventsInDay.reduce((acc, curr: IMEvent & {reservationsTotal?: number}): number => {
  acc += curr?.reservationsTotal ?? 0;
  return acc;
}, 0);

export const getMaximumFreeCapacity = (eventsInDay: IMEvent[]): number => eventsInDay.reduce((acc, curr): number => {
  acc = curr.availableCapacity > acc ? curr.availableCapacity : acc;
  return acc;
}, 0);

export const getEventsForDay = (events: IMEvent[], date: Date, hideFullCapacity = true) => {
  const predicate = hideFullCapacity ?
    (ev: IMEvent) => isSameDate(ev.start, date, 'day') && ev.availableCapacity > 0 :
    (ev: IMEvent) => isSameDate(ev.start, date, 'day')

  return events.filter(ev => predicate(ev));

}

