import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'echo-nx-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent {

  @Input() value!: unknown;
  @Output() add = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  @Input() isStatic = false;

  @Input()
  @HostBinding('class')
  public panelClass?: string;
}
