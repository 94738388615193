import {Injectable, InjectionToken} from "@angular/core";
import {  IEntityService, IGallery} from "@echo-nx/shared/common";
import {BaseGalleryService} from "@echo-nx/shared/ng/data-access";
import {map} from "rxjs/operators";



@Injectable()
export class MarlenkaGalleryService extends BaseGalleryService{
  public fetchKavarnaGallery(language?: string) {
    return this.fetchAll({
      filter: JSON.stringify({
        languageGroupId: '15b533e7-1625-4624-85d0-f80185ada7c5', // todo different identifier
        language: language ?? 'cs'
      })
    }).pipe(
      map(res => res.items?.[0])
    )
  }

  public fetchProdejnaGallery(language?: string) {
    return this.fetchAll({
      filter: JSON.stringify({
        languageGroupId: 'e39a393f-8f3d-4768-9738-740cc5442db0', // todo different identifier
        language: language ?? 'cs'
      })
    }).pipe(
      map(res => res.items?.[0])
    )
  }
}

export const MGALLERY_SERVICE_TOKEN = new InjectionToken<IEntityService<IGallery>>('MGALLERY_SERVICE_TOKEN');
