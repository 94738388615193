import gql from 'graphql-tag';

export const readDepartmentLanguageGroups = gql`
  query ReadDepartmentLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readDepartmentLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          language
          languageGroupId
          name
          description
          openingHours
          publishedAt
          unpublishedAt
          contact {
            _id
            name
            priority
            websites
            companyName
            firstName
            lastName
            telephones
            emails
            name
            ico
            dic
            dataBox
            tradeRegisterText
            bankInfo{
              bankName
              iban
              swift
              bankNumber
              bankCode
            }
            address{
              city
              province
              state
              zipcode
              street
            }

          }
        }
      }
    }
  }
`;
