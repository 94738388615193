import {IEpxColorful} from "./i-base-attributes";

export interface IBasePaginationData {
  page: number;
  take: number;
  total: number;
}

export interface IBasePaginationDesign {

  breakpoints?: IBasePaginationResponsiveBreakpoints;
  activeColorful?: IEpxColorful;
  normalColorful?: IEpxColorful;
  disabledColorful?: IEpxColorful;
  bgColor?: string;
  textSize?: string;
}

export interface IBasePaginationResponsiveBreakpoints {
  compact: number;
  big: number;
  small: number;
}
