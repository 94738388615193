import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlyrComponent } from './plyr/plyr.component';

@NgModule({
  imports: [CommonModule],
  declarations:[
    PlyrComponent
  ],
  exports: [PlyrComponent]
})
export class PlyrModule {}
