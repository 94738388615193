import gql from 'graphql-tag';

export const readAllMessages = gql`
  query readAllMessage($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllMessage(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        text
        subject
        sentAt
        paidAt
        count
        recipients
      }
    }
  }
`;
