import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollapsibleComponent} from "./collapsible.component";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    CollapsibleComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    CollapsibleComponent
  ]
})
export class CollapsibleModule {
}
