import { Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import {SnackbarData, SnackbarType} from "./types";

@Component({
  selector: 'echo-nx-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {

  private readonly _type?: SnackbarType;

  @HostBinding('class.warning') get getWarningClass() {
    return this._type === 'WARNING';
  }

  @HostBinding('class.error') get getErrorClass() {
    return this._type === 'ERROR';
  }

  @HostBinding('class.info') get getInfoClass() {
    return this._type === 'INFO';
  }

  @HostBinding('class.success') get getSuccessClass() {
    return this._type === 'SUCCESS';
  }

  public buttonText: string;
  public text: string;
  public hideButton: boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData, public snackBar: MatSnackBar) {
    const {text, type, buttonText, hideButton} = data;
    if (type) {
      this._type = data.type;
    }

    this.buttonText = buttonText ?? 'OK';
    this.text = text;
    this.hideButton = hideButton ?? false;
  }
}

