<ng-container *ngIf="mrdService?.currentLanguage$ | async as lang">
  <h2>{{texts[lang]?.title}}</h2>
  <p [innerHTML]="texts[lang]?.content"></p>
  <div class="buttons">
    <a class="link" (click)="customize()">
      {{texts[lang]?.buttons.customize}}
    </a>
    <button
      mat-flat-button
      class="button primary"
      style="color: white"
      color="accent"
      (click)="allowAll()"
    >
      {{texts[lang]?.buttons.allow}}
    </button>
    <button
      mat-stroked-button
      class="button primary"
      (click)="denyAll()"
    >
      {{texts[lang]?.buttons.deny}}
    </button>
  </div>

</ng-container>
