import {Component, EventEmitter, Input, Output} from '@angular/core';
import {INavigationItem} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-nav-mobile-level',
  templateUrl: './nav-mobile-level.component.html',
  styleUrls: ['./nav-mobile-level.component.scss']
})
export class NavMobileLevelComponent {

  @Output()
  public itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public textColor: string;

  @Input()
  public primaryColor: string;

  @Input()
  public item!: INavigationItem;
}
