import gql from 'graphql-tag';

export const readSelectedMessages = gql`
  query readSelectedMessages($ids: [String!]!) {
    response: readSelectedMessage(
      ids: $ids
    ) {
      _id
      text
      subject
      subject
      sentAt
      paidAt
      count
      recipients
    }
  }
`;
