import {Component, HostBinding, Input} from '@angular/core';
import {IBaseButtonData, IBaseButtonDesign, IEpxColorful, IEpxIcon} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-button-theme-c',
  templateUrl: './button-theme-c.component.html',
  styleUrls: ['./button-theme-c.component.scss']
})
export class ButtonThemeCComponent implements IBaseButtonDesign, IBaseButtonData {

  //TOGGLES

  @Input()
  dense?: boolean;

  @Input()
  hoverable?: boolean;

  @Input()
  outlined?: boolean;

  @Input()
  prominent?: boolean;


  //COLORS

  @Input()
  accentColor?: string;

  @Input()
  bgColor?: string;

  @Input()
  secondaryColor: string;

  @Input()
  textColor?: string;

  @Input()
  descriptionColorful?: IEpxColorful;


  //DATA

  @Input()
  title!: string;

  @Input()
  description?: string;


  //ICONS

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  endIcon?: IEpxIcon;

  //OTHER


  @Input()
  orientation;

  @Input()
  fontSize?: string;

  @Input()
  fluid?: 'horizontal' | 'vertical' | 'both';

  @Input()
  panelClass?: string;

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      'flex': this.fluid === 'horizontal' || this.fluid === 'both',
    };
  }

  @Input()
  set design({
               textColor,
               bgColor,
               secondaryColor,
               startIcon,
               endIcon,
               prominent,
               descriptionColorful,
               accentColor,
               fluid,
               fontSize,
               dense,
               outlined,
               hoverable,
               panelClass,
               orientation
             }: IBaseButtonDesign) {
    this.textColor = textColor ?? this.textColor;
    this.bgColor = bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.prominent = prominent;
    this.descriptionColorful = descriptionColorful;
    this.accentColor = accentColor;
    this.fluid = fluid;
    this.secondaryColor = secondaryColor;
    this.fontSize = fontSize;
    this.panelClass = panelClass;
    this.dense = dense;
    this.outlined = outlined;
    this.hoverable = hoverable;
    this.orientation = orientation;

    console.log('orientation', this.orientation);
  }


  @Input()
  set data({
             title,
             description,
           }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }
}
