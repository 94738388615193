<div class="step-navigation-bar" #navigationBar>
  <!--<button class="nav-button" cdkStepperPrevious>&larr;</button>-->
  <div class="steps-wrap">
    <div
      class="step"
      *ngFor="let step of steps; let i = index"
      [class.active]="selectedIndex === i"
      [class.disabled]="isDisabled(i)"
      [class.incomplete]="!step.completed"
      (click)="onClick(i)"
    >
      <ng-container
        class="test"
        *ngTemplateOutlet="
          step.stepLabel?.template;
          context: { $implicit: step }
        "
      ></ng-container>
    </div>
  </div>

  <!--<button class="nav-button" cdkStepperNext>&rarr;</button>-->
</div>

<div [style.display]="selected ? 'block' : 'none'">
  <!-- Content from the CdkStep is projected here -->
  <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
</div>
