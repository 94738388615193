import {IPaginatorButton} from "./i-paginator-button";

export const createDynamicButtons = (totalPages: number, maxButtons: number, activePage = 1): IPaginatorButton[] => {
  // console.log('creating buttons', totalPages, maxButtons, activePage);
  let buttons: IPaginatorButton[] = [];
  if (totalPages <= maxButtons) {
    for (let i = 0; i < totalPages; i++) {
      buttons.push({
        index: i,
        isActive: i + 1 === activePage,
        label: `${i + 1}`,
      });
    }
    return buttons;
  } else {
    maxButtons -= 2;
    /* BUTTON DISTRIBUTION CALCULATION */
    //these are buttons on the left and right side
    const edgePages = Math.floor(maxButtons / 3);

    //these are buttons in the middle
    const centerPages = maxButtons - Math.floor(maxButtons / 3) * 2;
    // console.log(edgePages + 1, activePage - (centerPages - 1) / 2);

    //groupDefinition is helper object that shows us number of groups or segments that are divided by '...' and number of buttons in them.
    let groupDefinition = [];
    if (activePage <= edgePages) {
      //if ActivePage is part of LEFT segment, no need for central segment... redistribute
      //add 1 to compensate for one missing '...' button
      groupDefinition = [
        edgePages + Math.ceil(centerPages / 2) + 1,
        edgePages + centerPages - Math.ceil(centerPages / 2),
      ];
    } else if (activePage >= totalPages - edgePages) {
      //if ActivePage is part of RIGHT segment, no need for central segment... redistribute
      //add 1 to compensate for one missing '...' button
      groupDefinition = [
        edgePages + centerPages - Math.ceil(centerPages / 2),
        edgePages + Math.ceil(centerPages / 2) + 1,
      ];
    } else if (edgePages + 1 >= activePage - (centerPages - 1) / 2) {
      //if segments on the LEFT are continuous, just merge them no need to display '...'
      groupDefinition = [edgePages + centerPages, 1 + edgePages];
    } else if (totalPages - edgePages <= activePage + (centerPages - 1) / 2) {
      //if segments on the RIGHT are continuous, just merge them no need to display '...'
      groupDefinition = [1 + edgePages, centerPages + edgePages];
    } else if (edgePages + 2 == activePage - (centerPages - 1) / 2) {
      //if segments on the LEFT are ALMOST, just merge them no need to display '...'
      groupDefinition = [edgePages + centerPages + 1, edgePages];
    } else if (totalPages - edgePages - 1 == activePage + (centerPages - 1) / 2) {
      //if segments on the RIGHT are continuous, just merge them no need to display '...'
      groupDefinition = [edgePages, centerPages + edgePages + 1];
    } else {
      //normal distribution
      groupDefinition = [edgePages, centerPages, edgePages];
    }

    //console.log('active', activePage, 'group', groupDefinition);

    //FIRST GROUP
    let idx = 0;
    for (let i = 0; i < groupDefinition[idx]; i++) {
      buttons.push({
        index: i,
        isActive: activePage === i+1,
        label: `${i + 1}`,
      });
    }
    buttons.push({
      index: -1,
      label: '...',
      isDisabled: true,
    });
    idx++;
    if (groupDefinition.length === 3) {
      //OPTIONAL MIDDLE GROUP
      const middleButtons = [];
      middleButtons.push({
        index: activePage - 1,
        isActive: true,
        label: `${activePage}`,
      });
      for (let i = 1; i <= (groupDefinition[idx] - 1) / 2; i++) {
        middleButtons.push({
          index: activePage - i - 1,
          label: `${activePage - i}`,
        });
        middleButtons.push({
          index: activePage + i - 1,
          label: `${activePage + i}`,
        });
      }

      buttons = [...buttons, ...middleButtons.sort((a, b) => a.index - b.index)];

      idx++;
      buttons.push({
        index: -1,
        label: '...',
        isDisabled: true,
      });
    }
    //LAST GROUP
    for (let i = totalPages - groupDefinition[idx]; i < totalPages; i++) {
      buttons.push({
        index: i,
        isActive: activePage === i+1,
        label: `${i + 1}`,
      });
    }
    return buttons;
  }
}
