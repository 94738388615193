import {Component, Inject, Injector, OnInit, Optional, ViewChild, ViewContainerRef} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {ISelectFieldSettings} from "./i-select-field.settings";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import { map, startWith, takeUntil} from "rxjs/operators";
import {IEntityService} from "@echo-nx/shared/common";
import {IEntityDefinition} from "../../../interfaces";
import {FormGroupDirective} from "@angular/forms";
import {hasValue} from "../../../utils/form-utils";
import {MatSelect} from "@angular/material/select";
import {Observable} from "rxjs";

@Component({
  selector: 'echo-nx-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent extends BaseFormFieldComponent<ISelectFieldSettings> implements OnInit {

  @ViewChild(MatSelect)
  public selectElement!: MatSelect;

  public hasValue = false;

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<ISelectFieldSettings>,
              injector: Injector,
              private viewContainerRef: ViewContainerRef,
              formGroupDirective: FormGroupDirective) {
    super(def,formGroupDirective, injector);
    this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      takeUntil(this.isDestroyed$),
      map(hasValue)
    ).subscribe(hasValue => {
      this.hasValue = hasValue
    });
  }

  public clearSelected(event: Event) {
    if(this.settings.isMultiple){
      this.formControl.setValue([]);
    } else {
      this.formControl.setValue(null);
    }
    event.stopPropagation();
  }

  public comparator = (a: any, b: any) => {
    const comparatorKey = this.settings?.comparatorKey ?? '_id';
    return a?.[comparatorKey] === b || a?.[comparatorKey] === b?.[comparatorKey];
  }

  override focusField() {
    super.focusField();
    this.selectElement.focus({preventScroll: true});
  }


  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    const {service, displayTransformFn} = this.settings;
    //console.log("ADD OWN FORM CONTROL SETTINGS", this.settings);
    if (service) {
      this.data = this.fetchData().pipe(map(items => {
        const dataArray = items.map((item: Record<string, any>) => {

          let displayValue;
          if (!displayTransformFn) {
            displayValue = item['name'];
          } else if (typeof displayTransformFn === 'string') {
            displayValue = item[displayTransformFn];
          } else {
            displayValue = displayTransformFn(item);
          }
          return {
            ...item,
            displayValue: displayValue
          };
        });

        return dataArray;
      }));
    }
  }

  private fetchData(): Observable<any> {
    const {service, formControlName} = this.settings;
    if (!service) {
      throw new Error(`Cannot fetch data, service is missing in select fields settings for ${formControlName}`)
    }

    const {fetchFunction, token} = service;
    const entityService = token ? this.injector.get<IEntityService>(token) : null;

    if (fetchFunction){
      return fetchFunction(entityService as any, this.injector, this.formGroup, this.entityFormService.valueReplay$);
    } else if (entityService){
      return entityService?.fetchAll()?.pipe(map(({items}) => items))
    } else {
      throw new Error(`No fetch function, no entity service in Select Field Component for "${this.settings.formControlName}"`)
    }
  }
}
