import { Routable } from './routable.type';
import { Widget } from './widget.type';
import { FlexLayoutSettings, GridLayoutSettings, OverlayLayoutSettings } from './layout-setting';

export type Layout<
  LayoutSettings = FlexLayoutSettings | GridLayoutSettings | OverlayLayoutSettings | string,
  WidgetDefinition = Widget[] | { [key: string]: Widget | Widget[] } | string
  > = {
  name: LayoutName;
  settings?: LayoutSettings;
  widgets?: WidgetDefinition;
} & Routable;

export type LayoutName =
  'BlockLayoutComponent' |
  'FlexLayoutComponent' |
  'GridLayoutComponent' |
  'GridThreeCellsComponent' |
  'OverlayLayoutComponent';
