//ARTICLE = warehouse item, its just generic type
import {IBaseOwner, IOwnedEntity} from "@echo-nx/shared/common";

export enum MCommodityType {
  // old stuff, deprecated
  "Vstupné" = '0101',
  "Degustace" = '0201',
  "Dárkový poukaz" = '0301',

  // dialog confirmed stuff
  'Vstupné dospělý' = '0101',
  'Vstupné děti' = '0102',
  'Vstupné ZTP' = '0103',
  'Vstupné rodina' = '0104',
  'Poukaz na degustaci BRANDY' = '0201',
  'Poukaz na nákup - 200 kč' = '0301',
  'Poukaz na nákup - 500 kč' = '0302'
}

//D3000S.VOUCHER_TYP
export interface IMDialogCommodity<T extends IBaseOwner | string = any> extends IOwnedEntity<T>{
  articleCode: string;    //KOD_ART_POUKAZU: string; // Kód artiklu = prakticky ID
  articleName: string;    //NAZEV: string;
  price: number;          //JEDNOTKOVA_CENA: number;
  articleType?: string;    //TYP_POUKAZU: string;
  isSellable?: number;     //PRODEJ_WEB?: number;
  seller?: string;
  footerText?: string;
}
