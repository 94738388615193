import {Routable} from './routable.type';
import {BaseWidgetSettings} from './widget-setting';
import {WidgetEvent} from './widget-event.type';

export type Widget<ConcreteWidgetSettings extends BaseWidgetSettings = BaseWidgetSettings> = {
  name: WidgetName;
  settings?: ConcreteWidgetSettings;
  event?: WidgetEvent<any>; // event that this widget can send
} & Routable;

// smart component names
export type WidgetName = 'DateTimeBarComponent' |
  'BreadcrumbsComponent' |
  'ButtonComponent' |
  'ChartsComponent' |
  'CheckboxComponent' |
  'CurrentDateComponent' |
  'CurrentTimeComponent' |
  'DatePickerComponent' |
  'DropzoneComponent' |
  'FilterComponent' |
  'HtmlViewerComponent' |
  'ImageComponent' |
  'ItemDetailComponent' |
  'ItemListComponent' |
  'LanguageSelectorComponent' |
  'MapComponent' |
  'NavbarComponent' |
  'NavigationComponent' |
  'PaginationComponent' |
  'PinComponent' |
  'PkInputJsonFieldComponent' |
  'ScreenNameComponent' |
  'SliderComponent' |
  'TextComponent' |
  'WeatherComponent' |
  'WebComponent' |
  // 'RectangleButtonComponent' | // deprecated
  'SearchResultComponent' |
  'BusDepartureComponent' |
  string;
