import gql from 'graphql-tag';

export const readAllCakesExclusive = gql`
  query ReadAllCakesExclusive($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean) {
    response: readAllCakesExclusive(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        title
        language
        languageGroupId
        categories {
          _id
          name
        }
        featuredImage{
          url
          type
          mime
          thumbnailUrl
          name
        }
        content
        createdAt
        publishedAt
        attachments{
          url
          name
          description
          type
        }
      }
    }
  }
`;
