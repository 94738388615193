import { Injectable, InjectionToken } from '@angular/core';
import {Apollo} from "apollo-angular";
import {ILogs, IMarlenkaOwner, MarlenkaEntityTypes, readAllLog, readSelectedLog} from "@echo-nx/marlenka/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable()
export class BaseLogService implements IEntityService<ILogs> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<ILogs>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ILogs>>>({
      query: readAllLog,
      variables: args,
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<ILogs[]> {
    return this.apollo.query<IPKApolloResult<ILogs[]>>({
      query: readSelectedLog,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<ILogs> {
    return this.apollo.query<IPKApolloResult<ILogs[]>>({
      query: readSelectedLog,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0]),
    )
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(log: ILogs[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Logs;
  }
}

export const MLOGS_SERVICE_TOKEN = new InjectionToken<IEntityService<ILogs>>('MLOGS_SERVICE_TOKEN');
