import gql from "graphql-tag";

export const addReservation = gql`
  mutation addReservation($input: [MReservationInput!]!){
    response: addReservations(inputReservations: $input){
      _id
      uniqueId
    }
  }
`;
