import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  IMarlenkaOwner,
  MarlenkaEntityTypes, readAllMessages, readSelectedMessages,
} from "@echo-nx/marlenka/common";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IMessage,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";


@Injectable()
export class BaseMessageService implements IEntityService<IMessage<IMarlenkaOwner>> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMessage<IMarlenkaOwner>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMessage<IMarlenkaOwner>>>>({
      query: readAllMessages,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMessage<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<IMessage<IMarlenkaOwner>[]>>({
      query: readSelectedMessages,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IMessage<IMarlenkaOwner>> {
    return this.apollo.query<IPKApolloResult<IMessage<IMarlenkaOwner>[]>>({
      query: readSelectedMessages,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(email: IMessage<IMarlenkaOwner>[]): Observable<MutationResult<IPKApolloResult<IMessage<IMarlenkaOwner>[]>>> {
    throw Error('NOT IMPLEMENTED :(');
/*    return this.apollo.mutate({
      mutation: addEmail,
      variables: {input: email}
    });*/
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Message;
  }
}

export const MMESSAGE_SERVICE_TOKEN = new InjectionToken<IEntityService<IMessage<IMarlenkaOwner>>>('MMESSAGE_SERVICE_TOKEN');
