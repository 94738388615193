export interface EchoNxFormErrors {
  errorTree: Record<string, any> | null;
  errorMessages: EchoNxFormErrorMessage[];
}

export interface EchoNxFormErrorMessage{
  key?: string[];
  label?: string[];
  errorKey: string;
  params: Record<string, any>;
}
