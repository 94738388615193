import {IEntityDefinition} from "../../interfaces";
import {IGroupFieldSettings, ITextFieldSettings} from "../../modules/form-fields";
import {Validators} from "@angular/forms";
import {endIconDefinition, routeToEntityDefinition, startIconDefinition} from "../entity-definition-helpers";

export const getButtonWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          // DATA
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Data',
              formControlName: 'buttonData',
              withoutCard: true,
              groupDefinition: [
                {
                  name: 'PkInputTextFieldComponent',
                  settings: {
                    nazev: 'Text',
                    formControlName: 'title',
                    povinnost: true
                  } as ITextFieldSettings,
                  data: null,
                  validators: [Validators.required],
                  cols: 12,
                },
                {
                  name: 'PkInputTextFieldComponent',
                  settings: {
                    nazev: 'Popis',
                    formControlName: 'description',
                  } as ITextFieldSettings,
                  data: null,
                  validators: [],
                  cols: 12,
                },
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
          // DESIGN
          {
            name: 'PkInputGroupFieldComponent',
            settings: {
              nazev: 'Design',
              formControlName: 'buttonDesign',
              withoutCard: true,
              groupDefinition: [
                startIconDefinition,
                endIconDefinition
              ]
            } as IGroupFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
    routeToEntityDefinition,
  ]
}
