import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseCheckboxDesign, IBaseCheckboxData, IBaseButtonData, IBaseButtonDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";
import {ContentSettings} from "./contentful-settings.type";
import {FilterValueObject} from "./filter-settings.type";


export type CheckboxWidgetSettings<D extends IBaseCheckboxDesign = IBaseCheckboxDesign> = {
  content?: ContentSettings;
  mapping?: string; // Partial<Record<keyof (IBaseButtonData & FilterValueObject), string>>;
  values?: CheckboxValue[];
  checkboxData?: IBaseCheckboxData;
  checkboxDesign?: D;
  targetWidget: string; //  uniqueName widgetu, ktery na to ma reagovat
  isRadio?: boolean;
  hideHeader?: boolean;
  filterTemplate?: string;
} & BaseWidgetSettings;

export type CheckboxValue = {
  variant?: ComponentVariant;
  buttonDesign?: IBaseButtonDesign;
  buttonData?: IBaseButtonData;
} & FilterValueObject;
