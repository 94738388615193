import gql from 'graphql-tag';

export const readAllKiosks = gql`
  query ReadAllKiosks($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllKiosk(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: true
    ){
      total
      page
      take
      hasMore
      items{
        _id
        createdAt
        updatedAt
        name
        owner{
          name
        }
        pythonUniqueId
        pwaUniqueId
        location {
          street
          province
          zipcode
          city
          state
        }

        timeSchedules {
          timeOn
          timeOff
          weekday
        }
      }
    }
  }
`
