import {Component, OnChanges} from '@angular/core';
import {IBasePaginationData, IBasePaginationDesign} from "@echo-nx/shared/common";
import {BasePaginationComponent} from "../base";

@Component({
  selector: 'echo-nx-pagination-variant-c',
  templateUrl: './pagination-variant-c.component.html',
  styleUrls: ['./pagination-variant-c.component.scss']
})
export class PaginationVariantCComponent extends BasePaginationComponent implements OnChanges, IBasePaginationDesign, IBasePaginationData {}
