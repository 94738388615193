import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataCardFilterComponent} from './data-card-filter.component';
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatBadgeModule} from "@angular/material/badge";
import {EntityFormModule} from "../../../forms";


@NgModule({
  declarations: [
    DataCardFilterComponent
  ],
  imports: [
    EntityFormModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatBadgeModule
  ],
  exports: [
    DataCardFilterComponent
  ]
})
export class DataCardFilterModule {
}
