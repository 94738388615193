import gql from "graphql-tag";

export const deleteCakeExclusive = gql`
  mutation deleteCakesExclusive($input: [String!]!){
    response: deleteCakesExclusive(ids: $input){
      userMessage
      description
      error
    }
  }
`;
