<div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden" [ngClass]="cardClass">
  <div class="flex items-start justify-between">
    <!-- Here user can pass children with ng-content or specify @input title to trigger default title.
    Css takes care of hiding the empty one. -->
    <div class="text-lg font-medium tracking-tight leading-6 truncate" id="default-title">{{title}}</div>
    <div id="title-content-wrapper"><ng-content select="[title]"></ng-content></div>
    <div class="ml-2 -mt-2 -mr-3">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
  <div class="flex my-4">
    <ng-content></ng-content>
  </div>
  <ng-content select="[footer]"></ng-content>
</div>
