<div class="item">
  <div id="chosen" class="box"></div>
  <span>Zvolený termín</span>
</div>

<div class="item">
  <div id="available" class="box"></div>
  <span>Volný termín</span>
</div>

<div class="item">
  <div id="no-filter" class="box"></div>
  <span>Termín nevyhovující filtru</span>
</div>

<div class="item">
  <div id="full" class="box"></div>
  <span>Plný termín</span>
</div>

<div class="item">
  <div id="no-events" class="box"></div>
  <span>Zavřeno</span>
</div>

