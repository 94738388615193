import gql from 'graphql-tag';

export const readSelectedArticle = gql`
  query ReadSelectedArticle($ids: [String!]!) {
    response: readSelectedArticle(
      ids: $ids
    ) {
      _id
      title
      content
      slug
      summary
      categories {
        _id
        name
      }
      attachments{
        _id
        url
        thumbnailUrl
        hash
        mime
        type
        name
        originalFileName
      }
      featuredImage {
        _id
        name
        url
        mime
        type
        thumbnailUrl
      }
      owner{
        _id
        name
      }
      createdAt
      language
      languageGroupId
      publishedAt
      unpublishedAt
    }
  }
`;
