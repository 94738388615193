import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {
  IBaseItemDetailData,
  IBaseItemDetailDesign,
  ICategory, IEpxAttribute, IEpxColorful,
  IEpxIcon,
  IMedium,
  MediumType
} from "@echo-nx/shared/common";

@Directive()
export class BaseItemDetailComponent implements IBaseItemDetailDesign, IBaseItemDetailData, OnChanges {

  @Input()
  title!: string;

  @Input()
  content!: string;

  @Input()
  perex?: string;

  @Input()
  date!: string;

  @Input()
  attributes?: IEpxAttribute[];

  @Input()
  primaryColor!: string;

  @Input()
  secondaryColor!: string;

  @Input()
  attachments!: IMedium[];

  @Input()
  categories!: ICategory[];

  @Input()
  image!: IMedium;

  @Input()
  dateText: IEpxIcon;

  @Input()
  contentColorful: IEpxColorful;

  @Input()
  titleText: IEpxColorful;

  @Output()
  attachmentClick: EventEmitter<string> = new EventEmitter<string>();

  // attachments split into images and videos
  public images: IMedium[] = [];
  public videos: IMedium[] = [];
  public documents: IMedium[] = [];

  @Input()
  set data(settings: IBaseItemDetailData) {
    const {attachments, content, date, image, title, categories, perex, attributes, } = settings;
    this.title = title;
    this.content = content;
    this.date = date;
    this.title = title;
    this.content = content;
    this.attachments = attachments;
    this.categories = categories;
    this.image = image;
    this.perex = perex;
    this.attributes = attributes;
  }


  @Input()
  set design(settings: IBaseItemDetailDesign) {
    const {secondaryColor, primaryColor, dateText, contentColorful, titleText} = settings;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.dateText = dateText;
    this.contentColorful = contentColorful;
    this.titleText = titleText;
  }

  ngOnChanges(changes: SimpleChanges) {
    // depends if you use data input or direct inputs
    const currentAttachments = (changes?.attachments?.currentValue as IMedium[]) ?? (changes?.data?.currentValue as IBaseItemDetailData)?.attachments;
    if (currentAttachments) {
      // todo this is quite common on multiple places, please unify
      const [images, videos, documents] = currentAttachments.reduce((acc, curr) => {
        const {type} = curr;
        switch (type) {
          case MediumType.Image:
            acc[0].push(curr);
            break;
          case MediumType.Video:
          case MediumType.YouTubeVideo:
            acc[1].push(curr);
            break;
          case MediumType.Document:
          case MediumType.Archive:
            acc[2].push(curr);
            break;
        }
        return acc;
      }, [[], [], []] as IMedium[][])
      this.images = images;
      this.videos = videos;
      this.documents = documents;
    }
  }
}
