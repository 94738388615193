import {ComponentPortal} from "@angular/cdk/portal";
import {Observable} from "rxjs";

export interface IPKStep {
  title: Observable<string>;
  nextButtonText: Observable<string>;
  disableNextButton?: Observable<boolean>;
  subtitle: Observable<string>;
  completed: Observable<boolean>;
  optional: boolean; // lol make it also observable$$$?
  componentPortal: ComponentPortal<any>;
}
