import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityFormComponent } from './entity-form.component';
import {PortalModule} from "@angular/cdk/portal";
import {ReactiveFormsModule} from "@angular/forms";
import {DynamicComponentPipeModule} from "@echo-nx/shared/ng/feature/common";
import {TranslateModule} from "@ngx-translate/core";
// import {FuseAlertModule} from "@fuse/components/alert";
import { FormStatusTextPipe } from './form-status-text.pipe';



@NgModule({
  declarations: [
    EntityFormComponent,
    FormStatusTextPipe
  ],
  imports: [
    CommonModule,
    PortalModule,
    ReactiveFormsModule,
    DynamicComponentPipeModule,
    TranslateModule,
    //FuseAlertModule
  ],
  exports: [
    EntityFormComponent
  ]
})
export class EntityFormModule { }
