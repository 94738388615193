import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import {SymetricalBoxSides} from "@echo-nx/shared/common";


@Directive({
  selector: '[echoNxGrid]',
})
export class GapDefinitionDirective implements AfterViewInit {
  @Input('echoNxGrid') gapDefinition!: SymetricalBoxSides<string>;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    console.log(this.gapDefinition);
    if (this.gapDefinition) {
      if (!this.gapDefinition.vertical) {
        this.gapDefinition.vertical = '0';
      }

      if (!this.gapDefinition.horizontal) {
        this.gapDefinition.vertical = '0';
      }

      this.el.nativeElement.style.gap =
        this.gapDefinition.vertical + ' ' + this.gapDefinition.horizontal;
      this.el.nativeElement.style.gridGap =
        this.gapDefinition.vertical + ' ' + this.gapDefinition.horizontal;
    }
  }
}
