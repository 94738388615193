import {Widget} from "../widget.type";
import { BaseLayoutSettingsType } from './base-layout-settings.type';

export type GridThreeCellsLayoutSettings = {

} & BaseLayoutSettingsType;

export type GridThreeCellsWidgetSettings = {
  header?: Widget;
  leftCell?: Widget;
  rightCell?: Widget;
}
