import {Component, ElementRef, Inject, Injector, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {IEntityDefinition} from "../../../interfaces";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IJsonFieldSettings} from "./i-json-field.settings";
import {FormGroupDirective} from "@angular/forms";
import {JSONContent, JSONEditor, Mode, createAjvValidator, MenuButtonItem, TextContent} from 'vanilla-jsoneditor'


@Component({
  selector: 'echo-nx-json-field',
  templateUrl: './json-field.component.html',
  styleUrls: ['./json-field.component.scss'],
})
export class JsonFieldComponent extends BaseFormFieldComponent<IJsonFieldSettings> implements OnInit, OnDestroy {

  @ViewChild('jsoneditor', {static: true})
  public editorElement!: ElementRef<HTMLDivElement>;

  private editor!: JSONEditor;


  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IJsonFieldSettings>, formGroupDirective: FormGroupDirective, injector: Injector) {
    super(def, formGroupDirective, injector);
  }


  override async ngOnInit(): Promise<void> {
    const {nativeElement} = this.editorElement;
    const {value} = this.formControl;

    this.editor = new JSONEditor({
      target: nativeElement,
      props: {
        content: {
          text: value
        },
        onRenderMenu: (mode, items) => items.filter((item) => {
          const {text} = item as MenuButtonItem;
          return text === 'text' || text === 'tree';
        }),
        mode: Mode.tree,
        onChange: (content) => {
          const {json} = content as JSONContent;
          const {text} = content as TextContent;
          const value = text ?? JSON.stringify(json)
          this.formControl.patchValue(value);
        }
      },

    })
  }


  override async ngOnDestroy(): Promise<void> {
    await super.ngOnDestroy();
  }

}



