import { AfterViewInit, Component, Injector, OnDestroy } from '@angular/core';
import { MarlenkaBasePageComponent } from '..';
import { CakeService } from '../../services/cake.service';
import {Observable, switchMap} from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import {IMarlenkaOwner, IMSubnavigationItem, ProductsPageTexts} from "@echo-nx/marlenka/common";
import {ICategory} from "@echo-nx/shared/common";


@Component({
  selector: 'marlenka-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent extends MarlenkaBasePageComponent<ProductsPageTexts> implements OnDestroy, AfterViewInit {

  public categories$: Observable<ICategory<IMarlenkaOwner>[]>;

  constructor(
    injector: Injector,
    private cakeService: CakeService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService
  ) {
    super(injector);
    /*this.categories$ = this.cakeService.fetchCategories().pipe(
      takeUntil(this.isDestroyed$),
    );*/
  }


  ngAfterViewInit() {
    this.categories$ = this.mrdService.currentLanguage$.pipe(switchMap((lang)=>this.cakeService.fetchCategories(lang).pipe(
      takeUntil(this.isDestroyed$),
      tap((categories) => {
        this.navigationService.setSubnavigationItems(
          categories.map((category => ({
            link: this.router.createUrlTree([`./${category.languageGroupId}`], { relativeTo: this.activeRoute }).toString(),
            title: category.name
          }) as IMSubnavigationItem))
        );
      })
    )));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationService.setSubnavigationItems(undefined);
  }
}
