<div
  *ngFor="let item of data"
  class="rounded-2xl flex overflow-hidden relative cursor-pointer"
  matRipple
  (click)="$event.stopPropagation(); itemClicked.emit(item)"
>
  <!-- CHECK -->
  <button mat-icon-button class="absolute top-2 left-2" (click)="$event.stopPropagation(); toggledSelect.emit(item)">
    <mat-icon
      [color]="selectionModel.isSelected(item) ? 'primary' : null"
      [fontSet]="selectionModel.isSelected(item) ? 'fas' : 'far'"
      [fontIcon]="selectionModel.isSelected(item) ? 'fa-check-circle' : 'fa-circle'"></mat-icon>
  </button>

  <!-- MAIN IMAGE -->
  <img
    class="object-cover"
    [src]="(gridDefinition.mediaUrl | isArray) ? (item | resolveKey:gridDefinition.mediaUrl) : (gridDefinition.mediaUrl | functionPipe:item)"
    [alt]="(gridDefinition.title | isArray) ? (item | resolveKey:gridDefinition.title) : (gridDefinition.title | functionPipe:item)">

  <!-- TITLE, SUBTITLE, DATE -->
  <div class="flex p-2 bg-gray-700 text-white bg-opacity-75 absolute bottom-0 left-0 right-0">
    <div class="flex flex-1 flex-col" [style]="gridDefinition.date ? 'max-width: 70%' : 'max-width: 100%'">
      <div class="truncate font-bold">{{ (gridDefinition.title | isArray) ? (item | resolveKey:gridDefinition.title) : (gridDefinition.title | functionPipe:item)}}</div>
      <div class="truncate font-light">{{(gridDefinition.subtitle | isArray) ? (item | resolveKey:gridDefinition.subtitle) : (gridDefinition.subtitle | functionPipe:item)}}</div>
    </div>

    <div *ngIf="gridDefinition.date" class="flex-1 text-right font-extralight italic">
      {{item | resolveKey:gridDefinition.date | date}}
    </div>
  </div>
</div>
