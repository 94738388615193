import gql from 'graphql-tag';

export const readSelectedMPartnerStore = gql`
  query ReadSelectedMPartnerStore($ids: [String!]!) {
    response: readSelectedPartnerStore(
      ids: $ids
    ) {
      _id
      language
      languageGroupId
      name
      url
      categories {
        _id
        name
      }
      media {
        _id
        mime
        name
        url
        type
      }
    }
  }
`;
