import {
  BaseTimestamps, IBaseOwner,
  ICategorizedEntity,
  ICategory,
  IMultilanguage, IOwnedEntity,
  IPaginatedLanguageGroup,
  MongoID
} from "@echo-nx/shared/common";

export const transformLanguages = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage>) => {
  const languages = entity?.docs?.map(d => d.language);
  return languages?.length > 0 ? languages.join(', ') : '';
}

export const transformNames = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage & { name?: string }>) => entity?.docs?.find(d => d.language === 'cs')?.name ?? entity?.docs?.[0]?.name

export const transformTitles = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage & { title?: string }>) => entity?.docs?.find(d => d.language === 'cs')?.title ?? entity?.docs?.[0]?.title

export const transformIds = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage & MongoID>) => entity?.docs?.find(d => d.language === 'cs')?._id ?? entity?.docs?.[0]._id

export const transformCreatedAt = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage & BaseTimestamps>) => (entity?.docs?.find(d => d.language === 'cs') ?? entity.docs?.[0])?.createdAt

export const transformCategories = (categories: ICategory[], entity: IPaginatedLanguageGroup<IMultilanguage & ICategorizedEntity<ICategory>>) => (entity?.docs?.find(d => d.language === 'cs') ?? entity?.docs?.[0])?.categories?.map(c => c.name)?.join(',') ?? 'Žádná'

export const transformOwners = (value: unknown, entity: IPaginatedLanguageGroup<IMultilanguage & IOwnedEntity<IBaseOwner>>) => entity?.docs?.find(d => d.language === 'cs')?.owner?.name ?? entity?.docs?.[0]?.owner?.name
