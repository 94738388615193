import {Inject, Injectable} from '@angular/core';
import {CanActivate, UrlTree, Router} from '@angular/router';
import { Observable} from 'rxjs';
import {URL_SETTINGS_TOKEN} from "../generic-tokens";
import {BaseAuthService} from "../services";
import {UrlSettings} from "../utils";

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: BaseAuthService,
    @Inject(URL_SETTINGS_TOKEN) private urlSettings: UrlSettings,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.authService.getTokenData();
    console.log('REDIRECT GUARD', token);
    this.router.navigateByUrl(token?.role?.cmsHomePath ?? '/dashboard');
    return true;
  }
}
