import {BaseWidgetSettings} from "./base-settings.type";
import {IPOIItem} from "../../i-poi-item";
import {ContentfulSettings} from "./contentful-settings.type";
import {Routable} from "../routable.type";

export type MapSettings = {
  isWeb?:boolean;
  mapViewOptions: IMapViewOptions;
  mapInfoOptions: IMapInfoOptions;
} & BaseWidgetSettings & ContentfulSettings;


/////////////////////////////
export interface IMapPointOptions {
  color: string;
  labelIcon: string;
  marker: string;
}

export interface IMapViewOptions {
  bounds?: google.maps.LatLngBoundsLiteral;
  data: IPOIItem[];
  mapId?: string;
  kmlOverlayUrl?: string;
  defaultPointOptions?: IMapPointOptions;
  mapPointMapping?: string; // Partial<Record<keyof IMapPointOptions, string>>;
  defaultCenter?: google.maps.LatLngLiteral;
  debounceBoundaryEventMs?: number;
}

export interface IMapInfoOptions extends Routable {
  variant: "variant-rsd" | "variant-a" | 'variant-b' | string;
  data: Record<string, any>;
  mapping: string;
}

export interface IMapInfoVRsdWidgetSettings {
  mapInfoVRsdData?: IMapInfoVRsdData;
  mapInfoVRsdDesign?: IMapInfoVRsdDesign;
}

export interface IMapInfoVRsdData{
  _id?: string;
  title: string;
  subtitle: string;
  start?: string;
  finish?: string;
  length?: number;
  status?: string;
}

export interface IMapInfoVRsdDesign{
  primaryColor?: string;
}

export interface MapMarkerProps {
  markerOptions: google.maps.MarkerOptions;
  poi: IPOIItem;
}
