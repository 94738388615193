<div class="title">
  <div class="wrap">
    <b>Prohlídky dne</b> {{ eventsService.selectedDate | date: 'dd.MM.yyyy' }}
  </div>
</div>
<echo-nx-table-item-list
  [fields]="eventTableFields"
  [items]="dayEvents$ | async"
  emptyMessage="Nebyly nalezeny žádné vyhovující termíny."
  (selected)="eventSelected($event)"
  [selectedItem]="reservationService.selectedEvent"
  style="margin-top: 1rem;"
></echo-nx-table-item-list>
