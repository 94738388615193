import {IPKStep} from "../utils";
import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {Subject} from "rxjs";
import {StepperComponent} from "@echo-nx/shared/ng/ui/smart";


@Component({
  selector: 'marlenka-stepper',
  templateUrl: './marlenka-stepper.component.html',
  styleUrls: ['./marlenka-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarlenkaStepperComponent implements OnDestroy {
  @ViewChild('stepper', { static: true })
  stepper!: StepperComponent;

  @Input()
  public steps!: IPKStep[];

  @Output()
  public stepsCompleted = new EventEmitter<void>();

  @Output()
  public backClicked = new EventEmitter<number>();

  public readonly isDestroyed$ = new Subject<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  public onNextStep() {
    // check if we are at last step and if its completed
    const lastStepIndex = this.steps.length - 1;
    if (this.stepper.selectedIndex === lastStepIndex && this.steps[lastStepIndex].completed) {
      this.stepsCompleted.emit();
    }

    this.elementRef?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.stepper.next();
  }

}


