import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input, OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IInjectableServiceData} from "@echo-nx/shared/ng/feature/common";
import {UntypedFormControl,} from "@angular/forms";
import {DataCardStateService} from "../../utils/data-card-state.service";
import {TemplatePortal} from "@angular/cdk/portal";
import {MatButton} from "@angular/material/button";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import { takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {buildEntityForm, IEntityDefinition} from "../../../forms";

@Component({
  selector: 'echo-nx-data-card-filter',
  templateUrl: './data-card-filter.component.html',
  styleUrls: ['./data-card-filter.component.scss']
})
export class DataCardFilterComponent implements OnInit, OnDestroy {
  private _isDestroyed$ = new Subject<boolean>();
  @ViewChild('filterPanelOpener') private _filterPanelOpener?: MatButton;
  @ViewChild('filterPanel') public filterPanelRef?: TemplateRef<any>;

  @Input()
  public filterDefinition!: IEntityDefinition[];

  @Input()
  public filterServiceData!: IInjectableServiceData;

  @Input()
  public hideSearch = false;

  @Input()
  public hideFilters = false;

  public filterService!: DataCardStateService;

  public searchControl!: UntypedFormControl;

  private _overlayRef?: OverlayRef;

  constructor(private injector: Injector,
              private _changeDetectorRef: ChangeDetectorRef,
              private _overlay: Overlay,
              private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.filterService = this.injector.get<DataCardStateService>(this.filterServiceData.token);

    // connect Search
    this.searchControl = this.filterService.getSearchControl();

    if (this.filterDefinition){
      buildEntityForm(this.filterDefinition, this.filterService.getFiltersControl().value, this.filterService.getFiltersControl());
    }
  }

  ngOnDestroy(): void {
    // emit
    this._isDestroyed$.next(true);
    this._isDestroyed$.unsubscribe();
  }

  //inefficient - should be pipeable
  isPanelOpened(): boolean {
    return this._overlayRef?.hasAttached() ?? false;
  }

  cancelEverything() {
    this.filterService.getFiltersControl().reset();
  }

  /**
   * Open the filter panel
   */
  openPanel(): void {
    // Return if the panel or its opener is not defined
    if (!this.filterPanelRef || !this._filterPanelOpener) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef?.attach(new TemplatePortal(this.filterPanelRef, this._viewContainerRef));
  }

  closePanel(): void {
    this._overlayRef?.detach();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._filterPanelOpener?._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().pipe(takeUntil(this._isDestroyed$)).subscribe(() => {
      this._overlayRef?.detach();
    });
  }
}
