<ng-container *ngIf="(pageSettings$ | async)?.texts as texts">
<ng-container *ngIf="buttonsText$ | async as buttonsText">
  <marlenka-jumbotron
    [title]="texts.jumbotron.title"
    [subtitle]="texts.jumbotron.subtitle"
    [withDivider]="true"
    [textColor]="'#F3DCAA'"
    [withBottomArrow]="true"
    bottomArrowFragment="history"
    [topWave]="true"
    background="url('https://i.imgur.com/FDFLJCM.jpg')"
    backgroundPosition="top"
    id="aboutUsMain"
  >
    <div style="margin-top: 200px;"></div>
  </marlenka-jumbotron>

  <!-- history -->
  <marlenka-jumbotron
    id="history"
    [title]="texts.history.title"
    [subtitle]="texts.history.subtitle"
    [withDivider]="true"
    [dividerWave]="true"
    background="url('https://i.imgur.com/h9fGTFm.jpg')"
  >
    <div style="width: 100%; display: flex; justify-content: center; align-items: center;" class="history-container">
      <div class="history-wrapper container medium" *ngIf="timeline$ | async as timeline">
        <echo-nx-timeline>
          <echo-nx-timeline-item *ngFor="let tl of timeline; let i = index" [flipSides]="i % 2 === 0">
            <div left>
              <div class="picture">
                <figure>
                  <echo-nx-medium-item [medium]="tl.featuredImage"></echo-nx-medium-item>
                </figure>
              </div>
            </div>
            <div right>
              <div class="content-box">
                <div class="content">
                  <h3 class="year">{{ tl.year }}</h3>
                  <marlenka-read-more [content]="tl.text" [limit]="200"
                                      [completeWords]="true"></marlenka-read-more>
                </div>
              </div>
            </div>
          </echo-nx-timeline-item>
        </echo-nx-timeline>
      </div>
    </div>
  </marlenka-jumbotron>

  <!-- awards -->
  <marlenka-jumbotron
    id="awards"
    [title]="texts.achievements.title"
    [subtitle]="texts.achievements.subtitle"
    [withDivider]="true"
    [dividerWave]="true"
    topArrowFragment="awards"
    background="#FFF2DC"
  >
    <div class="awards-wrapper container medium" *ngIf="awards$ | async as awards">
      <div class="awards">
        <echo-nx-article-preview
          panelClass="award-preview"
          *ngFor="let award of awards"
          [title]="award.title"
          [subtitle]="award.publishedAt | date: 'dd. MM. yyy | HH:mm'"
          [imgSrc]="award.featuredImage.url"
          [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/awards/' + award.languageGroupId"
        >
          <p bottom-row class="summary">
            {{ award.summary}}
          </p>
          <div class="buttons" bottom-row>
            <marlenka-read-more
              [link]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/awards/' + award.languageGroupId"></marlenka-read-more>
          </div>
        </echo-nx-article-preview>
      </div>
    </div>

    <a mat-stroked-button class="button primary"
       [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/awards'" style="margin-bottom: 2rem;">
      {{buttonsText.archiveAwards}}
    </a>
  </marlenka-jumbotron>

  <!-- certificates -->
  <marlenka-jumbotron
    id="certificates"
    [title]="texts.certificates.title"
    [subtitle]="texts.certificates.subtitle"
    [withDivider]="true"
    [dividerWave]="true"
  >
    <div class="fx-col container small certificates-wrapper">
      <div *ngFor="let certificate of certificates$ | async" class="fx-row">
        <div class="col fx-col">
          <figure>
            <img class="img-responsive" [src]="certificate.featuredImage?.url"
                 [alt]="certificate.featuredImage?.description"/>
          </figure>
        </div>
        <div class="col fx-col content-center">
          <time>
                        <span>
                            {{ certificate.publishedAt | date: 'dd. MM. yyy | HH:mm' }}
                        </span>
          </time>
          <h3 class="title">
            {{ certificate.title }}
          </h3>
          <div class="content">
            <marlenka-wysiwyg-view
              [html]="certificate.summary"></marlenka-wysiwyg-view>
          </div>
          <div class="buttons" bottom-row>
            <marlenka-read-more
              [link]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/certificates/' + certificate.languageGroupId"></marlenka-read-more>
          </div>
        </div>
      </div>
    </div>
    <a
      mat-stroked-button
      class="button primary"
      [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/certificates'"
      style="margin-bottom: 2rem; margin-top: 2rem;"
    >
      {{buttonsText.archiveCertificates}}
    </a>
  </marlenka-jumbotron>

  <!-- videos -->
  <marlenka-jumbotron
    id="gallery"
    [title]="texts.videos.title"
    [subtitle]="texts.videos.subtitle "
    [withDivider]="true"
    [dividerWave]="true"
    background="#FFF2DC"
  >
    <div class="awards-wrapper container medium" *ngIf="videos$ | async as videos">
      <div class="awards">
        <echo-nx-article-preview
          panelClass="award-preview"
          *ngFor="let video of videos"
          [title]="video.title"
          [subtitle]="video.publishedAt | date: 'dd. MM. yyy | HH:mm'"
          [imgSrc]="video.featuredImage.url"
          [routeTo]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/videos/' + video.languageGroupId"
        >
          <p bottom-row class="summary">
            <marlenka-wysiwyg-view
              [html]="video.summary"></marlenka-wysiwyg-view>
          </p>
          <div class="buttons" bottom-row>
            <marlenka-read-more
              [link]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/videos/' + video.languageGroupId"></marlenka-read-more>

          </div>
        </echo-nx-article-preview>
      </div>
    </div>
    <a mat-stroked-button class="button primary"
       [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/about/videos'" style="margin-bottom: 2rem;">
      {{buttonsText.archiveVideos}}
    </a>
  </marlenka-jumbotron>
</ng-container>
</ng-container>
