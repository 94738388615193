<div class="flex font-medium px-4 space-x-8 flex-1 items-center text-2xl"
     [ngClass]="[primaryColor | prefix:'bg']"
     *ngIf="dateTime$ | async as dateTime">
  <!-- date -->
  <div class="space-x-2">
    <i class="fas fa-calendar" [ngClass]="[secondaryColor | prefix:'text']"></i>
    <span [ngClass]="[textColor | prefix:'text']">{{dateTime | date:'dd.MM.yyyy'}}</span>
  </div>

  <!-- time -->
  <div class="space-x-2">
    <i class="fas fa-clock"  [ngClass]="[secondaryColor | prefix:'text']"></i>
    <span [ngClass]="[textColor | prefix:'text']">{{dateTime | date:'HH:mm'}}</span>
  </div>
</div>
