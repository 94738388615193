import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {IMarlenkaOwner, IMProduct} from "@echo-nx/marlenka/common";
import {BaseCakesService} from "@echo-nx/marlenka/ng/data-access";
import {ICategory, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {map, switchMap} from "rxjs/operators";
import {readAllCategories} from "@echo-nx/shared/ng/data-access";

@Injectable({
  providedIn: 'root'
})
export class CakeService extends BaseCakesService {

  private _cake: BehaviorSubject<IMProduct[]>;
  public readonly product$: Observable<IMProduct[]>;

  set product(product: IMProduct[]) {
    this._cake.next(product);
  }

  get productTypes() {
    return this._cake.getValue();
  }

  constructor(apollo: Apollo) {
    super(apollo);
    this._cake = new BehaviorSubject<IMProduct[]>([]);
    this.product$ = this._cake.asObservable();
  }

  public fetchAllForLanguageGroupCategory(languageGroupId: string, language?: string): Observable<IMProduct<ICategory<IMarlenkaOwner>>[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<any>>>>({
      query: readAllCategories,
      variables: {filter: JSON.stringify({languageGroupId: languageGroupId, language})}
    }).pipe(
      map(result => result.data.response.items.map(({_id}) => _id)),
      switchMap(ids =>
        this.fetchAll({
            filter: JSON.stringify({categories: {$in: ids}}),
            sortColumn: 'priority',
            sortDirection: 'DESC'
          }
        )
      ),
      map(response => response.items)
    )
  }

  public fetchAllForCategory(categoryId: string): Observable<IMProduct<ICategory<IMarlenkaOwner>>[]> {
    return this.fetchAll({
      filter: JSON.stringify({categories: {$in: [categoryId]}}),
      sortColumn: 'priority',
      sortDirection: 'DESC'
    }).pipe(
      map(response => response.items)
    );
  }
}
