import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Apollo} from "apollo-angular";
import {BaseTicketService} from "./base-ticket.service";
import {FetchAllArgs, IPaginatedResponse} from "@echo-nx/shared/common";
import {IMTicketType} from "@echo-nx/marlenka/common";

@Injectable({
  providedIn: 'root'
})
export class TicketService extends BaseTicketService {

  private _ticketTypes: BehaviorSubject<IMTicketType[]>;
  public readonly ticketTypes$: Observable<IMTicketType[]>;

  set ticketTypes(ticketTypes: IMTicketType[]) {
    this._ticketTypes.next(ticketTypes);
  }

  get ticketTypes() {
    return this._ticketTypes.getValue();
  }

  constructor(apollo: Apollo,) {
    super(apollo);
    this._ticketTypes = new BehaviorSubject<IMTicketType[]>([]);
    this.ticketTypes$ = this._ticketTypes.asObservable();
  }


  fetchAll(args: FetchAllArgs): Observable<IPaginatedResponse<IMTicketType>> {
    return super.fetchAll(args);
  }
}
