import {ElementRef, Injectable, Injector} from '@angular/core';
import {
  Overlay,
  OverlayConfig,
  OverlayRef
} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {v4 as uuidv4} from 'uuid';
import {LoadingComponent} from "./component/loading.component";


@Injectable({
  providedIn: 'root'
})
export class BaseLoadingService {
  protected panelClassList: string[] = [];
  protected backdropClass: string[] = ['cdk-overlay-darker-backdrop'];

  protected readonly loadingComponent = new ComponentPortal(LoadingComponent);
  public activeOverlays: { [id: string]: OverlayRef } = {}

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) {}

  public isLoading(loadingId?: string): boolean {
    if(loadingId) {
      return this.activeOverlays[loadingId] != null;
    }
    return Object.keys(this.activeOverlays).length > 0;
  }

  public startLoading(element?: ElementRef): string {
    const overlayConfig = element ? this.createPositionedByElementConfig(element) : this.createGlobalPositionedConfig();

    const id = uuidv4();

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.attach(this.loadingComponent);
    this.activeOverlays[id] = overlayRef;

    return id;
  }

  public stopLoading(loadingId: string) {
    this.activeOverlays[loadingId].detach();
    delete this.activeOverlays[loadingId];
  }

  public stopLoadingAll() {
    const loadingIds = Object.keys(this.activeOverlays);
    for (const loadingId of loadingIds) {
      this.stopLoading(loadingId);
    }
  }

  private createGlobalPositionedConfig(): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      panelClass: this.panelClassList,
      backdropClass: this.backdropClass,
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  private createPositionedByElementConfig(element: ElementRef): OverlayConfig {
    return new OverlayConfig({
      panelClass: [...this.backdropClass, 'cdk-overlay-centered'],
      width: `${element.nativeElement.clientWidth}px`,
      height: `${element.nativeElement.clientHeight}px`,
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(element)
        .withPositions([{
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          panelClass: this.panelClassList
        }])
    });
  }
}
