import {Directive, HostBinding, HostListener, Output, EventEmitter, Input} from '@angular/core';

@Directive({
  selector: '[echoNxDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() dropped = new EventEmitter<any>();

  @HostBinding('style.opacity') private opacity = '1';

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '0.65'
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '1';
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '1';
    this.dropped.emit(evt);
  }

}
