import {IBaseOwner, IKioskOwnerAttributes} from "@echo-nx/shared/common";


export interface IMarlenkaOwner extends IBaseOwner, IKioskOwnerAttributes {
  settings: IMOwnerSettings;
}

export interface IMOwnerSettings {
  daysBeforeReservationCanBeChanged?: number;
  maximumInvoiceItems?: number;
  reportRecipients?: string[];
}
