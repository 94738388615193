import {Component, Inject, Injector, OnInit, Optional} from '@angular/core';
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {IColorPickerFieldSetting} from "./i-color-picker-field.setting";
import {BaseFormFieldComponent} from "../base-form-field.component";
import {ColorEvent} from "ngx-color";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-color-picker-field',
  templateUrl: './color-picker-field.component.html',
  styleUrls: ['./color-picker-field.component.scss']
})
export class ColorPickerFieldComponent extends BaseFormFieldComponent<IColorPickerFieldSetting> implements OnInit {
  public showPicker = false;

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IColorPickerFieldSetting>,
              formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
  }

  colorChanged(event: ColorEvent): void {
    const {color} = event;
    const {hex} = color;
    this.formControl.setValue(hex);
  }
}

