import {
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[echoNxLongPress]',
})
export class LongPressDirective {
  @Input() public duration = 400;
  @Output() public longPress: EventEmitter<LongPressClickEvent> =
    new EventEmitter();

  public pointerUps$ = new Subject<PointerEvent>();
  public pointerDowns$ = new Subject<PointerEvent>();
  public isDestroyed = new Subject<void>();

  constructor(private _elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.pointerDowns$
      .pipe(
        mergeMap((event) =>
          of(event).pipe(delay(this.duration), takeUntil(this.pointerUps$))
        )
      )
      .subscribe((event) => {
        this.longPress.emit({
          event,
          element: this._elementRef,
        });
      });
  }

  public ngOnDestroy(): void {
    this.isDestroyed.next();
    this.isDestroyed.unsubscribe();
  }

  @HostListener('pointerup', ['$event'])
  public onPointerUp(event: PointerEvent): void {
    this.pointerUps$.next(event);
  }

  @HostListener('pointerdown', ['$event'])
  public onPointerDown(event: PointerEvent): void {
    this.pointerDowns$.next(event);
  }
}

export interface LongPressClickEvent {
  event: PointerEvent;
  element: ElementRef;
}
