import { animate, group, query, style, transition, trigger } from '@angular/animations';


const resetRoute = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'fixed', // using absolute makes the scroll get stuck in the previous page's scroll position on the new page
        top: 0, // adjust this if you have a header so it factors in the height and not cause the router outlet to jump as it animates
        left: 0,
        width: '100%',
        opacity: 0,
      })
    ],
    { optional: true }
  )
];


const slideTo = (direction) => ([
    ...resetRoute,
    query(':enter', [style({ opacity: 0, 'z-index': 11 })], { optional: true }),
    group([
      query(':leave', [style({ opacity: 1, position: 'absolute', top: 0, right: 0, width: '100%','z-index': 12 }), animate('0.3s', style({ opacity: 0 }))],
        { optional: true }),
      query(':enter', [style({ opacity: 0, 'z-index': 13 }), animate('0.3s', style({ opacity: 1, position: 'absolute', top: 0, right: 0, width: '100%' }))],
        { optional: true })
    ])
  ]);

export const routerAnimations = [
  trigger('routeAnimations', [
      transition('* <=> *', slideTo('left'))
    ]
  )
];
