import gql from "graphql-tag";

export const refundTransaction = gql`
  mutation refundTransaction($id: String!){
    response: refundTransaction(id: $id){
      userMessage
      description
      error
    }
  }
`;
