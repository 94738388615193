import {Component, Injector} from '@angular/core';
import {CreatePageTexts} from "@echo-nx/marlenka/common";
import {MarlenkaBasePageComponent} from "../../marlenka-base-page/marlenka-base-page.component";


/**
 * Slouží k vybrání typu nákupu (voucher vs rezervace)
 *
 **/

@Component({
  selector: 'marlenka-select-purchase-type-page',
  templateUrl: './select-purchase-type-page.component.html',
  styleUrls: ['./select-purchase-type-page.component.scss']
})
export class SelectPurchaseTypePageComponent extends MarlenkaBasePageComponent<CreatePageTexts> {

  constructor(injector: Injector) {
    super(injector);
  }
}
