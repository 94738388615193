import gql from 'graphql-tag';

export const readAllCategoriesOfEntity = gql`
  query ReadAllCategoriesOfEntity($language: String, $type: String!, $sortColumn: String, $sortDirection: String) {
    response: readAllCategoryOfType(
      language: $language,
      type: $type,
      sortColumn: $sortColumn,
      sortDirection: $sortDirection
    ) {
      _id
      color
      isSystemic
      name
      publishedAt
      description
      language
      languageGroupId
      featuredImage{
        _id
        mime
        type
        url
        description
        name
      }
      type
      priority,
      createdAt
    }
  }
`;
