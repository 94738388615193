<div class="flex space-x-3 items-center"
     [ngClass]="[primaryColor | prefix:'bg', fontSize | prefix: 'text', secondaryColor | prefix:'text']">
  <i *ngIf="startIcon" [ngClass]="[startIcon?.color | prefix:'text', startIcon.icon ?? '']"></i>
  <span
    *ngIf="title && !isWysiwyg"
    [ngClass]="[
        secondaryColor | prefix:'text',
        fontWeight | prefix: 'font',
        fontFamilyType | prefix: 'font',
        ]">{{title}}</span>


  <i *ngIf="endIcon" [ngClass]="[endIcon?.color | prefix:'text', endIcon.icon ?? '']"></i>
</div>
