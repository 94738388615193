import {ICategorizedEntity, IOwnedEntity} from "./i-owned-entity";
import {IBaseOwner} from "./i-base-owner";
import {ICategory} from "./i-category";

export interface ITheme<O extends IBaseOwner | string = any, C extends ICategory<O> | string = any> extends IOwnedEntity<O>, ICategorizedEntity<C> {
  name: string;

  variables: IThemeVariables
}

// matches libs/shared/ng/ui/generic/src/variables.css
export interface IThemeVariables {
  epxColorPrimary50: string;
  epxColorPrimary100: string;
  epxColorPrimary200: string;
  epxColorPrimary300: string;
  epxColorPrimary400: string;
  epxColorPrimary500: string;
  epxColorPrimary600: string;
  epxColorPrimary700: string;
  epxColorPrimary800: string;
  epxColorPrimary900: string;

  epxColorSecondary50: string;
  epxColorSecondary100: string;
  epxColorSecondary200: string;
  epxColorSecondary300: string;
  epxColorSecondary400: string;
  epxColorSecondary500: string;
  epxColorSecondary600: string;
  epxColorSecondary700: string;
  epxColorSecondary800: string;
  epxColorSecondary900: string;

  epxColorAccent50: string;
  epxColorAccent100: string;
  epxColorAccent200: string;
  epxColorAccent300: string;
  epxColorAccent400: string;
  epxColorAccent500: string;
  epxColorAccent600: string;
  epxColorAccent700: string;
  epxColorAccent800: string;
  epxColorAccent900: string;

  epxFontFamilyDisplay: string;
  epxFontFamilyBody: string;
  epxFontFamilyExtra: string;

  epxTextXs: string;
  epxTextSm: string;
  epxTextBase: string;
  epxTextLg: string;
  epxTextXl: string;
  epxText2Xl: string;
  epxText3Xl: string;
  epxText4Xl: string;
  epxText8Xl: string;
}
