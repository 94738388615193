<ng-container *ngIf="medium; else noMedium">
  <img
    [gallery]="groupService.galleryId"
    [lightbox]="index"
    [src]="medium?.url || medium?.thumbnailUrl"
    [class.cover]="cover"
    [alt]="medium?.description"
  />
</ng-container>
<ng-template #noMedium>
  <div class="empty-medium" [lightbox]="index" [gallery]="groupService.galleryId"></div>
</ng-template>
