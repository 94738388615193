<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup">
  <mat-label>{{ settings.nazev }}</mat-label>
  <input
    matInput
    autocomplete="off"
    (dateInput)="onInputChange($event)"
    [formControlName]="settings.formControlName"
    [matDatepicker]="picker"
    placeholder="{{ settings.nazev }}"
    [required]="settings.povinnost"
    (click)="picker.open()"
  />
  <mat-datepicker-toggle matSuffix (click)="removeAllEntities($event)">
    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
  </mat-datepicker-toggle>
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <mat-datepicker #picker></mat-datepicker>

  <!-- <mat-error *ngIf="settings.form.get(settings.formControlName)?.errors"><div *ngIf="settings.form.get(settings.formControlName)?.errors.pkerror">{{settings.form.get(settings.formControlName)?.errors}}</div>Toto pole je povinné!</mat-error> -->
  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>
</mat-form-field>
