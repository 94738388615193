import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {Sort} from "@angular/material/sort";
import {DataViewType} from "../interfaces";
import {FormControlTyped, FormGroupTyped} from './typed-forms';
import {FilterNames, MaterialPaginatorGroup} from "./filter-utils";

@Injectable()
export class DataCardStateService implements OnDestroy {
  private isDestroyed$: Subject<boolean> = new Subject<boolean>();
  public filterForm!: UntypedFormGroup;

  get filterCount(): number {
    return (Object.values(this.getFiltersControl().value ?? {}) as any[]).reduce((acc: number, curr: any) => {
      if (Array.isArray(curr)) {
        acc += curr.length;
      } else if (curr?.length > 0) {
        acc += 1;
      } else if (curr){
        acc += 1;
      }
      return acc;
    }, 0);
  }

  constructor() {
    this.filterForm = new UntypedFormGroup({});

    //create Search
    this.filterForm.addControl(FilterNames.SEARCH_FILTER_NAME, new UntypedFormControl(null))

    //create Paginator
    this.filterForm.addControl(FilterNames.PAGINATION_FILTER_NAME, new UntypedFormGroup({
      pageIndex: new UntypedFormControl(0),
      pageSize: new UntypedFormControl(10)
    }) as FormGroupTyped<MaterialPaginatorGroup>);

    //create Sort
    this.filterForm.addControl(FilterNames.SORT_FILTER_NAME, new UntypedFormGroup({
      active: new UntypedFormControl('_id'),
      direction: new UntypedFormControl('desc')
    }));

    //create control for ViewType
    this.filterForm.addControl(FilterNames.VIEWTYPE_FILTER_NAME, new UntypedFormControl(null));

    //create external
    this.filterForm.addControl(FilterNames.EXTERNAL_FILTER_NAME, new UntypedFormGroup({}));
  }

  /* FORM GETTERZ */
  public getPaginationControl(): FormGroupTyped<MaterialPaginatorGroup> {
    return this.filterForm.get(FilterNames.PAGINATION_FILTER_NAME) as FormGroupTyped<MaterialPaginatorGroup>;
  }

  public getSortControl(): FormGroupTyped<Sort> {
    return this.filterForm.get(FilterNames.SORT_FILTER_NAME) as FormGroupTyped<Sort>;
  }

  public getFiltersControl(): FormGroupTyped<any> {
    return this.filterForm.get(FilterNames.EXTERNAL_FILTER_NAME) as FormGroupTyped<any>;
  }

  public getSearchControl(): FormControlTyped<string> {
    return this.filterForm.get(FilterNames.SEARCH_FILTER_NAME) as FormControlTyped<string>;
  }

  public getDataViewTypeControl(): FormControlTyped<DataViewType | null> {
    return this.filterForm.get(FilterNames.VIEWTYPE_FILTER_NAME) as FormControlTyped<DataViewType | null>;
  }

  public getMongoFilters(): string | undefined {
    return undefined;
  }

  ngOnDestroy(): void {
    // console.log('goodbye');
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
