import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {getEventsForDay, IMEvent} from "@echo-nx/marlenka/common";
import {EventsService} from "@echo-nx/marlenka/ng/data-access";
import {BaseLoadingService} from "@echo-nx/shared/ng/feature/loading";
import {generateDummyDays} from "@echo-nx/shared/ng/feature/reservation";

@Component({
  selector: 'marlenka-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {

  constructor(public eventsService: EventsService, private loadingService: BaseLoadingService, private hostElement: ElementRef) {

  }

  ngOnInit(): void {
    const loadingId = this.loadingService.startLoading();
    const {selectedDate} = this.eventsService;
    this.fetchEvents(selectedDate)
      .then(events => {
        this.eventsService.dayEvents = getEventsForDay(events, selectedDate);
      })
      .finally(() => this.loadingService.stopLoading(loadingId));
  }


  private fetchEvents(date: Date): Promise<IMEvent[]> {
    // set dummy days during fetching
    this.eventsService.days = generateDummyDays(date);

    return this.eventsService.fetchEventsInMonth(date).then((events) => {
      this.eventsService.events = events;
      this.eventsService.days = this.eventsService.getDaysWithEvents(date, this.eventsService.events, this.eventsService.filter);
      return events;
    });
  }

  dateSelected(date: Date) {
    this.eventsService.selectedDate = date;
    this.eventsService.dayEvents = getEventsForDay(this.eventsService.events, date);
  }

  monthSelected(date: Date) {
    const loadingId = this.loadingService.startLoading();
    this.eventsService.selectedMonth = date;
    this.fetchEvents(date)
      .finally(() => this.loadingService.stopLoading(loadingId));
  }

}
