import gql from 'graphql-tag';

export const readAllRoles = gql`
  query ReadAllRoles($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllRole(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
      }
    }
  }
`;
