import gql from 'graphql-tag';

export const readAllPointsOfInterestForSynchronizing = gql`
  query ReadAllPointsOfInterest($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllPOIItem(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        name
        categories {
          icon
          name
          color
        }
        description
        owner{
          _id
          name
        }
        location {
          lat
          lng
        }
        featuredImage{
          _id
          url
          thumbnailUrl
          type
        }
        media{
          _id
          type
          url
          thumbnailUrl
          description
          createdAt
        }
        meta
        content
        createdAt
        deletedAt
      }
    }
  }
`
