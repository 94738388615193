import { Component, Input } from '@angular/core';
import { PageSettingService } from '../../services/page-setting.service';
import { ICompanyContact } from '@echo-nx/shared/common';
import {
  ScrollableService,
} from '@echo-nx/shared/ng/feature/common';
import { ContactService } from '../../services/contact.service';
import {
  CommonpageTexts,
  FooterTexts,
  MarlenkaFooterClass,
  MarlenkaPageIdentifier,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import {map, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {CookieConsentManager} from "@echo-nx/shared/ng/feature/cookies";

@Component({
  selector: 'marlenka-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {


  public footerTexts$: Observable<FooterTexts>;
  public mainContact$: Observable<ICompanyContact>;

  @Input()
  public classList?: MarlenkaFooterClass;

  @Input()
  public rows = 3;

  constructor(
    private pageSettingService: PageSettingService,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    public scrollService: ScrollableService,
    private contactService: ContactService,
    public cookieManager: CookieConsentManager,
  ) {
    this.footerTexts$ = this.mrdService.currentLanguage$.pipe(switchMap((language) => this.pageSettingService
      .getPageSettingsByIdentifier<CommonpageTexts>(
        MarlenkaPageIdentifier.COMMON,
        language
      )
      .pipe(map((pageSettings) => pageSettings.texts.footer))));

    this.mainContact$ = this.contactService.fetchMainContact();

  }

  public scrollToTop() {
    this.scrollService.scrollToTop('smooth');
  }
}
