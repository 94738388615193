import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  HostBinding,
  OnInit,
  Output,
  ElementRef,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { BaseCalendarDaySettings, CalendarDay } from '../calendar.types';

@Component({
  selector: 'echo-nx-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  private readonly isDestroyed$ = new Subject<boolean>();

  // todo multilanguage
  public readonly _days = ['PO', 'ÚT', 'ST', 'ČT', 'PÁ', 'SO', 'NE'];
  public readonly _months = [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
  ];

  public readonly today = new Date();
  private _currentMonth!: BehaviorSubject<Date>;
  private currentMonth$!: Observable<Date>;
  public selectedDate?: Date;

  @Input()
  initialDay!: Date;


  @HostBinding('class')
  @Input() panelClass!: ElementRef<unknown>;

  @Output()
  monthSelected = new EventEmitter<Date>();

  @Output()
  dateSelected = new EventEmitter<Date>();

  @Input()
  days: CalendarDay<BaseCalendarDaySettings>[] = [];

  // STYLES:
  @Input() selectedBackgroundColor = '#AB0E0E';
  @Input() selectedColor = '#FFFFFF';
  @Input() todayBorder = '1px dashed #AB0E0E';
  @Input() gridGap = 0.5;
  @Input() rounded = true;
  @Input() dayBorder = '1px solid rgba(110,75,0,0.25)';
  /**
   * The min height of cell in rem;
   * // consider: This could be avoided here by setting height in the concrete CalendarDay
   */
  @Input() cellMinHeight = 5.25;

  //public minHeight: string;

  get currentMonth(): Date {
    // console.log('getting current month', this._currentMonth, this._currentMonth.getValue());
    return this._currentMonth.getValue();
  }

  set currentMonth(date: Date) {
    this._currentMonth.next(date);
  }

  ngOnInit(): void {
    this._currentMonth = new BehaviorSubject(this.initialDay);
    this.currentMonth$ = this._currentMonth.asObservable();
    // set styles from input
    document.documentElement.style.setProperty(
      '--selected-color',
      this.selectedBackgroundColor
    );
    document.documentElement.style.setProperty(
      '--today-border',
      this.todayBorder
    );
    document.documentElement.style.setProperty(
      '--grid-gap',
      `${this.gridGap}rem`
    );
    document.documentElement.style.setProperty('--day-border', this.dayBorder);
    document.documentElement.style.setProperty(
      '--cell-min-height',
      `${this.cellMinHeight}rem`
    );

    // set today as default selected
    this.selectedDate = this.initialDay;

    // subscribe to month changes
    this.subscribeToMonthChange();
  }

  /**
   * Subscribes to month changes and emits the new value.
   */
  private subscribeToMonthChange() {
    this.currentMonth$
      .pipe(takeUntil(this.isDestroyed$), skip(1))
      .subscribe((currentMonth) => {
        this.monthSelected.emit(currentMonth);
      });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

  nextMonth() {
    const newYear =
      this.currentMonth.getMonth() === this._months.length - 1
        ? this.currentMonth.getFullYear() + 1
        : this.currentMonth.getFullYear();
    const newMonth = (this.currentMonth.getMonth() + 1) % 12;

    this.currentMonth = new Date(newYear, newMonth);
  }

  previousMonth() {
    const newYear =
      this.currentMonth.getMonth() === 0
        ? this.currentMonth.getFullYear() - 1
        : this.currentMonth.getFullYear();
    const newMonth =
      this.currentMonth.getMonth() - 1 < 0
        ? this._months.length - 1
        : this.currentMonth.getMonth() - 1;

    this.currentMonth = new Date(newYear, newMonth);
  }

  selectDate(year: number, month: number, day: number) {
    const date = new Date(year, month, day);
    this.selectedDate = date;
    this.dateSelected.emit(date);
  }

  isSelected(index: number) {
    return (
      this.selectedDate?.getDate() === index + 1 &&
      this.selectedDate.getMonth() === this.currentMonth.getMonth() &&
      this.selectedDate.getFullYear() === this.currentMonth.getFullYear()
    );
  }

  isToday(index: number) {
    return (
      this.today.getDate() === index + 1 &&
      this.today.getMonth() === this.currentMonth.getMonth() &&
      this.today.getFullYear() === this.currentMonth.getFullYear()
    );
  }
}
