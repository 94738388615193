import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemCardThemeRsdComponent } from './item-card-theme-rsd.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    ItemCardThemeRsdComponent
  ],
  imports: [
    PrefixModule,
    CommonModule
  ],
  exports:[
    ItemCardThemeRsdComponent
  ]
})
export class ItemCardThemeRsdThemeModule { }
