import gql from 'graphql-tag';
import {WIDGET_FRAGMENTS} from './widget.fragments';


export const LAYOUT_FRAGMENT = gql`

  fragment LayoutUnionFragment on LayoutUnionObject {
    ... FlexLayoutFragment
    ... GridLayoutFragment
    ... GridThreeCellsLayoutFragment
    ... OverlayLayoutFragment
  }

  fragment FlexLayoutFragment on FlexLayoutObject {
    name
    to
    settings {
      flexDirection
      justifyContent
      alignItems
      padding
      panelClass
      background {
        color
        image {
          url
        }
      }
    }
    widgets {
      ... WidgetsUnionFragment
    }
  }

  fragment GridLayoutFragment on GridLayoutObject {
    name
    to
    settings {
      gridGap
      padding
      gridTemplateColumns
      gridTemplateRows
      justifyContent
      alignItems
      panelClass
      background {
        color
        image {
          url
        }
      }
    }
    widgets {
      ... WidgetsUnionFragment
    }
  }

  fragment GridThreeCellsLayoutFragment on GridThreeCellsLayoutObject {
    name
    to
    gridThreeCellsWidgets: widgets {
      header {
        ... WidgetsUnionFragment
      }
      leftCell {
        ... WidgetsUnionFragment
      }
      rightCell {
        ... WidgetsUnionFragment
      }
    }
  }

  fragment OverlayLayoutFragment on OverlayLayoutObject {
    name
    to
    settings {
      padding
      background {
        color
        image {
          url
        }
      }
    }
    overlayWidgets: widgets {
      buttons {
        ... WidgetsUnionFragment
      }
      filters {
        ... WidgetsUnionFragment
      }
      overlay {
        ... WidgetsUnionFragment
      }
    }
  }

  ${WIDGET_FRAGMENTS}
`;
