<button
  class="flex justify-center rounded overflow-hidden items-stretch"
  [ngClass]="[bgColor | prefix:'bg']"
  [class.flex-1]="fluid === 'horizontal' || fluid === 'both'"
  [class.flex-col]="!!description">
  <!-- START ICON -->
  <div *ngIf="startIcon"
       class="flex items-center justify-center px-4 py-5 m-2"
       [class.px-0]="!!description"
       [class.flex-1]="!!description"
       [ngClass]="[
       startIcon.bgColor | prefix:'bg',
       startIcon.color | prefix:'text',
       description ? 'text-3xl' : '',
       ]">
    <i [ngClass]="startIcon.icon" class="fa-fw"></i>
  </div>

  <!-- TEXT -->
  <div class="flex flex-col flex-1 items-center py-4 px-6 justify-center items-center">
      <span [ngClass]="[textColor | prefix:'text']"
            class="text-xl text-center font-medium">{{title}}</span>
    <ng-container *ngIf="description">
      <span
        class="font-light text-center my-2 text-sm"
        [ngClass]="[descriptionColorful?.color | prefix:'text',descriptionColorful?.bgColor | prefix:'bg']">
        {{description}}
      </span>
    </ng-container>

  </div>

  <!-- END ICON -->
  <div *ngIf="endIcon"
       class="flex items-center justify-center px-4 py-5 m-2"
       [class.flex-1]="!!description"
       [class.px-0]="!!description"
       [ngClass]="[
         endIcon.bgColor | prefix:'bg',
         endIcon.color | prefix:'text',
         description ? 'text-3xl' : ''
       ]">
    <i [ngClass]="endIcon.icon" class="fa-fw"></i>
  </div>
</button>
