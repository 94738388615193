import { BaseWidgetSettings } from './base-settings.type';
import {IMedium} from "../../i-medium";
import {IEpxFluidable} from "../../structural";


export type ImageSettings<M extends IMedium<any> | string = IMedium<any>> = {
  url?: string; // deprecate in favor of image?
  image?: M;
  objectFit: ObjectFit;
  maxWidthPx?: number;
  panelClass?: string;
} & BaseWidgetSettings & IEpxFluidable;

export type ObjectFit = 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
