<marlenka-stepper
  #marlenkaStepper
  [steps]="reservationSteps"
  (stepsCompleted)="onReservationCompleted()"
  (backClicked)="backClicked.emit($event)"
>
<!--  <button
    buttons
    *ngIf="reservationService.selectedEvent$ | async"
    id="coupon-button"
    mat-flat-button
    [matBadge]="reservationService.selectedCoupons.length.toString()"
    matBadgePosition="above before"
    [matBadgeHidden]="reservationService.selectedCoupons.length === 0"
    (click)="openCouponDialog()"
    style="margin-left: auto;"
  >
    <mat-icon>redeem</mat-icon>
    <span class="text">Uplatnit voucher</span>
  </button>-->
</marlenka-stepper>
