import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonThemeCComponent} from './button-theme-c.component';
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    ButtonThemeCComponent,
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ButtonThemeCComponent
  ]
})
export class ButtonThemeCModule {
}
