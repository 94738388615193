import {Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, OnDestroy} from '@angular/core';

@Component({
  selector: 'echo-nx-webcam-scanner',
  templateUrl: './webcam-scanner.component.html',
  styleUrls: ['./webcam-scanner.component.scss']
})
export class WebcamScannerComponent implements OnInit, OnDestroy {

  public error = '';

  @Input()
  public width = 300;

  @Input()
  public height = 300;

  @Input()
  public interval = 250;

  @ViewChild('videoElement', {static: true})
  private videoElement!: ElementRef<HTMLVideoElement>;

  @ViewChild('canvasElement', {static: true})
  private canvasElement!: ElementRef<HTMLCanvasElement>;

  @Output()
  public imageScanned = new EventEmitter<ImageData>();

  private currentIntervalId: number | null = null;
  private currentStream?: MediaStream;

  async ngOnInit() {
    try {
      // init video webcam
      const {width, height, stream} = await this.initVideoStream(this.videoElement?.nativeElement);

      // save current  stream
      this.currentStream = stream;

      // setup canvas and get context
      const canvasContext = this.setupCanvasAndGetContext(this.canvasElement.nativeElement, width, height);

      // start interval drawing
      if (!this.currentIntervalId) {
        this.currentIntervalId = window.setInterval(() => {
          // draw it on the canvas
          canvasContext.fillRect(0, 0, width, height);
          canvasContext.drawImage(this.videoElement.nativeElement, 0, 0, width, height);

          // emit the image data
          this.imageScanned.emit(canvasContext.getImageData(0, 0, width, height))
        }, this.interval);
      }
    } catch (e) {
      this.error = e;
      console.error('error initing webscanner', e);
    }

  }

  ngOnDestroy(): void {
    if (this.currentIntervalId) {
      clearInterval(this.currentIntervalId);
    }

    // stop webcam
    for (const track of this.currentStream?.getTracks() ?? []) {
      track.stop();
    }

  }

  /**
   *
   * @param videoElement - html video element
   * @returns Promise with object containing *width* and *height* of video element
   */
  private async initVideoStream(videoElement: HTMLVideoElement): Promise<{ width: number, height: number, stream: MediaStream }> {
    // create options
    const mediaStreamOptions: MediaStreamConstraints = {
      video: {
        facingMode: "environment" // back camera
      },
      audio: false
    }

    return window.navigator?.mediaDevices?.getUserMedia(mediaStreamOptions)
      .then(stream => {
        // set the stream to our videoElement
        videoElement.srcObject = stream;

        // playback time
        videoElement.play();

        return {
          width: videoElement.width,
          height: videoElement.height,
          stream
        }
      });
  }

  private setupCanvasAndGetContext(canvasElement: HTMLCanvasElement, width: number, height: number): CanvasRenderingContext2D {
    canvasElement.width = width;
    canvasElement.height = height;
    return canvasElement.getContext('2d') as CanvasRenderingContext2D;
  }
}
