import {IMVoucherType} from "@echo-nx/marlenka/common";
import {Observable, Subject} from "rxjs";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {ReservationService, BaseVoucherTypeService} from "@echo-nx/marlenka/ng/data-access";
import {debounceTime, map, takeUntil} from "rxjs/operators";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'marlenka-use-voucher-step',
  templateUrl: './use-voucher-step.component.html',
  styleUrls: ['./use-voucher-step.component.scss']
})
export class UseVoucherStepComponent implements OnInit, OnDestroy {
  private isDestroyed$ = new Subject<boolean>();

  public selectedBackgroundId!: string;
  public text = new UntypedFormControl('');
  public title = new UntypedFormControl('');

  public voucherTypes$!: Observable<IMVoucherType[]>;

  constructor(public voucherTypeService: BaseVoucherTypeService, public reservationService: ReservationService) {
    this.text.patchValue(this.reservationService.voucherCustomization?.text);
    this.title.patchValue(this.reservationService.voucherCustomization?.title);
  }

  async ngOnInit() {
    // fetch backgrounds if not already fetched
    this.voucherTypes$ = this.voucherTypeService.fetchAll()
      .pipe(
        map(voucherTypes => {
          const emptyVoucher: IMVoucherType = {
            _id: null
          };

          // preselect first if nothing is selected
          if (!this.reservationService.voucherCustomization) {
            this.selectBackground(emptyVoucher);
          } else {
            this.selectedBackgroundId = this.reservationService.voucherCustomization.voucherTypeId;
          }

          return [emptyVoucher, ...voucherTypes.items];
        }));

    // setup text observable
    this.text.valueChanges.pipe(
      debounceTime(150),
      takeUntil(this.isDestroyed$)
    ).subscribe(text => {
      this.reservationService.voucherCustomization = {
        ...this.reservationService.voucherCustomization,
        text
      };
    });

    // setup title observable
    this.title.valueChanges.pipe(
      debounceTime(150),
      takeUntil(this.isDestroyed$)
    ).subscribe(title => {
      this.reservationService.voucherCustomization = {
        ...this.reservationService.voucherCustomization,
        title
      };
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  public selectBackground(voucherType: IMVoucherType) {
    console.log('selecting voucher id', voucherType._id);
    this.selectedBackgroundId = voucherType._id;
    this.reservationService.voucherCustomization = {
      ...this.reservationService.voucherCustomization,
      voucherTypeId: voucherType._id
    };
  }
}
