//ACTIVE and PAID FOR ARTICLE => Voucher... KOD_POUKAZU = printed CODE ON PAPER

// D3000S.VOUCHERY_SN
export interface IMDialogVoucher {
  articleCode: string;
  articleName: string;
  code: string;
  invoiceNo: string;
  boughtAt: Date;
  expiresAt: Date;
  eventStart: Date;
  changeCount: number;
  usedAt: Date;
  contactName: string;
  contactEmail: string;
  contactTel: string;
  articleType: string;

  noChildren: number;
  noAdults: number;

  price: number;

  footerText: string;

  seller: "Marlenka" | "Slevomat" | string;
}
