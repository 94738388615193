import {AfterViewInit, Component, Inject, Injector, OnInit, Optional, ViewChild} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IDateFieldSettings} from "./i-date-field.settings";
import {MatDatepicker, MatDatepickerInputEvent} from "@angular/material/datepicker";
import {getFirstError} from "../../../utils/form-utils";
import {IEntityDefinition} from "../../../interfaces";
import {takeUntil} from "rxjs/operators";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent extends BaseFormFieldComponent<IDateFieldSettings> implements OnInit, AfterViewInit{
  public firstError ?: string;

  @ViewChild('picker')
  picker!: MatDatepicker<any>;

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IDateFieldSettings>,
              formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def, formGroupDirective, injector);
  }


  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.firstError = getFirstError(this.settings.formControlName, this.formGroup);
  }

  public onInputChange(event: MatDatepickerInputEvent<any>){
    // sadly null is wrong value, we need undefined
    if(event.value === null){
      this.picker.select(undefined);
    }
  }

  public removeAllEntities(event: Event) {
    // dont propagate to input underneath it
    event.stopPropagation();
    this.picker.select(undefined);
    this.formControl.patchValue(null);
  }

  ngAfterViewInit(): void {
    this.formGroup?.get(this.settings.formControlName)?.valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe((_) => {
      this.firstError = getFirstError(this.settings.formControlName, this.formGroup);
    })
  }

}
