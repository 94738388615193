export interface IStatResult{
    year?: number;
    month?: number;
    day?: number;
    count: number;
}

export enum TimeperiodType {
  'All',
  'Year',
  'Month',
  'Day'
}
