import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ItemDetailVariantBComponent} from "./item-detail-variant-b.component";
import {PrefixModule} from "../../prefix/prefix.module";




@NgModule({
  declarations: [
    ItemDetailVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ItemDetailVariantBComponent
  ],
})
export class ItemDetailVariantBModule { }
