import gql from 'graphql-tag';

export const readSelectedIntegrations = gql`
  query ReadSelectedIntegrations($ids: [String!]!) {
    response: readSelectedApiIntegration(
      ids: $ids
    ) {
      _id
      name
      endpoint
      integrationType
      cronExpression
      method
      owner{
        _id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
