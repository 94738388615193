import gql from "graphql-tag";

export const deleteTimelines = gql`
  mutation deleteTimelines($input: [String!]!){
    response: deleteTimeline(ids: $input){
      userMessage
      description
      error
    }
  }
`;
