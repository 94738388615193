import {Component} from '@angular/core';


@Component({
  selector: 'marlenka-debug-page',
  templateUrl: './debug-page.component.html',
  styleUrls: ['./debug-page.component.scss']
})
export class DebugPageComponent {

}
