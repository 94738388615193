import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {IMapPointOptions, IMapViewOptions, IPOIItem, MapMarkerProps, remapItem} from "@echo-nx/shared/common";
import {Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import ZoomControlOptions = google.maps.ZoomControlOptions;

const customMarkerPath = 'M 8.523438 24.496094 C 1.335938 14.210938 0 13.15625 0 9.375 C 0 4.199219 4.253906 0 9.5 0 C 14.746094 0 19 4.199219 19 9.375 C 19 13.15625 17.664062 14.210938 10.476562 24.496094 C 10.003906 25.167969 8.996094 25.167969 8.523438 24.496094 Z M 9.5 13.28125 C 11.6875 13.28125 13.457031 11.53125 13.457031 9.375 C 13.457031 7.21875 11.6875 5.46875 9.5 5.46875 C 7.3125 5.46875 5.542969 7.21875 5.542969 9.375 C 5.542969 11.53125 7.3125 13.28125 9.5 13.28125 Z M 9.5 13.28125';


@Component({
  selector: 'echo-nx-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapViewComponent implements IMapViewOptions, OnInit, AfterViewInit, OnDestroy, OnChanges {
  public center: google.maps.LatLngLiteral = {
    lat: 49.75,
    lng: 14.25
  };

  debouncer$: Subject<void> = new Subject<void>();
  isDestroyed$: Subject<void> = new Subject<void>();

  public options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoom: 7,
    zoomControl: true,
    disableDoubleClickZoom: false,
    mapTypeControl: false,
    streetViewControl: false,
    scrollwheel: false,
    maxZoom: 20,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    } as ZoomControlOptions,
    minZoom: 5,
    fullscreenControl: false,

  };

  public infoWindowOptions: google.maps.InfoWindowOptions = {
    disableAutoPan: true,
    position: {
      lat: 50.5,
      lng: 13
    }
  };

  @Input() defaultPointOptions?: IMapPointOptions;

  @Input() public data!: IPOIItem[];

  /**
   * A Map ID is a unique identifier that represents a single instance of a Google Map.
   * You can create Map IDs and update a style associated with a Map ID at any time in the Google Cloud Console
   * without changing embedded JSON styling in your application code.
   * https://developers.google.com/maps/documentation/javascript/styling#creating_map_styles
   */
  @Input() public mapId?: string;

  @Input() public kmlOverlayUrl?: string;

  @Input() public showIconInPointLabels?: boolean;

  @Input() public enableClustering = true;

  @Input() public defaultCenter = {
    lat: 49.75,
    lng: 14.25
  } as google.maps.LatLngLiteral;

  @Input() public debounceBoundaryEventMs = 400;

  @Input() public mapPointMapping?: string;// Partial<Record<keyof IMapPointOptions, string>>;

  /**
   * This will bound map to specific coordinates and prevent zooming and panning out
   */
  @Input() public bounds?: google.maps.LatLngBoundsLiteral;

  @Input() set settings({data, mapId, defaultCenter, kmlOverlayUrl, bounds}: IMapViewOptions) {
    this.data = data;
    this.kmlOverlayUrl = kmlOverlayUrl;
    this.mapId = mapId;
    this.defaultCenter = defaultCenter;
    this.bounds = bounds;
  }

  @Output() boundsChanged = new EventEmitter<google.maps.LatLngBounds>();

  @Output() pointSelected = new EventEmitter<IPOIItem>();

  @ViewChild(MapInfoWindow) private infoWindow!: MapInfoWindow;

  @ViewChild('mapEl') private googleMap!: GoogleMap;

  public mapMarkers?: MapMarkerProps[];

  //private clusterer!: MarkerClusterer;

  ngOnInit() {
    this.debouncer$.pipe(takeUntil(this.isDestroyed$), debounceTime(this.debounceBoundaryEventMs)).subscribe(() => {
      this.boundsChanged.emit(this.googleMap.getBounds() as google.maps.LatLngBounds);
    });
    if (this.bounds) {
      this.options.restriction = {
        strictBounds: false,
        latLngBounds: this.bounds
      };
    }
    if (this.defaultCenter) {
      this.center = this.defaultCenter;
    } else {
      // set center to device center
      this.setCenterFromGeolocation();
    }
    this.convertIPointToMarkers();

    /*if (infoWindowType === 'info-window-1') {
      this.infoWindowButtonSettings = {
        text: 'Více',
        textBackgroundColor: '#009FDA',
        textAlign: 'center',
        textColor: 'white'
      };
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    const {data} = changes;
    const {currentValue} = data;
    if (currentValue) {
      this.convertIPointToMarkers();
    }
  }

  ngAfterViewInit(): void {
    return;
  }

  private mapLabelIcon(key: string, point: IPOIItem): google.maps.MarkerLabel {
    return {
      fontFamily: 'font-awesome',
      text: remapItem(point as Record<any, any>, JSON.stringify({"icon": key}))?.icon
    }
  }

  private convertIPointToMarkers() {
    const mapping = JSON.parse(this.mapPointMapping ?? "{}");

    this.mapMarkers = this.data?.map((pt) => {
      return {
        poi: pt,
        markerOptions: {
          position: {lat: pt.location.lat, lng: pt.location.lng},
          title: pt.name,
          label: mapping?.labelIcon ? this.mapLabelIcon(mapping?.labelIcon, pt) : undefined,
          icon: {
            path: customMarkerPath,
            fillColor: pt.categories.find(({color}) => !!color)?.color ?? '#304ffe',
            fillOpacity: 1,
            scale: 1.5
          }
        }
      };
    })
  }


  private setCenterFromGeolocation() {
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
    });
  }

  openInfoWindow(marker: MapMarker, info: IPOIItem) {
    this.pointSelected.emit(info);
    this.infoWindow.open(marker);
  }

  closeInfoWindow() {
    this.pointSelected.emit(undefined);
    this.infoWindow.close();
  }

  _boundsChanged() {
    this.debouncer$.next();

  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }


}


