import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LightboxGroupDirective} from './directives/lightbox-group.directive';
import {MediumItemComponent} from './components/medium-item/medium-item.component';
import {LightboxModule} from "ng-gallery/lightbox";
import {GalleryModule} from "ng-gallery";


@NgModule({
  imports: [
    CommonModule,
    GalleryModule,
    LightboxModule/*.withConfig({
      panelClass: 'fullscreen',
    })*/
  ],
  declarations: [
    LightboxGroupDirective,
    MediumItemComponent
  ],
  exports: [
    MediumItemComponent,
    LightboxGroupDirective
  ]
})
export class SharedNgFeatureLightboxGalleryModule {
}
