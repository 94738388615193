export class EchoNxException extends Error {

  code: number;
  description?: string;

  constructor(message: string, code: number, description?: string) {
    super(message);
    this.name = "EchoNxException";
    this.code = code;
    this.description = description;
  }
}
