import {IMarlenkaOwner} from "./owner";
import {
  ICategorizedEntity,
  ICategory,
  ICompanyContact,
  IContact, IMultilanguage,
  IOwnedEntity, IPublishableOwnedEntity
} from "@echo-nx/shared/common";

export interface IMDepartment<T extends
    string[] |
    IContact[] | ICompanyContact[] =
    string[] | IContact[] | ICompanyContact[]
  >
  extends ICategorizedEntity<ICategory<IMarlenkaOwner> | string>, IMultilanguage, IPublishableOwnedEntity<IMarlenkaOwner | string> {
  name: string;
  description?: string;
  openingHours?: string;
  contact: T;
  priority?: number;
}
