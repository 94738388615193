<div
  class="border-2 rounded-full flex flex-row flex-shrink border-collapse overflow-hidden"

  [ngClass]="[bgColor | prefix:'border']"
  *ngIf="buttons$ | async as buttons">
  <!-- PAGINATION PREV BUTTONS -->
  <button class="p-2 button"
          [disabled]="activeIndex === 0"
          [ngClass]="activeIndex === 0 ? [disabledColorful?.color | prefix:'text', disabledColorful?.bgColor | prefix:'bg'] :
                                      [normalColorful?.color | prefix:'text', normalColorful?.bgColor | prefix:'bg']"
          (click)="previousClicked()">
    <i class="fas fa-chevron-left fa-fw"></i>
  </button>

  <!-- PAGINATION INDIVIDUAL PAGE BUTTONS-->
  <button class="button"
          *ngFor="let button of buttons"
          (click)="pageClicked(button.index)"
          [disabled]="button.isDisabled || button.isActive"
          [ngClass]="button.isDisabled ? ([disabledColorful.color | prefix:'text', disabledColorful.bgColor | prefix:'bg']) : (button.isActive ?
     ['-ml-px pl-px',activeColorful.color | prefix:'text', activeColorful.bgColor | prefix:'bg'] :
     [normalColorful.color | prefix:'text', normalColorful.bgColor | prefix:'bg'])">
    <span *ngIf="button.index === 0" class="border-l h-3/4" [ngClass]="[bgColor | prefix:'border']"></span>
    <span class="my-2 mx-4">{{button.label}}</span>
    <span class="border-r h-3/4" [ngClass]="[bgColor | prefix:'border']"></span>
  </button>

  <!-- PAGINATION NEXT BUTTONS -->
  <button class="p-2 button"
          [disabled]="!hasNext()"
          [ngClass]="!hasNext() ? [disabledColorful?.color | prefix:'text', disabledColorful?.bgColor | prefix:'bg'] :
                                      [normalColorful?.color | prefix:'text', normalColorful?.bgColor | prefix:'bg']"
          (click)="nextClicked()">
    <i class="fas fa-chevron-right fa-fw"></i>
  </button>


</div>
