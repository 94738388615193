import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextVariantBComponent } from './text-variant-b.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    TextVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    TextVariantBComponent
  ]
})
export class TextVariantBModule { }
