import {IMedium} from "./i-medium";
import {IBaseOwner} from "./i-base-owner";
import {ICategorizedEntity, IPublishableOwnedEntity} from "./i-owned-entity";
import {ICategory} from "./i-category";
import {IMultilanguage} from "./base-document-types";
import {IBaseAuth} from "./i-base-auth";
import {IMeta} from "./i-meta";


export interface IArticle<O extends IBaseOwner | string = any,
  M extends IMedium<O> | string = any,
  A extends IBaseAuth<O> | string = any,
  E = any>
  extends IPublishableOwnedEntity<O>, ICategorizedEntity<ICategory<O> | string>, IMeta<E>, IMultilanguage {
  title: string;
  summary?: string;
  content: string; //WYSIWYG
  featuredImage: M;
  attachments?: M[];
  author?: A;
  slug: string;
}
