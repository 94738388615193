import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'split', pure: false })
export class SplitPipe implements PipeTransform {
  transform(value: string, position?: number): string {
    if (!value) {
      return '';
    }
    const result = value.split('/');
    position = position ? position : 0;
    return result[position];
  }
}
