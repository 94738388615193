import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonThemeBComponent} from './button-theme-b.component';
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    ButtonThemeBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ButtonThemeBComponent
  ]
})
export class ButtonThemeBModule {
}
