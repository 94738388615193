import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarVariantCComponent} from "./navbar-variant-c.component";
import {PrefixModule} from "../../../prefix/prefix.module";
import {NavDropdownItemModule} from "../../nav-dropdown-item/nav-dropdown-item.module";
import {OverlayModule} from "@angular/cdk/overlay";
import {NavMobileModule} from "../../nav-mobile/nav-mobile.module";


@NgModule({
  declarations: [
    NavbarVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule,
    NavDropdownItemModule,
    OverlayModule,
    NavMobileModule
  ],
  exports: [
    NavbarVariantCComponent
  ]
})
export class NavbarVariantCModule {
}
