import {InjectionToken, Injector} from "@angular/core";
import {IEntityService} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
// import {EntityFormService} from "@echo-nx/shared/ng/feature/entity-management";
// import {IEntityDefinition} from "@echo-nx/shared/ng/feature/entity-management";

export interface IInjectableServiceData<Service = any, Entity = any> {
  token: InjectionToken<Service>;
  fetchFunction?: (service: IEntityService<Entity>, injector?: Injector, ...args: any[]) => Observable<any>;
}


// todo move to feature-forms
export interface IInjectableEntityFormServiceData<Service = any, Entity = any> {
  token: InjectionToken<Service/*EntityFormService*/>;
  // used to initialize the **entire** form group, not necessarily what the entity-form-component will build as fields. to persist
  getEntityDefinition?: ((entity?: any, lang?: string, service?: IEntityService, injector?: Injector) => any /*IEntityDefinition[]*/) | any/*IEntityDefinition[]*/;
}
