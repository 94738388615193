import gql from 'graphql-tag';

export const readSelectedLog = gql`
  query ReadSelectedLog($ids: [String!]!) {
    response: readSelectedLog(
      ids: $ids
    ) {
      _id
      timestamp
      level
      message
      meta
    }
  }
`;
