import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, ViewChild} from '@angular/core';
import {MarlenkaBasePageComponent} from '../..';
import {CakeService} from '../../../services/cake.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {animate, query, sequence, stagger, state, style, transition, trigger} from '@angular/animations';
import {NavigationService} from '../../../services/navigation.service';
import {
  IMarlenkaOwner,
  IMPageSettings,
  IMProduct,
  IMSubnavigationItem,
  ProductDetailPageTexts
} from "@echo-nx/marlenka/common";
import {ScrollableService} from "@echo-nx/shared/ng/feature/common";
import {ICategory, IMedium} from "@echo-nx/shared/common";
import {LightboxGroupDirective} from "@echo-nx/shared/ng/feature/lightbox-gallery";


@Component({
  selector: 'marlenka-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  animations: [
    trigger('aside', [
      state('void', style({background: 'white', transform: 'translateX(+100%)'})),
      state('*', style({background: 'white', opacity: 1, transform: 'none'})),
      transition(':enter', [
        sequence([
          animate('1250ms ease-in-out')]
        )
      ])
    ]),
    trigger('listAnimation', [
      state('visible', style({
        opacity: 1, transform: 'none'
      })),
      transition('* => *', [
        query(':enter', [
          style({
            opacity: 0, transform: 'translateY(+100vw)'
          }),
          stagger(125, [
            animate('0.8s ease-in-out', style({
              opacity: 1, transform: 'none'
            }))
          ])
        ])
      ])
    ])
  ],
  //providers: [LightboxGroupService]

})

export class ProductDetailComponent extends MarlenkaBasePageComponent<ProductDetailPageTexts> implements AfterViewInit, OnDestroy {

  show = false;
  animateList = false;

  public pageSettings$: Observable<IMPageSettings<ProductDetailPageTexts>>;
  public product$: Observable<IMProduct<ICategory<IMarlenkaOwner>>>;
  public categoryProducts$: Observable<IMProduct<ICategory<IMarlenkaOwner>>[]>;

  @ViewChild('productGallery', {read: LightboxGroupDirective})
  private productGallery: LightboxGroupDirective;

  constructor(injector: Injector, private cakeService: CakeService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              // private galleryService: LightboxGroupService,
              protected navigationService: NavigationService,
              private scrollableService: ScrollableService) {
    super(injector);
    const {params} = this.activeRoute.snapshot;
    const {categoryId} = params;
    this.categoryProducts$ = this.mrdService.currentLanguage$.pipe(
      switchMap(lang => this.cakeService.fetchAllForLanguageGroupCategory(categoryId, lang)),
      takeUntil(this.isDestroyed$),
      tap(products => {
        if (products.length > 0 && !params.productId) {
          const {languageGroupId} = products[0];
          this.router.navigate([languageGroupId], {relativeTo: activeRoute, skipLocationChange: true});
        }
      })
    );

    this.product$ = combineLatest(
      [this.mrdService.currentLanguage$, this.activeRoute.params]
    ).pipe(
      switchMap(([language, routeParams]) => {
        scrollableService.scrollToTop();
        this.productGallery?.clearGroup();
        return this.cakeService.fetchAll({filter: JSON.stringify({languageGroupId: routeParams.productId, language})})
      }),
      filter((cakes) => !!cakes && cakes.items.length == 1),
      map(articles => articles?.items?.[0]),
    );
  }

  ngAfterViewInit(): void {
    this.mrdService.currentLanguage$.pipe(switchMap(lang => this.cakeService.fetchCategories(lang))).pipe(
      takeUntil(this.isDestroyed$)).subscribe((categories) => {
      this.navigationService.setSubnavigationItems(
        categories.map((category => ({
          link: this.router.createUrlTree([`/${this.mrdService.currentLanguage.toLowerCase()}/products/${category.languageGroupId}`]).toString(),
          title: category.name
        }) as IMSubnavigationItem))
      );
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationService.setSubnavigationItems(undefined);
  }
}
