import {Injectable, InjectionToken} from "@angular/core";
import {
  EkioskEntityTypes,
  FetchAllArgs,
  ICategory,
  IEntityService,
  IKioskTimeline, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {readAllTimelines, readSelectedTimelines, readSelectedTimelinesWithScreens} from "../queries";
import {map} from "rxjs/operators";
import {addKioskTimeline, deleteKioskTimeline} from "../mutations";


@Injectable()
export class BaseTimelineService implements IEntityService<IKioskTimeline> {

  constructor(protected apollo: Apollo) {
  }

  getType(): string {
    return EkioskEntityTypes.Kiosk;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    return this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
      mutation: deleteKioskTimeline,
      variables: {input: ids}
    });
  }

  executeMutation(mutation: any, variables: any) {
    return this.apollo.mutate({
      mutation,
      variables
    });
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IKioskTimeline>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IKioskTimeline>>>({
      query: readAllTimelines,
      variables: args
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<IKioskTimeline[]> {
    return this.apollo.query<IPKApolloResult<IKioskTimeline[]>>({
      query: readSelectedTimelines,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSelectedWithScreens(ids: string[]): Observable<IKioskTimeline[]> {
    return this.apollo.query<IPKApolloResult<IKioskTimeline[]>>({
      query: readSelectedTimelinesWithScreens,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IKioskTimeline> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    )
  }

  fetchSingleWithScreens(id: string): Observable<IKioskTimeline> {
    return this.fetchSelectedWithScreens([id]).pipe(
      map(res => res[0])
    )
  }


  save(entities: IKioskTimeline[]): Observable<MutationResult<IPKApolloResult<IKioskTimeline[]>>> {
    return this.apollo.mutate<IPKApolloResult<IKioskTimeline[]>>({
      mutation: addKioskTimeline,
      variables: {input: entities}
    });
  }

}

export const BASE_KIOSK_TIMELINE_SERVICE_TOKEN = new InjectionToken<IEntityService<IKioskTimeline>>('BASE_KIOSK_TIMELINE_SERVICE_TOKEN');
