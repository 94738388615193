import {FlexLayoutSettings, GridItemSettings, GridLayoutSettings, Layout, Routable} from './index';
import {IBackgroundObject} from "../structural";

export type Zone = {
  // how to display layouts inside this cell
  display: 'flex' | 'grid' | string;
  padding?: string;

  // style data
  background?: IBackgroundObject;

  // layouts
  layouts: Layout[];

  panelClass?: string;
  withContainer?: boolean;
  containerClass?: string;
} & Routable & FlexLayoutSettings & GridLayoutSettings & GridItemSettings;

