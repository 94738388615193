import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  addTicketType,
  deleteTicketType,
  IMarlenkaOwner, IMDialogCommodity,
  IMTicketType,
  MarlenkaEntityTypes, readAllTicketTypes,
  readSelectedTicketTypes, readTicketTypesLanguageGroups
} from "@echo-nx/marlenka/common";
import {readAllCategories, readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs, IBaseOwner,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";


@Injectable()
export class BaseTicketService implements IEntityService<IMTicketType<IMDialogCommodity | string>> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args: FetchAllArgs): Observable<IPaginatedResponse<IMTicketType>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMTicketType>>>({
      query: readAllTicketTypes,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMTicketType<IMDialogCommodity>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMTicketType<IMDialogCommodity>>>>({
      query: readTicketTypesLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMTicketType[]> {
    return this.apollo.query<IPKApolloResult<IMTicketType[]>>({
      query: readSelectedTicketTypes,
      variables: {
        ids: ids
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSingle(id: string): Observable<IMTicketType> {
    return this.apollo.query<IPKApolloResult<IMTicketType[]>>({
      query: readSelectedTicketTypes,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(language: string): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language,
        })
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }


  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteTicketType,
      variables: {
        input: ids
      }
    })
  }

  save(ticketTypes: IMTicketType<string>[]): Observable<MutationResult<IPKApolloResult<IMTicketType[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMTicketType[]>>({
      mutation: addTicketType,
      variables: {input: ticketTypes}
    });
  }

  getType(): string {
    return MarlenkaEntityTypes.TicketTypes;
  }
}

export const MTICKET_SERVICE_TOKEN = new InjectionToken<IEntityService<IMTicketType>>('MTICKET_SERVICE_TOKEN');
