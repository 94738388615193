<div *ngFor="let ticketType of ticketTypes" class="ticket-type">
  <mat-icon
    *ngIf="ticketType.description"
    [matTooltip]="ticketType.description"
    class="help-icon"
  >
    help_outline
  </mat-icon>

  <!-- ICON pig-code cba -->
  <ng-container
    *ngIf="
        ticketType.icon === 'family_2_2' ||
        ticketType.icon === 'family_2_1' ||
        ticketType.icon === 'family_3_1' ||
        ticketType.icon === 'family_1_3' ||
        ticketType.icon === 'marlenka_family_icon' ||
        ticketType.icon === 'marlenka_kid_icon' ||
        ticketType.icon === 'marlenka_handicap_icon';
      else regularIcon
    "
  >
    <mat-icon class="main-icon" [svgIcon]="ticketType.icon"></mat-icon>
  </ng-container>
  <ng-template #regularIcon>
    <mat-icon class="main-icon">{{ ticketType.icon }}</mat-icon>
  </ng-template>

  <!-- TITLE -->
  <div class="title">
    {{ ticketType.name }}
  </div>

  <!-- COUNTER -->
  <div class="borders">
    <echo-nx-counter
      [value]="ticketType | itemCount: reservationService.selectedTickets"
      (add)="addTicket(ticketType)"
      (remove)="removeTicket(ticketType)"
      panelClass="marlenka-counter"
    ></echo-nx-counter>
  </div>

  <!-- PRICE -->
  <div class="price">
    <span>{{ ticketType.commodityType.price }} Kč</span>
  </div>
</div>
