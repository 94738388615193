import {Component, Input} from '@angular/core';

@Component({
  selector: 'marlenka-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent {

  @Input() name!: string;
  @Input() description!: string;

}
