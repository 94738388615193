import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {print} from 'graphql';
import {
  CommonEntityTypes, FetchAllArgs,
  ICategory, IEntityService, IFileMetada, IMedium,
  IPaginatedResponse, IPKApolloResult,
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {readAllCategoriesOfEntity, readAllMedia, readSelectedMedia} from "../queries";
import {editMedium, uploadFile, deleteMedia} from "../mutations";
import {HttpClient} from "@angular/common/http";
import {URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";


@Injectable()
export class BaseMediumService implements IEntityService<IMedium<any>> {

  constructor(private apollo: Apollo, private http: HttpClient, @Inject(URL_SETTINGS_TOKEN) protected urlSettings: UrlSettings) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMedium<any>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMedium<any>>>>({
      query: readAllMedia,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMedium<any>[]> {
    return this.apollo.query<IPKApolloResult<IMedium<any>[]>>({
      query: readSelectedMedia,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<IMedium<any>> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteMedia,
      variables: { input: ids, /*forceDelete: true*/ }
    });
  }

  save(medium: IMedium<any>[]): Observable<MutationResult<IPKApolloResult<IMedium<any>[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMedium<any>[]>>({
      mutation: editMedium,
      variables: {input: medium}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<any>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  /**
   * @deprecated
   * @param file
   * @param metadata
   */
  upload(file: File, metadata: IFileMetada) {
    // construct form data
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: print(uploadFile),
        variables: {
          file: null,
          metadata: metadata
        },
      }),
    );
    formData.append(
      'map',
      JSON.stringify({
        file: ['variables.file']
      }),
    );
    formData.append('file', file, file.name);

    return this.http.post(this.urlSettings.fullGraphqlPath, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getType(): string {
    return CommonEntityTypes.Media;
  }
}

export const BASE_MEDIUM_SERVICE_TOKEN = new InjectionToken<IEntityService<IMedium<any>>>('BASE_MEDIUM_SERVICE_TOKEN');
