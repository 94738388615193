import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'echo-nx-feature-cms-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit{
  @Input()
  public diameter = 40;

  ngOnInit(): void {
    return;
  }

}
