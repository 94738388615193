import {Pipe, PipeTransform} from '@angular/core';
import {getFirstDayOfMonth} from "@echo-nx/shared/common";



@Pipe({
  name: 'firstDayOfMonth'
})
export class FirstDayOfMonthPipe implements PipeTransform {

  transform(value: Date): number {
    return getFirstDayOfMonth(value)
  }

}
