import {Pipe, PipeTransform} from '@angular/core';
import {INavigationItem} from "@echo-nx/shared/common";

@Pipe({
  name: 'activeChildrenCount'
})
export class ActiveChildrenCountPipe implements PipeTransform {

  transform(value: INavigationItem): number {
    return value?.children?.reduce((acc, curr) => {
      if (curr.isActive) acc += 1;
      return acc;
    }, 0);
  }

}
