<ng-container *ngIf="pageSettings$ | async as pageSettings">
  <div class="reservation-wrap container small">
    <h1 class="small">Voucher</h1>
    <div class="warning" *ngIf="pageSettings.texts?.warning?.title || pageSettings.texts?.warning?.subtitle">
      <div class="title" [innerHTML]="pageSettings.texts?.warning?.title"></div>
      <div class="subtitle" [innerHTML]="pageSettings.texts?.warning?.subtitle"></div>
    </div>
    <marlenka-voucher-stepper
      (backClicked)="onBackClicked($event)"
    ></marlenka-voucher-stepper>
  </div>
</ng-container>
