<div class="item-container" cdkDropListGroup>
  <div
    cdkDropList
    (cdkDropListDropped)="dropListDropped($event)"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  ></div>
  <div
    cdkDropList
    *ngFor="let item of innerItems;let i=index"
    [cdkDropListData]="{item:item,index:i}"
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="dropListDropped($event)"
    (cdkDropListEntered)="cdkDropListEntered($event)"
  >
    <div class="preview-item" cdkDrag (cdkDragStarted)="dragStarted(i)">
      <ng-container [ngSwitch]="previewSettings.isMedium">
        <ng-container *ngSwitchCase="true">
          <img
            [src]="item.thumbnailUrl"/>
          <button class="media-delete" mat-icon-button (click)="removeEntity(item)">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div
            class="media-text">{{item}}</div>
          <button class="media-delete" mat-icon-button (click)="removeEntity(item)">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
