import gql from 'graphql-tag';

export const readSelectedCakeExclusive = gql`
  query ReadSelectedCakeExclusive($ids: [String!]!) {
    response: readSelectedCakesExclusive(
      ids: $ids
    ) {
      _id
      content
      title
      language
      languageGroupId
      categories {
        _id
        name
      }
      featuredImage{
        _id
        url
        mime
        name
        type
        description
      }
      attachments{
        _id
        type
        description
        mime
        name
        url
      }
      createdAt
      publishedAt
    }
  }
`;
