import {AbstractControl, Validators} from "@angular/forms";

export function ValidateDIC(control: AbstractControl) {
  if (control?.parent?.get('paysTaxes')?.value && !control.value) {
    return Validators.required(control);
  }
  return null;
}

export function ValidateEmail(control: AbstractControl) {
  if (control.value) {
    const regex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/
    if (control.value.match(regex) === null) {
      return {error: 'Email není ve správném formátu!'};
    }
  }
  return null;
}
