export interface InstagramMediaData {
  id: string;
  permalink: string;
  media_url: string;
  media_type: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM' | string;
  caption: string;
}

/*
  id - The Media's ID.
  permalink - The Media's permanent URL. Will be omitted if the Media contains copyrighted material, or has been flagged for a copyright violation.
  media_url - The Media's URL.
  media_type - The Media's type. Can be IMAGE, VIDEO, or CAROUSEL_ALBUM.
  caption - The Media's caption text. Not returnable for Media in albums.
  thumbnail_url - The Media's thumbnail image URL. Only available on VIDEO Media.
  timestamp - The Media's publish date in ISO 8601 format.
  username -The Media owner's username.
*/

