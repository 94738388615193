import gql from 'graphql-tag';

export const readSingleWeatherData = gql`
  query ReadAllWeatherData($filter: String){
    response: readAllWeatherData(
      filter: $filter,
      sortDirection: "DESC",
      page: 0,
      take: 1
    ){
      total
      page
      take
      hasMore
      items{
        _id
        stationName
        stationLocationName
        owner{
          _id
          name
        }

        temperature
        windSpeed
        pressure
        precipitation
      }
    }
  }
`
