<div class="people">
  <label id="people-choice">{{numberOfPplInReservation > 0 ? 'Počet osob v rezervaci:' : 'Zvolte počet osob:'}}</label>
  <echo-nx-counter
    [isStatic]="numberOfPplInReservation > 0"
    [value]="numberOfPplInReservation > 0 ? numberOfPplInReservation : eventsService.filter.people"
    panelClass="marlenka-counter"
    (add)="addPeople()"
    (remove)="removePeople()"
  ></echo-nx-counter>
</div>

<!--<div class="language">
  <label id="language-choice">Jazyk prohlídky: </label>
  <mat-radio-group
    aria-labelledby="language-choice"
    [value]="eventsService.filter.language"
    (change)="languageChosen($event)"
  >
    <mat-radio-button value="CZ">Český</mat-radio-button>
    <mat-radio-button value="EN">Anglický</mat-radio-button>
  </mat-radio-group>
</div>-->
