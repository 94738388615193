import gql from 'graphql-tag';

export const readAllergensLanguageGroups = gql`
  query ReadAllergensLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllergensLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          language
          languageGroupId
          text
          number
          icon
        }
      }
  }
}
`;
