export interface IServerResponse{
  isSuccessful: boolean;
  message?: string;
}


export interface IMutationResponse {
  error: boolean;
  userMessage: string;
  description?: string;
}
