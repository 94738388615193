import {IEnvironmentAPISettings, IEnvironmentKioskSettings} from "@echo-nx/shared/common";


export class UrlSettings {

  constructor(apiSettings: IEnvironmentAPISettings, kioskSettings?: IEnvironmentKioskSettings, appVersion?: string, isWeb?: boolean, cmsBaseUrl?: string) {
    this.apiSettings = apiSettings;
    this.kioskSettings = kioskSettings;
    this.appVersion = appVersion;
    this.isWeb = isWeb;
    this.cmsBaseUrl = cmsBaseUrl;
  }

  appVersion?: string;
  apiSettings: IEnvironmentAPISettings;
  cmsBaseUrl?: string;
  kioskSettings?: IEnvironmentKioskSettings;
  auth?: {
    loginRedirect?: string;
  };
  appendTokenIgnoreOperations?: string[]
  appendTokenIgnoreUrls?: string[];
  ssrTokenPath?: string;
  isWeb?: boolean;

  get fullApiPath() {
    return `${this.apiSettings.protocol}://${this.apiSettings.apiUrl}`;
  }

  get fullGraphqlPath() {
    return `${this.fullApiPath}${this.apiSettings.graphqlSuffix}`;
  }

  get wssPath() {
    return `${this.kioskSettings?.wssProtocol}://${this.apiSettings.apiUrl}${this.kioskSettings?.wssSuffix}`
  }

}

type UrlCreationSettings = {
  apiSettings: IEnvironmentAPISettings;
  kioskSettings?: IEnvironmentKioskSettings;
  appVersion?: string;
  isWeb?: boolean;
  cmsBaseUrl?: string;
}

export function createUrlSettings(settings: UrlCreationSettings) {
  // console.log('creating url settings', settings);
  const {apiSettings, kioskSettings, appVersion, isWeb, cmsBaseUrl} = settings;
  return () => new UrlSettings(apiSettings, kioskSettings, appVersion, isWeb, cmsBaseUrl)
}
