import gql from 'graphql-tag';

export const readAllAuths = gql`
  query ReadAllAuths($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllAuths(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
       owner {
         _id
       }
        name
        email
        role {
          _id
          name
        }
      }
    }
  }
`;
