import gql from 'graphql-tag';

export const readTicketTypesLanguageGroups = gql`
  query ReadTicketTypesLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean) {
    response: readTicketTypesLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          language
          languageGroupId
          description
          icon
          name
          publishedAt
          noAdults
          noChildren
          commodityType {
            _id
            price
            articleName
          }
          categories {
            _id
            name
            type
            description
          }
        }
      }
    }
  }
`;
