import {Component, Injector} from '@angular/core';
import {ArticleService} from "../../services/article.service";
import {MarlenkaTimelineService} from "@echo-nx/marlenka/ng/data-access";
import {MarlenkaBasePageComponent} from "../marlenka-base-page/marlenka-base-page.component";
import {
  AboutpageTexts,
  ButtonTexts,
  CommonpageTexts, IMarlenkaOwner,
  IMPageSettings,
  IMTimeline,
  MarlenkaPageIdentifier,
  MarlenkaRouteData
} from "@echo-nx/marlenka/common";
import {IArticle, ICategory, IMedium} from "@echo-nx/shared/common";
import {filter, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {BaseGalleryService} from '@echo-nx/shared/ng/data-access';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";

@Component({
  selector: 'marlenka-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent extends MarlenkaBasePageComponent<AboutpageTexts> {
  public buttonsText$: Observable<ButtonTexts>;
  public awards$: Observable<IArticle<IMarlenkaOwner, IMedium<any>>[]>;
  public certificates$: Observable<IArticle<IMarlenkaOwner, IMedium<any>>[]>;
  public videos$: Observable<IArticle<IMarlenkaOwner, IMedium<any>>[]>;

  public timeline$: Observable<IMTimeline<IMedium<any>>[]>;

  public pageSettings$!: Observable<IMPageSettings<AboutpageTexts>>;

  constructor(injector: Injector,
              public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
              private blogService: ArticleService,
              private galleryService: BaseGalleryService,
              private baseTimelineService: MarlenkaTimelineService) {
    super(injector);

    this.awards$ = this.fetchArticlesInCategory(({awardsArticleCategory}) => (awardsArticleCategory as ICategory)?._id)

    this.certificates$ = this.fetchArticlesInCategory(({certificatesArticleCategory}) => (certificatesArticleCategory as ICategory)?._id)

    this.videos$ = this.fetchArticlesInCategory(({videosArticleCategory}) => (videosArticleCategory as ICategory)?._id)


    this.timeline$ = this.mrdService.currentLanguage$.pipe(
      switchMap((language) => this.baseTimelineService.fetchAll({
        filter: JSON.stringify({language}),
        sortColumn: 'year',
        sortDirection: 'ASC'
      })),
      takeUntil(this.isDestroyed$),
      map(res => res.items)
    );

    this.buttonsText$ = this.mrdService.currentLanguage$.pipe(
      switchMap((language) => this.pageSettingsService.getPageSettingsByIdentifier<CommonpageTexts>(MarlenkaPageIdentifier.COMMON, language)),
      map((pageSettings) => pageSettings.texts.buttons)
    );
  }

  private fetchArticlesInCategory<T>(mappingFn: (pageSettings: IMPageSettings) => string): Observable<T[]> {
    return this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      map(lang => lang.toLowerCase()),
      switchMap(lang => this.pageSettings$.pipe(
        map(mappingFn),
        switchMap(categoryId => categoryId ? this.blogService.fetchArticlesInCategory(categoryId, 3, lang) : of([]))
      ))
    );
  }
}
