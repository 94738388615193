import {BaseDocumentType} from "./base-document-types";


export interface IAuthLog extends BaseDocumentType {
    lastIp: string,
    lastSignature: string;
    refreshTokenId: string;
    tokenId: string,
    userId: string
}
