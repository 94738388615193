import {Component, Injector} from '@angular/core';
import {MarlenkaBasePageComponent} from '../marlenka-base-page/marlenka-base-page.component';
import {filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {GridMenuItem} from '../../components/menu-grid-banner/menu-grid-banner.component';
import {Observable, of} from 'rxjs';
import {ArticleService} from '../../services/article.service';
import {OwnerService} from '../../services/owner.service';
import {
  ButtonTexts,
  CommonpageTexts,
  HomepageTexts,
  IMarlenkaOwner,
  MarlenkaPageIdentifier
} from "@echo-nx/marlenka/common";
import {IArticle, ICategory, IMedium, InstagramMediaData} from "@echo-nx/shared/common";
import { url } from 'inspector';
import { ProductDetailComponent } from '../products-page/product-detail/product-detail.component';

@Component({
  selector: 'marlenka-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends MarlenkaBasePageComponent<HomepageTexts> {
  public buttonsText$: Observable<ButtonTexts>;
  public menuBanner: HomepageMenuBanner;
  public recentNewsArticles$: Observable<IArticle<IMarlenkaOwner,IMedium>[]>;
  public reviewsArticles$: Observable<IArticle<IMarlenkaOwner,IMedium>[]>;
  public instagramPhotos$: Observable<InstagramMediaData[]>;

  constructor(injector: Injector, private blogService: ArticleService, private ownerService: OwnerService) {
    super(injector);

    this.recentNewsArticles$ = this.mrdService.currentLanguage$.pipe(
      map(lang => lang.toLowerCase()),
      switchMap(lang => this.pageSettings$.pipe(
        map(({newsArticleCategory}) => (newsArticleCategory as ICategory)?._id),
        filter(categoryId => !!categoryId),
        switchMap(categoryId => categoryId ? this.blogService.fetchArticlesInCategory(categoryId, 3, lang) : of([]))
      ))
    )

    this.reviewsArticles$ = this.mrdService.currentLanguage$.pipe(
      map(lang => lang.toLowerCase()),
      switchMap(lang => this.pageSettings$.pipe(
        map(({reviewsArticleCategory}) => (reviewsArticleCategory as ICategory)?._id),
        switchMap(categoryId => categoryId ? this.blogService.fetchArticlesInCategory(categoryId, 3, lang) : of([]))
      ))
    )

    this.pageSettings$ = this.pageSettings$.pipe(
      takeUntil(this.isDestroyed$),
      tap(({texts}) => {
        const {menuBanner} = texts;
        const {products, aboutUs, achievements, cakes, events, jobs} = menuBanner;
        this.menuBanner = {
          mainItem: new GridMenuItem({
            title: products.title,
            foregroundColor: '#F3DCAA',
            backgroundColor: '#000000',
            subtitle: products.subtitle,
            backgroundImg: typeof products.backgroundImg === 'string' ? products.backgroundImg : products.backgroundImg.url,
            routeTo: products.link
          }, this.mrdService),
          mainSideItems: [
            new GridMenuItem({
              title: aboutUs.title,
              subtitle: aboutUs.subtitle,
              foregroundColor: "#F3DCAA",
              backgroundImg: typeof aboutUs.backgroundImg === 'string' ? aboutUs.backgroundImg : aboutUs.backgroundImg.url,
              backgroundColor: '#750000',
              routeTo: aboutUs.link
            }, this.mrdService),
            new GridMenuItem({
              title: events.title,
              subtitle: events.subtitle,
              foregroundColor: '#750000',
              backgroundColor: '#F3DCAA',
              backgroundImg: typeof events.backgroundImg === 'string' ? events.backgroundImg : events.backgroundImg.url,
              routeTo: events.link
            }, this.mrdService)
          ],
          bottomItems: [
            new GridMenuItem({
              title: achievements.title,
              foregroundColor: "#F3DCAA",
              backgroundColor: '#750000',
              backgroundImg: typeof achievements.backgroundImg === 'string' ? achievements.backgroundImg : achievements.backgroundImg.url,
              routeTo: achievements.link
            }, this.mrdService),
            new GridMenuItem({
              title: cakes.title,
              foregroundColor: '#750000',
              backgroundColor: '#F3DCAA',
              backgroundImg: typeof cakes.backgroundImg === 'string' ? cakes.backgroundImg : cakes.backgroundImg.url,
              routeTo: cakes.link
            }, this.mrdService),
            new GridMenuItem({
              title: jobs.title,
              foregroundColor: "#F3DCAA",
              backgroundColor: '#750000',
              backgroundImg: typeof jobs.backgroundImg === 'string' ? jobs.backgroundImg : jobs.backgroundImg.url,
              routeTo: jobs.link
            }, this.mrdService)
          ]
        };
      })
    );

    this.instagramPhotos$ = this.ownerService.fetchInstagramPhotos().pipe(takeUntil(this.isDestroyed$));

    this.buttonsText$ = this.mrdService.currentLanguage$.pipe(switchMap((language) => this.pageSettingsService
      .getPageSettingsByIdentifier<CommonpageTexts>(
        MarlenkaPageIdentifier.COMMON,
        language
      )
      .pipe(map((pageSettings) => pageSettings.texts.buttons))));
  }

}


interface HomepageMenuBanner {
  mainItem: GridMenuItem;
  mainSideItems: GridMenuItem[];
  bottomItems: GridMenuItem[];
}
