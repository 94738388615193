import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {
  addContact,
  deleteContact,
  IMarlenkaOwner,
  MarlenkaEntityTypes, readAllContact,
  readSelectedContact
} from "@echo-nx/marlenka/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IOwnedContact,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";

@Injectable()
export class BaseContactsService implements IEntityService<IOwnedContact<IMarlenkaOwner>> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IOwnedContact<IMarlenkaOwner>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IOwnedContact<IMarlenkaOwner>>>>({
      query: readAllContact,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IOwnedContact<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<IOwnedContact<IMarlenkaOwner>[]>>({
      query: readSelectedContact,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IOwnedContact<IMarlenkaOwner>> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteContact,
      variables: {input: ids}
    });
  }

  save(cake: IOwnedContact<IMarlenkaOwner>[]): Observable<MutationResult<IPKApolloResult<IOwnedContact<IMarlenkaOwner>[]>>> {
    return this.apollo.mutate<IPKApolloResult<IOwnedContact<IMarlenkaOwner>[]>>({
      mutation: addContact,
      variables: {input: cake}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Contact;
  }

}

export const MCONTACTS_SERVICE_TOKEN = new InjectionToken<IEntityService<IOwnedContact<IMarlenkaOwner>>>('MCONTACTS_SERVICE_TOKEN');
