import {FroalaOptions} from "froala-editor";

const defaultButtons = {
  'moreText': {
    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'inlineClass', 'fontSize', 'clearFormatting']
  },
  'moreParagraph': {
    'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'outdent', 'indent', 'paragraphFormat', 'paragraphStyle', 'quote']
  },
  'moreRich': {
    'buttons': ['insertLink', 'insertTable', 'specialCharacters', 'openPicker', 'insertHR', 'specialCharacters']
  },
  'moreMisc': {
    'buttons': ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
    'align': 'right',
    'buttonsVisible': 2
  },
  'common': {
    'buttons': ['language', 'insertVideo']
  }
};

export const simpleButtons = {
  'text': {
    'buttons': ['bold', 'italic', 'underline']
  },
  'custom': {
    'align': 'center',
    'buttons': ['language']
  },
  'common': {
    'align': 'right',
    'buttons': ['fullscreen', 'html']
  }
};

export const defaultFroalaOptions: Partial<FroalaOptions> = {
  key: 'qc1H2pH2A1A1E6A5B5oCe1ZSc2XHe1Cd1f1KIWCWMJHXCLSwD1D1D1E1F1H4A11B1D6F6==',
  charCounterCount: true,
  language: 'cs',
  toolbarButtons: defaultButtons as any,
  toolbarButtonsXS: defaultButtons as any,
  toolbarButtonsSM: defaultButtons as any,
  toolbarButtonsMD: defaultButtons as any,
  videoResize: true,
  videoUpload: false,

  pluginsEnabled: ['specialCharacters', 'draggable', 'help', 'print', 'fontFamily', 'fontSize', 'align', 'lists', 'link', 'table', 'colors', 'image', 'quote', 'paragraphFormat', 'paragraphStyle', 'inlineClass', 'fullscreen', 'charCounter', 'draggable', 'url', 'video', 'codeView'],
  paragraphStyles: {
    'text-golden': 'Zlatý text',
    'text-red': 'Červený text',
    'uppercase': 'Uppercase'
  },
  fontSize: ['10','14','18','24','40','70'],
  fontSizeSelection: true,
  inlineClasses: {
    'text-golden': 'Zlatý text',
    'text-red': 'Červený text',
    'uppercase': 'Uppercase'
  },
  tableStyles:{
    'fr-dashed-borders': 'Přerušovaný rámeček',
    'fr-alternate-rows': 'Střídavé řádky',
    'table-wo-borders': 'Bez rámečku'
  },
  paragraphFormatSelection: true
};
