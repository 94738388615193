// helper functions:
import {IDiscountCoupon, IMDialogCommodity, IMItemType, IMReservedItem} from "../interfaces";

export interface SummaryItem {
  uniqueId: string;
  name: string;
  icon: string;
  unitCount: number;
  unitPrice: number;
  totalPrice: number;
  type: SummaryItemType;
  itemType?: IMItemType // DiscountCoupon does not have an ItemType
}

type SummaryItemType = 'ticket' | 'product' | 'discount-coupon';

export const getSummaryForItemType = (items: IMReservedItem<IMItemType<IMDialogCommodity>>[], icon: string, type: SummaryItemType): SummaryItem[] =>
  items.reduce((acc, item) => {
    // attempt to find the item by ID in the summary array
    const itemIdx = acc.findIndex(e => e.uniqueId === item.itemType.languageGroupId);

    // destructure price
    const {price} = item.itemType.commodityType;
    if (itemIdx === -1) {
      const {languageGroupId, name} = item.itemType;

      acc.push({
        uniqueId: languageGroupId,
        name,
        unitCount: 1,
        unitPrice: price,
        totalPrice: price,
        icon: icon,
        type: type,
        itemType: item.itemType
      })

    } else {
      acc[itemIdx].totalPrice += price;
      acc[itemIdx].unitCount += 1;
    }
    return acc;
  }, [] as SummaryItem[]).sort((a, b) => a.uniqueId > b.uniqueId ? 1 : -1);


export const getSummaryForCoupons = (coupons: IDiscountCoupon[], icon = "local_offer"): SummaryItem[] =>
  coupons.map(coupon => ({
    uniqueId: coupon.code,
    type: 'discount-coupon',
    totalPrice: coupon.price,
    unitPrice: coupon.price,
    unitCount: 1,
    name: coupon.name,
    icon: icon
  }));
