import {
  IBaseItemListData,
  IBaseItemListDesign,
  ICategory,
  IEpxColorful,
  IEpxIcon,
  IMedium
} from "@echo-nx/shared/common";
import {Directive, HostBinding, Input} from "@angular/core";

@Directive()
export class BasePhotoCardComponent implements IBaseItemListDesign, IBaseItemListData {

  @Input()
  accentColor: string;

  @Input()
  categories: ICategory<any>[];

  @Input()
  date: string;

  @Input()
  dateText: IEpxIcon;

  @Input()
  image: IMedium<any>;

  @Input()
  perex: string;

  @Input()
  primaryColor: string;

  @Input()
  titleText: IEpxColorful;

  @Input()
  title: string;

  @Input()
  fluid;

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
      'flex': this.fluid === 'horizontal' || this.fluid === 'both',
      'h-full': this.fluid === 'vertical' || this.fluid === 'both',
    };
  }

  @Input()
  set design({titleText, dateText, accentColor, primaryColor, fluid }: IBaseItemListDesign) {
    this.titleText = titleText;
    this.dateText = dateText;
    this.accentColor = accentColor;
    this.primaryColor = primaryColor;
    this.fluid = fluid;
  }

  @Input()
  set data({title, date, image,  categories, perex, footer}: IBaseItemListData) {
    this.title = title;
    this.date = date;
    this.image = image;
    this.categories = categories;
    this.perex = perex;
  }


}
