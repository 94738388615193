import {Inject, Injectable} from '@angular/core';
import {IAuthResponse} from "@echo-nx/shared/common";
import {BaseAuthService, URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SsrAuthService extends BaseAuthService<any> {

  constructor(@Inject(URL_SETTINGS_TOKEN) urlSettings: UrlSettings, httpClient: HttpClient) {
    super(httpClient, urlSettings);
  }

  public async login(): Promise<IAuthResponse> {
    const {ssrTokenPath} = this.urlSettings;

    // fetch token
    const tokenResponse = await fetch(ssrTokenPath ?? '/api/ssrtoken').catch();
    const response: IAuthResponse = await tokenResponse.json();

    await this.processAuthResponse(response);

    // return response
    return response;
  }
}
