import { NavigationEnd, NavigationStart } from '@angular/router';

export enum RouteScrollBehaviour {
  KEEP_POSITION = 'KEEP_POSITION',
  GO_TO_TOP = 'GO_TO_TOP',
}

export interface ScrollableOptionsRouteData {
  scrollBehavior?: RouteScrollBehaviour;
}

/**
 * Defines a strategy to handle route scrolling.
 */
export interface RouteScrollStrategy {
  /**
   * Partial route path
   */
  partialRoute: string;
  /**
   * Associated behavior
   */
  behaviour: RouteScrollBehaviour;
  /**
   * Whether it should be applied before navigation (default is after)
   */
  onceBeforeNavigation?: boolean;
}

export interface IScrollPositionRestore {
  /**
   * Which event to match
   */
  event: NavigationStart | NavigationEnd;
  /**
   * Used to keep track of the known positions.
   * The key is the id of the entry (according to the route ids)
   * The value is the scroll position. Any is used because there are different representations
   */
  positions: Record<string, any>;
  /**
   * Trigger to react to
   * Imperative: e.g., user clicked on a link
   * Popstate: e.g., browser back button
   * Hashchange: e.g., change in the URL fragment
   */
  trigger: 'imperative' | 'popstate' | 'hashchange' | undefined;
  /**
   * Id to restore
   */
  idToRestore: number;
  /**
   * The route's data (if any defined)
   */
  routeData?: ScrollableOptionsRouteData;
}
