<div class="header"
     [class.dark]="isDark"
     (click)="isOpen = !isOpen">
    <mat-icon>
        {{isOpen ? 'expand_more' : 'chevron_right'}}
    </mat-icon>
    <ng-content select="[header]"></ng-content>
</div>
<ng-container *ngIf="isOpen">
    <ng-content select="[content]"></ng-content>
</ng-container>
