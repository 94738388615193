import gql from 'graphql-tag';

export const readSelectedContact = gql`
  query ReadSelectedContact($ids: [String!]!) {
    response: readSelectedContact(
      ids: $ids
    ) {
      _id
      name
      priority
      websites
      firstName
      lastName
      telephones
      emails
      ico
      companyName
      dic
      dataBox
      address {
        city
        province
        street
        state
        zipcode
      }
      bankInfo{
        bankName
        bankCode
        bankNumber
        iban
        swift
      }
      tradeRegisterText
    }
  }
`;
