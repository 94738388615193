<mat-list class="flex flex-auto flex-wrap">
  <!-- stav -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>donut_small</mat-icon>
    <div mat-line class="font-bold">Stav</div>
    <div mat-line class="text-primary-400 text-lg">{{reservationState}}</div>
  </mat-list-item>

  <!-- code -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>vpn_key</mat-icon>
    <div mat-line class="font-bold">Kód</div>
    <div mat-line class="text-primary-400 text-lg">{{reservation.uniqueCode}}</div>
  </mat-list-item>

  <!-- DEN -->
  <ng-container *ngIf="reservation.event">
    <mat-list-item class="item">
      <mat-icon mat-list-icon>event</mat-icon>
      <div mat-line class="font-bold">Dne</div>
      <div mat-line class="text-primary-400 text-lg">{{reservation.event.start | date:'dd.MM.yyyy'}}</div>
    </mat-list-item>

    <!-- ZACATEK -->
    <mat-list-item class="item">
      <mat-icon mat-list-icon>hourglass_top</mat-icon>
      <div mat-line class="font-bold">Začátek</div>
      <div mat-line class="text-primary-400 text-lg">{{reservation.event.start | date:'HH:mm'}}</div>
    </mat-list-item>
  </ng-container>

  <!-- FAKTURA -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>receipt</mat-icon>
    <div mat-line class="font-bold">Faktura</div>
    <div mat-line class="text-primary-400 text-lg">{{reservation.invoiceCode || '???'}}</div>
  </mat-list-item>

  <!-- VYTVORENO -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>timelapse</mat-icon>
    <div mat-line class="font-bold">Datum vytvoření</div>
    <div mat-line class="text-primary-400 text-lg">{{reservation.createdAt | date:'HH:mm dd.MM.yyyy'}}</div>
  </mat-list-item>

  <!-- gluten -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>spa</mat-icon>
    <div mat-line class="font-bold">Počet bezlepkářů</div>
    <div mat-line class="text-primary-400 text-lg">{{reservation.glutenFreeCount}}</div>
  </mat-list-item>

  <!-- children -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>child_care</mat-icon>
    <div mat-line class="font-bold">Počet dětí</div>
    <div mat-line class="text-primary-400 text-lg">{{noChildrenInReservation}}</div>
  </mat-list-item>

  <!-- total person -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>people</mat-icon>
    <div mat-line class="font-bold">Počet osob</div>
    <div mat-line class="text-primary-400 text-lg">{{noAdultsInReservation + noChildrenInReservation}}</div>
  </mat-list-item>

  <!-- total claimed tickets -->
  <mat-list-item class="item" *ngIf="!reservation.event">
    <mat-icon mat-list-icon [svgIcon]="'heroicons_outline:ticket'"></mat-icon>
    <div mat-line class="font-bold">Počet uplatněných vstupenek</div>
    <div mat-line class="text-primary-400 text-lg">{{noClaimedTickets}} / {{reservation.tickets?.length}}</div>
  </mat-list-item>

  <!-- total products -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon svgIcon="heroicons_outline:cake"></mat-icon>
    <div mat-line class="font-bold">Počet produktů</div>
    <div mat-line class="text-primary-400 text-lg">{{reservation.products.length}}</div>
  </mat-list-item>

  <!-- uuid -->
  <mat-list-item class="item">
    <mat-icon mat-list-icon>key</mat-icon>
    <div mat-line class="font-bold">UUID (nesdílet)</div>
    <div mat-line class="text-primary-400">{{reservation.uniqueId}}</div>
  </mat-list-item>
</mat-list>
