import {IMItemType, IMReservedItem} from "./common";
import {IMDialogCommodity} from "./dialog";
import {IMarlenkaOwner} from "./owner";
import {IMedium, IMultilanguage} from "@echo-nx/shared/common";


export interface IMProductType<T extends IMDialogCommodity | string = IMDialogCommodity | string> extends IMItemType<T> {
  image: IMedium<IMarlenkaOwner> | string;
}

export type IMReservedProduct<E extends IMProductType | string = IMProductType | string> = IMReservedItem<E>
