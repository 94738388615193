// @ts-nocheck
import * as moment from "moment";
import {IMMultilanguageString} from "../interfaces";

export const remapItems = <T>(remoteItems: Record<string, unknown>[], mapping: string): T[] => {
  return remoteItems.map(rItem => remapItem<T>(rItem, mapping));
};

const htmlEntities = {
  // add whatever you feel is necessary https://www.freeformatter.com/html-entities.html
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: '\''
};

const unescapeHTML = (str: string) => str.replace(/\&([^;]+);/g, function (entity, entityCode) {
  let match;
  if (entityCode in htmlEntities) {
    return htmlEntities[entityCode];
    /*eslint no-cond-assign: 0*/
  } else if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
    return String.fromCharCode(parseInt(match[1], 16));
    /*eslint no-cond-assign: 0*/
  } else if (match = entityCode.match(/^#(\d+)$/)) {
    return String.fromCharCode(~~match[1]);
  } else {
    return entity;
  }
});


const transformFunctions: Record<string, (value: string, dbItem?: any) => any> = {
  'subtractMonth': value => {
    return value ? moment(value).subtract('1', 'month') : ''
  },
  'time': (value: string) => {
    return moment(value).format("HH:mm");
  },
  'date': (value: string) => {
    return value ? moment(value).format("DD.MM.YYYY") : '';
  },
  'datetime': (value: string) => {
    return moment(value).format("HH:mm DD.MM.YYYY");
  },
  'dateRange': (value: string, item: any) => {
    const [start, end] = value.split("-");
    const startDate = start && item[start] ? moment(item[start]).format("DD.MM.YYYY") : null;
    const endDate = end && item[end] ? moment(item[end]).format("DD.MM.YYYY") : null;
    return `${startDate} ${endDate ? `- ${endDate}` : ''}`;
  },
  'timeRange': (value: string, item: any) => {
    const [start, end] = value.split("-");
    const startDate = start && item[start] ? moment(item[start]).format("HH:mm") : null;
    const endDate = end && item[end] ? moment(item[end]).format("HH:mm") : null;
    return `${startDate} ${endDate ? `- ${endDate}` : ''}`;
  },
  'key': (key: string, dbItem: any) => key.split('.').reduce((accu: Record<string, Record<string, any> | any>, currentKey: string, index: number) => {
    // todo implement array: key[i]
    // pokud existuje hodnota nebo jsme na posledni iteraci (tam uz muzeme vratit undefined)
    if (accu[currentKey] || index === key.split('.').length - 1) {
      return accu[currentKey];
    } else {
      // pokud nejsme na posledni iteraci a neni hodnota, vratime prazdny objekt at nespadne dalsi cyklus na tom, ze nemuze precist undef
      return {};
    }
  }, dbItem),
  'static': (value: any) => value,
  'default': (key: any, defaultValue: any) =>  item[key] ?? defaultValue,
  'first': (key: any, item: any) => item?.[key]?.[0] ?? '',
  'join': (key: any, item: any) => item?.[key]?.join(", "),
  'moment': (value: string) => value ? moment(value) : value,
  'unescape': unescapeHTML,
  'czechLang': (key: string, item: any) => item[key].find(({lang}) => lang === 'cs')?.value, //todo deprecate once multilang is converted fully
  'numeric': value => value ? Number(value) : 0,
  'count': (value) => value?.length ?? 0,
  'icon': value => `fas ${value}`,
}

// REGEX TO SLIT STRING TO FUNCTION AND ITS ARGS
// todo vysrat se na regex a projit to sekvencne
const createTransformRegex = (): RegExp => new RegExp(/(?<fn>\w+)\((?<args>[^\s]+)\)/, 'g');

const evaluateTransformFunctions = (dbItemKey: string, dbItem: any) => {
  const transformRegex = createTransformRegex();
  const valueTransformFns = transformRegex.exec(dbItemKey);
  if (!valueTransformFns) {
    return dbItemKey;
  } else {
    const {fn, args} = valueTransformFns.groups;
    const res = evaluateTransformFunctions(args, dbItem);
    // console.log(`Running transform function "${fn}" with arguments "${args}" = ${res}`,transformFunctions[fn](res, dbItem));
    return transformFunctions[fn](res, dbItem);
  }
}

export const remapItem = <T = Record<string, any>>(dbItem: Record<string, unknown>, _mapping: string): T => {
  // z gql to chodi jako string
  const mapping: Record<string, any> = JSON.parse(_mapping); // val can be string | Record | Array<any>
  return Object.entries(mapping).reduce((acc: T, current) => {
    const [targetKey, mapperKey] = current;

    if (typeof mapperKey === 'string') {
      // its a key or fn
      const transformRegex = createTransformRegex();
      let value;
      if (!transformRegex.exec(mapperKey)) {
        value = transformFunctions['key'](mapperKey, dbItem);
      } else {
        value = mapperKey.replace(transformRegex, (res) => evaluateTransformFunctions(res, dbItem))
      }
      acc[targetKey] = value;
    } else if (Array.isArray(mapperKey)) {
      // its an array yay
      acc[targetKey] = mapperKey.map(k => remapItem(dbItem, JSON.stringify(k)));
    } else {
      // its an object
      acc[targetKey] = remapItem(dbItem, JSON.stringify(mapperKey));
    }

    return acc;
  }, {} as T);
};
