import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {NavigationService} from '../../../services/navigation.service';
import {MarlenkaBasePageComponent} from '../../marlenka-base-page/marlenka-base-page.component';
import {ICategory, IContact} from "@echo-nx/shared/common";
import {
  DistributionPageTexts,
  IMDepartment,
  IMPartnerStore,
  IMSubnavigationItem,
  MarlenkaRouteData
} from "@echo-nx/marlenka/common";
import {BaseDistributionService, BasePartnerStoreService} from "@echo-nx/marlenka/ng/data-access";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {BaseCategoryService} from "@echo-nx/shared/ng/data-access";


@Component({
  selector: 'marlenka-distribution-detail-page',
  templateUrl: './distribution-detail-page.component.html',
  styleUrls: ['./distribution-detail-page.component.scss']
})
export class DistributionDetailPageComponent extends MarlenkaBasePageComponent<DistributionPageTexts> implements OnInit, OnDestroy {
  public distributionCategory$: Observable<ICategory<any>>;
  public distributions$: Observable<IMDepartment<IContact[]>[]>;
  public partnerStores$: Observable<IMPartnerStore[]>;
  public onDestroy$: Subject<void> = new Subject<void>();

  constructor(injector: Injector, private router: Router,
              private activeRoute: ActivatedRoute,
              private categoryService: BaseCategoryService,
              private distributionService: BaseDistributionService,
              private navigationService: NavigationService,
              private partnerStoreService: BasePartnerStoreService,
              public mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
    super(injector);
  }

  ngOnInit(): void {
    //distributions can change using submenu, so it now needs to listen for RouterChange
    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter((routerEvent) => routerEvent instanceof NavigationEnd),
      startWith('anysink'),
    ).subscribe(() => {
      const {id} = this.activeRoute.snapshot.params;
      this.distributionCategory$ = this.mrdService.currentLanguage$.pipe(switchMap(language => this.categoryService.fetchAll({
        filter: JSON.stringify({
          languageGroupId: id,
          language
        })
      }).pipe(map(categories => categories.items[0]))))
      this.distributions$ = this.distributionCategory$.pipe(switchMap(category =>
        this.distributionService.fetchAll({
          filter: JSON.stringify({categories: {$in: [category._id]}}),
          sortColumn: 'priority',
          sortDirection: 'DESC'
        }).pipe(map(val => val.items))));
      this.partnerStores$ = this.distributionCategory$.pipe(switchMap(category =>
        this.partnerStoreService.fetchAll({
          filter: JSON.stringify({categories: {$in: [category._id]}}),
        }).pipe(map(val => val.items))));

    });

    this.mrdService.currentLanguage$.pipe(takeUntil(this.onDestroy$), switchMap(language => this.distributionService.fetchCategories(language).pipe(
      takeUntil(this.onDestroy$)
    ))).subscribe(
      (categories) => {
        this.navigationService.setSubnavigationItems(
          categories.map((category => ({
            link: this.router.createUrlTree([`../${category.languageGroupId}`], {relativeTo: this.activeRoute}).toString(),
            title: category.name
          }) as IMSubnavigationItem))
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.navigationService.setSubnavigationItems(undefined);
  }

}
