export const echoNxDebounce = <F extends (...args: any[]) => Promise<any>>(func: F | null, waitFor: number) => {
  let timeout: number;

  return (...args: Parameters<F>): Promise<ReturnType<F> | null> | null =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = window.setTimeout(() => resolve(func ? func(...args) : null), waitFor);
    })
}
