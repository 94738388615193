import { Pipe, PipeTransform } from '@angular/core';

import { addressToString } from '../../utils';
import {IAddress} from "@echo-nx/shared/common";

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: IAddress): string {
    return addressToString(value);
  }

}
