import {Component, Input} from '@angular/core';
import {CdkStep} from "@angular/cdk/stepper";

@Component({
  selector: 'echo-nx-step-label',
  templateUrl: './step-label.component.html',
  styleUrls: ['./step-label.component.scss'],
})
export class StepLabelComponent {

  @Input() title!: string;
  @Input() subtitle?: string;
  @Input() step?: CdkStep;
}
