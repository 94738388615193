import {ElementRef, Injectable, NgZone} from "@angular/core";
import {Observable, Subscriber} from "rxjs";


export interface ObservableOptions {
  /*if undefined or false observer will automatically apply classes to ref element*/
  preventAppendClassModifiers?: boolean;

  breakpoints?: Record<string, number>;
}

@Injectable({providedIn: 'root'})
export class ResizeObserverService {

  /*
  * Optionally take breakpoints from module
  * */
  defaultBreakpoints = {};

  private resizeObserver: ResizeObserver;

  private entryMap: WeakMap<Element, (rect: ResizeObserverEntry) => void> = new WeakMap();

  constructor(private _ngZone: NgZone) {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const rectFn = this.entryMap.get(entry.target);
        if (rectFn) {
          rectFn(entry);
        }
      }
    });
  }

  private appendClassModifiers(entry: ResizeObserverEntry, options?: ObservableOptions,) {

    const breakpoints = options?.breakpoints ?? this.defaultBreakpoints;
    const matchedBreakpoints: string[] = [];
    // Update the matching breakpoints on the observed element.
    Object.keys(breakpoints).forEach(function (breakpoint) {
      const minWidth = breakpoints[breakpoint];
      if (entry.contentRect.width >= minWidth) {
        entry.target.classList.add(breakpoint);
        matchedBreakpoints.push(breakpoint);
      } else {
        entry.target.classList.remove(breakpoint);
      }
    });
    return matchedBreakpoints;
  }

  public observeBreakpoints(elementRef: ElementRef<Element>, options?: ObservableOptions): Observable<string[]> {
    return new Observable((subscriber: Subscriber<string[]>) => {

      this.entryMap.set(
        // key
        elementRef.nativeElement,
        // value fn
        (entry) => {
          this._ngZone.run(() => {
            const breakpoints = this.appendClassModifiers(entry, options);
            subscriber.next(breakpoints);
          })
        }
      );

      subscriber.next([]);
      this.resizeObserver.observe(elementRef.nativeElement);
      return () => {
        this.resizeObserver.unobserve(elementRef.nativeElement);
        this.entryMap.delete(elementRef.nativeElement);
      };
    });
  }

  public observe(elementRef: ElementRef<Element>): Observable<DOMRectReadOnly> {
    return new Observable((subscriber: Subscriber<DOMRectReadOnly>) => {
      this.entryMap.set(elementRef.nativeElement,
        (entry) => {
          subscriber.next(entry.contentRect)
        });
      this.resizeObserver.observe(elementRef.nativeElement);
      return () => {
        this.resizeObserver.unobserve(elementRef.nativeElement);
        this.entryMap.delete(elementRef.nativeElement);
      };
    });
  }

}
