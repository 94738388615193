import {IMarlenkaOwner} from "./owner";
import {IMDialogCommodity} from './dialog';
import {IMultilanguage, IPublishableOwnedEntity, MongoID} from "@echo-nx/shared/common";


export interface IMItemType<T extends IMDialogCommodity | string = IMDialogCommodity | string> extends IPublishableOwnedEntity<IMarlenkaOwner>, IMultilanguage{
  name: string;
  description?: string;
  commodityType: T;
}

export interface IMReservedItem<E extends IMItemType | string> extends MongoID {
  itemType: E; // product or ticket
  code?: string; // cislo vstupenky nebo desgustace, vraci dialog az po vytvoreni - proto optional.
  price?: number; // cena vstupenky (0 nebo vychozi cena)
  discountCouponCode?: string;

  claimedAt?: Date;
  usedAt?: Date;
  expiresAt?: Date;
}
