import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'total'
})
export class TotalPipe implements PipeTransform {

  transform(scraps: Record<string, any>[], keyName: string, decimals = 2): string {
    return scraps.reduce((acc: number, curr: any) => acc + curr[keyName], 0).toFixed(decimals)
  }
}
