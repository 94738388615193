import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WeatherDataVariantAComponent} from "./weather-data-variant-a.component";
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    WeatherDataVariantAComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    WeatherDataVariantAComponent
  ]
})
export class WeatherDataVariantAModule {
}
