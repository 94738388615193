import {IBaseFormFieldSettings} from "../base-form-field.settings";
import {IEntityDefinition} from "../../../interfaces";


export interface IGroupFieldSettings extends IBaseFormFieldSettings {
  color?: string;
  expandable?: boolean;
  defaultExpand?: boolean;
  disableable?: boolean;
  withoutCard?: boolean;
  groupDefinition: IEntityDefinition[];
}
