import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMapInfoOptions, IMapViewOptions, IPOIItem, remapItem} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  @Input() mapViewOptions!: IMapViewOptions;
  @Input() mapInfoOptions!: IMapInfoOptions;

  @Output() boundsChanged: EventEmitter<google.maps.LatLngBounds> = new EventEmitter<google.maps.LatLngBounds>();

  @Output() infoWindowClicked = new EventEmitter<string>();

  pointSelected(pt: IPOIItem){
    if(this.mapInfoOptions.mapping){
      this.mapInfoOptions.data = remapItem(pt as any, this.mapInfoOptions.mapping);
    } else {
      this.mapInfoOptions.data = pt;
    }
  }



}
