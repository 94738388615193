import {Component, Injector, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {MarlenkaBasePageComponent} from "../../../marlenka-base-page/marlenka-base-page.component";
import {CreateReservationPageTexts} from "@echo-nx/marlenka/common";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'nx-monorepo-voucher-stepper-page',
  templateUrl: './voucher-stepper-page.component.html',
  styleUrls: ['./voucher-stepper-page.component.scss']
})
export class VoucherStepperPageComponent extends MarlenkaBasePageComponent<CreateReservationPageTexts> implements OnInit {


  constructor(private location: Location, injector: Injector){
    super(injector);
  }

  ngOnInit() {
    return;
  }


  onBackClicked(stepIndex: number){
    if (stepIndex === 0) {
      this.location.back();
    }
  }
}
