import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileReader'
})
export class FileReaderPipe implements PipeTransform {

  transform(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = ev => {
        resolve(reader.result as string)
      }

      reader.readAsDataURL(file);
    });
  }

}
