<div class="p-4 max-w-xl bg-white">
  <div class="border-b-2">
    <div class="text-2xl font-black" [ngClass]="[primaryColor | prefix:'text']">{{title}}</div>
    <div class="text-gray-800 text-sm font-light mb-2">{{subtitle}}</div>
  </div>
  <div class="grid grid-cols-2 mt-2 mb-4 gap-y-1">
    <div class="flex flex-col">
      <div class="text-sm text-gray-400">Délka:</div>
      <div>{{length}} km</div>
    </div>
    <div class="flex flex-col datos_col">
      <div class="text-sm text-gray-400">Stav:</div>
      <div>{{status}}</div>
    </div>
    <div class="flex flex-col">
      <div class="text-sm text-gray-400">Zahájení:</div>
      <div>{{start}}</div>
    </div>
    <div class="flex flex-col datos_col">
      <div class="text-sm text-gray-400">Zprovoznění:</div>
      <div>{{finish}}</div>
    </div>
  </div>
  <div class="p-4 text-white flex flex-grow w-100 text-3xl text-center justify-center" [ngClass]="[primaryColor | prefix:'bg']" (click)="detailClicked.emit(this._id)">Detail</div>
</div>
