<ng-container *ngIf="(pageSettings$ | async)?.texts as pageTexts">

<marlenka-jumbotron
  [title]="pageTexts.jumbotron.title"
  [subtitle]="pageTexts.jumbotron.subtitle"
  [withDivider]="true"
  background="#fff"
  [withTopArrow]="false"
>
</marlenka-jumbotron>
<div class="reservation-wrap container small">
  <div class="reservation-buttons">
    <button
      mat-button
      class="reservation-button ticket-button"
      [routerLink]="'reservation'"
    >
      <span class="subtitle">Vstupenka</span>
      <img
        class="icon"
        src="../../../../assets/ticket_icon.svg"
        alt="Vstupenka"
      />
      <hr/>
        <span class="title">Koupit vstupenku<hr>uplatnit voucher</span>
    </button>

    <button
      mat-button
      class="reservation-button voucher-button"
      [routerLink]="'voucher'"
    >
      <span class="subtitle">Voucher</span>
      <img
        class="icon"
        src="../../../../assets/voucher_icon.svg"
        alt="Vstupenka"
      />
      <hr/>
      <span class="title">Koupit voucher</span>
    </button>
    <!--<button
            mat-button
            class="reservation-button voucher-button voucher-button-second"
            (click)="isReservation = true"
          >
            <span class="subtitle">Voucher</span>
            <img
              class="icon"
              src="../../../../assets/voucher_icon.svg"
              alt="Vstupenka"
            />
            <hr/>
            <span class="title">Uplatnit voucher na termín</span>
          </button>-->
  </div>
</div>

</ng-container>
