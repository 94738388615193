import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Renamed from pkTableKey
 */
@Directive({
  selector: '[echoNxTableKey]',
})
export class TableKeyDirective {
  @Input() echoNxTableKey!: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
