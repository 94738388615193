export interface AresICO {
  ICO: string;
  DIC?: string;
  nazev: string;
  mesto: string;
  PSC: string;
  ulice: string;
  cisloPopisne: string;
  stat: string;
}
