<h1 *ngIf="name" class="title">{{name}}</h1>

<!-- description -->
<p *ngIf="description" class="description">{{description}}</p>

<!-- extra info -->
<ng-content select="[extra-info]"></ng-content>

<!-- content -->
<ng-content select="[content]"></ng-content>
