import {IDiscountCoupon, IMEvent, IMReservation, IMReservedTicket, IMTicketType} from '../interfaces';
import * as moment from "moment";
import {CMSTransactionState, ITransaction, PaymentInfo} from "@echo-nx/shared/common";

export const getPersonCountFromTicketTypes = (ticketTypes: IMTicketType[]): number => ticketTypes.reduce((sum, ticket) => {
  sum += ticket.noAdults + ticket.noChildren;
  return sum;
}, 0);

export const getPersonCountFromCoupons = (coupons: IDiscountCoupon[]): number => coupons.reduce((sum, curr) => sum + curr.personCount, 0);

export const getChildrenAndAdultCount = (reservation: IMReservation): [number, number] => {
  return reservation.tickets.reduce((sum, ticket: IMReservedTicket<IMTicketType>) => {
    sum[0] += ticket.itemType?.noChildren ?? 0;
    sum[1] += ticket.itemType?.noAdults ?? 0;
    return sum;
  }, [0, 0]);
}

export const canChangeReservationDate = (reservation: IMReservation, dayDiff: number): boolean => {
  const diffFromToday = Math.floor(moment((reservation.event as IMEvent).start).diff(moment(), 'days', true));
  return diffFromToday >= dayDiff && reservation.canBeChangedByUser;
}

export const getReservationState = (items: ITransaction<PaymentInfo>[]) => items?.length > 0 ? items[items?.length - 1]?.status : CMSTransactionState.TransactionInitiated
