import gql from "graphql-tag";

export const deleteAllergen = gql`
  mutation deleteAllergens($input: [String!]!){
    response: deleteAllergens(ids: $input){
      userMessage
      description
      error
    }
  }
`;
