import {IMarlenkaOwner} from '.';
import {ICategorizedEntity, ICategory, IMedium, IMultilanguage, IOwnedEntity} from "@echo-nx/shared/common";

export interface IMPartnerStore extends IOwnedEntity<IMarlenkaOwner | string>, ICategorizedEntity<ICategory<IMarlenkaOwner> | string>, IMultilanguage {
  //based on https://docs.google.com/document/d/1debATyVvBxM_TVMawDmiqG4WVher0jVNoTZsQ4etMuQ/edit#
  //better check
  name: string;
  media: IMedium<IMarlenkaOwner> | string;
  url: string;
}
