import { Component, Injector, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PageSettingService } from '../../services/page-setting.service';
import { switchMap } from 'rxjs/operators';
import { IMPageSettings, MarlenkaRouteData } from '@echo-nx/marlenka/common';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";

@Component({
  template: '',
})
export class MarlenkaBasePageComponent<Texts> implements OnDestroy {
  public isDestroyed$ = new Subject<boolean>();

  public pageSettings$: Observable<IMPageSettings<Texts>>;
  public mrdService: LanguageRouteDataService<MarlenkaRouteData>;
  public pageSettingsService: PageSettingService;

  constructor(private injector: Injector) {
    this.mrdService = injector.get(LanguageRouteDataService); // tee-hee
    this.pageSettingsService = injector.get(PageSettingService);

    this.pageSettings$ = this.mrdService.currentLanguage$.pipe(switchMap((language) => this.mrdService.routeData$.pipe(
      switchMap(({ identifier }) =>
        this.pageSettingsService.getPageSettingsByIdentifier<Texts>(identifier, language)
      )
    )));
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }
}
