import {gql} from 'apollo-angular';

export const addContactAndAuth = gql`
  mutation addUserAndAuth($input: [AuthInput!]!){
    response: addUserAndAuth(inputAuth: $input){
      message
      success
    }
  }
`;
