import {MarlenkaPaymentType} from "../interfaces";
import {MApiResponse, PaymentInitData} from "@echo-nx/shared/common";

export const initiateMarlenkaPayment = (id: string, type: MarlenkaPaymentType) =>
  fetch(`/api/initiate-payment/${type}/${id}`)
    .then(async (res) => {
      if (res.status !== 200) {
        const {message, code} = await res.json() as MApiResponse;
        throw Error(message);
      }
      const {data: {redirectUrl}} = await res.json() as MApiResponse<PaymentInitData>;
      window.location.href = redirectUrl;
    })
