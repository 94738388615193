import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotoCardVariantAComponent} from "./photo-card-variant-a.component";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    PhotoCardVariantAComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    PhotoCardVariantAComponent
  ]
})
export class PhotoCardVariantAModule {
}
