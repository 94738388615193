<!--TODO SORRY THIS IS NOT WORKING AFTER MARLENKA REWORK-->
<div class="text">
  Provozovatel tohoto webového portálu používá soubory cookie (a podobné technologie),
  aby své webové stránky lépe přizpůsobil návštěvníkům a usnadnil jejich používání. Využíváme cookies nutné pro
  zajištění funkčnosti webu a rovněž cookies sloužící k analýze provozu na našich webových stránkách. V souladu se
  <a [href]="gdprUrl" target="_blank" class="url">Zásadami ochrany osobních údajů sdílíme informace</a> o vašem užívání naší webové stránky také s
  našimi partnery v oblasti
  sociálních médií, reklamy a analýzy. Souhlas udělíte kliknutím na tlačítko „Souhlasím“, <!--své rozhodnutí můžete upravit
  kliknutím na tlačítko „Podrobné nastavení“, případně svůj souhlas neudělit či odvolat zde.-->
</div>

<div class="buttons">
<!--  <button (click)="consentChanged.emit(true)">Povolit vše</button>
  <button (click)="consentChanged.emit(false)">Odmítnout vše</button>-->
</div>

