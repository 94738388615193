import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, BehaviorSubject, from} from 'rxjs';
import {catchError, filter, take, switchMap} from 'rxjs/operators';
import {URL_SETTINGS_TOKEN} from "../generic-tokens";
import {BaseAuthService} from "./base-auth.service";
import {UrlSettings} from "../utils";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: BaseAuthService, @Inject(URL_SETTINGS_TOKEN) protected urlSettings: UrlSettings) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const {fullApiPath} = this.urlSettings;

    // which operations to not append token to
    const ignoreOps = this.urlSettings?.appendTokenIgnoreOperations ?? [
      'Login',
      'Refresh',
      'setPassword',
      'resetPassword',
    ];

    const ignoreUrls = this.urlSettings?.appendTokenIgnoreUrls ?? [
      `${fullApiPath}/kiosk-login`,
    ];

    //console.log('WILL I INTERCEPT?',request.url);
    if (!request.url.startsWith(fullApiPath) || ignoreUrls.some(url => url === request.url) || ignoreOps.some(op => op === request.body?.operationName)) {
      //console.log('not intercepting');
      return next.handle(request);
    }

    // first have a look at our current token, do we have it? is it not expired?
    const token = this.authService.getToken();
    const isLoggedIn = this.authService.isLoggedIn();
    const isExpired = this.authService.isTokenExpired();

    if (isLoggedIn && token && !isExpired) {
      // we have valid token
      request = this.addToken(request, token);
    } else {
      // we have invalid token, try refresh
      if (this.authService.hasValidRefreshToken()) {
        console.log("not logged in but good refresh token");
        return this.tryRefreshing(request, next);
      } else {
        // invalid token, no refresh token

        // find reason of 401 (timeout / never logged in)
        if (token) {
          console.log("No valid token or refresh token, logging out.");
          return from(this.authService.logout()).pipe(
            switchMap(() =>
              throwError(
                new HttpErrorResponse({
                  error: 'Odhlášen z důvodu neaktivity',
                  status: 401
                }))
            )
          )
        } else {
          console.log("Trying to call intercepted route without token", request.url);
          return throwError(
            new HttpErrorResponse({
              error: 'Nejste přihlášen',
              status: 401
            }));
        }
      }
    }

    // we should have everything and there should not be any error, but for safety sake, let's do shit again
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (this.authService.hasValidRefreshToken()) {
          return this.tryRefreshing(request, next);
        } else {
          return throwError(error);
        }
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }


  private tryRefreshing(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      //Im sorry unreadable code follows
      //convert promise to observable
      console.log("refresh start");
      const token = this.authService.getToken();
      const refreshToken = this.authService.getRefreshToken();
      if (token && refreshToken) {
        return from(this.authService.refresh(token, refreshToken))
          .pipe(
            // switchMap the result from original promise
            switchMap((token) => {
              const new_token = this.authService.getToken();
              if (token && new_token) {
                // is successful, so append the new token to our requests
                // stop refreshing
                this.isRefreshing = false;
                this.refreshTokenSubject.next(new_token);
                return next.handle(this.addToken(request, new_token));
              } else {
                // not successful so
                return throwError(
                  new HttpErrorResponse({
                    error: 'Odhlášen z důvodu neaktivity',
                    status: 401
                  }));
              }
            }));
      } else {
        throw new Error('Token interceptor is refreshing but has no tokens to refresh with!');
      }
    } else {
      // we are already refreshing, hold the request and just subscribe to the refreshToken so when it completes, add the jwt and send it
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        // not sure why we are taking the 1 here, since we cannot do more than one refresh, but this is copied from smarter guys so whatevs
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
