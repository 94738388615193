import gql from 'graphql-tag';

export const readDistributionsLanguageGroups = gql`
  query ReadDistributionLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readDistributionLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          categories {
            _id
            name
            description
          }
          _id
          language
          languageGroupId
          priority
          createdAt
          name
          openingHours
          contact{

            _id
            name
            firstName
            lastName
            telephones
            websites
            emails
            owner{
              _id
              name
            }
            ico
            priority
            dic
            dataBox
            tradeRegisterText
            bankInfo{
              bankName
              iban
              swift
              bankNumber
              bankCode
            }
            address{
              city
              province
              state
              zipcode
              street
            }
          }
        }
      }
    }
  }
`;
