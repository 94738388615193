
import {IMarlenkaOwner} from "../owner";
import {IMPageSettings} from "../page-settings";
import {IMultilanguage, IOwnedEntity} from "@echo-nx/shared/common";

export interface IMNavigation extends IOwnedEntity<IMarlenkaOwner | string>, IMultilanguage {
  name: string;
  pages: string[] | IMPageSettings<any>[];
  page?: string | IMPageSettings<any>; //if null it is main navigation, otherwise it belong to a page and is subnavigation on said page?
}

