
export interface IAirSensorMetrics {
  /* Temperatuire in celsius*/
  temperature: number;

  /*Pressure in Pascal*/
  pressure: number;

  /* % Relative humidity*/
  humidity: number;

  /*pm1, pm2.5 and pm10*/
  particulateMatters: IParticulateMatter[];

  /*co parts per milion*/
  carbonMonoxidePpmAmount: number;

  /*co2 parts per milion*/
  carbonDioxidePpmAmount: number;

  /*o3 parts per milion*/
  ozonePpmAmount: number;

  /*no2 parts per milion*/
  nitrogenDioxidePpmAmount: number;
}

export interface IParticulateMatter {size: number, amount: number}
