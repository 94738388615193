import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {IBaseNavbarData, IBaseNavbarDesign, IEpxColorful, IMedium, INavigationItem} from "@echo-nx/shared/common";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {NavMobileComponent} from "../nav-mobile/nav-mobile.component";
import {take} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive()
export class BaseNavBarComponent implements IBaseNavbarData, IBaseNavbarDesign, AfterViewInit {

  @Output()
  itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  bgColor: string;

  @Input()
  fullLogo: IMedium;

  @Input()
  hoverColorful: IEpxColorful;

  @Input()
  primaryColor: string;

  @Input()
  secondaryColor: string;

  @Input()
  smallLogo: IMedium;

  @Input()
  textColor: string;

  @Input()
  navigation: INavigationItem;

  @Input()
  set design({hoverColorful, textColor, primaryColor, secondaryColor, bgColor}: IBaseNavbarDesign) {
    this.hoverColorful = hoverColorful;
    this.textColor = textColor;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.bgColor = bgColor;
  }

  @Input()
  set data({smallLogo, fullLogo,navigation}: IBaseNavbarData) {
    this.smallLogo = smallLogo;
    this.fullLogo = fullLogo;
    this.navigation = navigation;
  }


  public sideMenuActive = false;
  private overlayRef: OverlayRef;
  private navPortal: ComponentPortal<NavMobileComponent>;

  constructor(private overlay: Overlay, private element: ElementRef<HTMLElement>) {}

  public onSearchSubmit(event: SubmitEvent, text: string){
    event.preventDefault();
    this.search.next(text);
  }

  ngAfterViewInit(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position()
        .global()
    });
    this.navPortal = new ComponentPortal(NavMobileComponent);
    }

  public toggleSideNav(force?: 'close' | 'open') {
    if(force === 'close' || this.sideMenuActive){
      this.overlayRef.detach();

      this.sideMenuActive = false;
    } else {
      const test = this.overlayRef.attach(this.navPortal);
      test.instance.navigation = this.navigation;
      test.instance.logo = this.fullLogo ?? this.smallLogo;
      test.instance.primaryColor = this.primaryColor;
      this.overlayRef.outsidePointerEvents().pipe(take(1)).subscribe(()=>
        test.instance.navClosed.next()
      );
      test.instance.navClosed.pipe(take(1)).subscribe(()=>{
        this.toggleSideNav('close');
      });
      test.instance.itemClicked.pipe(takeUntil(test.instance.navClosed)).subscribe((ic)=>{
        this.itemClicked.next(ic);
      });
      this.sideMenuActive = true;
    }
  }
}
