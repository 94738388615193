<ng-container *ngIf="footerTexts$ | async as footerTexts">
  <footer class="footer" [class]="classList?.firstRow">
    <!-- FIRST ROw, CONTACT INFO -->
    <div class="first" *ngIf="rows >= 3" [class]="classList?.firstRow">
      <div class="container">
        <div class="fx-row first-row">
          <div class="col">
            <div class="title">
              {{ footerTexts.reception.title | uppercase }}
            </div>
            <div class="links">
              <a href="#">+420 595 177 444</a>
              <a href="#">marlenka@marlenka.cz</a>
            </div>
          </div>
          <div class="col" *ngIf="mainContact$ | async as mainContact">
            <div class="title">
              {{ footerTexts.company.title | uppercase }}
            </div>
            <div class="links">
              <div>
                <b>{{ mainContact.name }}</b>
              </div>
              <div [innerHTML]="mainContact.address | address"></div>
              <div>
                <b>{{ footerTexts.ico.title }}:</b>
                {{ mainContact.ico }},
                <b>{{ footerTexts.dic.title }}:</b>
                {{ mainContact.dic }}
              </div>
              <div>
                <b>{{ footerTexts.dataBox.title }}:</b>
                {{ mainContact.dataBox }}
              </div>
              <div>
                <b>{{ footerTexts.bank.title }}:</b>
                {{ mainContact.bankInfo.bankName }},
                {{ mainContact.bankInfo.bankNumber }} /{{
                  mainContact.bankInfo.bankCode
                }}
              </div>
              <div>
                <b>SWIFT:</b> {{ mainContact.bankInfo.swift }}, <b>IBAN: </b
                >{{ mainContact.bankInfo.iban }}
              </div>
            </div>
          </div>

          <div class="col scroll-top-col">
            <div class="scrollTop">
              <a (click)="scrollToTop()">
                <img src="../../../assets/scrolltop.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="fx-row court-row">
          <p>
            {{ footerTexts.court }}
          </p>
        </div>
      </div>
    </div>
    <!-- SECOND ROW = GDPR, SOCIAL SITES, SCROLL TO TOP -->
    <div
      class="second"
      [class]="{ secondBg: rows === 3 }"
      *ngIf="rows >= 2"
      [class]="classList?.secondRow"
    >
      <div class="container">
        <div class="fx-row second-row">
          <div class="col">
            <div class="links">
              <a target="_blank" [href]="footerTexts.links.privacyTermsLink">{{
                footerTexts.links.privacyTerms
              }}</a>
              <a target="_blank" [href]="footerTexts.links.newDocumentLink">{{
                footerTexts.links.newDocument
              }}</a>
              <a (click)="cookieManager.openDialog()">{{
                footerTexts.links.cookieTerms
              }}</a>
            </div>
          </div>
          <div class="col">
            <div class="links social-networks">
              <div class="icon">
                <a
                  target="_blank"
                  href="https://www.facebook.com/Marlenka.Official"
                >
                  <img src="/assets/fb_icon.svg" alt="Facebook" />
                </a>
              </div>
              <div class="icon">
                <a
                  target="_blank"
                  href="https://www.instagram.com/marlenka.official"
                >
                  <img src="/assets/ig_icon.svg" alt="Instagram" />
                </a>
              </div>
              <div class="icon">
                <a
                  target="_blank"
                  href="https://www.youtube.com/user/marlenkahoneycake"
                >
                  <img src="/assets/ytb_icon.svg" alt="Youtube" />
                </a>
              </div>
              <div class="icon">
                <a target="_blank" href="https://twitter.com/marlenka_ofcl">
                  <img src="/assets/tw_icon.svg" alt="Twitter" />
                </a>
              </div>
              <div class="icon">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/marlenka/"
                >
                  <img src="/assets/linkedin_icon.svg" alt="LinkedIn Logo" />
                </a>
              </div>
            </div>
          </div>
          <div class="col scroll-top-col">
            <div class="spacer">
              <a
                href="https://www.emarlenka.cz/"
                target="_blank"
                mat-flat-button
                class="button"
                >E-shop</a
              >
              <a
                href="https://marlenka.us/"
                target="_blank"
                mat-flat-button
                class="button"
                >E-shop USA</a
              >
            </div>
            <div class="scrollTop" *ngIf="rows === 2">
              <a (click)="scrollToTop()">
                <img src="../../../assets/scrolltop.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- THIRD ROW = COPYRIGHT -->
    <div class="third" *ngIf="rows >= 1" [class]="classList?.thirdRow">
      <div class="container">
        <div class="fx-row third-row">
          <div class="col">
            <p>© 2022 MARLENKA international s.r.o.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</ng-container>
