import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {IArticle, ICategory, IMedium, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {BaseCakesExclusiveService} from "@echo-nx/marlenka/ng/data-access";
import {map, switchMap} from "rxjs/operators";
import {IMarlenkaOwner, IMProduct} from "@echo-nx/marlenka/common";
import {readAllCategories} from "@echo-nx/shared/ng/data-access";


@Injectable({
  providedIn: 'root'
})
export class CakesExclusiveService extends BaseCakesExclusiveService {

  constructor(apollo: Apollo) {
    super(apollo);
  }

  public fetchAllForCategory(categoryId: string): Observable<IArticle<IMarlenkaOwner,IMedium<any>>[]> {
    return this.fetchAll({
      filter: JSON.stringify({categories: {$in: [categoryId]}}),
    }).pipe(
      map(response => response.items)
    );
  }

  public fetchAllForLanguageGroupCategory(languageGroupId: string, language?: string): Observable<IArticle<IMarlenkaOwner,IMedium>[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory>>>({
      query: readAllCategories,
      variables: {filter: JSON.stringify({languageGroupId: languageGroupId, language})}
    }).pipe(
      map(result => result.data.response.items.map(({_id}) => _id)),
      switchMap(ids =>
        this.fetchAll({
            filter: JSON.stringify({categories: {$in: ids}})
          }
        )
      ),
      map(response => response.items)
    )
  }
}
