import {BaseSoftDeletableDocumentType} from "../base-document-types";
import {IMedium} from "../i-medium";
import {IBaseOwner} from "../i-base-owner";
import {WidgetName} from "../kiosk";

export interface WidgetTemplate extends BaseSoftDeletableDocumentType {
  title: string;
  description?: string;
  featuredImage?: IMedium<IBaseOwner> | string;
  name: WidgetName;
}
