import {Component, Inject, OnInit, EventEmitter} from '@angular/core';

import {COOKIE_CONSENT_CONFIG, CookieConsentConfig} from "../types/config";
import { Cookie } from '../types/cookie';
import {CookieConsentDialog} from "../types/cookie-consent-dialog";

@Component({
  selector: 'echo-nx-cookie-consent-dialog',
  templateUrl: './cookie-consent-dialog.component.html',
  styleUrls: ['./cookie-consent-dialog.component.scss']
})
export class CookieConsentDialogComponent implements OnInit, CookieConsentDialog {
  public consentChanged = new EventEmitter<Cookie>();

  public gdprUrl?: string;



  constructor(@Inject(COOKIE_CONSENT_CONFIG) private cookieConsentConfig: CookieConsentConfig) {
    const {gdprUrl} = this.cookieConsentConfig;
    this.gdprUrl = gdprUrl;
  }


  ngOnInit(): void {
    return;
  }
}
