import {IRsdURL} from "./i-rsd-odkaz";

export interface IRsdSoubor {
  GUIDSouboru: string;
  URL: string;
  PosledniZmena: number;
  Velikost: number
}

export interface IRsdFotoSoubor extends IRsdSoubor {
  URLNahledu: string;
}

export interface IRsdSoubory {
  Mapa: IRsdURL;
  PDFSoubory: IRsdSoubor[];
  Fotogalerie: IRsdFotoSoubor[];
  Letak: IRsdSoubor;
}
