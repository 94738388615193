import gql from 'graphql-tag';

export const readAllWidgetTemplates = gql`
  query ReadAllWidgetTemplates($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllWidgetTemplate(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        name
        title
        description

      }
    }
  }
`
