import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from "./table.component";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
  FilterPipeModule,
  FormatPipeModule,
  PluckPipeModule,
  ResolveKeyPipeModule
} from "@echo-nx/shared/ng/feature/common";
import {MatRippleModule} from "@angular/material/core";
import {StatusCellModule} from "../status-cell/status-cell.module";
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule} from "@angular/material/checkbox";
import {ExpandedItemDirective} from "./expanded-item.directive";


@NgModule({
  declarations: [
    TableComponent,
    ExpandedItemDirective
  ],
  exports: [
    TableComponent,
    ExpandedItemDirective
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,

    FilterPipeModule,
    PluckPipeModule,
    FormatPipeModule,
    ResolveKeyPipeModule,


    // other dependencies
    MatRippleModule,
    StatusCellModule,
    MatCheckboxModule,

  ],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
    }

    // https://material.angular.io/components/checkbox/overview#noop
    // https://stackoverflow.com/questions/52364296/matcheckbox-preventdefault-and-event-checked-value
  ]
})
export class TableModule {
}
