import {Injector, Pipe, PipeTransform} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {createPortalInjector} from '../../utils';
import {DYNAMIC_COMPONENT_INPUT} from "../../generic-tokens";
import {DynamicComponentRegistryService} from "../../services";

@Pipe({
  name: 'dynamicComponent'
})
export class DynamicComponentPipe implements PipeTransform {

  constructor(
    private injector: Injector,
    private componentRegistry: DynamicComponentRegistryService
  ) {
  }

  // https://github.com/codethatstack/platform/tree/master/libs/portals/src/lib
  transform(value: { name: string; [key: string]: any }): ComponentPortal<unknown> {
    const {name} = value;
    const injector = createPortalInjector(DYNAMIC_COMPONENT_INPUT, {...value}, this.injector);

    const component = this.componentRegistry.get(name);
    if (!component) {
      throw new Error(`Component "${name}" was not found in DynamicComponentRegistryService. Have you imported the corresponding module?`);
    }

    return new ComponentPortal(component, null, injector);
  }

}
