import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BasePaginationComponent} from './base-pagination.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    BasePaginationComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    BasePaginationComponent
  ]
})
export class BasePaginationModule { }
