import {IBaseFormFieldSettings} from "../base-form-field.settings";
import {IInjectableServiceData} from "@echo-nx/shared/ng/feature/common";
import {UntypedFormGroup} from "@angular/forms";

export interface ISelectFieldSettings extends IBaseFormFieldSettings {
  service?: IInjectableServiceData,
  valueType: "array" | "numeric" | "string",
  displayTransformFn?: ((item: any) => string) | string; // co zobrazit v nahled, typicky **name**
  form?: UntypedFormGroup;
  isMultiple: boolean;
  comparatorKey?: string;
}
