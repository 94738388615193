import gql from 'graphql-tag';

export const readSelectedEventLanguages = gql`
  query ReadSelectedEventLanguages($ids: [String!]!) {
    response: readSelectedEventLanguage(ids: $ids) {
      _id
      name
      nameShort
      warningTitle
      warningContent
    }
  }
`;
