import gql from 'graphql-tag';

// for frontend:
export const readAllPageSettings = gql`
  query ReadAllPageSettings($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String,  $filter: String) {
    response: readAllPageSettings(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items{
        _id
        name
        identifier
        anchors{
          name
        }
        title
        language
        languageGroupId
      }
    }
  }
`

