import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import jsQR, {QRCode} from "jsqr";

@Component({
  selector: 'echo-nx-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent {

  @Output()
  public qrCodeDetected = new EventEmitter<QRCode>();

  public onImageScanned(imageData: ImageData) {
    const code = jsQR(imageData.data, imageData.width, imageData.height, {inversionAttempts: "attemptBoth"});
    // console.log('image scanned, result of jsQr', code);
    if (code) {
      this.qrCodeDetected.emit(code);
    }
  }
}
