import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixBackgroundUrl',
  pure: true
})
export class FixBackgroundUrlPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return this.getMemberShipLevel(value);
  }
  getMemberShipLevel(text: string): string {
    if(text && typeof(text) === 'string') {
      if (text.includes(".")) {
        return `url(${text})`;
      }
      return text;
    }
    return '';
  }
}
