export interface NavPol {
  id: number,
  children: NavPol[],
  parent: NavPol,
  nazev: string,
  url: string,
  ikonka?: string,
  parent_id: number
}

export const getChildrenInTreeRepresentation = (children: NavPol[], tree: Record<number, NavPol> = {}) => {
  const missingParents: NavPol[] = [];

  //add new child to the map of all nodes
  if (children) {
    for (const child of children) {
      child.children = [];
      tree[child.id] = child;
      if (child.parent_id != null) {
        if(tree[child.parent_id] == null){
          missingParents.push(child);
        } else {
          tree[child.parent_id].children.push(child);
        }
      }
    }
    if(missingParents.length > 0){
      tree = getChildrenInTreeRepresentation(missingParents, tree);
    }

    // find root level (no parents)
    const root: NavPol[] = [];
    for (const key of Object.keys(tree).map(k => Number(k))) {
      if (tree[key].parent_id == null) {
        root.push(tree[key]);
      }
    }
    return root;
  }
  return tree;
};
