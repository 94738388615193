import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrefixPipe} from "./prefix.pipe";

@NgModule({
  declarations: [PrefixPipe],
  imports: [
    CommonModule
  ],
  exports:[
    PrefixPipe
  ]
})
export class PrefixModule { }
