import {IBaseFormFieldSettings} from "../base-form-field.settings";
import {IDataCardDialogSettings} from "../../../../data-card";



export interface IEntityPickerFieldSettings extends IBaseFormFieldSettings {
  dialogSettings: IDataCardDialogSettings;

  buttonText?: string;
  disableRemoveAll?: boolean;
  displayTransformFn?: (selected: any[]) => string;
  // overrideColumnDefinitions?: IColumnDefinition[]; // Removed: I dont want this module to be dependant on data-card module

  // isMultilanguage?: boolean;
  preview?: {
    style: 'media' | 'chips' | 'portal',
    keys: {
      name: string[] | ((item: any) => string),
      url?: string[] | ((item: any) => string),
    }
  }

  // Transformation function to run on selected entities (The result is then fed to patchValues of formControl)
  transformFn?: (entities: any) => any;
}
