import {Injectable, InjectionToken} from "@angular/core";
import {
  CommonEntityTypes,
  FetchAllArgs, IArticle,
  IBaseOwner,
  ICategory,
  IEntityService,
  IGallery, IMutationResponse,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";

import {Apollo, MutationResult} from "apollo-angular";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {
  readAllGallery,
  readSelectedGallery,
  readGalleryLanguageGroups,
  readAllCategories
} from "../queries";
import {addGallery, deleteGallery} from "../mutations";


@Injectable()
export class BaseGalleryService implements IEntityService<IGallery> {
  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IGallery>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IGallery>>>({
      query: readAllGallery,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IGallery>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IGallery>>>({
      query: readGalleryLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IGallery[]> {
    return this.apollo.query<IPKApolloResult<IGallery[]>>({
      query: readSelectedGallery,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IGallery> {
    return this.apollo.query<IPKApolloResult<IGallery[]>>({
      query: readSelectedGallery,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
      catchError(() => {
        throw Error('No such ID')
      })
    )
  }

  getType(): string {
    return CommonEntityTypes.Gallery;
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate<IPKApolloResult<IMutationResponse>>({
      mutation: deleteGallery,
      variables: { input: ids }
    });
  }

  save(allergen: IGallery[]): Observable<MutationResult<IPKApolloResult<IGallery[]>>> {
    return this.apollo.mutate<IPKApolloResult<IGallery[]>>({
      mutation: addGallery,
      variables: {input: allergen}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(language: string, owner?: string): Observable<ICategory<IBaseOwner>[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language ?? 'cs',
          owner
        })
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }
}

export const BASE_GALLERY_SERVICE_TOKEN = new InjectionToken<IEntityService<IGallery>>('BASE_GALLERY_SERVICE_TOKEN');
