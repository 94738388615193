import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasKey'
})
export class HasKeyPipe implements PipeTransform {

  transform(item: Record<string, never>, key: string): boolean {
    return {}.hasOwnProperty.call(item, key);
  }

}
