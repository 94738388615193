import {gql} from 'apollo-angular';

export const deleteCategory = gql`
  mutation deleteCategory($input: [String!]!){
    response: deleteCategory(_ids: $input){
      userMessage
      error
      description
    }
  }
`;
