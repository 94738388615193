import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import {
  CommonEntityTypes,
  FetchAllArgs, IBaseOwner, ICategory,
  IEntityService,
  IMutationResponse, IPaginatedResponse, IPKApolloResult, IPOIItem,
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
  readAllCategoriesOfEntity,
  readAllPointsOfInterest, readAllPointsOfInterestForSynchronizing,
  readSelectedPointsOfInterest
} from "../queries";
import {addPointOfInterest} from "../mutations";

@Injectable()
export class BasePointsOfInterestService implements IEntityService<IPOIItem> {
  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IPOIItem>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IPOIItem>>>({
      query: readAllPointsOfInterest,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchAllForSync(args?: FetchAllArgs): Observable<IPaginatedResponse<IPOIItem>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IPOIItem>>>({
      query: readAllPointsOfInterestForSynchronizing,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IPOIItem[]> {
    return this.apollo.query<IPKApolloResult<IPOIItem[]>>({
      query: readSelectedPointsOfInterest,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IPOIItem> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0] || null)
    );
  }

  getType(): string {
    return CommonEntityTypes.POIItem;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(category: IPOIItem[]): Observable<MutationResult<IPKApolloResult<IPOIItem[]>>> {
    return this.apollo.mutate<IPKApolloResult<IPOIItem[]>>({
      mutation: addPointOfInterest,
      variables: {input: category}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IBaseOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IBaseOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

}

export const BASE_POINTS_OF_INTEREST_SERVICE_TOKEN = new InjectionToken<IEntityService<IPOIItem>>('BASE_POINTS_OF_INTEREST_SERVICE_TOKEN');
