import {Component, Input} from '@angular/core';
import {BaseItemCardComponent} from "../base-item-card.component";

@Component({
  selector: 'echo-nx-item-card-variant-b',
  templateUrl: './item-card-variant-b.component.html',
  styleUrls: ['./item-card-variant-b.component.scss']
})
export class ItemCardVariantBComponent extends BaseItemCardComponent {

}
