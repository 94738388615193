<cdk-accordion class="flex flex-col gap-1.5">
  <ng-container *ngFor="let child of item.children; let index = index">
    <ng-container [ngSwitch]="(child | activeChildrenCount) > 0">
      <!--NODE-->
      <cdk-accordion-item
        *ngSwitchCase="true"
        #accordionItem="cdkAccordionItem"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index"
      >
        <!--Header-->
        <div
          [ngClass]="[textColor | prefix:'text', primaryColor | prefix: 'hover:text']"
          class="cursor-pointer font-medium px-2 py-1 hover:font-bold flex-row flex cursor-pointer relative font-display text-lg"
          (click)="accordionItem.toggle()">
          <div class="">{{child.title}}</div>
          <div class="ml-auto">
            <i class="fas" [ngClass]="[accordionItem.expanded ? 'fa-caret-up' : 'fa-caret-down']"></i>
          </div>
        </div>
        <div
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index">
          <div class="pl-2">
            <echo-nx-nav-mobile-level (itemClicked)="itemClicked.next($event)"
                                      [item]="child"></echo-nx-nav-mobile-level>
          </div>
        </div>
      </cdk-accordion-item>


      <!--LEAF-->
      <div
        *ngSwitchDefault
        (click)="itemClicked.next(child.screen?.slug ?? child.externalLink)"
        [ngClass]="[textColor | prefix:'text', primaryColor | prefix: 'hover:text']"
        class="cursor-pointer px-2 py-1 relative font-display text-lg font-medium hover:font-bold">
        {{child.title}}
      </div>
    </ng-container>
  </ng-container>
</cdk-accordion>

