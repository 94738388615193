export interface IBaseCheckboxData {
  title?: string;
}

export interface IBaseCheckboxDesign {
  primaryColor?: string;
  accentColor?: string;
  secondaryColor?: string;
  fluid?: boolean;
  panelClass?: string;
  itemsClass?: string;
}



