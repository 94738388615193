<ng-container *ngIf="(pageSettings$ | async)?.texts as texts">
  <marlenka-jumbotron
    [title]="texts.jumbotron.title"
    [subtitle]="texts.jumbotron.subtitle"
    [withDivider]="true"
    [topWave]="true"
    [textColor]="'#F3DCAA'"
    [withBottomArrow]="true"
    bottomArrowFragment="articles"
    background="url('https://i.imgur.com/nxHSth8.jpg')"
  >
    <div style="margin-top: 400px;"></div>
  </marlenka-jumbotron>
  <ng-container *ngIf="articles$ | async as articles">
    <div class="articles-wrap container small" id="articles">
      <article class="single-article" *ngFor="let article of articles.items">
        <div class="col col-left">
          <figure>
            <img class="main-img" src="{{ article.featuredImage?.url }}" />
          </figure>
        </div>

        <div class="col col-right">
          <time>
                        <span>
                            {{ article.publishedAt | date: 'dd. MM. yyy | HH:mm' }}
                        </span>
          </time>
          <h2>
            <a [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/home/articles/' + article.languageGroupId">
              {{ article.title }}
            </a>
          </h2>
          <div class="summary">
            <marlenka-wysiwyg-view [html]="article.summary"> </marlenka-wysiwyg-view>
          </div>
          <div class="buttons">
            <marlenka-read-more [link]="'/' + mrdService.currentLanguage.toLowerCase() + '/home/articles/' + article.languageGroupId"></marlenka-read-more>
          </div>
        </div>
        <hr />
      </article>
      <echo-nx-paginator class="marlenka-paginator" [currentPage]="articles.page + 1" [totalItems]="articles.total" [itemsPerPage]="articles.take"></echo-nx-paginator>
    </div>
  </ng-container>
</ng-container>
