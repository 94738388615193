import gql from 'graphql-tag';

export const readAllMPartnerStore = gql`
  query ReadAllMPartnerStore($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllPartnerStore(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        language
        languageGroupId
        name
        url
        media {
           _id
          url
          description
        }
        owner {
          _id
        }
      }
  }
}
`;
