import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import {unitOfTime} from "moment";

@Pipe({
  name: 'dateAdd'
})
export class DateAddPipe implements PipeTransform {

  transform(value: Date, amount: number, unit: unitOfTime.DurationConstructor, format = 'HH:mm'): string {
    return moment(value).add(amount, unit).format(format)
  }

}
