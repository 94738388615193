import {getDaysInMonth, isSameDate} from "@echo-nx/shared/common";
import {
  getMaximumFreeCapacity,
  getTotalCapacity,
  getTotalReservations,
  IMEvent,
  IMFilter
} from "@echo-nx/marlenka/common";
import {CalendarDay, CalendarDaySettings} from "./calendar.types";
import {CalendarDayComponent} from "./calendar-day/calendar-day.component";


export const getBackgroundColor = (day: Date, eventsInDay: IMEvent[], isFull: boolean, isHistorical: boolean, filter: IMFilter): string => {
  if (/*day.getDay() % 6 === 0*/ eventsInDay.length === 0 || isHistorical) {
    return '#C4C4C4';
  } else if (isFull) {
    return '#ffffff';
  } else if (!hasFilterValue(eventsInDay, filter)) {
    return '#FFEAC0';
  } else {
    return '#FFC400';
  }
}

const hasFilterValue = (eventsInDay: IMEvent[], filter: IMFilter): boolean =>
  eventsInDay.some(ev =>
/*    ev.language === filter.language &&*/
    ev.availableCapacity >= filter.people
  );

export const generateDummyDays = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = getDaysInMonth(month, year);
  const days: CalendarDay<CalendarDaySettings>[] = []
  for (let i = 0; i < daysInMonth; i++) {
    days.push({
      name: 'CalendarDayComponent',
      date: new Date(year, month, i + 1),
      settings: {
        backgroundColor: '#ffffff',
        color: 'white',
        dayFontSize: '0rem',
      }
    })
  }
  return days;
}

export const getDaysFromEvents = (date: Date, events: IMEvent[], calendarDaySettingBuilder?: (isFull: boolean, isClosed: boolean, maximumFreeCapacity: number, totalReservationCount?: number) => CalendarDaySettings): CalendarDay<CalendarDaySettings>[] => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = getDaysInMonth(month, year);

  // map events to days
  const days: CalendarDay<CalendarDaySettings>[] = [];

  for (let i = 0; i < daysInMonth; i++) {
    const day = new Date(year, month, i + 1);
    const eventsInDay = events.filter(event => isSameDate(event.start, day, 'day'));

    const totalFreeCapacity = getTotalCapacity(eventsInDay);
    const totalReservationCount = getTotalReservations(eventsInDay);
    const isFull = totalFreeCapacity === 0;
    const maximumFreeCapacity = getMaximumFreeCapacity(eventsInDay);
    const isClosed = eventsInDay.length === 0;

    const settings: CalendarDaySettings = calendarDaySettingBuilder ? calendarDaySettingBuilder(isFull, isClosed, maximumFreeCapacity, totalReservationCount) : {
      text: isClosed ? 'zavřeno' : isFull ? 'obsazeno' : `${maximumFreeCapacity} míst`,
      textFontSize: '1.25rem',
      backgroundColor: isClosed ? '#C4C4C4' : isFull ? 'rgba(221,3,86,0.6)' : 'rgba(221,3,86,0.2)',
      dayFontSize: '1rem',
      color: '#2D323E',
    };

    days.push({
      date: day,
      name: 'CalendarDayComponent',
      settings: settings
    });
  }
  return days;
}
