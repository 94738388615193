import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {URL_SETTINGS_TOKEN, UrlSettings} from "@echo-nx/shared/ng/feature/common";
import {switchMap} from "rxjs/operators";
import {SsrAuthService} from "@echo-nx/shared/ng/feature/ssr";

@Injectable()
export class MarlenkaSsrTokenInterceptor implements HttpInterceptor {

  constructor(public authService: SsrAuthService, @Inject(URL_SETTINGS_TOKEN) protected urlSettings: UrlSettings) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const {fullApiPath} = this.urlSettings;



    const ignoreUrls = this.urlSettings?.appendTokenIgnoreUrls ?? [
      `${fullApiPath}/ssrtoken`,
    ];

    const requestUrlWithoutQueryParams = request.url.split('?')[0]
    if (!request.url.startsWith(fullApiPath) || ignoreUrls.some(url => url === requestUrlWithoutQueryParams)) {
      return next.handle(request);
    }

    const token =  this.authService.getToken();
    const isTokenExpiredOrMissing = this.authService.isTokenExpired();
    // ssr token never expires, admin token does
    // if admin token expires, it will relog to ssr. next request admin sso will kick in again.
    if (isTokenExpiredOrMissing) {
      // let ssr relog
      return from(this.authService.login()).pipe(
        switchMap(({data}) => next.handle(this.addToken(request, data.token)))
      )
    } else {
      return next.handle(this.addToken(request, token));
    }

  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
