import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {IMDialogCommodity, IMTicketType, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-ticket-type-grid',
  templateUrl: './ticket-type-grid.component.html',
  styleUrls: ['./ticket-type-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketTypeGridComponent implements OnInit {
  @Input() ticketTypes!: IMTicketType<IMDialogCommodity>[];
  @Input() cellSize = '17.5rem';

  constructor(public reservationService: ReservationService, public mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
  }

  ngOnInit() {
    // set styles
    document.documentElement.style.setProperty('--cell-size', this.cellSize);
  }


  addTicket(ticketType: IMTicketType<IMDialogCommodity>) {
    this.reservationService.addTicket(ticketType);
  }

  removeTicket(ticketType: IMTicketType<IMDialogCommodity>) {
    this.reservationService.removeTicket(ticketType);
  }

}
