import gql from "graphql-tag";

export const deleteProduct = gql`
  mutation deleteProduct($input: [String!]!){
    response: deleteProducts(ids: $input){
      userMessage
      description
      error
    }
  }
`;
