import { Component, Input, ViewEncapsulation } from '@angular/core';
import {
  IMarlenkaOwner,
  MarlenkaRouteData,
} from '@echo-nx/marlenka/common';
import {IArticle, IMedium} from '@echo-nx/shared/common';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticlePreviewComponent {
  @Input()
  public article?: IArticle<IMarlenkaOwner,IMedium<IMarlenkaOwner>>;

  constructor(public mrdService: LanguageRouteDataService<MarlenkaRouteData>) {}
}
