import { CommonEntityTypes } from '@echo-nx/shared/common';

enum MEntityTypes {
  TicketTypes = 'TicketTypes',
  ProductTypes = 'ProductTypes',
  VoucherTypes = 'VoucherTypes',
  Products = 'Products',
  CakesExclusive = 'CakesExclusive',
  Awards = 'Awards',
  Reservations = 'Reservations',
  DialogCommodityType = 'DialogCommodityType',
  Allergens = 'Allergens',
  Navigation = 'MNavigation',
  Distribution = 'Distribution',
  Department = 'Department',
  PartnerStore = 'PartnerStore',
  Timeline = 'Timeline',
  PageSettings = 'PageSettings',
  Auth = 'Auth',
  Gallery = 'Gallery',
  Settings = 'Settings',
  EventLanguage = 'EventLanguage',
  EventTopic = 'EventTopic',
}

export type MarlenkaEntityTypesType = MEntityTypes | CommonEntityTypes;

export const MarlenkaEntityTypes = {
  ...MEntityTypes,
  ...CommonEntityTypes,
};
