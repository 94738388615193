import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrefixModule} from "../../prefix/prefix.module";
import {ButtonThemeRsdComponent} from "./button-theme-rsd.component";

@NgModule({
  declarations: [
    ButtonThemeRsdComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ButtonThemeRsdComponent
  ]

})
export class ButtonThemeRsdModule { }
