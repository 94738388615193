import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotoCardVariantCComponent} from "./photo-card-variant-c.component";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    PhotoCardVariantCComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    PhotoCardVariantCComponent
  ]
})
export class PhotoCardVariantCModule {
}
