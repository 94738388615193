<ng-container *ngIf="prominent; else bigboi">
  <button [class.fluid]="fluid === 'horizontal' || fluid === 'both'"
          [ngClass]="[bgColor | prefix:'bg',  textColor | prefix:'text', rounded ? 'rounded-lg' : '']"
          class="flex-shrink-0 relative overflow-hidden flex-row">
    <svg class="decoration absolute bottom-0 left-0 top-0 text-white" *ngIf="decorate" viewBox="0 0 80 80" fill="none"
         style="opacity: 0.1;">
      <rect x="22" y="20" width="25" height="25" rx="4" transform="rotate(-45 0 0)" fill="currentColor"/>
      <rect x="20" y="50" width="25" height="25" rx="4" transform="rotate(-45 0 0)" fill="currentColor"/>
    </svg>
    <div class="content flex w-full items-stretch">
      <div *ngIf="startIcon" [ngClass]="[startIcon.bgColor | prefix:'bg']" class="icon-wrapper">
        <i [ngClass]="[startIcon.color | prefix:'text']" class="fas {{startIcon.icon}}"></i>
      </div>
      <div class="flex flex-wrap-0 flex-shrink-0 flex-grow font-bold text-xl px-4 py-3 items-center"
           [ngClass]="[titleAlign]">{{title}}</div>
      <div *ngIf="endIcon" [ngClass]="[endIcon.bgColor | prefix:'bg']" class="icon-wrapper">
        <i [ngClass]="[endIcon.color | prefix:'text']" class="fas {{endIcon.icon}}"></i>
      </div>
    </div>
  </button>
</ng-container>
<ng-template #bigboi>
  <button
    [class.fluid]="fluid === 'horizontal' || fluid === 'both'"
    [ngClass]="[bgColor | prefix:'bg', rounded ? 'rounded-lg' : '']"
    class="bigboi flex-shrink-0 overflow-hidden">
    <svg *ngIf="decorate" class="absolute bottom-0 left-0 mb-8 text-white" viewBox="0 0 375 283" fill="none"
         style="transform: scale(1.5); opacity: 0.1;">
      <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="currentColor"/>
      <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="currentColor"/>
    </svg>
    <div class="big icon-wrapper" *ngIf="startIcon" [ngClass]="[startIcon.bgColor | prefix:'bg']">
      <i [ngClass]="[startIcon.color | prefix:'text', titleAlign]" class="big fas {{startIcon.icon}}"></i>
    </div>
    <div [ngClass]="[
      textColor | prefix:'text']"
         class="flex flex-grow align-center relative px-6 pb-4 pt-2 w-full justify-center">
      <div class="flex flex-col justify-center items-center mb-3">
        <span class="block font-semibold text-xl mb-2"><i *ngIf="endIcon" [ngClass]="[endIcon.color | prefix:'text']"
                                                          class="fas mr-2 {{endIcon.icon}}"></i>{{title}}</span>
        <span style="min-height:35px"  *ngIf="description">
          <span

            class="block rounded-full font-bold px-3 py-2 leading-none flex items-center"
            [ngClass]="[
              descriptionColorful.color | prefix:'text',
              descriptionColorful.bgColor | prefix:'bg']">{{description}}</span>
          </span>
      </div>
    </div>
  </button>
</ng-template>

