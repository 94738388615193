import {Pipe, PipeTransform} from '@angular/core';
import * as moment from "moment";
import {unitOfTime} from "moment";

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

  transform(date1: Date, date2: Date, unit: unitOfTime.DurationConstructor = 'minutes', absolute = false): number {
    const d1 = moment(date1);
    const result = Math.ceil(d1.diff(date2, unit, true));
    return absolute ? Math.abs(result) : result;
  }

}
