<ng-container *ngFor="let child of item?.children; let i = index">
  <div
    [class.w-full]="offset"
    class="px-4 py-2"
    *ngIf="child.isActive"
  >
    <!--[echoNxKioskRouteTo]="(child | activeChildrenCount) > 0 ? undefined : (child.screen?.slug ?? child.externalLink)"-->
    <div
      (mouseenter)="activeIndex = i"
      (mouseleave)="activeIndex = -1"
      [ngClass]="{'cursor-pointer': child.screen?.slug ?? child.externalLink}"
      class="relative font-display text-lg font-medium text-primary-500 hover:text-secondary-500 ">
      <!--Item content-->
      <div
        (click)="child.screen?.slug ?? child.externalLink ? itemClicked.next(child.screen?.slug ?? child.externalLink) : undefined"
        class="flex flex-1 items-center">
        <span class="flex flex-1">{{child.title}}
          <i *ngIf="child.externalLink" class="text-xs ml-1 fas fa-external-link-alt"></i>
        </span>
        <i *ngIf="(child | activeChildrenCount) > 0" [ngClass]="[offset ? 'fa-caret-right' : 'fa-caret-down']"
           class="ml-1 fas "></i>
      </div>
      <!--Item children -->
      <div
        *ngIf="(child | activeChildrenCount) > 0"
        [class.hidden]="activeIndex !== i"
        [ngClass]="[offset ? 'left-full top-0' : '']"
        class="z-50 flex flex-col w-56 space-y-2 absolute shadow-lg bg-white ring-1 ring-slate-200">
        <echo-nx-nav-dropdown-item (itemClicked)="itemClicked.next($event)" [item]="child"
                                   [offset]="true"></echo-nx-nav-dropdown-item>
      </div>
    </div>
  </div>
</ng-container>
