import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDetailVariantCComponent } from './item-detail-variant-c.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    ItemDetailVariantCComponent
  ],
    imports: [
        CommonModule,
        PrefixModule
    ],
  exports: [
    ItemDetailVariantCComponent
  ],
})
export class ItemDetailVariantCModule { }
