import {BaseWidgetSettings} from "./base-settings.type";

export type RectangleButtonSettings = {
  text: string;
  textColor?: string;

  chipText?: string;
  chipTextColor?: string;
  chipBackgroundColor?: string;

  icon: string;
  iconColor: string;
} & BaseWidgetSettings;
