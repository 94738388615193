import gql from 'graphql-tag';

export const readSelectedOwner = gql`
  query ReadSelectedOwner($ids: [String!]!) {
    response: readSelectedOwner(
      ids: $ids
    ) {
      _id
      name
      createdAt
    }
  }
`;
