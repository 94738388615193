import gql from 'graphql-tag';

export const readProductLanguageGroups = gql`
  query ReadProductLanguageGroups($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String,  $filter: String, $includeNotPublished: Boolean) {
    response: readProductsLanguageGroups(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        docs {
          _id
          name
          media{
            _id
            url
            thumbnailUrl
            name
            mime
            description
            type
          }
          description
          allergens {
            _id
            text
            number
          }
          weight
          boxSize {
            height
            width
            depth
          }
          ean
          package {
            amountOfCakes
            weight
            ean
            boxSize {
              height
              width
              depth
            }
          }
          pallet {
            height
            amountOfCakes
            amountOfLayers
            amountOfPackagesPerLayer
          }
          tempCondition {
            amountOfDays
            units
            temperatureFrom
            temperatureTo
          }
          owner {
            _id
          }
          categories {
            _id
            name
          }
          priority
          publishedAt
          attachments {
            _id
            name
            mime
            type
          }
          createdAt
          language
          languageGroupId
          stores {
            _id
            name
          }
        }
      }
    }
  }
`;
