import { MarlenkaPageIdentifier } from '../enums';
import {BaseRouteData} from "@echo-nx/shared/common";

export interface MarlenkaRouteData extends BaseRouteData {
  identifier?: MarlenkaPageIdentifier;
  hideFooter?: boolean;
  animation?: string;
  headerClass?: string[];
  footerClass?: MarlenkaFooterClass;
  footerRows?: number;
}

export interface MarlenkaFooterClass {
  wrapClass?: string[];
  firstRow?: string[];
  secondRow?: string[];
  thirdRow?: string[];
}
