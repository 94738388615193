import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';



// https://github.com/ngx-translate/core#write--use-your-own-loader

export class EchoNxValidator {
  static validatorRCFactory(): ValidatorFn {
    return (control): ValidationErrors |null => {
      if (!control.value) {
        return null;
      }

      if (control.value.includes('/')) {
        return {rodneCisloError: {hasBacklash: true}}; // 'Rodné číslo zadejte bez lomítka!'
      } else if (control.value.length !== 10) {
        return {rodneCisloError: {invalidLength: true}};
      } else if (control.value % 11 !== 0) {
        return {rodneCisloError: {invalidFormat: true}}; // 'Rodné číslo není správné!'
      } else {
        return null;
      }

    };
  }

  static validatorBirthDateFactory(): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (moment(control.value).isBefore(moment(new Date(1900, 1, 1))) || moment(control.value).isAfter(moment(new Date()).add('1 day'))) {
        return {birthdayError: true};
      } else {
        return null;
      }

    };
  }

  static validatorForChipEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        // eslint-disable-next-line no-control-regex
        const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        for (const email of control.value) {
          if (email?.match(regex) === null) {
            return {email: true};
          }
        }
      }
      return null;
    };
  }

  static validatorForChipPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /^[+]?[()/0-9. -]{9,}$/;
        for (const phoneNumber of control.value) {
          if (phoneNumber.match(regex) === null) {
            return {telephone: true};
          }
        }
      }
      return null;
    };
  }

  static validatorForPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /^[+]?[()/0-9. -]{9,}$/;
        if (control.value.match(regex) === null) {
          return {telephone: true};
        }
      }
      return null;
    };
  }

  static validatorForIntPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
        if (control.value.match(regex) === null) {
          return { telephoneInt: true};
        }
      }
      return null;
    };
  }


  static validatorForEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const regex = /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/
        if (control.value.match(regex) === null) {
          return {email: true};
        }
      }
      return null;
    };
  }
}
