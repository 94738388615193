import {InjectionToken} from "@angular/core";
import {IEntityService} from "@echo-nx/shared/common";
/*import {FuseNavigationItem} from "@fuse/components/navigation";*/

/**
 * Tento token slouží k DI servicy, která zajišťuje navigaci pro CMS.
 * Každé CMS má možnost vlastní implementace pro fetch navigace.
 * Aktuálně bude většina CMS fungovat stejně jako doposud a to tak, že bude navigace hardcoded.
 */
export const CMS_NAVIGATION_SERVICE_TOKEN = new InjectionToken<IEntityService/*<FuseNavigationItem>*/>('CMS_NAVIGATION_SERVICE_TOKEN');
