import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StepperComponent} from "./stepper/stepper.component";
import {StepLabelComponent} from "./stepper/step/step-label.component";
import {PaginatorComponent} from "./paginator/paginator.component";

@NgModule({
  imports: [CommonModule],
  declarations: [StepperComponent, StepLabelComponent, PaginatorComponent],
  exports: [StepperComponent, StepLabelComponent, PaginatorComponent]
})
export class SharedNgUiSmartModule {}
