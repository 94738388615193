<ng-container *ngIf="(pageSettings$ | async)?.texts as pageTexts">
    <!--  <marlenka-jumbotron
      [title]="product.category.name | multilang: mrdService.currentLanguage"

      [withDivider]="true"
      [topWave]="true"
      background="#FFF6E6"
      id="product-jumbotron"
    >-->
    <div *ngIf="product$ | async as product" [style.backgrounc]="'#FFF6E6'" class="product-wrapper">
        <div class="back">
            <a [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/products'">
                <img src="/assets/back_arrow.svg" alt="Všechny produkty"/>
              {{pageTexts.goBack}}
            </a>
        </div>
        <div class="product">
            <div class="left-col">
                <img [src]="product.media.url" [alt]="product.media.description" class="img-fluid"/>

                <div class="gallery" echoNxLightboxGalleryGroup #productGallery>
                  <echo-nx-medium-item
                    *ngFor="let attachment of product.attachments; let i = index"
                    [index]="i"
                    [medium]="attachment"></echo-nx-medium-item>
                    <!--<ng-container *ngFor="let attachment of product.attachments">
                        <echo-nx-lightbox-medium [medium]="attachment"></echo-nx-lightbox-medium>
                    </ng-container>-->
                </div>
            </div>

            <div class="right-col">
                <h2>{{ product.name  }}</h2>
                <marlenka-wysiwyg-view [html]="product.description"></marlenka-wysiwyg-view>
                <!--
                {{ product | json }}-->
                <div class="main-info" *ngIf="product.weight || product.boxSize || product.ean">
                    <table class="main-table">
                        <tr *ngIf="product.weight">
                            <td class="bold">{{ pageTexts.weight.title }}:</td>
                            <td>{{ product.weight }} {{pageTexts.units?.grams ?? 'g'}}</td>
                        </tr>
                        <tr *ngIf="product.boxSize">
                            <td class="bold">{{ pageTexts.boxDimensions.title }}:</td>
                            <td>{{ product.boxSize.height }} x {{ product.boxSize.width }} x {{ product.boxSize.depth }} {{pageTexts.units?.millimetres ?? 'mm'}}</td>
                        </tr>
                        <tr *ngIf="product.ean">
                            <td class="bold">{{ pageTexts.ean.title  }}:</td>
                            <td>{{ product.ean }}</td>
                        </tr>
                    </table>
                </div>
                <hr/>
                <div class="product-more" *ngIf="product.package || product.tempCondition?.length > 0 || product.pallet?.length > 0">
                    <div class="more" [class.show]="show">
                        <div class="group" *ngIf="product.package">
                            <h4>
                                {{ pageTexts.massPackaging.title }}
                            </h4>

                            <table class="group-table">
                                <tr>
                                    <td class="bold">{{ pageTexts.cakeCountOfMassPackaging.title  }}:</td>
                                    <td>{{ product.package.amountOfCakes }} {{pageTexts.units?.pieces ?? 'ks'}}</td>
                                </tr>
                                <tr>
                                    <td class="bold">{{ pageTexts.weightOfMassPackaging.title  }}:</td>
                                    <td>{{ product.package.weight }} {{pageTexts.units?.grams ?? 'g'}}</td>
                                </tr>
                                <tr>
                                    <td class="bold">{{ pageTexts.dimensionsOfMassPackaging.title  }}:</td>
                                    <td>{{ product.package.boxSize.height }}x{{ product.package.boxSize.width }}x{{ product.package.boxSize.depth }} {{pageTexts.units?.millimetres ?? 'mm'}}</td>
                                </tr>
                                <tr>
                                    <td class="bold">{{ pageTexts.eanOfMassPackaging.title }}:</td>
                                    <td>{{ product.package.ean }}</td>
                                </tr>
                            </table>
                        </div>

                        <div class="group three-cols" *ngIf="product.pallet?.length > 0">
                            <div class="row first-row">
                                <div class="col first-col">
                                    <h4 style="margin-bottom: 0;">
                                        {{ pageTexts.palletPackaging.title }}
                                    </h4>
                                </div>
                            </div>

                            <hr/>

                            <div class="pallet-table-wrap">
                                <table class="pallent-table">

                                    <tr>
                                        <td></td>
                                        <td class='numeric' *ngFor="let palletconf of product.pallet">
                                            <h5 style="margin-bottom: 0;">{{ palletconf.height }} {{pageTexts.units?.centimetres ?? 'cm'}}</h5>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="bold">
                                            {{ pageTexts.cakeCountOfPalletPackaging.title }}
                                        </td>
                                        <td class='numeric' *ngFor="let palletconf of product.pallet">
                                            {{ palletconf.amountOfCakes }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bold">
                                            {{ pageTexts.layerCountOfPalletPackaging.title }}
                                        </td>
                                        <td class='numeric' *ngFor="let palletconf of product.pallet">
                                            {{ palletconf.amountOfLayers }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bold">
                                            {{ pageTexts.massPackageLayerCountOfPalletPackaging.title }}
                                        </td>
                                        <td class='numeric' *ngFor="let palletconf of product.pallet">
                                            {{ palletconf.amountOfPackagesPerLayer }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="bold">
                                            {{ pageTexts.massPackageCountOfPalletPackaging.title }}
                                        </td>
                                        <td class='numeric' *ngFor="let palletconf of product.pallet">
                                            {{ palletconf.amountOfPackagesPerLayer * palletconf.amountOfLayers }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div *ngIf="product.tempCondition?.length > 0" class="group">
                            <h4>
                                {{ pageTexts.storage.title  }}
                            </h4>

                            <div class="row" *ngFor="let temp of product.tempCondition">
                                <div class="col one-row">
                                    <span>{{ temp.amountOfDays }} {{ temp.units  }}&nbsp; </span>
                                    <!-- {pri teplote od} {-15} {do} {10} -->
                                    {{ pageTexts.storageTemperatureFrom.title  }}
                                    {{ temp.temperatureFrom }}
                                    {{ pageTexts.storageTemperatureTo.title  }}
                                    {{ temp.temperatureTo }}℃
                                </div>
                            </div>
                        </div>
                        <hr style="margin-top: 1rem;"/>
                    </div>
                    <button *ngIf="product.package || product.tempCondition?.length > 0 || product.pallet?.length > 0" (click)="show = !show">
                      <span *ngIf="show">{{pageTexts.readLess }}</span>
                      <span *ngIf="!show">{{pageTexts.readMore }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--    </marlenka-jumbotron>-->
    <div class="aside" @aside (@aside.done)="animateList = true">
        <ng-container *ngIf="categoryProducts$ | async as allProducts">
            <div class="aside-items-wrap" [@listAnimation]="allProducts.length">

                <h4>
                    {{ allProducts[0].categories[0].name  }}
                    <!-- #TODO idk if this is the right usage, but it works 4 me :/ -->
                </h4>
                <div class="other-product" *ngFor="let otherProduct of allProducts">
                    <a [routerLink]="'../' + otherProduct.languageGroupId" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <figure>
                            <img [src]="otherProduct.media.thumbnailUrl || otherProduct.media.url" [alt]="otherProduct.media.description" class="img-fluid"/>
                        </figure>
                        <span class="title">{{ otherProduct.name }}</span>
                    </a>
                </div>

            </div>
        </ng-container>
    </div>
</ng-container>
