
// todo read from DB
export const getStepperTitleFor = (step: 'event' | 'tickets' | 'use-voucher' | 'products' | 'contact' | 'summary', lang: string): string => {
  //todo delete this - this is quick fix so we dont have to deal with en and de
  lang = 'cs';
  switch (step) {
    case "event":
      if (lang === 'en') {
        return 'Date';
      } else if (lang === 'de') {
        return 'Datum';
      } else {
        return 'Termín';
      }
    case "tickets":
      if (lang === 'en') {
        return 'Tickets';
      } else if (lang === 'de') {
        return 'Tickets';
      } else {
        return 'Vstupenky';
      }
    case "use-voucher":
      if (lang === 'en') {
        return 'Claim vouchers';
      } else if (lang === 'de') {
        return 'Gutschein einlösen';
      } else {
        return 'Uplatnit vouchery';
      }
    case "products":
      if (lang === 'en') {
        return 'Products';
      } else if (lang === 'de') {
        return 'Produkte';
      } else {
        return 'Produkty';
      }
    case "contact":
      if (lang === 'en') {
        return 'Contact';
      } else if (lang === 'de') {
        return 'Kontakt';
      } else {
        return 'Kontakt';
      }
    case "summary":
      if (lang === 'en') {
        return 'Summary';
      } else if (lang === 'de') {
        return 'Zusammenfassung';
      } else {
        return 'Souhrn';
      }
  }
}
