import {Component, Inject, Injector, Optional} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {IHiddenFieldSettings} from "./i-hidden-field-settings";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-hidden-field',
  templateUrl: './hidden-field.component.html',
  styleUrls: ['./hidden-field.component.scss']
})
export class HiddenFieldComponent extends BaseFormFieldComponent<IHiddenFieldSettings> {

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IHiddenFieldSettings>,formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
  }


}
