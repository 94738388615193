import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonThemeDComponent } from './button-theme-d.component';
import {PrefixModule} from "../../prefix/prefix.module";



@NgModule({
  declarations: [
    ButtonThemeDComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ButtonThemeDComponent
  ]
})
export class ButtonThemeDModule { }
