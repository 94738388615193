import {Component, ViewEncapsulation} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {MarlenkaRouteData} from '@echo-nx/marlenka/common';
import {ILanguage} from '@echo-nx/shared/common';
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {Observable, combineLatest} from "rxjs";
import {LanguageProviderService} from "@echo-nx/shared/ng/data-access";

@Component({
  selector: 'marlenka-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent {
  public selectedLang?: ILanguage;
  public languages$: Observable<ILanguage[]>;

  constructor(public mrdService: LanguageRouteDataService<MarlenkaRouteData>, private languageProviderService: LanguageProviderService) {
    this.languages$ = combineLatest([
      mrdService.currentLanguage$,
      this.languageProviderService.fetchAll().pipe(map(res => res.items))
    ]).pipe(
      map(([initialLanguage, languages]) => {
        const sortedLanguages = languages.sort((a, b) => {
          const nameA = a.code.toUpperCase(); // ignore upper and lowercase
          const nameB = b.code.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0
        });

        this.selectedLang = languages.find(l => l.code === initialLanguage);
        return sortedLanguages;
      })
    )
  }

  public onLanguageChosen(lang: ILanguage) {
    this.mrdService.currentLanguage = lang.code;
    this.selectedLang = lang;
  }
}
