import {IRsdLocation} from "./i-rsd-location";
import {IRsdKraj} from "./i-rsd-kraj";
import {IRsdOdkaz, IRsdVideo} from "./i-rsd-odkaz";
import {IRsdSoubory} from "./i-rsd-soubor";

export interface IRsdStavba {
  // mongo ID
  _id?: string;

  // RSD data
  BudeDokonceno: number;
  Soubory: IRsdSoubory;
  Stav: string;
  Odkazy: IRsdOdkaz[];
  Videogalerie: IRsdVideo[];
  Delka: number;
  LastUpdated: Date | string;
  Location: IRsdLocation;
  Nazev: string;
  IdStavby: number;
  EvidencniCislo: string;
  BudeZahajeno: number;
  Popis: string;
  Kraje: IRsdKraj[];
  Komunikace: string;
  SpravceStavby: string;
  KodStavby: string;
  DatumVytvoreniZaznamu: Date | string;
  Modernizace: boolean;
  DataOStavbe?: string;
  GPSLongitude: number;
  GPSLatitude: number;
}
