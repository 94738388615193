import {Injectable} from "@angular/core";
import {IComponentType} from "../types";
import {WidgetName} from "@echo-nx/shared/common";


@Injectable({
  providedIn: 'root'
})
export class DynamicComponentRegistryService {
  private _componentRegistry: Map<WidgetName, IComponentType> = new Map<WidgetName, IComponentType>();
  // todo lazy loading improvement: https://github.com/codethatstack/platform/tree/master/libs/portals/src/lib

  public add(name: WidgetName, component: IComponentType) {
    // console.log(`Adding ${name} to component registry`);
    this._componentRegistry.set(name, component);
  }

  public get(name: WidgetName) {
    return this._componentRegistry.get(name);
  }
}
