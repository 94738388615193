import {
  AfterViewInit,
  Component, EventEmitter, Input, OnChanges, Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CdkDropList,
  CdkDragEnter,
  CdkDropListGroup,
  moveItemInArray, CdkDragDrop, CdkDragStart,
} from '@angular/cdk/drag-drop';
import {SortableGridSettings} from "./sortableGridSettings";


@Component({
  selector: 'echo-nx-sortable-grid',
  templateUrl: './sortable-grid.component.html',
  styleUrls: ['./sortable-grid.component.scss'],
})
export class SortableGridComponent implements AfterViewInit, OnChanges {


  @ViewChild(CdkDropListGroup) listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder!: CdkDropList;

  @Input() items!: { _id: string }[];
  @Input() previewSettings: SortableGridSettings = {
    showIndex: true
  }

  @Output() itemsChanged: EventEmitter<{ _id: string }[]> = new EventEmitter();


  public innerItems!: { _id: string }[];


  public currentDraggingItemIdx?: number;

  public target?: CdkDropList;
  public targetIndex?: number;
  public source?: CdkDropList;
  public sourceIndex?: number;
  public activeContainer ?: any;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['items'].previousValue !== changes['items'].currentValue){
      this.innerItems = changes['items'].currentValue;
      console.log('inner',this.innerItems);
    }
  }

  ngAfterViewInit() {
    this.innerItems = this.items;

    const phElement = this.placeholder.element.nativeElement;
    if(phElement) {
      phElement.style.display = 'none';
    }
  }

  dragStarted(e: number){
    console.log('drag Started',e);
    this.currentDraggingItemIdx = e;
  }

  dropListDropped(e: CdkDragDrop<any>) {
    this.currentDraggingItemIdx = undefined;
    console.log('droppppped from',e.previousContainer.data?.index, ' to', e.container.data?.index, );

    if (e.previousContainer.data?.index !==e.container.data?.index) {
      console.log('new items', this.items);
      moveItemInArray(this.innerItems, e.previousContainer.data?.index, e.container.data?.index);
      console.log('heyooo');
      //this.itemsChanged.emit(this.innerItems);
    }
  }

  cdkDropListEntered(e: CdkDragEnter) {
    const targetIdx = e.container.data?.index;
    console.log('dragging from',this.currentDraggingItemIdx, ' to', targetIdx );
    //todo this is not working, this should be done by cdkDropListSortPredicate
    if(this.currentDraggingItemIdx < targetIdx){
      e.container.element.nativeElement.style.flexDirection='row-reverse';
    } else {
      e.container.element.nativeElement.style.flexDirection='row-reverse';
    }
  }

  removeEntity(entity: {_id: string}){
    this.innerItems = this.innerItems.filter(i => i._id !== entity._id);
    this.itemsChanged.emit(this.innerItems);
  }
}
