import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {BasePageSettingsService} from "@echo-nx/marlenka/ng/data-access";

@Injectable({
  providedIn: 'root'
})
export class PageSettingService extends BasePageSettingsService {

  constructor(apollo: Apollo) {
    super(apollo);
  }
}
