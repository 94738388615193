import gql from 'graphql-tag';

export const readSelectedRole = gql`
  query ReadSelectedRole($ids: [String!]!) {
    response: readSelectedRole(
      ids: $ids
    ) {
      _id
      owner{
        _id
        name
      }
      name
      description
      privileges{
        description
        entityType
        isAllowed
        name
        categories
      }
      createdAt
    }
  }
`;
