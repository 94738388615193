<div class="paginator">
    <button [disabled]="currentPage === 1" class="item prev-btn" aria-hidden="false" aria-label="Předchozí stránka" (click)="changePage(currentPage - 1)"><</button>
    <ng-container *ngIf="currentPage - 2 > 0">
        <button class="item" *ngIf="currentPage - 2 === 1; else backTemplate" (click)="changePage(1)">1</button>
    </ng-container>
    <button class="item" *ngIf="currentPage - 1 > 0" (click)="changePage(currentPage - 1)">{{ currentPage - 1 }}</button>
    <button disabled class="item" [style.color]="foregroundColor">{{ currentPage }}</button>
    <button class="item" *ngIf="currentPage + 1 <= totalPages" (click)="changePage(currentPage + 1)">{{ currentPage + 1 }}</button>
    <ng-container *ngIf="currentPage + 2 <= totalPages">
        <button class="item" *ngIf="currentPage + 2 === totalPages; else forwardTemplate" (click)="changePage(currentPage + 2)">{{ currentPage + 2 }}</button>
    </ng-container>
    <ng-template #forwardTemplate>
        <button disabled class="item">...</button>
        <button class="item" (click)="changePage(totalPages)" [style.color]="foregroundColor">{{ totalPages }}</button>
    </ng-template>
    <ng-template #backTemplate>
        <button class="item" (click)="changePage(1)" [style.color]="foregroundColor">1</button>
        <button disabled class="item" [style.color]="foregroundColor">...</button>
    </ng-template>
    <button [disabled]="currentPage === totalPages" class="item next-btn" aria-hidden="false" aria-label="Následující stránka" (click)="changePage(currentPage + 1)">></button>
</div>
<div class="info">({{ showingFrom }} - {{ showingTo }})/{{ totalItems }}</div>
