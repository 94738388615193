import {Widget} from "../widget.type";
import { BaseLayoutSettingsType } from './base-layout-settings.type';

export type OverlayLayoutSettings = {

} & BaseLayoutSettingsType;

export type OverlayWidgetsDefinition = {
  buttons?: Widget[];
  filters?: Widget[];
  overlay: Widget;
}
