import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActiveChildrenCountPipe} from "./active-children-count.pipe";



@NgModule({
  declarations: [ActiveChildrenCountPipe],
  imports: [
    CommonModule
  ],
  exports:[
    ActiveChildrenCountPipe
  ]
})
export class NavActiveChildrenCountModule { }
