import { IKioskOwner } from './kiosk-owner';
import {IKioskScreen} from "../structural";
import {IOwnedEntity} from "../i-owned-entity";

export interface IKioskTimelineScreen<S extends IKioskScreen | string = any> {
  screen: S;
  timeout: number;
  transitionName?: string;
}

export interface IKioskTimeline<O extends IKioskOwner | string = any, S extends IKioskScreen | string = any > extends IOwnedEntity<O> {
  name: string;
  screens: IKioskTimelineScreen<S>[];
}
