import { Component, Input } from '@angular/core';

@Component({
  selector: 'echo-nx-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss']
})
export class CollapsibleComponent {

  @Input()
  public isDark = true;

  @Input()
  public isOpen = true;

}
