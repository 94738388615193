import { Injectable, InjectionToken } from '@angular/core';

import {Apollo, MutationResult} from 'apollo-angular';
import {Observable, of} from 'rxjs';

import { map } from 'rxjs/operators';

import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
  ScreenTemplate
} from "@echo-nx/shared/common";
import {readAllScreenTemplates, readAllScreenTemplatesFull} from "../queries/screen-template";
import {mapGqlScreens} from "../utils";



@Injectable()
export class ScreenTemplateService implements IEntityService<ScreenTemplate>{

  constructor(protected apollo: Apollo) {}

  delete(ids: string[]): Observable<any> {
    throw new Error('Not implemented');
  }

  executeMutation(mutation: any, vars: any) {
    throw new Error('Not implemented');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<ScreenTemplate>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ScreenTemplate>>>({
      query: readAllScreenTemplates,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchAllFull(args?: FetchAllArgs): Observable<ScreenTemplate[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ScreenTemplate>>>({
      query: readAllScreenTemplatesFull,
      variables: args
    }).pipe(
      map(result => result.data.response.items),
      map(screenTemplates => {
        // thanks to graphql we need to remap this when Widget is not an array but object with keys specifying their positions
        return screenTemplates?.map(screenTemplate => mapGqlScreens(screenTemplate)) ?? []
      })

    );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    return of([]);
  }

  fetchSelected(ids: string[]): Observable<ScreenTemplate[]> {
    throw new Error('Not implemented');
  }

  fetchSingle(id: string): Observable<ScreenTemplate> {
    throw new Error('Not implemented');
  }

  getType(): string {
    return "";
  }

  save(entities: ScreenTemplate[]): Observable<MutationResult<IPKApolloResult<ScreenTemplate[]>>> {
    throw new Error('Not implemented');
  }


}

export const SCREEN_TEMPLATE_SERVICE_TOKEN = new InjectionToken<IEntityService<ScreenTemplate>>('SCREEN_TEMPLATE_SERVICE_TOKEN');

