<div class="flex flex-row justify-center items-center p-2 py-4 mx-8" [ngClass]="[primaryColor | prefix:'bg']">
  <div class="flex items-center justify-center text-3xl pr-2">
    <i [ngClass]="[startIcon?.icon, startIcon?.color | prefix:'text']"></i>
  </div>
  <span class="text-2xl font-display pl-2 text-center" [ngClass]="[accentColor | prefix:'text']" >
    {{title}}
  </span>
</div>

<!-- VALUE + SUFFIX -->
<div class="flex flex-col flex-1 justify-center py-4 px-8 col-span-3">
  <div class="flex space-x-2 justify-center">
    <span class="text-5xl font-bold h-full">{{value}}</span>
    <span class="text-xl font-light h-full" style="vertical-align: super">{{suffix}}</span>
  </div>
  <span *ngIf="description" class="text-xs mt-2 text-center text-bold">{{description}}</span>
</div>

