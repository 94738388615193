export * from './marlenka-page-settings';
export * from './home-page-settings';
export * from './products-page-settings';
export * from './distribution-page-settings';
export * from './excursion-page-settings';
export * from './contact-page-settings';
export * from './career-page-settings';
export * from './articles-page-settings';
export * from './common-page-settings';
export * from './awards-page-settings';
export * from './product-detail-page-settings';
export * from './create-page-settings';
export * from './create-reservation-setings';
export * from './cakes-exclusive-detail-page-settings';
export * from './about-page-settings';
export * from './cakes-exclusive-page-settings';
