import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateAddPipe} from "./date-add.pipe";
import {DateDiffPipe} from "./date-diff.pipe";
import {DaysOfMonthPipe} from "./days-of-month.pipe";
import {MonthNamePipe} from "./month-name.pipe";




@NgModule({
  declarations: [
    DateAddPipe,
    DateDiffPipe,
    DaysOfMonthPipe,
    MonthNamePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateDiffPipe,
    DateAddPipe,
    DaysOfMonthPipe,
    MonthNamePipe,
  ]
})
export class DateModule { }
