<table
  [class.rounded]="rounded"
  [class.with-divider]="rounded"
>
  <!-- HEADER -->
  <thead
    [class.capitalize]="capitalizeHeader"
    [style.background-color]="headerBackgroundColor"
    [style.color]="headerColor"
  >
  <tr>
    <th *ngFor="let field of fields">{{field.label}}</th>
  </tr>
  </thead>

  <tbody
    [class.capitalize]="capitalizeBody"
    [style.background-color]="bodyBackgroundColor"
    [style.color]="bodyColor"
  >
  <!-- ITEM ROWS -->
  <tr
    *ngFor="let item of items; last as isLast"
    (click)="selectable ? setSelected(item) : null"
    [class.selectable]="selectable"
    [style.background-color]="item?._id === selectedItem?._id ? selectedBackgroundColor : item.backgroundColor"
    [style.color]="item?._id === selectedItem?._id ? selectedColor : ''"
  >
    <!-- ITEM CELLS -->
    <td *ngFor="let field of fields">
      <!-- if item contains the key, render it -->
      <ng-container *ngIf="item | hasKey:field.key; else noKey">
        <!-- display raw value or use specified formatter -->
        {{field.formatter ? field.formatter(item) : item[field.key]}}
      </ng-container>

      <!-- key not found, render the template instead -->
      <ng-template #noKey [ngTemplateOutlet]="getTemplate(field.key)" [ngTemplateOutletContext]="{$implicit: item}">
      </ng-template>


    </td>
  </tr>
  </tbody>
</table>
<p *ngIf="!items || items.length === 0">
  {{emptyMessage}}
</p>
