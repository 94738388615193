<ng-container *ngIf="pageSettings$ | async as pageSettings">
  <marlenka-jumbotron
    [title]="
      pageSettings.texts.jumbotron.title
    "
    [subtitle]="
      pageSettings.texts.jumbotron.subtitle

    "
    [withDivider]="true"
    [topWave]="true"
    background="#FFF6E6"
    id="products"
  >
    <div class="wrapper">
      <div class="categories container medium">
        <a
          class="category"
          *ngFor="let category of categories$ | async"
          [routerLink]="category.languageGroupId"
        >
          <figure>
            <div class="img-wrap">
              <img
                [src]="category.featuredImage.url"
                [alt]="category.featuredImage.description"
              />
            </div>
          </figure>
          <h2>{{ category.name }}</h2>
        </a>
      </div>
    </div>
  </marlenka-jumbotron>
</ng-container>
