import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {ProductTypeService, ReservationService} from "@echo-nx/marlenka/ng/data-access";
import {BaseLoadingService} from "@echo-nx/shared/ng/feature/loading";
import {PRODUCT_STEP_COMPONENT_INPUT, ProductStepComponentInput} from "../../../utils";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {createCustomSnackbar} from "@echo-nx/shared/ng/feature/snackbar";
import {Observable, Subject, tap} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  IMarlenkaOwner,
  IMDialogCommodity,
  IMProductType,
  IMTicketType,
  MarlenkaRouteData
} from "@echo-nx/marlenka/common";
import {ICategory} from "@echo-nx/shared/common";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-products-step',
  templateUrl: './products-step.component.html',
  styleUrls: ['./products-step.component.scss']
})
export class ProductsStepComponent implements OnInit, OnDestroy {

  // public readonly loremText = faker.lorem.sentences(8);

  productTypes$!: Observable<IMProductType<IMDialogCommodity>[]>;

  isDestroyed$: Subject<void> = new Subject();

  constructor(
    private mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    public reservationService: ReservationService,
    private loadingService: BaseLoadingService,
    public productService: ProductTypeService,
    private matSnackBar: MatSnackBar,
    @Optional()
    @Inject(PRODUCT_STEP_COMPONENT_INPUT)
    public data?: ProductStepComponentInput
    ) { }

  async ngOnInit() {


    this.productTypes$ = this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      tap(() => this.loadingService.startLoading()),
      switchMap((lang) =>
        this.productService.fetchAll({filter: JSON.stringify({language: lang})})
      ),
      map(res => res.items as IMProductType<IMDialogCommodity>[]),
      tap(()=> this.loadingService.stopLoadingAll())
    );

    // fetch ticket type
   /* if (this.productService.productTypes.length === 0) {
      const loadingId = this.loadingService.startLoading();
      this.productService.productTypes = await this.productService.fetchAll().toPromise().then(res => res.items);
      this.loadingService.stopLoading(loadingId);
    }*/

    this.reservationService.onNextFailed$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      createCustomSnackbar('Musíte koupit alespoň jeden produkt nebo vstupenku.', this.matSnackBar);
    });

  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

}
