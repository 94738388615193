import {Component, Inject, Injector, Input, OnInit, Optional} from '@angular/core';
import {BaseFormFieldComponent} from "../base-form-field.component";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {IAutocompleteFieldSettings} from "./i-autocomplete-field.settings";
import {Observable, of} from "rxjs";
import {map, startWith, withLatestFrom} from "rxjs/operators";
import {FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'echo-nx-autocomplete-field',
  templateUrl: './autocomplete-field.component.html',
  styleUrls: ['./autocomplete-field.component.scss']
})
export class AutocompleteFieldComponent extends BaseFormFieldComponent<IAutocompleteFieldSettings> implements OnInit {

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IAutocompleteFieldSettings>,formGroupDirective: FormGroupDirective, injector: Injector) {
    super(def,formGroupDirective, injector);
  }

  public filteredData$?: Observable<any>;

  public labelKey!: string;

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.labelKey = this.getLabelKey();
    const {formControlName} = this.settings;

    // todo Implement data from Service via token similar to SelectField
    this.filteredData$ = this.formGroup?.get(formControlName)?.valueChanges
      .pipe(
        startWith(''), // to trigger search
        withLatestFrom(this.data ?? of([])),
        map(([searchValue, data]) => typeof searchValue === 'string' ? [searchValue, data] : [searchValue[this.labelKey], data]),
        map(([label, data]) => label ? this._filter(label, data) : data.slice()),
      );
  }


  private _filter(label: string, data: any[]) {
    const filterValue = label.toLowerCase();

    return data.filter(item => {
      return item[this.labelKey].toLowerCase().includes(filterValue)
    });
  }

  private getLabelKey() {
    return this.settings.labelKey ?? 'name';
  }

}
