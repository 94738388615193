/**
 * @param min Integer. Inclusive. Lower bounds.
 * @param max Integer. Inclusive. Upper bounds.
 * @returns Random integer in specified range.
 */
export const getRandomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 *
 * @param min Number. Inclusive. Lower bounds.
 * @param max Number. Exclusive. Upper Bounds.
 * @returns Random arbitrary number in specified range.
 */
export const getRandomNumber = (min: number, max: number): number => Math.random() * (max - min) + min;

/**
 *
 * @param num Number to round.
 * @param decimals How many decimal places, default is 2.
 * @returns Rounded number to specified decimal places.
 */
export const roundToDecimals = (num: number, decimals = 2) => {
  const p = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * p) / p;
}


export const generateUniqueCode = (length = 8) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  let code = '';
  for (let i = 0; i < length; i++) {
    const randomIdx = Math.floor(Math.random() * letters.length);
    code += letters.charAt(randomIdx);
  }
  return code;
};

export function getEnumKeyByEnumValue(myEnum: Record<string, unknown>, enumValue: unknown) {
  const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

export const concatFileNames = (files: FileList, separator = '; ') => {
  const names = [];
  for (let i = 0; i < files.length; i++) {
    names.push(files.item(i)?.name);
  }

  return names.join(separator);
};

export const isValueICO = (val: string) => val?.length === 8;
export const isValueBirthNumber = (val: string) => val?.length === 11;

export const formatFileSize = (bytes: number, decimals = 2 ): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const getRandomColor = () => Math.floor(Math.random()*16777215).toString(16);

export const getRandomColorClass = () => {
  const pool: string[] = [
    'yellow',
    'red',
    'green',
    'blue',
    'indigo',
    'purple',
    'pink',
    'primary',
    'secondary',
    'accent',
    'gray'
  ]

  return `${pool[getRandomInt(0, pool.length - 1)]}-500`
}

export const getRandomIcon = (): string => {
  const pool: string[] = [
    `calendar-alt`,
    `clipboard-list`,
    `folder`,
    `newspaper`,
    `paperclip`,
    `photo-video`,
    `cloud`,
    `file-alt`,
    `landmark`,
    `map`,
    `book-open`,
  ]

  return `fas fa-${pool[getRandomInt(0, pool.length - 1)]}`
}


/**
 * credit goes to https://stackoverflow.com/a/48218209/1386360
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const mergeDeep = (...objects) =>{
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}
