import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  addVoucherType,
  IMarlenkaOwner,
  IMVoucherType,
  MarlenkaEntityTypes,
  readAllVoucherTypes,
  readSelectedVoucherTypes
} from "@echo-nx/marlenka/common";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";

@Injectable()
export class BaseVoucherTypeService implements IEntityService<IMVoucherType>{

  constructor(private apollo: Apollo,) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMVoucherType>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMVoucherType>>>({
      query: readAllVoucherTypes,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[], includeDeleted = false): Observable<IMVoucherType[]> {
    return this.apollo.query<IPKApolloResult<IMVoucherType[]>>({
      query: readSelectedVoucherTypes,
      variables: {
        ids: ids,
        includeDeleted,
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string, includeDeleted = false): Observable<IMVoucherType> {
    return this.apollo.query<IPKApolloResult<IMVoucherType[]>>({
      query: readSelectedVoucherTypes,
      variables: {
        ids: [id],
        includeDeleted,
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(voucherTypes: IMVoucherType[]): Observable<MutationResult<IPKApolloResult<IMVoucherType[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMVoucherType[]>>({
      mutation: addVoucherType,
      variables: {
        input: voucherTypes
      }
    })

    /*return this.apollo.mutate<IPKApolloResult<IMReservationVoucher[]>>({
      mutation: addReservation,
      variables: {input: reservations}
    });*/
  }

  executeMutation<T extends IMVoucherType[] | IMVoucherType>(mutation: any, vars: any) {
    return this.apollo.mutate<IPKApolloResult<T>>({
      mutation: mutation,
      variables: vars
    });
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.VoucherTypes;
  }

}

export const MVOUCHER_TYPE_SERVICE_TOKEN = new InjectionToken<IEntityService<IMVoucherType>>('MVOUCHER_TYPE_SERVICE_TOKEN');
