import {Directive, Injector, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {EntityFormService} from "@echo-nx/shared/ng/feature/entity-management";


@Directive({
  selector: '[echoNxGroupComponentTree]'
})
export class GroupComponentTreeDirective implements OnInit {

  @Input()
  private entityFormService!: EntityFormService;

  @Input()
  public groupControlName!: string;

  public componentTree: Record<string, any> = {};

  constructor(@Optional() @SkipSelf() private gctDirective: GroupComponentTreeDirective) {

  }

  ngOnInit() {
    if (!this.gctDirective){
      this.entityFormService.componentTree[this.groupControlName] = {};
      this.componentTree = this.entityFormService.componentTree[this.groupControlName];
    } else {
      this.gctDirective.componentTree[this.groupControlName] = {};
      this.componentTree = this.gctDirective.componentTree[this.groupControlName];
    }
  }

  public addComponent(name: string, portal: any) {
    if (this.componentTree[name]) {
      return;
    }
    this.componentTree[name] = portal;
  }
}
