import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IMarlenkaOwner} from "@echo-nx/marlenka/common";
import {IOwnedContact} from "@echo-nx/shared/common";
import {BaseContactsService} from "@echo-nx/marlenka/ng/data-access";


@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseContactsService {

  constructor(apollo: Apollo) {
    super(apollo);
  }


  fetchMainContact(): Observable<IOwnedContact<IMarlenkaOwner>> {
    const args = {
      filter: JSON.stringify({isMain: true})
    };
    return this.fetchAll(args).pipe(
      map(res => res.items[0])
    );
  }

}
