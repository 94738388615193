import gql from 'graphql-tag';

export const readAllThemesForSynchronizer = gql`
  query readAllThemes($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllTheme(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        name
        createdAt
        owner{
          _id
          name
        }
        variables{
        epxColorPrimary50
        epxColorPrimary100
        epxColorPrimary200
        epxColorPrimary300
        epxColorPrimary400
        epxColorPrimary500
        epxColorPrimary600
        epxColorPrimary700
        epxColorPrimary800
        epxColorPrimary900

        epxColorAccent50
        epxColorAccent100
        epxColorAccent200
        epxColorAccent300
        epxColorAccent400
        epxColorAccent500
        epxColorAccent600
        epxColorAccent700
        epxColorAccent800
        epxColorAccent900

        epxColorSecondary50
        epxColorSecondary100
        epxColorSecondary200
        epxColorSecondary300
        epxColorSecondary400
        epxColorSecondary500
        epxColorSecondary600
        epxColorSecondary700
        epxColorSecondary800
        epxColorSecondary900

        epxFontFamilyBody
        epxFontFamilyDisplay
        epxFontFamilyExtra

        epxTextBase
        epxTextXs
        epxTextSm
        epxTextLg
        epxTextXl
          epxText2Xl
          epxText4Xl
        epxText3Xl
          epxText8Xl
      }

      }
    }
  }
`;
