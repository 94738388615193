import {IEpxFluidable} from "./i-base-attributes";


export interface IBaseDatePickerData {
  placeholder: string;
}

export interface IBaseDatePickerDesign extends IEpxFluidable {

}


export interface IBaseCalendarDesign extends IEpxFluidable {
  bgColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  activeColor?: string;
}


