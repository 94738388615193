import { Pipe, PipeTransform } from '@angular/core';
import {slugify} from "@echo-nx/shared/common";

@Pipe({
  name: 'DetailUrl',
  pure: false
})
export class DetailUrlPipe implements PipeTransform {
  transform(value: string, entity?: string, category?: string): any {
    if(value !== null) {
      let returnString = '/';
      if (entity) {
        returnString += slugify(entity) + '/';
      }
      if (category) {
        returnString += slugify(category) + '/';
      }
      return returnString + slugify(value);
    }
  }
}
