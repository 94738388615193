import {Injectable, OnDestroy} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {IEntityDefinition} from "../../interfaces";
import {IBaseFormFieldSettings, IGroupFieldSettings, IRepeaterFieldSettings} from "../form-fields";
import {buildEntityForm} from "../../utils/form-utils";
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Injectable()
export class EntityFormService implements OnDestroy {
  protected _isDestroyed$ = new Subject<boolean>();

  // for the early birds
  public valueReplay$ = new ReplaySubject(1);
  public onValidation$ = new ReplaySubject<null>(1);

  public form: UntypedFormGroup = new UntypedFormGroup({});
  public entityDefinitions?: IEntityDefinition[];

  //
  public componentTree: Record<string, any> = {};

  get hasControls() {
    return Object.keys(this.form.controls).length > 0;
  }

  get isConcept() {
    const hasFormPublishedAtField = this.entityDefinitions?.some(definition => definition.settings.formControlName === 'publishedAt');
    const isFormNotPublished = !this.form?.get('publishedAt')?.value;
    return hasFormPublishedAtField && isFormNotPublished
  }

  public initEntityForm(entityDefinitions: IEntityDefinition[], entity?: Record<string, any>) {
    buildEntityForm(entityDefinitions, entity, this.form);
    this.entityDefinitions = entityDefinitions;

    this.form.valueChanges
      .pipe(takeUntil(this._isDestroyed$))
      .subscribe(this.valueReplay$)
  }

  public validateForm(): boolean | undefined {
    this.form.updateValueAndValidity();
    this.onValidation$.next(null);
    return this.form.valid || this.isConcept;
  }

  public getFormData(): Record<string, any> {
    return this.transformDataForOutput(this.entityDefinitions ?? [], this.form?.value);
  }

  private transformDataForOutput(entityDefinitions: IEntityDefinition[], formValue: Record<string, any>) {
    // console.log("TRANSFORM FOR OUTPUT CALLED", entityDefinitions, formValue)
    const formValueCopy = {...formValue};
    for (const entityDefinition of entityDefinitions) {
      const {name, settings} = entityDefinition;
      const {formControlName, transformFn} = settings as IBaseFormFieldSettings;

      let childrenEntityDefinition: IEntityDefinition[];
      if (name === "PkInputGroupFieldComponent") {
        childrenEntityDefinition = (settings as IGroupFieldSettings).groupDefinition;
        formValueCopy[formControlName] = this.transformDataForOutput(childrenEntityDefinition, formValueCopy[formControlName]);
      } else if (name === "PkInputRepeaterFieldComponent") {
        childrenEntityDefinition = (settings as IRepeaterFieldSettings).rowDefinition;
        const repeaterValue = (formValueCopy[formControlName] as any[]);
        formValueCopy[formControlName] = repeaterValue.map(formGroupValue => this.transformDataForOutput(childrenEntityDefinition, formGroupValue));
      }

      formValueCopy[formControlName] = transformFn ? transformFn(formValueCopy[formControlName]) : formValueCopy[formControlName];
    }

    return formValueCopy;
  }

  ngOnDestroy(): void {
    this._isDestroyed$.next(true);
    this._isDestroyed$.complete();
  }
}
