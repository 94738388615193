import gql from 'graphql-tag';

export const readSelectedPageSettings = gql`
  query ReadSelectedPageSettings($ids: [String!]!) {
    response: readSelectedPageSettings(
      ids: $ids
    ) {
      _id
      identifier
      name
      icon
      title
    }
  }
`;
