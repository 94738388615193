import {CommonPageTextItem} from "./marlenka-page-settings";

export interface AboutpageTexts {
  jumbotron: CommonPageTextItem;
  history: CommonPageTextItem;
  achievements: CommonPageTextItem,
  certificates: CommonPageTextItem;
  videos: CommonPageTextItem;
}


