import {InjectionToken} from "@angular/core";

export interface CookieConsentConfig {
  gdprUrl?: string;
  // which component to used for the consent dialog. must be registered in DynamicComponentRegistry
  dynamicComponentKey?: string;

  cookieName?: string;
  expiryDays?: number;
  domain?: string,
  secure?: boolean,
  sameSite?: 'Lax' | 'None' | 'Strict';
}

export const COOKIE_CONSENT_CONFIG = new InjectionToken<CookieConsentConfig>('COOKIE_CONSENT_CONFIG');
