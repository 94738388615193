<div class="flex flex-1" [formGroup]="formGroup">
  <mat-checkbox
    class="my-2"
    [color]="'primary'"
    [formControlName]="settings.formControlName"
    [required]="settings.povinnost"
    (click)="formControl.patchValue(!formControl.value)"
  >
    {{settings.nazev}}
  </mat-checkbox>
</div>
