import { IOwnedEntity } from './i-owned-entity';
import { IBaseOwner } from './i-base-owner';
import { MongoID } from './base-document-types';
import { IMedium } from './i-medium';

export interface IVTScene<O extends IBaseOwner | string = any, M extends IMedium<any> | string = any> extends IOwnedEntity<O>{
  name?: string;
  prefix?: string;
  description?: string;
  points?: IVTPoint[];
  faceSize?: number;
  initialPosition?: IVTPosition;
}

export interface IVTPoint<S extends IVTScene | string = any> extends MongoID {
  icon?: string;
  location: IVTPosition;
  title?: string;
  description?: string;
  content?: string; // wysiswyg
  scene?: S;
  orientation?: IVTPosition;
}

export interface IVTPosition {
  pitch: number,
  yaw: number,
  fov?: number
}
