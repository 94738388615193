import gql from 'graphql-tag';

export const readAllWeatherDataForSynchronizing = gql`
  query ReadAllWeatherData($filter: String){
    response: readWeatherSensorDataForSynchronizer(
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        stationName
        stationLocationName
        owner{
          _id
          name
        }

        temperature
        windSpeed
        pressure
        precipitation
      }
    }
  }
`
