import {Component} from '@angular/core';
import {BaseItemCardComponent} from "../base-item-card.component";


@Component({
  selector: 'echo-nx-item-card-theme-rsd',
  templateUrl: './item-card-theme-rsd.component.html',
  styleUrls: ['./item-card-theme-rsd.component.scss']
})
export class ItemCardThemeRsdComponent extends BaseItemCardComponent{

}
