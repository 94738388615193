<mat-form-field appearance="outline" fxFlex [formGroup]="formGroup">
  <mat-label>{{ settings.nazev }}</mat-label>
  <mat-select
    [compareWith]="comparator"
    [formControlName]="settings.formControlName"
    [required]="settings.povinnost"
    [multiple]="settings.isMultiple">
    <mat-option
      *ngFor="let item of data | async"
      [value]="item">
      {{ item['displayValue'] }}
    </mat-option>
  </mat-select>
  <button *ngIf="hasValue" mat-icon-button matSuffix (click)="clearSelected($event)">
    <mat-icon>close</mat-icon>
  </button>

  <!-- ERROR -->
  <mat-error *ngIf="firstErrorKey$ | async as firstErrorKey" >
    {{firstErrorKey.key | translate: firstErrorKey.params}}
  </mat-error>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
  </mat-icon>
</mat-form-field>
