import gql from 'graphql-tag';

export const readAllEventsForSynchronizing = gql`
  query ReadAllEventsForSynchronizing($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeDeleted: Boolean = true){
    response: readAllEvent(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeDeleted: $includeDeleted
    ){
      total
      page
      take
      hasMore
      items{
        _id
        title
        slug
        summary
        content
        categories {
          _id
          name
          color
          icon
        }
        location{
          description
          gps{
            lat
            lng
          }
        }
        owner{
          _id
          name
        }
        featuredImage{
          _id
          url
          thumbnailUrl
          type
        }
        attachments{
          _id
          type
          mime
          url
          thumbnailUrl
          name
          description
          createdAt
        }
        createdAt
        deletedAt
        startingAt
        endingAt
        publishedAt
        unpublishedAt
      }
    }
  }
`
