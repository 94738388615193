import {gql} from 'apollo-angular';

export const addScreen = gql`
  mutation addScreen($input: [ScreenInputObject!]!){
    response: addScreen(inputScreen: $input){
      _id
      slug
    }
  }
`;
