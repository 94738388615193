import {Component, Inject, Injector, OnInit, Optional, ViewChild} from '@angular/core';
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";
import {IEntityDefinition} from "../../../interfaces";
import {IGroupFieldSettings} from "./i-group-field.settings";
import {BaseFormFieldComponent} from "../base-form-field.component";
import {UntypedFormGroup, FormGroupDirective} from "@angular/forms";
import {CdkPortalOutletAttachedRef} from "@angular/cdk/portal";
import {GroupComponentTreeDirective} from "./group-component-tree.directive";

@Component({
  selector: 'echo-nx-group-field',
  templateUrl: './group-field.component.html',
  styleUrls: ['./group-field.component.scss']
})
export class GroupFieldComponent extends BaseFormFieldComponent<IGroupFieldSettings> implements OnInit {
  @ViewChild(GroupComponentTreeDirective, {read: GroupComponentTreeDirective})
  public groupComponentTreeDirective!: GroupComponentTreeDirective;

  public expanded = true;
  public color?: string;

  public childFormGroup!: UntypedFormGroup;


  private componentTree: Record<string, any> = {};

  constructor(@Optional() @Inject(DYNAMIC_COMPONENT_INPUT) def: IEntityDefinition<IGroupFieldSettings>,
              formGroupDirective: FormGroupDirective,injector: Injector) {
    super(def,formGroupDirective, injector);
    this.childFormGroup = this.formGroup.get(this.settings.formControlName) as UntypedFormGroup;
    this.expanded = this.settings.defaultExpand ?? true;
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (this.settings.color) {
      this.color = this.settings.color;
    }

    for (const [name, portal] of Object.entries(this.componentTree)){
      this.groupComponentTreeDirective.addComponent(name, portal);
    }
  }


  public onPortalAttached(formControlName: string, portal: CdkPortalOutletAttachedRef){
    this.componentTree[formControlName] = portal;
  }

  public toggleDisabled() {
    this.disabled = !this.disabled;
    const {groupDefinition} = this.settings;
    for (const field of groupDefinition) {
      const {formControlName} = field.settings;
      const control = this.formControl?.get(formControlName);
      this.disabled ? control?.disable() : control?.enable();
    }
  }
}
