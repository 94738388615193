<ng-container *ngIf="(pageSettings$ | async)?.texts as pageTexts">
    <marlenka-jumbotron
        [title]="pageTexts.jumbotron.title"
        background="#FFF6E6"
        [topWave]="true"
        [dividerWave]="true"
        id="contact-jumbotron"
    >
        <div class="departments pb-4 container small">
            <div class="wrapper">
                <ng-container *ngFor="let department of departments$ | async">
                    <div class="department">
                        <div class="title">
                            <h3>
                                {{ department.name }}
                            </h3>
                        </div>

                        <h4 *ngIf="department.openingHours.length">{{pageTexts.workingHours}}: {{ department.openingHours }}</h4>

                        <h4 *ngIf="department.description">
                            {{ department.description }}
                        </h4>
                        <ng-container *ngIf="department.contact as contacts">
                            <marlenka-contact-detail *ngFor="let contact of contacts | sort: 'priority'" [contact]="contact"></marlenka-contact-detail>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <figure class="contact-img container small">
            <img class="img-responsive pt-4 pb-4" src="https://i.imgur.com/IxbRmob.jpg" alt="Marlenka" />
        </figure>
    </marlenka-jumbotron>
</ng-container>
