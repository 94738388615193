<ng-container *ngIf="buttonsText$ | async as buttonsText">
  <ng-container *ngIf="pageSettings$ | async as pageSettings">
    <!-- MAIN BANNER -->
    <marlenka-menu-grid-banner
      *ngIf="menuBanner"
      [mainItem]="menuBanner.mainItem"
      [mainSideItems]="menuBanner.mainSideItems"
      [bottomItems]="menuBanner.bottomItems"
      [topWave]="false"
    ></marlenka-menu-grid-banner>

    <!-- ARTICLES -->
    <div class="container medium">
      <marlenka-jumbotron
        [title]="pageSettings.texts.articles.title"
        [subtitle]="pageSettings.texts.articles.subtitle"
        [withDivider]="true"
        id="news"
      >
        <div class="article-grid">
          <marlenka-article-preview
            *ngFor="let article of recentNewsArticles$ | async"
            [article]="article"
          ></marlenka-article-preview>
        </div>

        <a
          mat-stroked-button
          class="button primary"
          [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/home/articles'"
          style="margin: 2rem auto;"
        >
          <!--Archiv článků-->
          {{buttonsText.archiveArticles}}
        </a>
      </marlenka-jumbotron>
    </div>


    <!-- REVIEWS -->
    <marlenka-jumbotron
      [title]="pageSettings.texts.reviews?.title"
      [subtitle]="pageSettings.texts.reviews?.subtitle"
      [withDivider]="true"
      background="#FFF6E6"
      id="reviews"
    >
      <div class="container medium">
        <div class="article-grid">
          <marlenka-article-preview
            *ngFor="let article of reviewsArticles$ | async"
            [article]="article"
          ></marlenka-article-preview>
        </div>
      </div>
      <a
        mat-stroked-button
        class="button primary"
        [routerLink]="'/' + mrdService.currentLanguage.toLowerCase() + '/home/reviews'"
        style="margin: 2rem auto;"
      >
        <!--Archiv článků-->
        {{buttonsText.archiveArticles}}
      </a>

    </marlenka-jumbotron>

    <!-- SOCIALS -->
    <marlenka-jumbotron
      *ngIf="instagramPhotos$ | async as instagramMediaData"
      [title]="pageSettings.texts.social.title"
      [subtitle]="pageSettings.texts.social.subtitle"
      [withDivider]="true"
      id="instagram"
    >
      <div class="instagram-grid container medium">
        <a
          *ngFor="let medium of instagramMediaData"
          class="grid-image"
          [href]="medium.permalink"
          target="_blank"
        >
          <img [src]="medium.media_url" [alt]="medium.caption"/>
        </a>
      </div>
    </marlenka-jumbotron>
  </ng-container>

</ng-container>
