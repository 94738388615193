import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BasePaginationComponent} from "../base";


@Component({
  selector: 'echo-nx-pagination-variant-a',
  templateUrl: './pagination-variant-a.component.html',
  styleUrls: ['./pagination-variant-a.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationVariantAComponent extends BasePaginationComponent {}

