import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrefixModule} from "../../prefix/prefix.module";
import {NavDropdownItemComponent} from "./nav-dropdown-item.component";
import {NavActiveChildrenCountModule} from "../nav-active-children-count/nav-active-children-count.module";




@NgModule({
  declarations: [
    NavDropdownItemComponent,

  ],
  imports: [
    NavActiveChildrenCountModule,
    CommonModule,
    PrefixModule,
  ],
  exports: [
    NavDropdownItemComponent
  ]
})
export class NavDropdownItemModule { }
