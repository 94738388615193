import gql from 'graphql-tag';

export const readAllSensorsData = gql`
  query ReadAllSensorsData($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String){
    response: readAllSensorData(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        stationType
        stationName
        stationLocationName
        metrics
      }
    }
  }
`
