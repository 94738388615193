import {gql} from 'apollo-angular';

export const addCategory = gql`
  mutation addCategory($input: [CategoryObjectInput!]!){
    response: addCategory(inputCategory: $input){
      name
      _id
    }
  }
`;
