import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface MarlenkaNotificationDialogData {
  title?: string;
  content?: string;
}

@Component({
  selector: 'marlenka-bg-marlenka-notification-dialog',
  templateUrl: './marlenka-notification-dialog.component.html',
  styleUrls: ['./marlenka-notification-dialog.component.scss'],
})
export class MarlenkaNotificationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MarlenkaNotificationDialogData) {}
}
