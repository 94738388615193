import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ResolveKeyPipe} from "./resolve-key.pipe";



@NgModule({
  declarations: [ResolveKeyPipe],
  imports: [
    CommonModule
  ],
  exports: [ResolveKeyPipe]
})
export class ResolveKeyPipeModule { }
