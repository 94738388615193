import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult,
  WidgetTemplate
} from "@echo-nx/shared/common";
import {readAllWidgetTemplates} from "../queries/widget-template";


@Injectable()
export class WidgetTemplateService implements IEntityService<WidgetTemplate>{

  constructor(protected apollo: Apollo) {}

  delete(ids: string[]): Observable<any> {
    throw new Error('Not implemented')
  }

  executeMutation(mutation: any, vars: any) {
    throw new Error('Not implemented')
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<WidgetTemplate>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<WidgetTemplate>>>({
      query: readAllWidgetTemplates,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchCategories(): Observable<ICategory<any>[]> {
    throw new Error('Not implemented')
  }

  fetchSelected(ids: string[]): Observable<WidgetTemplate[]> {
    throw new Error('Not implemented')
  }

  fetchSingle(id: string): Observable<WidgetTemplate> {
    throw new Error('Not implemented')
  }

  save(entities: WidgetTemplate[]): Observable<MutationResult<IPKApolloResult<WidgetTemplate[]>>> {
    throw new Error('Not implemented')
  }

  getType(): string {
    return '';
  }
}

export const WIDGET_TEMPLATE_SERVICE_TOKEN = new InjectionToken<IEntityService<WidgetTemplate>>('CMS_EKIOSK_WIDGET_TEMPLATE_SERVICE_TOKEN');

