/*export interface IMMultilanguageReference<T = any> {
  lang: string,
  value: T[] | string[];
}

export type IMMultilanguageReferences = IMMultilanguageReference[];*/


export interface IMultilanguageObject<T extends any[]> {
  lang: string;
  value: T;
}
