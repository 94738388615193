export interface ProductDetailPageUnits {
  millimetres: string;
  centimetres: string;
  grams: string;
  pieces: string;
}

export interface ProductDetailPageTexts {
  weight: { title: string; };
  boxDimensions: { title: string; };
  ean: { title: string; };

  massPackaging: { title: string; };
  cakeCountOfMassPackaging: { title: string; };
  weightOfMassPackaging: { title: string; };
  dimensionsOfMassPackaging: { title: string; };
  eanOfMassPackaging: { title: string; };

  palletPackaging: { title: string; };
  cakeCountOfPalletPackaging: { title: string; };
  massPackageCountOfPalletPackaging: { title: string; };
  massPackageLayerCountOfPalletPackaging: { title: string; };
  layerCountOfPalletPackaging: { title: string; };

  storage: { title: string; };
  storageTemperatureFrom: { title: string; };
  storageTemperatureTo: { title: string; };

  units: ProductDetailPageUnits

  readLess: string,
  readMore: string,
  goBack: string

}


