import {Component} from '@angular/core';
import {BaseItemCardComponent} from "../base-item-card.component";

@Component({
  selector: 'echo-nx-item-card-variant-a',
  templateUrl: './item-card-variant-a.component.html',
  styleUrls: ['./item-card-variant-a.component.scss'],
})
export class ItemCardVariantAComponent extends BaseItemCardComponent {

}
