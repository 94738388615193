import {IEpxFluidable, IEpxIcon} from "./i-base-attributes";


export interface IBaseWeatherData {
  title?: string;
  value?: string;
  suffix?: string;
  description?: string;
}


export interface IBaseWeatherDesign extends IEpxFluidable {
  primaryColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  textColor?: string;
  startIcon?: IEpxIcon;
  panelClass?: string;
}



