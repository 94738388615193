import {Injectable, InjectionToken} from "@angular/core";
import {addAuths, IMarlenkaOwner, MarlenkaEntityTypes, readAllAuths, readSelectedAuths, deleteAuth} from "@echo-nx/marlenka/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {Apollo, MutationResult} from "apollo-angular";
import {
  FetchAllArgs,
  IBaseAuth,
  ICategory,
  IEntityService,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class BaseAuthService implements IEntityService<IBaseAuth<IMarlenkaOwner>> {
  constructor(private apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IBaseAuth<IMarlenkaOwner>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IBaseAuth<IMarlenkaOwner>>>>({
      query: readAllAuths,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IBaseAuth<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<IBaseAuth<IMarlenkaOwner>[]>>({
      query: readSelectedAuths,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IBaseAuth<IMarlenkaOwner>> {
    return this.apollo.query<IPKApolloResult<IBaseAuth<IMarlenkaOwner>[]>>({
      query: readSelectedAuths,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteAuth,
      variables: {input: ids, forceDelete: true}
    })
  }


  save(auth: IBaseAuth<IMarlenkaOwner>[]): Observable<MutationResult<IPKApolloResult<IBaseAuth<IMarlenkaOwner>[]>>> {
    return this.apollo.mutate<IPKApolloResult<IBaseAuth<IMarlenkaOwner>[]>>({
      mutation: addAuths,
      variables: {input: auth}
    })
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.Auth;
  }

}

export const MAUTH_SERVICE_TOKEN = new InjectionToken<IEntityService<IBaseAuth<IMarlenkaOwner>>>('MAUTH_SERVICE_TOKEN');
