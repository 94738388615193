import {IMarlenkaAppEnvironment} from "./i-marlenka-app-environment";

export const environment = {
  production: true,
  gTagId: 'GTM-PXJSBSS', // 'GTM-W7483DJ'
  apiSettings: {
    protocol: 'https',
    apiUrl: 'marlenka.cz/api',
    graphqlSuffix: '/graphql',
  }
} as IMarlenkaAppEnvironment;
