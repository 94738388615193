<button *ngIf="!isStatic" mat-icon-button (click)="remove.emit()">
  <mat-icon>remove</mat-icon>
</button>

<div class="counter">
  {{value}}
</div>

<button *ngIf="!isStatic" mat-icon-button (click)="add.emit()">
  <mat-icon>add</mat-icon>
</button>
