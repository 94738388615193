import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemCardVariantBComponent} from './item-card-variant-b.component';
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    ItemCardVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ItemCardVariantBComponent
  ]
})
export class ItemCardVariantBModule {
}
