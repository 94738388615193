import gql from 'graphql-tag';

export const readAllGalleryForSynchronizing = gql`
  query ReadAllGalleryForSynchronizing($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeDeleted: Boolean = true) {
    response: readAllGallery(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
      includeDeleted: $includeDeleted
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        description
        slug
        color
        updatedAt
        featuredImage{
          _id
          name
          alt
          mime
          type
          url
          thumbnailUrl
        }
        media {
          _id
          name
          alt
          mime
          type
          url
          description
          thumbnailUrl
        }
        categories{
          _id
          name
          color
          icon
        }
        owner {
          _id
          name
        }
        createdAt
        publishedAt
        deletedAt
        unpublishedAt
        deletedAt
      }
    }
  }
`;
