<button [attr.aria-label]="title"
        class="font-display"
        [ngClass]="[bgColor | prefix:'bg', accentColor | prefix:'border', textColor | prefix:'text', panelClass ?? '']"
        [class.prominent]="prominent"
        [class.dense]="dense"
        [class.outlined]="outlined"
        [class.px-2]="dense"
        [class.fluid]="fluid === 'horizontal' || fluid === 'both'"
        [class.col]="orientation === 'col' || orientation === 'col-reverse'"
        [class.row]="orientation === 'row' || orientation === 'row-reverse'">
  <div *ngIf="startIcon" class="flex start wrapper">
    <div
      class="icon flex-1 text-2xl"
      [ngClass]="[startIcon.bgColor | prefix:'bg', accentColor | prefix:'border', startIcon.color | prefix:'text']">
      <i [ngClass]="startIcon.icon" class="fa-fw"></i>
    </div>
    <div
      *ngIf="prominent"
      class="line"
      [ngClass]="[accentColor | prefix:'border']">
    </div>
  </div>
  <span class="content">
    <span class="title text-lg" [ngClass]="[fontSize | prefix: 'text']">{{title}}</span>
    <span class="description"
          [ngClass]="[descriptionColorful?.bgColor | prefix:'bg', descriptionColorful?.color | prefix:'text']">
      {{description}}
    </span>
  </span>
  <div class="flex end wrapper" *ngIf='endIcon'>
    <div
      *ngIf="prominent"
      class="line"
      [ngClass]="[accentColor | prefix:'border']">
    </div>
    <div
      class="icon flex-1 text-2xl"
      [ngClass]="[endIcon.bgColor | prefix:'bg', accentColor | prefix:'border', endIcon.color | prefix:'text']">
      <i [ngClass]="endIcon.icon" class="fa-fw"></i>
    </div>
  </div>
</button>
