import gql from 'graphql-tag';

export const readAllGallery = gql`
  query ReadAllGallery($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String) {
    response: readAllGallery(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        description
        slug
        color
        updatedAt
        owner {
          _id
          name
        }
        featuredImage{
          _id
          name
          mime
          type
          url
          thumbnailUrl
        }
        media {
          _id
          name
          mime
          type
          url
          thumbnailUrl
        }
        createdAt
        publishedAt
        language
        languageGroupId
      }
    }
  }
`;
