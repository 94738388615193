import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ICategory, IMedium} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-map-info-map-info-variant-a',
  templateUrl: './map-info-variant-a.component.html',
  styleUrls: ['./map-info-variant-a.component.scss']
})
export class MapInfoVariantAComponent {
  @Input() _id?: string;

  @Input() primaryColor?: string;

  @Input() title!: string;

  @Input() subtitle!: string;

  @Input() publishedAt?: string;

  @Input() featuredImage?: IMedium;

  @Input() categories?: ICategory[];


  @Output() detailClicked = new EventEmitter<string>();

  @Input()
  set settings(settings: any){
    const {primaryColor, title, subtitle, _id, featuredImage, categories, publishedAt} = settings ?? {};
    this._id = _id;
    this.primaryColor = primaryColor ?? 'primary-500';
    this.title = title;
    this.subtitle = subtitle;
    this.categories = categories;
    this.featuredImage = featuredImage;
    this.publishedAt = publishedAt;
  }

}
