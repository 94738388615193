import { Injectable, InjectionToken } from '@angular/core';
import {Apollo, MutationResult} from 'apollo-angular';
import {
  addCakeExclusive, deleteCakeExclusive,
  IMarlenkaOwner,
  MarlenkaEntityTypes,
  readAllCakesExclusive, readCakesExclusiveLanguageGroups, readSelectedCakeExclusive
} from "@echo-nx/marlenka/common";
import {readAllCategories, readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {
  FetchAllArgs, IArticle, IBaseOwner,
  ICategory,
  IEntityService,
  IMedium, IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";




@Injectable()
export class BaseCakesExclusiveService implements IEntityService<IArticle> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IArticle<IMarlenkaOwner,IMedium<any>>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IArticle<IMarlenkaOwner,IMedium<any>>>>>({
      query: readAllCakesExclusive,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IArticle<IMarlenkaOwner,IMedium<any>>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IArticle<IMarlenkaOwner,IMedium<any>>>>>({
      query: readCakesExclusiveLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IArticle<IMarlenkaOwner,IMedium<any>>[]> {
    return this.apollo.query<IPKApolloResult<IArticle<IMarlenkaOwner,IMedium<any>>[]>>({
      query: readSelectedCakeExclusive,
      variables: {
        ids: [ids]
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IArticle<IMarlenkaOwner,IMedium<any>>> {
    return this.apollo.query<IPKApolloResult<IArticle<IMarlenkaOwner,IMedium<any>>[]>>({
      query: readSelectedCakeExclusive,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0])
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteCakeExclusive,
      variables: {input: ids}
    });
  }

  save(blog: IArticle<IMarlenkaOwner,IMedium<any>>[]): Observable<MutationResult<IPKApolloResult<IArticle<IMarlenkaOwner,IMedium<any>>[]>>> {
    return this.apollo.mutate<IPKApolloResult<IArticle<IMarlenkaOwner,IMedium<any>>[]>>({
      mutation: addCakeExclusive,
      variables: { input: blog }
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(language: string): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<ICategory<IBaseOwner>>>>({
      query: readAllCategories,
      variables: {
        filter: JSON.stringify({
          type: this.getType(),
          language: language,
        }),
        sortColumn: 'publishedAt',
        sortDirection: 'DESC'
      } as FetchAllArgs
    }).pipe(
      map(result => result?.data?.response?.items ?? [])
    );
  }

  getType(): string {
    return MarlenkaEntityTypes.CakesExclusive;
  }

}

export const MCAKES_EXCLUSIVE_SERVICE_TOKEN = new InjectionToken<IEntityService<IArticle<IMarlenkaOwner,IMedium<any>>>>('MCAKES_EXCLUSIVE_SERVICE_TOKEN');
