import {ICategorizedEntity, IOwnedEntity} from "./i-owned-entity";
import {IMedium} from "./i-medium";
import {IMultilanguage, PublishableTimestamp} from "./base-document-types";
import {IBaseOwner} from "./i-base-owner";
import {IMeta} from "./i-meta";


export interface IGallery<O extends IBaseOwner | string = any, M extends IMedium<O> | string = any> extends IOwnedEntity<O>, ICategorizedEntity, PublishableTimestamp, IMeta<any>, IMultilanguage {
    name: string;
    description?: string;
    slug?: string;
    color?: string;
    featuredImage: M;
    media: M[];
}
