import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IMapInfoVRsdData, IMapInfoVRsdDesign} from "@echo-nx/shared/common";


@Component({
  selector: 'echo-nx-map-info-theme-rsd',
  templateUrl: './map-info-theme-rsd.component.html',
  styleUrls: ['./map-info-theme-rsd.component.scss']
})
export class MapInfoThemeRsdComponent implements IMapInfoVRsdData, IMapInfoVRsdDesign {
  @Input() _id?: string;

  @Input() primaryColor?: string;

  @Input() title!: string;

  @Input() subtitle!: string;

  @Input() start?: string;

  @Input() finish?: string;

  @Input() status?: string;

  @Input() length?: number;

  @Output() detailClicked = new EventEmitter<string>();

  @Input()
  set settings(settings: IMapInfoVRsdData & IMapInfoVRsdDesign){
    const {primaryColor, title, subtitle, start, finish, status, length, _id} = settings ?? {};
    this._id = _id;
    this.primaryColor = primaryColor ?? 'primary-500';
    this.title = title;
    this.subtitle = subtitle;
    this.start = start;
    this.finish = finish;
    this.status = status;
    this.length = length;
  }

}
