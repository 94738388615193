import {IEpxAttribute, IEpxColorful, IEpxFluidable, IEpxIcon, IEpxOrientation} from "./i-base-attributes";
import {IMedium} from "../i-medium";
import {ICategory} from "../i-category";

export interface IBaseItemListData {
  _id?: string;
  title?: string;
  perex?: string;
  date?: string;
  footer?: string;
  categories?: ICategory<any>[];
  image?: IMedium<any>;
  // attachments?: IMedium<any>[]; @deprecated, use footer instead
  attributes?: IEpxAttribute[];
}

export interface IBaseItemListDesign extends IEpxFluidable {
  primaryColor?:string;
  secondaryColor?: string;
  accentColor?: string;

  titleText?: IEpxColorful;
  prominent?: boolean;

  dateText?: IEpxIcon;
  footerText?: IEpxIcon;

  horizontalBreakpoint?: number;
  baseOrientation?: IEpxOrientation;
  breakOrientation?: IEpxOrientation;
}
