// COMMAND INTERFACE TO AND FROM DIALOG

export enum IMCommandType {
  "INIT_ONLINE_ORDER" = 10,
  "ADD_ONLINE_ORDER_ITEM" = 11,
  "INIT_LOCAL_ORDER" = 20,
  "ADD_LOCAL_ORDER_ITEM" = 21,

  "CHANGE_ORDER" = 40,
  "CLAIM_TICKET" = 45,
  "END_ONLINE_ORDER" = 50,
  "END_LOCAL_ORDER" = 51,
  "MAKE_PDF" = 60
}

// D3000S.USER_EPX_UDALOSTI
export interface IMDialogCommand {
  ID: number;
  TYP_UDALOSTI: IMCommandType;
  TERMIN_PROHLIDKY: Date;
  KONTAKT_JMENO: string;
  KONTAKT_EMAIL: string;
  KONTAKT_TELEFON: string;

  KOD_POUKAZU: string;
  KOD_ART_POUKAZU: string;

  //MNOZSTVI: string;

  JEDNOTKOVA_CENA: number;
  POCET_DOSPELY: number;
  POCET_DETI: number;

  CISLO_SLEV_KARTY: string;
  PLATNOST_SLEV_KARTY: Date;

  NO_SKUPINY_ERP: number;
  NO_DOKLADU_ERP: number;

  DATUM_EXPIRACE: string;

  ID_UZIVATELE: string; // Pokud je uživatel přihlášen, bude vyplněno... v případě online je to nákup k uživatelskému účtu, pokud je to na pokladně tak aktuálně přihlášený uživatel - zaměstnanec
  JMENO_PARTNERA: string;
  ULICE: string;
  MESTO: string;
  PSC: string;
  STAT: string;
  ICO: string;
  DIC: string;
  PLATCE_DPH: boolean; //numeric(1)
  TYP_ZMENY: number;

  ECHOPIX_OBJECTID: string; //čistě náš sloupeček pro pamatování našeho ID

  DIALOG_STATUS: number;
  ECHOPIX_STATUS: number;
  DIALOG_TS: Date;
  ECHOPIX_TS: Date;
}
