import gql from "graphql-tag";

export const useTicket = gql`
  mutation useTicket($ticketId: String!, $code: String!){
    response: useTicket(ticketId: $ticketId, code: $code){
      description
      error
      userMessage
    }
  }
`;


export const useTickets = gql`
  mutation useTicket($tickets: [MReservedTicketInput!]!, $reservationId: String!){
    response: useTickets(tickets: $tickets, reservationId: $reservationId){
      description
      error
      userMessage
    }
  }
`;
