import {gql} from 'apollo-angular';

export const updateScreens = gql`
  mutation updateScreens($screens: [JSONObject!]!,$widgets: [JSONObject!]!){
    response: updateScreens(screens:$screens, widgets:  $widgets){
      description
      error
      userMessage
    }
  }
`;
