import gql from 'graphql-tag';

export const readSelectedVoucherTypes = gql`
  query ReadSelectedVoucherTypes($ids: [String!]!, $includeDeleted: Boolean) {
    response: readSelectedVoucherTypes(
      ids: $ids,
      includeDeleted: $includeDeleted
    ) {
      _id
      name
      bgMedium {
        _id
        name
        url
        mime
        type
        thumbnailUrl
      }
      voucherPreview {
        _id
        name
        url
        mime
        type
        thumbnailUrl
      }
      contentPosition {
        top
        left
      }
      text {
        color
        fontSize
        textAlignment
      }
      title {
        color
        fontSize
        textAlignment
      }
      logoAlignment
      maxWidth
    }
  }
`;
