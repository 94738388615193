<ng-container *ngIf="selectedCategory$ | async as category">
    <marlenka-jumbotron
        id="history"
        [title]="category.name"
        [subtitle]="category.description"
        [withDivider]="true"
        [dividerWave]="true"
        background="#FFF6E6"
    >
      <div class="grid container medium">
      <div class="item" *ngFor="let cake of cakesExclusive$ | async; let i = index">
<!--        <div class="text">
          <h4 class="title">{{cake.title}}</h4>
          <div [innerHTML]="cake.content" class="content"></div>
        </div>-->
        <echo-nx-medium-item class="medium" [cover]="true" [medium]="cake.featuredImage"></echo-nx-medium-item>
      </div>
      </div>
    </marlenka-jumbotron>
</ng-container>
