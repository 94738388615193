import {IEpxColorful, IEpxFluidable, IEpxIcon, IEpxOrientation} from "./i-base-attributes";

export interface IBaseButtonData {
  title: string;
  description?: string;
}

export interface IBaseButtonDesign extends IEpxFluidable {
  bgColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  textColor?: string;

  descriptionColorful?: IEpxColorful;

  prominent?: boolean;
  outlined?: boolean;
  dense?: boolean;
  hoverable?: boolean;

  orientation?: IEpxOrientation;

  startIcon?: IEpxIcon;
  endIcon?: IEpxIcon;

  fontSize?: string;
  panelClass?: string; // todo deprecate
}



