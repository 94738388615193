import {Injectable, InjectionToken} from "@angular/core";
import {
  CommonEntityTypes,
  FetchAllArgs, IBaseLog, IBaseOwner,
  ICategory,
  IEntityService, IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {readAllCategoriesOfEntity, readAllLogs, readSelectedLogs} from "../queries";


@Injectable()
export class BaseLogsService implements IEntityService<IBaseLog> {

  constructor(protected apollo: Apollo) {
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, vars: any): any {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IBaseLog>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IBaseLog>>>({
      query: readAllLogs,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchCategories(): Observable<ICategory<IBaseOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IBaseOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType(),
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IBaseLog[]> {
    return this.apollo.query<IPKApolloResult<IBaseLog[]>>({
      query: readSelectedLogs,
      variables: {
        ids: ids
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSingle(id: string): Observable<IBaseLog> {
    return this.fetchSelected([id]).pipe(
      map(logs => logs[0])
    );
  }

  getType(): string {
    return CommonEntityTypes.Logs;
  }

  save(entities: IBaseLog[]): Observable<MutationResult<IPKApolloResult<IBaseLog[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }
}

export const BASE_LOGS_SERVICE_TOKEN = new InjectionToken<IEntityService<IBaseLog>>('BASE_LOGS_SERVICE_TOKEN');

