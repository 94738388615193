import gql from 'graphql-tag';

export const readAllEventTopics = gql`
  query ReadAllEventTopics(
    $search: String
    $page: Int
    $take: Int
    $sortColumn: String
    $sortDirection: String
    $filter: String
  ) {
    response: readAllEventTopic(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      filter: $filter
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        nameShort
        warningTitle
        warningContent
      }
    }
  }
`;
