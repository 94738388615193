import gql from 'graphql-tag';

export const readAllArticles = gql`
  query ReadAllArticles($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean){
    response: readAllArticle(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      includeNotPublished: $includeNotPublished,
      filter: $filter
    ){
      total
      page
      take
      hasMore
      items{
        _id
        title
        summary
        content
        featuredImage {
          _id
          name
          url
          mime
          type
          thumbnailUrl
        }
        categories {
          _id
          
          name
        }
        owner{
          _id
          name
        }
        createdAt
        publishedAt
        language
        languageGroupId
        unpublishedAt
      }
    }
  }
`
