import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, observeOn, scan, takeUntil } from 'rxjs/operators';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import {
  IScrollPositionRestore,
  RouteScrollBehaviour,
  RouteScrollStrategy,
} from '../types';

const componentName = 'ScrollableService';

@Injectable()
export class ScrollableService implements OnDestroy {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.initPositionStore();
    this.subscribeToPositionRestoration();
  }

  /*QUEUES*/
  /**
   * Queue of strategies to add
   */
  private addQueue: RouteScrollStrategy[] = [];
  /**
   * Queue of strategies to add for onBeforeNavigation
   */
  private addBeforeNavigationQueue: RouteScrollStrategy[] = [];
  /**
   * Queue of strategies to remove
   */
  private removeQueue: string[] = [];

  /*OBSERVABLES*/
  private onDestroy$: Subject<void> = new Subject();
  private scrollPositionRestore$!: Observable<IScrollPositionRestore>;

  private scrollOnRouting = true;
  private logDebugInfo = false;

  private scrollable?: ElementRef<HTMLElement>;

  /**
   * Registered strategies
   */
  private routeStrategies: RouteScrollStrategy[] = [];

  setScrollable(el: ElementRef<HTMLElement>, setterFromDirective = false) {
    this.scrollOnRouting = setterFromDirective;
    this.scrollable = el;
  }

  scrollToTop(behavior?: 'smooth' | undefined) {
    if (this.scrollable) {
      console.log('scrolling with', this.scrollable.nativeElement.nodeName);
      this.scrollable.nativeElement.scrollTo({ top: 0, behavior });
    } else {
      console.log('scrolling with window');
      window.scrollTo({ top: 0 });
    }
  }

  scrollToElement(element?: HTMLElement | null) {
    if (element) {
      if (this.scrollable) {
        this.scrollable.nativeElement.scroll({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
      }
    } else {
      console.error('something went wrong during scroll');
    }
  }

  initPositionStore = () => {
    if (this.logDebugInfo) {
      console.log(` Creating RouterEvents/Position observable`);
    }
    /*this.router.events.subscribe((src)=>{console.log(`${componentName}:: ${src} - test on routing`)})*/
    this.scrollPositionRestore$ = this.router.events.pipe(
      filter(
        (event: any) =>
          event instanceof NavigationStart || event instanceof NavigationEnd
      ),
      // Accumulate the scroll positions
      scan(
        (acc, event) => {
          if (this.logDebugInfo) {
            console.log(
              `${componentName}:: Updating the known scroll positions`
            );
          }
          const positions: Record<string, any> = {
            ...acc.positions, // Keep the previously known positions
          };

          if (event instanceof NavigationStart && this.scrollable) {
            if (this.logDebugInfo) {
              console.log(
                `${componentName}:: Storing the scroll position of the custom viewport`
              );
            }
            positions[`${event.id}`] = this.scrollable.nativeElement.scrollTop;
          }

          return {
            event,
            positions,
            trigger:
              event instanceof NavigationStart
                ? event.navigationTrigger
                : acc.trigger,
            idToRestore:
              (event instanceof NavigationStart &&
                event.restoredState &&
                event.restoredState.navigationId + 1) ||
              acc.idToRestore,
            routeData: this.activatedRoute.firstChild?.routeConfig?.data,
          } as IScrollPositionRestore;
        }
      ),
      filter(
        (scrollPositionRestore: IScrollPositionRestore) =>
          !!scrollPositionRestore.trigger
      ),
      observeOn(asyncScheduler)
    );
  };

  subscribeToPositionRestoration = () => {
    this.scrollPositionRestore$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((scrollPositionRestore: IScrollPositionRestore) => {
        if (!this.scrollOnRouting) {
          return false;
        }
        const existingStrategy = this.routeStrategies.find(
          (strategy) =>
            scrollPositionRestore.event.url.indexOf(strategy.partialRoute) > -1
        );
        if (this.logDebugInfo) {
          console.log(
            `${componentName}:: existingStrategy ${existingStrategy}`
          );
        }
        const existingStrategyWithKeepScrollPositionBehavior =
          (existingStrategy &&
            existingStrategy.behaviour ===
              RouteScrollBehaviour.KEEP_POSITION) ||
          false;
        const routeDataWithKeepScrollPositionBehavior =
          (scrollPositionRestore.routeData &&
            scrollPositionRestore.routeData.scrollBehavior &&
            scrollPositionRestore.routeData.scrollBehavior ===
              RouteScrollBehaviour.KEEP_POSITION) ||
          false;

        const shouldKeepScrollPosition =
          existingStrategyWithKeepScrollPositionBehavior ||
          routeDataWithKeepScrollPositionBehavior;

        if (scrollPositionRestore.event instanceof NavigationEnd) {
          this.processRemoveQueue(this.removeQueue);

          // Was this an imperative navigation? This helps determine if we're moving forward through a routerLink, a back button click, etc
          // Reference: https://www.bennadel.com/blog/3533-using-router-events-to-detect-back-and-forward-browser-navigation-in-angular-7-0-4.htm
          const imperativeTrigger =
            (scrollPositionRestore.trigger &&
              'imperative' === scrollPositionRestore.trigger) ||
            false;

          // Should scroll to the top if
          // no strategy or strategy with behavior different than keep position
          // OR no route data or route data with behavior different than keep position
          // OR imperative
          // Reference: https://medium.com/javascript-everyday/angular-imperative-navigation-fbab18a25d8b

          // Decide whether we should scroll back to top or not
          const shouldScrollToTop =
            !shouldKeepScrollPosition || imperativeTrigger;

          if (this.logDebugInfo) {
            console.log(
              `${componentName}:: Existing strategy with keep position behavior? `,
              existingStrategyWithKeepScrollPositionBehavior
            );
            console.log(
              `${componentName}:: Route data with keep position behavior? `,
              routeDataWithKeepScrollPositionBehavior
            );
            console.log(
              `${componentName}:: Imperative trigger? `,
              imperativeTrigger
            );
            console.log(
              `${componentName}:: Should scroll? `,
              shouldScrollToTop
            );
          }

          if (shouldScrollToTop) {
            if (this.scrollable) {
              if (this.logDebugInfo) {
                console.log(
                  `${componentName}:: Scrolling a custom viewport to top: `,
                  this.scrollable.nativeElement.nodeName
                );
              }
              this.scrollable.nativeElement.scrollTo({ top: 0, left: 0 });
            }
          } else {
            if (this.logDebugInfo) {
              console.log(`${componentName}:: Not scrolling`);
            }

            if (this.scrollable) {
              this.scrollable.nativeElement.scrollTop =
                scrollPositionRestore.positions[
                  `${scrollPositionRestore.idToRestore}`
                ];
            }
          }

          this.processRemoveQueue(
            this.addBeforeNavigationQueue.map(
              (strategy) => strategy.partialRoute
            ),
            true
          );
          if(this.logDebugInfo) {
            console.log(
              `${componentName}::adding on Stop ${this.addBeforeNavigationQueue}`
            );
          }
          this.processAddQueue(this.addQueue);
          this.addQueue = [];
          this.removeQueue = [];
          this.addBeforeNavigationQueue = [];
        } else {
          // current event is NavigationStart
          if(this.logDebugInfo) {
            console.log(
              `${componentName}::adding on Start ${this.addBeforeNavigationQueue}`
            );
          }
          this.processAddQueue(this.addBeforeNavigationQueue);
        }
        return true;
      });
  };

  processAddQueue(queue: any) {
    for (const partialRouteToAdd of queue) {
      const pos = this.routeStrategyPosition(partialRouteToAdd.partialRoute);
      if (pos === -1) {
        this.routeStrategies.push(partialRouteToAdd);
      }
    }
  }

  processRemoveQueue(queue: any, removeOnceBeforeNavigation = false) {
    for (const partialRouteToRemove of queue) {
      const pos = this.routeStrategyPosition(partialRouteToRemove);
      if (
        !removeOnceBeforeNavigation &&
        pos > -1 &&
        this.routeStrategies[pos].onceBeforeNavigation
      ) {
        continue;
      }
      if (pos > -1) {
        this.routeStrategies.splice(pos, 1);
      }
    }
  }

  routeStrategyPosition(partialRoute: string) {
    return this.routeStrategies
      .map((strategy) => strategy.partialRoute)
      .indexOf(partialRoute);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
