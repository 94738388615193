import { IBaseAuth } from './i-base-auth';
import { IBaseOwner } from './i-base-owner';
import {ICategorizedEntity, IOwnedEntity} from './i-owned-entity';
import { ICategory } from './i-category';

export interface INotification <T extends IBaseOwner | string = any> extends IOwnedEntity<T>, ICategorizedEntity {
  text: string;
  dateOfSending: Date;
  dateOfPayment?: Date;
  count: number;
  receivers: string[];
  author: IBaseAuth<T> | string;
  subject?: string;
}
