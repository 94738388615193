<echo-nx-article-preview
  panelClass="m-article-preview"
  [title]="article.title"
  [subtitle]="article.publishedAt | date: 'dd. MM. yyy | HH:mm'"
  [imgSrc]="article.featuredImage?.url"
  [routeTo]="
    '/' + mrdService.currentLanguage.toLowerCase() + '/home/articles/' + article.languageGroupId
  "
>
  <div class="buttons" bottom-row>
    <marlenka-read-more [link]="'/' + mrdService.currentLanguage.toLowerCase() + '/home/articles/' + article.languageGroupId"></marlenka-read-more>
  </div>
</echo-nx-article-preview>
