import gql from 'graphql-tag';

export const readAllProduct = gql`
  query ReadAllProduct($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $includeNotPublished: Boolean, $filter: String) {
    response: readAllProducts(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        name
        language
        languageGroupId
        media{
          _id
          url
          thumbnailUrl
          name
          mime
          description
          type
        }
        description
        allergens {
            _id
            text
            number
        }
        weight
        boxSize {
            height
            width
            depth
        }
        ean
        package {
            amountOfCakes
            weight
            ean
          	boxSize {
              height
              width
              depth
            }
        }
        pallet {
            height
            amountOfCakes
            amountOfLayers
            amountOfPackagesPerLayer
        }
        tempCondition {
            amountOfDays
            units
            temperatureFrom
            temperatureTo
        }
        owner {
          _id
        }
        categories {
          _id
          name
        }
        priority
        publishedAt
        attachments {
          _id
          name
          mime
          type
          url
          thumbnailUrl
        }
        createdAt
        stores {
          _id
          name
        }
      }
  }
}
`;
