import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IEntityDefinition} from "../../../interfaces";


/**
 * This pipe extracts correct form and filters entity$ so children have only one item from array
 */
@Pipe({
  name: 'transformForRepeaterPortal'
})
export class TransformForRepeaterPortalPipe implements PipeTransform {

  transform(entityDefinition: IEntityDefinition): any {
    return {
      ...entityDefinition,
      settings: {
        ...entityDefinition.settings,
      }
    }
  }

}
