import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'StartsWith',
  pure: true
})
export class StartsWithPipe implements PipeTransform {
  transform(haystack: string, needle: string): any {
    if(!haystack) return false;
    return haystack.lastIndexOf(needle, 0) === 0
  }
}
