import { AfterViewInit, Component, Injector, OnDestroy } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { combineLatest, Observable } from 'rxjs';
import { MarlenkaBasePageComponent } from '..';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import {IArticle, IMedium, IPaginatedResponse} from "@echo-nx/shared/common";
import {ArticlesPageTexts, IMarlenkaOwner} from "@echo-nx/marlenka/common";

@Component({
  selector: 'marlenka-articles-page',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.scss']
})
export class ArticlesPageComponent extends MarlenkaBasePageComponent<ArticlesPageTexts> {

  public articles$: Observable<IPaginatedResponse<IArticle<IMarlenkaOwner,IMedium<IMarlenkaOwner>>>>;
  public pageNumber$: Observable<number>;
  public take = 10;

  constructor(injector: Injector, private activatedRoute: ActivatedRoute, private blogService: ArticleService, private navigationService: NavigationService) {
    super(injector);
    this.pageNumber$ = activatedRoute.queryParams.pipe(
      takeUntil(this.isDestroyed$),
      map(params => parseInt(params.page as string,10) || 1)
    );

    this.articles$ = combineLatest([this.mrdService.routeData$, this.pageNumber$, this.mrdService.currentLanguage$]).pipe(
      takeUntil(this.isDestroyed$),
      switchMap(([routeData, pageNumber, language]) => {
        return this.blogService.fetchPaginated(blogService.mapIdentifierToCategory(routeData.identifier), pageNumber - 1, this.take, language);
      })
    );
  }

}
