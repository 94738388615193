import {IBackgroundObject} from './background-object';
import {ICategorizedEntity, IOwnedEntity} from "../i-owned-entity";
import {IKioskOwner, Routable, Zone} from "../kiosk";
import {ITheme} from "../i-theme";
import {IMultilanguage} from "../base-document-types";
import {IMeta} from "../i-meta";

// todo since this interface is used for web-page too, it should be renamed
export interface IKioskScreen extends IOwnedEntity<IKioskOwner | string>, Routable, IMultilanguage, ICategorizedEntity, IMeta<any> {
  name?: string;
  slug: string;

  theme?: ITheme | string;

  zones: Zone[];

  gridTemplateColumns?: string;
  padding?: string;
  background?: IBackgroundObject;

  isDisabilityMode?: boolean;
}
