import gql from 'graphql-tag';

export const readSelectedDepartment = gql`
  query ReadSelectedDepartment($ids: [String!]!) {
    response: readSelectedDepartment(
      ids: $ids
    ) {
      _id
      language
      languageGroupId
      name
      description
      openingHours
      publishedAt
      unpublishedAt
      contact {
        _id
        name
        firstName
        lastName
        telephones
        emails
        name
        websites
        companyName
        ico
        dic
        dataBox
        tradeRegisterText
        bankInfo{
          bankName
          iban
          swift
          bankNumber
          bankCode
        }
        address{
          city
          province
          state
          zipcode
          street
        }
      }
    }
  }
`;
