import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseCategoryService, BaseMediumService, BaseVirtualTourSceneService, LanguageProviderService} from "./services";


@NgModule({
  providers: [
    BaseCategoryService,
    BaseMediumService,
    BaseVirtualTourSceneService,
    LanguageProviderService
  ],
  imports: [CommonModule],
})
export class SharedNgDataAccessModule {
}
