import {gql} from 'apollo-angular';

export const deleteGallery = gql`
  mutation deleteGallery($input: [String!]!){
    response: deleteGallery(ids: $input){
      userMessage
      error
      description
    }
  }
`;
