import {IEpxIcon} from "./i-base-attributes";

export interface IBaseTextData {
  title?: string;
}

export interface IBaseTextDesign {
  primaryColor?: string;
  secondaryColor?: string;
  startIcon?: IEpxIcon;
  endIcon?: IEpxIcon;
  fontSize?: string;
  fontWeight?: string;
  panelClass?: string;
  fontFamilyType?: FontFamilyType;
  isWysiwyg?: boolean;
}

export type FontFamilyType = 'body' | 'display' | 'extra';
