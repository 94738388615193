import {Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit} from "@angular/core";
import {merge, Observable, Subject, tap} from "rxjs";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, ValidatorFn} from "@angular/forms";
import {filter, map} from "rxjs/operators";
import {IEntityDefinition} from "../../interfaces";
import {EntityFormService} from "../entity-form";
import {getEntityDefinitionLabel} from "../../utils/form-utils";
import {IBaseFormFieldSettings} from "./base-form-field.settings";
import {DYNAMIC_COMPONENT_INPUT} from "@echo-nx/shared/ng/feature/common";


@Component({template: ''})
export abstract class BaseFormFieldComponent<S extends IBaseFormFieldSettings = IBaseFormFieldSettings> implements OnInit, OnDestroy {

    public entityFormService!: EntityFormService;

    protected elRef!: ElementRef;

    protected isDestroyed$ = new Subject<void>();

    public formGroup: UntypedFormGroup;

    formControl!: AbstractControl;

    @Input()
    settings!: S;

    @Input()
    data?: Observable<any>;

    disabled = false;

    validators?: ValidatorFn[];

    public label?: string;

    public firstErrorKey$!: Observable<{ key: string, params?: any } | null>;

    protected constructor(@Inject(DYNAMIC_COMPONENT_INPUT) private definition: IEntityDefinition & Record<string, any>, private formGroupDirective: FormGroupDirective, protected injector: Injector) {
        this.formGroup = formGroupDirective.form;
        this.elRef = this.injector.get(ElementRef);

        if (definition) {
            const {disabled, settings, data, validators, entityFormServiceData} = definition;
            this.disabled = disabled ?? false;
            this.settings = settings;
            this.formControl = this.formGroup.get(this.settings.formControlName) as UntypedFormControl;
            this.data = data;
            this.validators = validators;

            const {token} = entityFormServiceData ?? {};
            if (token) {
                this.entityFormService = this.injector.get<EntityFormService>(token);
            }

            const {nazev} = this.settings;
            this.label = getEntityDefinitionLabel(nazev, this.formGroup, this.settings);
        }
    }

    async ngOnInit(): Promise<void> {
        const {valueChanges} = this.formControl;

        this.firstErrorKey$ = merge(valueChanges, this.entityFormService.onValidation$).pipe(
            map(() => Object.entries(this.formControl.errors ?? {})),
            filter(entries => entries.length > 0),
            map(entries => entries[0]),
            map(([key, params]) => ({key, params})),
            tap(() => {
                this.formControl.markAsTouched()
            }),
        )
    }


    async ngOnDestroy(): Promise<void> {
        this.isDestroyed$.next();
        this.isDestroyed$.complete();
    }

    public focusField() {
        (this.elRef.nativeElement as HTMLElement).scrollIntoView({behavior: 'smooth'});
    }
}
