import gql from 'graphql-tag';

export const readSelectedAuths = gql`
  query ReadSelectedAuths($ids: [String!]!) {
    response: readSelectedAuths(
      ids: $ids
    ) {
      _id
      owner {
        _id
      }
      name
      email
      role {
        _id
        name
      }
    }
  }
`;
