import {ICategorizedEntity, IOwnedEntity} from "./i-owned-entity";
import { IBaseOwner } from './i-base-owner';
import {IMeta} from "./i-meta";

export interface IMedium<T extends IBaseOwner | string = any, M = any> extends IOwnedEntity<T>, ICategorizedEntity, IMeta<M> {
  name: string;
  description?: string;
  alt?: string;
  url?: string;
  thumbnailUrl?: string;
  originalFileName: string;
  mime: string;
  type: MediumType
  metadata?: ImageMetadata | VideoMetadata;// | DocumentMetadata;

  //SHA-1
  hash?: string;
}

export interface ImageMetadata {
  size: number;
  width: number;
  height: number;
  orientation: MediumOrientation;
}

export interface VideoMetadata extends ImageMetadata {
  codec: string;
  length: number;
  bitrate: number;
  framerate: number;
}

export interface DocumentMetadata {
  numberOfPages: number;
  orientation: MediumOrientation;
}

export enum MediumOrientation {
  Square = "Square",
  Vertical = "Vertical",
  Horizontal = "Horizontal"
}

export enum MediumType {
  YouTubeVideo = "YouTubeVideo",
  Document = "Document",
  Image = "Image",
  Video = "Video",
  Slideshow = "Slideshow",
  Archive = "Archive",
}

export enum MediumState {
  Processing = "Processing",
  Processed = "Processed",
  NotProcessed = "NotProcessed"
}

export const getMediumTypeFromMime = (mimeType: string): MediumType => {
  const docRegex = new RegExp(/application\/.*(word|pdf|html|csv|office|excel|xml).*/g);
  const archiveRegex = new RegExp(/application\/.*(rar|zip|7z|tar).*/g);
  if (docRegex.exec(mimeType)) {
    return MediumType.Document;
  } else if (mimeType.includes('image')) {
    return MediumType.Image;
  } else if (mimeType.includes('video')) {
    return MediumType.Video;
  } else if (archiveRegex.exec(mimeType)) {
    return MediumType.Archive
  } else {
    // falback to document??
    return MediumType.Document;
  }
}

export const getMimeFromFilename = (filename: string): string => {
  const pdfRegex = new RegExp(/\.pdf/gi);
  if (pdfRegex.exec(filename)) {
    return 'application/pdf';
  } else {
    // yolo
    return 'image/jpeg';
  }
}
