import {FontFamilyType} from "./i-base-text";

export interface IBaseLanguageDesign {
  primaryColor?: string;
  secondaryColor?: string;
  fontSize?: string;
  fontWeight?: string;
  panelClass?: string;
  fontFamilyType?: FontFamilyType;
}

