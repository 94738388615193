import {BaseLayoutSettingsType} from './base-layout-settings.type';

export type FlexLayoutSettings = {
  flexDirection?: 'row' | 'column' | string;
  justifyContent?: FlexAlignment;
  alignItems?: FlexAlignment;
  itemGap?: number; // deprecate this
  panelClass?: string;
} & BaseLayoutSettingsType;

export type FlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'space-around' | 'stretch' | string;
