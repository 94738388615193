import { MongoID } from './base-document-types';

export const syslogLevels =
  ["emerg",
  "alert",
  "crit",
  "error",
  "warning",
  "notice",
  "info",
  "debug"] as const;
export type SyslogLevels = typeof syslogLevels[number];



export interface IBaseLog<O = any> extends MongoID {
  owner?: O;
  timestamp: Date,
  level: SyslogLevels,
  message: string,
  meta?: any
}
