import gql from 'graphql-tag';

export const readSelectedLogs = gql`
  query readSelectedLog($ids: [String!]!) {
    response: readSelectedLog(
      ids: $ids
    ) {
      _id
      level
      message
      meta
      timestamp
    }
  }
`;
