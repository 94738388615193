<echo-nx-card title="Zvolte soubory">
  <div class="flex-1 space-y-8">
    <div
      mat-ripple
      echoNxDragAndDrop
      (dropped)="onFilesDropped($event)"
      class="flex justify-center items-center flex-1 border-dashed border-2 border-primary-600 rounded-md bg-accent-700 cursor-pointer"
      (click)="fileInput.click()"
    >
      <p class="py-12">Zde přetáhněte soubory nebo klikněte pro výběr souborů.</p>
    </div>

    <div class="flex flex-col flex-1 divide-y divide-accent-700" *ngIf="filesToUpload.length > 0">
      <div
        *ngFor="let fileUpload of filesToUpload; let i = index"
        class="flex py-4 px-2 space-x-4"
      >
        <!-- icon -->
        <div class="flex items-center justify-center border-2 rounded-md border-accent-600">
          <mat-icon class="icon-size-8 mx-10" [svgIcon]="fileUpload.file.type | fileIcon"></mat-icon>
        </div>

        <!-- file description -->
        <div class="flex flex-col flex-1">
          <!-- first row: name, size, remove/cancel button -->
          <div class="flex items-center flex-1">
            <span class="font-medium mr-8">{{fileUpload.file.name}}</span>
            <span class="font-light">{{fileUpload.file.size | format:{method: 'filesize'} }}</span>
            <button class="ml-auto" mat-icon-button (click)="removeFile(fileUpload, i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <!-- second row: progress bar -->
          <mat-progress-bar class="my-4" mode="determinate" [value]="fileUpload.progress"></mat-progress-bar>

          <!-- third row: percentage done, speed? -->
          <div class="flex justify-between">
            <span class="font-medium">{{fileUpload.progress}} %</span>
            <span *ngIf="fileUpload.speed" class="font-light">{{fileUpload.speed}} kB/s</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</echo-nx-card>

<input hidden type="file" multiple #fileInput (change)="onFilesAdded($event)">
