export interface IEpxIcon extends IEpxColorful {
  icon?: string;
  offset?: boolean; //fixme temporary - is only for button Variant A
}

export interface IEpxColorful {
  color?: string;
  bgColor?: string;
}

export interface IEpxAttribute {
  startIcon?: string;
  text: string;
  endIcon?: string;
}

export interface IEpxFluidable {
  fluid?: IEpxFluid;
}

export type IEpxFluid = "horizontal" | "vertical" | "both";

export enum EpxOrientation {
  'col' = 'col',
  'col-reverse' = 'col-reverse',
  'row' = 'row',
  'row-reverse' = 'row-reverse',
}

export type IEpxOrientation = keyof typeof EpxOrientation
