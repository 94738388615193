import {
  addProductType, deleteProductType,
  IMarlenkaOwner, IMDialogCommodity,
  IMProductType,
  MarlenkaEntityTypes,
  readAllProductTypes, readProductTypesLanguageGroups,
  readSelectedProductTypes
} from "@echo-nx/marlenka/common";
import {Injectable, InjectionToken} from "@angular/core";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {Apollo, MutationResult} from "apollo-angular";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable()
export class BaseProductTypeService implements IEntityService<IMProductType<IMDialogCommodity | string>> {

  constructor(private apollo: Apollo) {
  }

  getType(): string {
    return MarlenkaEntityTypes.ProductTypes;
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMProductType>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMProductType>>>({
      query: readAllProductTypes,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMProductType<IMDialogCommodity>>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMProductType<IMDialogCommodity>>>>({
      query: readProductTypesLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMProductType[]> {
    return this.apollo.query<IPKApolloResult<IMProductType[]>>({
      query: readSelectedProductTypes,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response)
    );
  }

  fetchSingle(id: string): Observable<IMProductType> {
    return this.apollo.query<IPKApolloResult<IMProductType[]>>({
      query: readSelectedProductTypes,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null)
    );
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteProductType,
      variables: { input: ids }
    });
  }

  save(productTypes: IMProductType<string>[]): Observable<MutationResult<IPKApolloResult<IMProductType[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMProductType[]>>({
      mutation: addProductType,
      variables: { input: productTypes }
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }
}

export const MPRODUCT_TYPE_SERVICE_TOKEN = new InjectionToken<IEntityService<IMProductType>>('MPRODUCT_TYPE_SERVICE_TOKEN');
