<!--FullScreen Nav-->
<div class="p-2 h-full flex flex-1 flex-col" [ngClass]="[bgColor | prefix: 'bg']">
  <!--Basic header (close + logo)-->
  <div
    class="cursor-pointer mx-2 py-6 border-b-2 border-gray-100 flex flex-row items-center space-between"
    [ngClass]="[primaryColor | prefix: 'text']">
    <i class="fas text-xl fa-times w-16" (click)="navClosed.next()"></i>
    <img alt="logo" class="h-14 flex" [src]="logo?.url"/>
    <div class="w-16"></div>
  </div>
  <echo-nx-nav-mobile-level (itemClicked)="itemClicked.next($event)" [item]="navigation"></echo-nx-nav-mobile-level>
</div>
