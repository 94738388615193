import gql from 'graphql-tag';

export const getReadEventsInRangeQuery = gql`
  query ReadEventsInRange($filter: String) {
    response: readAllEvent(
      filter: $filter
      sortColumn: "start"
      sortDirection: "ASC"
    ) {
      items {
        _id
        maximumCapacity
        availableCapacity
        duration
        start
        language
        categories {
          _id
          name
        }
        eventTopic {
          _id
          name
          nameShort
          warningTitle
          warningContent
        }
        eventLanguage {
          _id
          name
          nameShort
          warningTitle
          warningContent
        }
      }
    }
  }
`;
