<div *ngIf="routeTo; else withoutLink">
  <a [routerLink]="routeTo">
    <figure>
      <img class="main-img" src="{{ imgSrc }}" />
    </figure>
  </a>
  <div class="content">
    <h5 class="subtitle">
      <span>{{ subtitle }}</span>
    </h5>
    <a href="{{ routeTo }}">
      <h3 class="title">{{ title }}</h3>
    </a>
    <ng-content select="[bottom-row]"></ng-content>
  </div>
</div>


<ng-template #withoutLink
  ><figure>
    <img class="main-img" src="{{ imgSrc }}" />
  </figure>
  <div class="content">
    <h5 class="subtitle">
      <span>{{ subtitle }}</span>
    </h5>
    <h3 class="title">{{ title }}</h3>
    <ng-content select="[bottom-row]"></ng-content></div
></ng-template>
