import {
  IMarlenkaOwner,
  IMNavigation,
  MarlenkaEntityTypes,
  MarlenkaRouteData,
  readAllNavigations
} from "@echo-nx/marlenka/common";
import {Injectable, InjectionToken} from "@angular/core";
import {Apollo, MutationResult} from "apollo-angular";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {LanguageProviderService} from "@echo-nx/shared/ng/data-access";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Injectable()
export class BaseNavigationService implements IEntityService<IMNavigation> {

  constructor(protected apollo: Apollo, protected mrdService: LanguageRouteDataService<MarlenkaRouteData>) {
  }

  fetchAll(args: FetchAllArgs): Observable<IPaginatedResponse<IMNavigation>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMNavigation>>>({
      query: readAllNavigations,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMNavigation[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchSingle(id: string): Observable<IMNavigation> {
    throw Error('NOT IMPLEMENTED :(');

  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    throw Error('NOT IMPLEMENTED :(');
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(pageSettings: IMNavigation[]): Observable<MutationResult<IPKApolloResult<IMNavigation[]>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  getType(): string {
    return MarlenkaEntityTypes.Navigation;
  }

}

export const MNAVIGATION_SERVICE_TOKEN = new InjectionToken<IEntityService<IMNavigation>>('MNAVIGATION_SERVICE_TOKEN');
