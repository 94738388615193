import {IBaseOwner, ICategorizedEntity, IOwnedEntity} from "@echo-nx/shared/common";

export interface IMessage<T extends IBaseOwner> extends IOwnedEntity<T | string>, ICategorizedEntity {
  text: string;
  sentAt: Date;
  paidAt?: Date;
  count: number;
  subject?: string;
  recipients: string[];
}
