import {IMedium} from "../i-medium";
import {ICategory} from "../i-category";
import {IEpxAttribute, IEpxColorful, IEpxIcon} from "./i-base-attributes";

export interface IBaseItemDetailData {
  title?: string;
  content?: string;
  perex?: string;
  date?: string;
  attributes?: IEpxAttribute[];

  categories?: ICategory[];
  image?: IMedium;
  attachments?: IMedium[];
}

export interface IBaseItemDetailDesign {
  primaryColor?:string;
  secondaryColor?: string;
  contentColorful?: IEpxColorful;
  titleText?: IEpxColorful;
  dateText?: IEpxIcon;
}
