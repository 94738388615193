import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusDepartureComponent } from './bus-departure.component';
import {PrefixModule} from "../prefix/prefix.module";



@NgModule({
  declarations: [
    BusDepartureComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    BusDepartureComponent
  ]
})
export class BusDepartureModule { }
