import {IKioskScreen, ScreenTemplate} from "@echo-nx/shared/common";

export const mapGqlScreens = <T extends IKioskScreen | ScreenTemplate>(screen: T): T => ({
  ...screen,
  zones: screen.zones.map(zone => ({
    ...zone,
    layouts: zone.layouts.map(layout => ({
      name: layout.name,
      settings: layout.settings,
      content: layout.content,
      to: layout.to,
      widgets: layout.widgets ?? (layout as any).gridThreeCellsWidgets ?? (layout as any).overlayWidgets ?? []
    }))
  }))
});
