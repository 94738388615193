<ng-container *ngIf="article$ | async as article">
    <marlenka-jumbotron background="#FFF2DC" [withDivider]="false" [topWave]="true" wrapClass="pt-3 pb-3">
        <div class="article-header container small">
            <div class="col col-left">
                <time>
                    <span>
                        {{ article.publishedAt | date: 'dd. MM. yyy | HH:mm' }}
                    </span>
                </time>
                <h2>
                    {{ article.title}}
                </h2>
                <div *ngIf="article.summary" class="summary" style="color: #776337; font-weight: 600;">
                    <marlenka-wysiwyg-view [html]="article.summary"> </marlenka-wysiwyg-view>
                </div>
            </div>

            <div class="col col-right">
                <figure>
                    <img class="main-img" src="{{ article.featuredImage?.url }}" />
                </figure>
            </div>
        </div>
    </marlenka-jumbotron>
    <div class="article-content container small">
        <marlenka-wysiwyg-view [html]="article.content"> </marlenka-wysiwyg-view>
    </div>

    <div class="attachments pb-4 pt-4 pr-8 pl-8" echoNxLightboxGalleryGroup *ngIf="attachmentsGroup?.images?.length > 0">
        <div class="container">
            <ng-container *ngFor="let attachment of attachmentsGroup.images; let i = index">
                <div class="image">
                    <echo-nx-medium-item [index]="i" [medium]="attachment"></echo-nx-medium-item>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="documents pb-4 pt-4 pr-8 pl-8" *ngIf="attachmentsGroup?.others?.length > 0">
        <div class="container small">
            <hr />
            <h4>Dokumenty ke stažení</h4>
            <div class="documents-wrapper">
                <ng-container *ngFor="let attachment of attachmentsGroup.others">
                    <div class="document">
                        <div class="icon {{ attachment.mime }}"></div>
                        <div class="name">
                            <a target="_blank" href="{{ attachment.url }}" download
                                >{{ attachment.name }}
                                <div class="download-icon"><img src="assets/downIcon.svg" /></div
                            ></a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
