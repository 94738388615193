import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {
  addEvent, deleteEvent,
  getReadEventsInRangeQuery,
  IMarlenkaOwner,
  IMEvent,
  MarlenkaEntityTypes,
  readAllEvents, readSelectedEvents
} from "@echo-nx/marlenka/common";
import {readAllCategoriesOfEntity} from "@echo-nx/shared/ng/data-access";
import {FetchAllArgs, ICategory, IEntityService, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class BaseEventService implements IEntityService<IMEvent> {

  constructor(protected apollo: Apollo,) {
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<IMEvent>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMEvent>>>({
      query: readAllEvents,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMEvent[]> {
    return this.apollo.query<IPKApolloResult<IMEvent[]>>({
      query: readSelectedEvents,
      variables: {
        ids: ids
      }
    }).pipe(
      map(res => res.data.response),
    )
  }

  fetchSingle(id: string): Observable<IMEvent> {
    return this.apollo.query<IPKApolloResult<IMEvent[]>>({
      query: readSelectedEvents,
      variables: {
        ids: [id]
      }
    }).pipe(
      map(res => res.data.response[0] || null),
    )
  }

  delete(ids: string[]): Observable<any> {
    return this.apollo.mutate({
      mutation: deleteEvent,
      variables: {input: ids}
    });
  }

  save(events: IMEvent[]): Observable<MutationResult<IPKApolloResult<IMEvent[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMEvent[]>>({
      mutation: addEvent,
      variables: {input: events}
    });
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }


  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo.query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        type: this.getType()
      }
    }).pipe(
      map(result => result.data.response)
    );
  }

  public fetchEventsInRange(start: Date, end: Date): Observable<IMEvent[]> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMEvent>>>({
      query: getReadEventsInRangeQuery,
      variables: {
        filter: JSON.stringify({
          start: {
            $gt: start.toISOString(),
            $lte: end.toISOString()
          },
        })
      }
    }).pipe(
      map(res => res.data.response.items as any)
    )
  }

  getType(): string {
    return MarlenkaEntityTypes.Event;
  }

}

export const MEVENT_SERVICE_TOKEN = new InjectionToken<IEntityService<IMEvent>>('MEVENT_SERVICE_TOKEN');
