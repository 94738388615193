import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {BaseOwnerService} from "@echo-nx/marlenka/ng/data-access";
import {IMarlenkaOwner, readAllOwner, readOwnerIntegrations} from "@echo-nx/marlenka/common";
import {InstagramMediaData, IOwnerIntegrations, IPaginatedResponse, IPKApolloResult} from "@echo-nx/shared/common";
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class OwnerService extends BaseOwnerService {

  constructor(apollo: Apollo, private httpClient: HttpClient) {
    super(apollo);
  }

  public fetchInstagramPhotos(limit: number = 10): Observable<InstagramMediaData[]> {
    return this.apollo.query<IPKApolloResult<IOwnerIntegrations>>({
      query: readOwnerIntegrations
    }).pipe(
      map(result => result.data.response),
      switchMap(({ socialSites }) => {
        // destructure the data
        const { instagram } = socialSites;
        const { long_lived_token, short_lived_token } = instagram;

        // ask fb api for the ids and then the media data
        return this.httpClient.get<{ data: InstagramMediaData[] }>(`https://graph.instagram.com/me/media?fields=id,permalink,media_url,media_type,caption&limit=${limit * 3}&access_token=${long_lived_token ?? short_lived_token}`)
          .pipe(
            map(response => {
              const photos = response.data.filter(item => item.media_type !== 'VIDEO');
              if (photos.length > limit) {
                return photos.slice(0, limit);
              }
              return photos;
            })
          );
      })
    );
  }

}
