import {Component, HostBinding, Input} from '@angular/core';
import {IBaseWeatherData, IBaseWeatherDesign, IEpxIcon} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-weather-data-variant-a',
  templateUrl: './weather-data-variant-a.component.html',
  styleUrls: ['./weather-data-variant-a.component.scss']
})
export class WeatherDataVariantAComponent implements IBaseWeatherData, IBaseWeatherDesign {

  @Input()
  accentColor?: string;

  @Input()
  description?: string;

  @Input()
  primaryColor?: string;

  @Input()
  secondaryColor?: string;

  @Input()
  startIcon?: IEpxIcon;

  @Input()
  suffix?: string;

  @Input()
  textColor?: string;

  @Input()
  title?: string;

  @Input()
  value?: string;

  @Input()
  fluid?;

  @HostBinding('class') get classList(): any {
    return {
      "flex-1": this.fluid === 'horizontal' || this.fluid === 'both'
    };
  }

  @Input()
  set data({title, description, value, suffix}: IBaseWeatherData) {
    this.title = title;
    this.description = description;
    this.value = value;
    this.suffix = suffix;
  }

  @Input()
  set design({textColor, primaryColor, startIcon, accentColor, secondaryColor, fluid}: IBaseWeatherDesign) {
    this.textColor = textColor;
    this.primaryColor = primaryColor;
    this.startIcon = startIcon;
    this.accentColor = accentColor;
    this.secondaryColor = secondaryColor;
    this.fluid = fluid;
  }

}
