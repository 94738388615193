<ng-container *ngIf="{distributions: distributions$ | async, texts: (pageSettings$ | async)?.texts} as context">
    <marlenka-jumbotron *ngIf="partnerStores$ | async as partnerStores"
                        [title]="context.texts.jumbotron.title"
                        [withDivider]="true" background="url('assets/distribution.png')" id="distributionPlaces">
        <div class="content">
            <marlenka-wysiwyg-view [html]="context.distributions[0].categories[0].description"> </marlenka-wysiwyg-view>
        </div>
        <div class="partner-stores" *ngIf="partnerStores.length">
            <div class="store" *ngFor="let store of partnerStores">
                <img [src]="store.media.url" [alt]="store.media.description" />
            </div>
        </div>
    </marlenka-jumbotron>

    <marlenka-jumbotron
        *ngIf="context.distributions?.length"
        [title]="context.distributions[0].categories[0].name"
        [withDivider]="true"
        [dividerWave]="true"
        background="#fff"
        id="distribution-jumbotron"
    >
        <div class="single-distributions-wrap container small">
            <div class="single-distribution" *ngFor="let distribution of context.distributions">
                <div class="title">
                    <h3>
                        {{ distribution.name  }}
                    </h3>
                </div>
                <ng-container *ngIf="distribution.contact as contacts">
                    <marlenka-contact-detail *ngFor="let contact of contacts | sort: 'priority'" [contact]="contact"></marlenka-contact-detail>
                </ng-container>
            </div>
        </div>
    </marlenka-jumbotron>

</ng-container>
