<div class="flex flex-col" [ngClass]="[primaryColor | prefix:'bg', secondaryColor | prefix: 'text']">

  <!-- categories -->
  <div *ngIf="categories" class="flex text-center space-y-2 space-x-0 sm:space-y-0 sm:space-x-2 flex-col sm:flex-row ">
    <div class="flex"
         *ngFor="let cat of categories">
      <div class="flex p-2 justify-center items-center text-white" [ngClass]="[cat.color | prefix:'bg']">
        <i *ngIf="cat.icon" class="fa-fw" [ngClass]="[cat?.icon]"></i>
      </div>
      <span class="px-4 py-1 bg-slate-200 font-medium text-base"
            [ngClass]="[cat.color | prefix:'text']">{{cat.name}}</span>
    </div>
  </div>

  <!-- the rest -->
  <div class="flex flex-col p-4 space-y-4">
    <!-- date, title, attributes -->
    <div class="flex flex-col space-y-2 px-4 py-2 border-l-8 items-start" [ngClass]="[secondaryColor | prefix: 'border']">
      <!-- date -->
      <div class="font-medium text-sm"
           [ngClass]="[dateText?.bgColor | prefix:'bg', dateText?.color | prefix:'text']">
        {{date}}
      </div>

      <!-- title -->
      <div class="text-2xl font-medium font-display"
           [ngClass]="[titleText?.color | prefix:'text']">
        {{title}}
      </div>

      <!-- attributes -->
      <div *ngIf="attributes" class="flex flex-col space-y-2 space-x-0 sm:space-x-4 sm:space-y-0 sm:flex-row">
        <div class="space-x-2 flex items-center font-base text-sm font-medium"
             *ngFor="let attribute of attributes">
          <i *ngIf="attribute.startIcon" [ngClass]="attribute.startIcon"></i>
          <span>{{attribute.text}}</span>
          <i *ngIf="attribute.endIcon" [ngClass]="attribute.endIcon"></i>
        </div>
      </div>
    </div>


    <!-- perex, featured image -->
    <div class="flex flex-col-reverse sm:flex-row">
      <!-- perex -->
      <div *ngIf="perex" class="flex-1 prose max-w-none" style="color: inherit;" [innerHTML]="perex"></div>

      <!-- featured image -->
      <div class="max-w-lg py-4 px-0 sm:py-0 sm:px-4" *ngIf="image">
        <img
          [src]="image.url"
          [alt]="image.description ?? 'Featured image'">
      </div>
    </div>

    <!-- content -->
    <div class="prose max-w-none" [ngClass]="[contentColorful?.color | prefix:'text']" style="color: inherit" [innerHtml]="content"></div>

    <!-- images -->
    <div *ngIf="images" class="grid gap-4 grid-cols-2 md:grid-cols-4 cursor-pointer">
      <div class="relative" *ngFor="let image of images" (click)="attachmentClick.emit(image._id)">
        <img class="h-40 w-full object-cover" [src]="image.url" [alt]="image.description">
      </div>
    </div>

    <!-- divider -->
    <hr [ngClass]="[secondaryColor | prefix:'border']">

    <!-- DOCUMENTS -->
    <div class="flex flex-col" [ngClass]="[secondaryColor | prefix: 'text']" *ngIf="documents.length > 0">
      <div class="py-2 text-2xl font-medium font-display">PŘÍLOHY</div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div *ngFor="let doc of documents" [ngClass]="[primaryColor | prefix:'bg']"
             class="flex flex-row items-center text-xl p-2 cursor-pointer hover:text-secondary-500"
             (click)="attachmentClick.emit(doc._id)">
          <i class="fas fa-paperclip px-2"></i>
          <div class="flex flex-1 flex-col">
            <span class="font-medium font-display">{{doc.name}}</span>
            <span class="font-body text-sm">{{doc.originalFileName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


