import { Pipe, PipeTransform } from '@angular/core';
import {FormControlStatus} from "@angular/forms";

@Pipe({
  name: 'formStatusText'
})
export class FormStatusTextPipe implements PipeTransform {

  transform(value: FormControlStatus, isConcept: boolean): string {
    const isValid = (value === 'VALID' || value === 'DISABLED');
    return isValid ? 'Formulář je validní' : isConcept ? 'Neúplný KONCEPT' : 'Formulář obsahuje chyby';
  }

}
