const publishStringToFilter = (publishStatus: string) =>{
  if(publishStatus === "published"){
    return {publishedAt: {$lte: new Date()}}
  } else if(publishStatus === "concept"){
    return {publishedAt: null}
  } else {
    return {publishedAt: {$gt: new Date()}}
  }
}

export const getTransformForPublishStatus = (statuses?: { displayValue:string, _id: string }[]): Record<string, any> => {
  if(!statuses || statuses.length === 0) return {};
  const items = statuses.map(({_id}) => publishStringToFilter(_id));
  return {$or: items};
}

export const getTransformForCategories = (categories?: string[]): Record<string, any> => {
  if(!categories || categories.length === 0) return {};
  return { categories: {
    $in: categories
  }}
}
