import {IRsdFotoSoubor} from "./i-rsd-soubor";
import {IRsdLocation} from "./i-rsd-location";
import {IRsdKraj} from "./i-rsd-kraj";
import {IRsdVideo} from "./i-rsd-odkaz";

export interface IRsdSprava {
  nazev: string,
  html: string,
  lokace: IRsdLocation;
  mainImageUrl: string;
  videogalerie: IRsdVideo[];
  fotogalerie: IRsdFotoSoubor[],
  kraje: IRsdKraj[],
}
