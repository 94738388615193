import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListItemComponent} from './list-item/list-item.component';
import {ArticlePreviewComponent} from './article-preview/article-preview.component';
import {CounterComponent} from './counter/counter.component';
import {MatIconModule} from '@angular/material/icon';
import {TableItemListComponent} from './table-item-list/table-item-list.component';
import {
  CheckPipesModule,
  SharedNgFeatureCommonModule,
} from '@echo-nx/shared/ng/feature/common';
import {TimelineComponent} from './timeline/timeline.component';
import {TimelineItemComponent} from './timeline/timeline-item/timeline-item.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    CheckPipesModule,
    SharedNgFeatureCommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [
    ListItemComponent,
    ArticlePreviewComponent,
    CounterComponent,
    TableItemListComponent,
    TimelineComponent,
    TimelineItemComponent,
  ],
  exports: [
    ListItemComponent,
    ArticlePreviewComponent,
    CounterComponent,
    TableItemListComponent,
    TimelineComponent,
    TimelineItemComponent,
  ],
})
export class SharedNgUiGenericModule {
}
