import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'echo-nx-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent {

  @Input()
  public monitorHeight = 1920;

  @Input()
  public monitorWidth = 1080;

  @HostBinding('style.height.px')
  height!: number;


  /**
   * Calculates height based on width to keep desired aspect ratio.
   * @param entry
   */
  public onScreenResize(entry: ResizeObserverEntry) {
    const { contentRect } = entry;
    const { width } = contentRect;
    this.height = Math.floor(width * (this.monitorHeight / this.monitorWidth));
  }

}
