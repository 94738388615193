import { Injectable, InjectionToken } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  addReservation,
  changeReservationDate,
  useTicket,
  IMarlenkaOwner,
  IMDialogCommodity,
  IMProductType,
  IMReservation,
  IMReservationVoucher,
  IMTicketType,
  MarlenkaEntityTypes,
  readAllReservations,
  IMReservedTicket,
  useTickets,
  readReservationByUuid,
} from '@echo-nx/marlenka/common';
import { readAllCategoriesOfEntity } from '@echo-nx/shared/ng/data-access';
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult,
} from '@echo-nx/shared/common';
import { readSelectedReservations } from '@echo-nx/marlenka/common';

@Injectable()
export class BaseReservationService
  implements
    IEntityService<
      IMReservation<IMTicketType | string, IMProductType | string>
    >
{
  constructor(protected apollo: Apollo) {}

  fetchByUniqueId(
    uuid: string
  ): Observable<
    IMReservation<
      IMTicketType<IMDialogCommodity>,
      IMProductType<IMDialogCommodity>
    >
  > {
    return this.apollo
      .query<IPKApolloResult<any>>({
        query: readReservationByUuid,
        variables: {
          uuid,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  fetchAll(
    args?: FetchAllArgs,
    override?: boolean
  ): Observable<
    IPaginatedResponse<
      IMReservation<
        IMTicketType<IMDialogCommodity>,
        IMProductType<IMDialogCommodity>
      >
    >
  > {
    const variables = { ...args };
    const internalFilters = { event: { $exists: true } };
    if (args?.filter && !override) {
      //use intersection of internal and external filters
      variables.filter = JSON.stringify({
        $and: [JSON.parse(args.filter), internalFilters],
      });
    } else if (args?.filter && override) {
      //override internal with external filters
      variables.filter = args.filter;
    } else {
      //use just internal
      variables.filter = JSON.stringify(internalFilters);
    }
    return this.apollo
      .query<
        IPKApolloResult<
          IPaginatedResponse<
            IMReservation<
              IMTicketType<IMDialogCommodity>,
              IMProductType<IMDialogCommodity>
            >
          >
        >
      >({
        query: readAllReservations,
        variables,
      })
      .pipe(map((result) => result.data.response));
  }

  fetchSelected(
    ids: string[],
    includeDeleted = false
  ): Observable<IMReservation[]> {
    return this.apollo
      .query<IPKApolloResult<IMReservation[]>>({
        query: readSelectedReservations,
        variables: {
          ids: ids,
          includeDeleted,
        },
      })
      .pipe(map((res) => res.data.response));
  }

  fetchSingle(id: string, includeDeleted = false): Observable<IMReservation> {
    return this.apollo
      .query<IPKApolloResult<IMReservation[]>>({
        query: readSelectedReservations,
        variables: {
          ids: [id],
          includeDeleted,
        },
      })
      .pipe(map((res) => res.data.response[0] || null));
  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(
    reservations: IMReservation<string, string>[]
  ): Observable<MutationResult<IPKApolloResult<IMReservation[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMReservation[]>>({
      mutation: addReservation,
      variables: { input: reservations },
    });
  }

  saveAsVoucher(
    vouchers: IMReservationVoucher<string, string, string>[]
  ): Observable<MutationResult<IPKApolloResult<IMReservationVoucher[]>>> {
    return this.apollo.mutate<IPKApolloResult<IMReservationVoucher[]>>({
      mutation: addReservation,
      variables: { input: vouchers },
    });
  }

  executeMutation<
    T extends IMReservation[] | IMReservation | IMutationResponse
  >(mutation: any, vars: any) {
    return this.apollo.mutate<IPKApolloResult<T>>({
      mutation: mutation,
      variables: vars,
    });
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return this.apollo
      .query<IPKApolloResult<ICategory<IMarlenkaOwner>[]>>({
        query: readAllCategoriesOfEntity,
        variables: {
          type: this.getType(),
        },
      })
      .pipe(map((result) => result.data.response));
  }

  public changeReservationDate(
    reservationUuid: string,
    eventId: string
  ): Observable<IMReservation> {
    return this.executeMutation<IMReservation>(changeReservationDate, {
      reservationUuid,
      eventId,
    }).pipe(map((res) => res.data?.response as IMReservation));
  }

  public useTicket({
    code,
    _id,
  }: IMReservedTicket): Observable<IMutationResponse> {
    return this.executeMutation<IMutationResponse>(useTicket, {
      code,
      ticketId: _id,
    }).pipe(map((res) => res.data?.response as IMutationResponse));
  }

  public useTickets(
    tickets: IMReservedTicket[],
    reservationId: string
  ): Observable<IMutationResponse> {
    return this.executeMutation<IMutationResponse>(useTickets, {
      reservationId,
      tickets,
    }).pipe(map((res) => res.data?.response as IMutationResponse));
  }

  getType(): string {
    return MarlenkaEntityTypes.Reservations;
  }
}

export const MRESERVATION_SERVICE_TOKEN = new InjectionToken<
  IEntityService<IMReservation>
>('MRESERVATION_SERVICE_TOKEN');
