import gql from 'graphql-tag';

export const readSelectedTimelines = gql`
  query ReadSelectedTimelines($ids: [String!]){
    response: readSelectedKioskTimeline(ids: $ids){
      _id
      name
      owner{
        _id
      }
      screens{
        _id
        timeout
        screen{
          _id
        }
        transitionName
      }

    }
  }

`;