import gql from 'graphql-tag';

export const readSelectedLanguage = gql`
  query ReadSelectedLanguage($ids: [String!]!) {
    response: readSelectedLanguage(
      ids: $ids
    ) {
        _id
        name
        code
        publishedAt
      }
    }
`;
