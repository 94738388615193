import {ScreenTemplate} from '../structural';
import {WidgetTemplate} from '../structural';
import {IKioskScreen} from '../structural';
import {IBaseOwner} from "../i-base-owner";

export interface IKioskOwner extends IBaseOwner, IKioskOwnerAttributes {

}

export interface IKioskOwnerAttributes {
  availableWidgetTemplates?: WidgetTemplate[] | string[]; // v DB pole ObjectId
  availableScreenTemplates?: ScreenTemplate[] | string[]; // v DB pole ObjectId
  defaultKioskHomescreen?: IKioskScreen | string;
}
