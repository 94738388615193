import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseWeatherData, IBaseWeatherDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";
import {ContentfulSettings} from "./contentful-settings.type";

export type WeatherWidgetSettings<D extends IBaseWeatherDesign = IBaseWeatherDesign> = {
  variant?: ComponentVariant;
  weatherData?: IBaseWeatherData;
  weatherDesign?: D;
} & BaseWidgetSettings & ContentfulSettings;
