import {IEntityDefinition} from "../../interfaces";
import {IGroupFieldSettings, IWysiwygFieldSettings} from "../../modules/form-fields";

export const getHtmlViewerWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [

    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          // DATA
          {
            name: 'PkInputWysiwygComponent',
            settings: {
              nazev: 'Text',
              formControlName: 'html'
            } as IWysiwygFieldSettings,
            data: null,
            validators: [],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
  ]
}
