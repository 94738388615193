import gql from 'graphql-tag';

// todo move to base common
export const readOwnerSettings = gql`
  query ReadOwnerSettings {
    response: readOwnerSettings{
      daysBeforeReservationCanBeChanged
      maximumInvoiceItems
      reportRecipients
    }
  }
`;
