import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotoCardVariantBComponent} from "./photo-card-variant-b.component";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    PhotoCardVariantBComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    PhotoCardVariantBComponent
  ]
})
export class PhotoCardVariantBModule {
}
