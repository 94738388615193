import {Component, Input, OnInit} from '@angular/core';
import {UnionContact} from "@echo-nx/shared/common";
import {
  CommonpageTexts,
  FooterTexts,
  IMarlenkaOwner,
  MarlenkaPageIdentifier,
  MarlenkaRouteData
} from "@echo-nx/marlenka/common";
import {PageSettingService} from "../../services/page-setting.service";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";
import {ScrollableService} from "@echo-nx/shared/ng/feature/common";
import {ContactService} from "../../services/contact.service";
import {CookieConsentManager} from "@echo-nx/shared/ng/feature/cookies";
import {map, switchMap} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'marlenka-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent {
  public footerTexts$: Observable<FooterTexts>;

  @Input()
  public contact!: UnionContact<IMarlenkaOwner>;

  constructor(
    private pageSettingService: PageSettingService,
    public mrdService: LanguageRouteDataService<MarlenkaRouteData>,
  ) {
    this.footerTexts$ = this.mrdService.currentLanguage$.pipe(switchMap((language) =>
      this.pageSettingService.getPageSettingsByIdentifier<CommonpageTexts>(
        MarlenkaPageIdentifier.COMMON,
        language.toLowerCase()
      ).pipe(map((pageSettings) => pageSettings.texts.footer))
    ));

  }
}
