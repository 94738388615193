import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectFieldComponent} from "./select-field.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [SelectFieldComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FlexModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class SelectFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkSelectFieldComponent', SelectFieldComponent);
  }
}
