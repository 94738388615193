import {AfterViewInit, Component, Injector, OnDestroy} from '@angular/core';
import {MarlenkaBasePageComponent} from '../../marlenka-base-page/marlenka-base-page.component';
import {CakesExclusiveService} from '../../../services/cakes-exclusive.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {NavigationService} from '../../../services/navigation.service';
import {CakesExclusiveDetailPageTexts, IMarlenkaOwner, IMSubnavigationItem} from "@echo-nx/marlenka/common";
import {IArticle, ICategory, IMedium} from "@echo-nx/shared/common";

@Component({
  selector: 'marlenka-cakes-exclusive-category-detail',
  templateUrl: './cakes-exclusive-category-detail.component.html',
  styleUrls: ['./cakes-exclusive-category-detail.component.scss']
})
export class CakesExclusiveCategoryDetailComponent extends MarlenkaBasePageComponent<CakesExclusiveDetailPageTexts> implements AfterViewInit, OnDestroy {

  public cakesExclusive$: Observable<IArticle<IMarlenkaOwner,IMedium<any>>[]>;
  public selectedCategory$: Observable<ICategory<any>>;

  constructor(injector: Injector,
              private cakesExclusiveService: CakesExclusiveService,
              private activeRoute: ActivatedRoute,
              protected navigationService: NavigationService,
              private router: Router) {
    super(injector);
    this.cakesExclusive$ = combineLatest([this.mrdService.currentLanguage$,this.activeRoute.params]).pipe(
      filter(([_,params]) => !!params?.categoryId),
      switchMap(([language,params]) => {
        const {categoryId} = params;
        return this.cakesExclusiveService.fetchAllForLanguageGroupCategory(categoryId,language);
      })
    );

    const allCategories$ = this.mrdService.currentLanguage$.pipe(switchMap((lang =>this.cakesExclusiveService.fetchCategories(lang))));

    this.selectedCategory$ = combineLatest([allCategories$, this.activeRoute.params]).pipe(
      filter(([_,params]) => !!params?.categoryId),
      tap(([categories])=> {
        this.navigationService.setSubnavigationItems(
          categories.map((category => ({
            link: this.router.createUrlTree([`/${this.mrdService.currentLanguage.toLowerCase()}/cakes-exclusive/${category.languageGroupId}`]).toString(),
            title: category.name
          }) as IMSubnavigationItem))
        );
      }),
      map(([categories,params]) => {
        const {categoryId} = params;
        return categories.find(cat => cat.languageGroupId === categoryId)
      })
    );
  }

  ngAfterViewInit(): void {
    return;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationService.setSubnavigationItems(undefined);
  }

}
