import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {firstValueFrom, Observable, Subject, tap} from "rxjs";
import {map, switchMap, takeUntil,} from "rxjs/operators";
import {ReservationService, TicketService} from "@echo-nx/marlenka/ng/data-access";
import {BaseLoadingService} from "@echo-nx/shared/ng/feature/loading";
import {IMarlenkaOwner, IMDialogCommodity, IMTicketType, MarlenkaRouteData} from "@echo-nx/marlenka/common";
import {ICategory} from "@echo-nx/shared/common";
import {createCustomSnackbar} from "@echo-nx/shared/ng/feature/snackbar";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TICKET_STEP_COMPONENT_INPUT, TicketStepComponentInput} from "../../../utils";
import {LanguageRouteDataService} from "@echo-nx/shared/ng/feature/language";


@Component({
  selector: 'marlenka-tickets-step',
  templateUrl: './tickets-step.component.html',
  styleUrls: ['./tickets-step.component.scss']
})
export class TicketsStepComponent implements OnInit, OnDestroy {
  private readonly isDestroyed$ = new Subject<boolean>();
  public categorizedTickets$!: Observable<ICategorizedTicket[]>;

  constructor(
    private mrdService: LanguageRouteDataService<MarlenkaRouteData>,
    public reservationService: ReservationService,
    private ticketService: TicketService,
    private matSnackBar: MatSnackBar,
    private loadingService: BaseLoadingService,
    @Optional()
    @Inject(TICKET_STEP_COMPONENT_INPUT)
    public data?: TicketStepComponentInput
  ) {
  }

  async ngOnInit() {
    this.reservationService.onNextFailed$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      createCustomSnackbar('Nepovolený počet vstupenek k rezervaci.', this.matSnackBar);
    });
    this.categorizedTickets$ = this.mrdService.currentLanguage$.pipe(
      takeUntil(this.isDestroyed$),
      switchMap((lang) =>{
        this.loadingService.startLoading();
        return this.ticketService.fetchAll({filter: JSON.stringify({language: lang}), sortDirection: "ASC", sortColumn: 'publishedAt'});
      }),
      map(res => res.items as IMTicketType<string | IMDialogCommodity>[]),
      map(tickets => {
          return tickets.reduce((acc, curr) => {
            // get the category
            const category = curr.categories?.[0] as ICategory<IMarlenkaOwner>;
            if (!category) return acc;

            // attempt to find the category
            const groupIndex = acc.findIndex(group => group._id === category._id);

            // category already exists, just add the ticket
            if (groupIndex !== -1) {
              acc[groupIndex].tickets.push(curr);
            } else {
              // category doesnt exist yet, create it
              acc.push({
                _id: category._id as string,
                name: category.name,
                description: category.description,
                tickets: [curr]
              })
            }
            return acc;
          }, [] as ICategorizedTicket[])
        }
      ),
      tap(()=> this.loadingService.stopLoadingAll())
    );
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.unsubscribe();
  }

}

interface ICategorizedTicket {
  _id: string;
  name: string;
  description?: string;
  tickets: IMTicketType[];
}
