import gql from 'graphql-tag';

export const readSelectedEvents = gql`
  query ReadSelectedEvents($ids: [String!]!) {
    response: readSelectedEvent(ids: $ids) {
      _id
      availableCapacity
      maximumCapacity
      language
      start
      duration
      categories {
        _id
        name
      }
      eventTopic {
        _id
        name
        nameShort
        warningTitle
        warningContent
      }
      eventLanguage {
        _id
        name
        nameShort
        warningTitle
        warningContent
      }
    }
  }
`;
