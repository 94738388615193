import {IOwnedEntity} from "./i-owned-entity";
import {IBaseOwner} from "./i-base-owner";

export interface IApiIntegration<O extends IBaseOwner | string = any, S = any> extends IOwnedEntity<O> {
  name: string;
  endpoint: string;
  method: 'post' | 'get';
  integrationType: string; // dict key name
  fetchOptions?: {
    updatedAtKeyName?: string; // jak endpoint nazyva updatedAt v jejich modelu // this is mostly unused now
    headers?: Record<string, string>;
    queryString?: any; // this is mostly unused now
    body?: any;
  }
  headers?: any;
  body?: any;
  cronExpression?: string;
  isActive?: boolean;
  settings?: S; // concrete settings for mapper functions
}
