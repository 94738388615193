import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WysiwygFieldComponent} from './wysiwyg-field.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FroalaEditorModule} from "angular-froala-wysiwyg";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";


@NgModule({
  declarations: [
    WysiwygFieldComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    FroalaEditorModule
  ]
})
export class WysiwygFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('PkInputWysiwygComponent', WysiwygFieldComponent,);
  }
}
