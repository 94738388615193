import {NativeDateAdapter} from '@angular/material/core';
import * as moment from 'moment';
import {Injectable} from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // upravuje mat-datetimepicker tak, aby pondelok bol prvy den v tyzdni a upravuje input datetime
  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): Date | null {
    if (typeof value === 'string' && value.includes('T')) {
      return moment(value).toDate();
    }
    return moment(value, "DD.MM.YYYY hh:mm").toDate();
  }


}
