import {IBaseFormFieldSettings} from "../base-form-field.settings";
import {UntypedFormGroup} from "@angular/forms";

export interface IColorPickerFieldSetting extends IBaseFormFieldSettings {
  showAlways?: boolean;
  suffixAction?: {
    icon: string;
    hint?: string;
    onActionClick: (settings: IBaseFormFieldSettings, formGroup: UntypedFormGroup) => void
  }
}
