export type WithBadge = {
  matBadgeDesign?: {
    matBadgePosition?: 'above after' | 'above before' | 'below before' | 'below after' | 'before' | 'after' | 'above' | 'below';
    matBadgeSize?: 'small' | 'medium' | 'large';
  };
  matBadgeData?: {
    title?: string;
  };
  dynamicBadgeData?: {
    prefix?: DynamicBadgeData;
    suffix?: DynamicBadgeData;
    settings?: DynamicBadgeDataSettings;
  }

}

export type DynamicBadgeData = 'active-filter'

export type DynamicBadgeDataSettings = {
  filterTemplate?: string; // for active-filter
}
