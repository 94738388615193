import {ChangeDetectionStrategy, Component, OnChanges} from '@angular/core';
import {IBasePaginationData, IBasePaginationDesign} from "@echo-nx/shared/common";
import {BasePaginationComponent} from "../base";

@Component({
  selector: 'echo-nx-pagination-variant-b',
  templateUrl: './pagination-variant-b.component.html',
  styleUrls: ['./pagination-variant-b.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationVariantBComponent extends BasePaginationComponent implements OnChanges, IBasePaginationDesign, IBasePaginationData {


}
