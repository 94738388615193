import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemDetailVariantAComponent } from './item-detail-variant-a.component';
import {PrefixModule} from "../../prefix/prefix.module";




@NgModule({
  declarations: [
    ItemDetailVariantAComponent
  ],
  imports: [
    CommonModule,
    PrefixModule
  ],
  exports: [
    ItemDetailVariantAComponent
  ],
})
export class ItemDetailVariantAModule { }
