import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {IBasePaginationData, IBasePaginationDesign, IEpxColorful} from "@echo-nx/shared/common";
import {BehaviorSubject, combineLatest, delay, distinctUntilChanged, Observable, of, Subject, tap} from 'rxjs';
import {IPaginatorButton} from "./i-paginator-button";
import {ResizeObserverService} from "@echo-nx/shared/ng/feature/responsive";
import {createDynamicButtons} from "./dynamic-paginator";
import {debounceTime, map} from "rxjs/operators";

@Component({
  selector: 'echo-nx-base-pagination',
  template: '',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasePaginationComponent implements OnInit, OnChanges, OnDestroy, IBasePaginationDesign, IBasePaginationData, AfterViewInit {

  public activeBreakpoints: string[] = [];

  private isDestroyed = new Subject<void>();

  @Input()
  normalColorful?: IEpxColorful;

  @Input()
  activeColorful?: IEpxColorful;

  @Input()
  disabledColorful?: IEpxColorful;

  @Input()
  bgColor?: string;

  @Input()
  textSize?: string;

  @Input()
  page: number;

  @Input()
  take: number;

  @Input()
  total: number;

  public buttons$: Observable<IPaginatorButton[]>;

  @Input()
  breakpoints = {
    small: 230,
    compact: 640,
    big: 960
  }

  private _total = new BehaviorSubject<number>(0);
  public total$ = this._total.asObservable();

  constructor(protected el: ElementRef, public responsiveService: ResizeObserverService/*, public change: ChangeDetectorRef*/) {
  }

  get activeIndex() {
    return this._activeIndex.value
  };

  private _activeIndex = new BehaviorSubject<number>(0);
  public activeIndex$ = this._activeIndex.asObservable();

  @Output()
  public pageSelected = new EventEmitter<number>();

  @Input()
  set design({
               disabledColorful,
               activeColorful,
               normalColorful,
               bgColor,
               breakpoints
             }: IBasePaginationDesign) {
    this.breakpoints = breakpoints ?? this.breakpoints;
    this.disabledColorful = disabledColorful;
    this.activeColorful = activeColorful;
    this.normalColorful = normalColorful;
    this.bgColor = bgColor;
  }

  @Input()
  set data(data: IBasePaginationData) {
    if (data) {
      const {page, take, total} = data;
      this.page = page;
      this.take = take;
      this.total = total;
    }
  }

  hasPrevious() {
    return this.activeIndex > 0;
  }

  hasNext(): boolean {
    return this.activeIndex < Math.ceil(this.total / this.take) - 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.total?.previousValue !== changes.total?.currentValue) {
      this._total.next(changes.total.currentValue);
    }
  }

  public nextClicked() {
    if (this.hasNext) {
      this.pageSelected.emit(this.activeIndex + 1);
      this._activeIndex.next(this.activeIndex + 1);
    }
  }

  public previousClicked() {
    if (this.hasPrevious) {
      this.pageSelected.emit(this.activeIndex - 1);
      this._activeIndex.next(this.activeIndex - 1);
    }
  }

  public pageClicked(index: number) {
    this.pageSelected.emit(index);
    this._activeIndex.next(index);
  }

  ngOnDestroy(): void {
    this.isDestroyed.next();
    this.isDestroyed.complete();
  }

  ngAfterViewInit() {
    return;
  }

  ngOnInit(): void {
    this.buttons$ = combineLatest([
        this.responsiveService.observeBreakpoints(this.el, {
          breakpoints: this.breakpoints
        }),
      //of(['compact']),
        this.activeIndex$,
        this.total$,
      ]
    ).pipe(
      /*distinctUntilChanged(([breakpointsPrev, indexPrev, totalPrev], [breakpointsCurr, indexCurr, totalCurr]) => {
          console.log('DIST UNTIL CHANGED', [breakpointsPrev, indexPrev, totalPrev], [breakpointsCurr, indexCurr, totalCurr])
          return (breakpointsCurr.length === breakpointsPrev.length) &&
            (indexPrev === indexCurr) &&
            (totalPrev === totalCurr)
        }
      ),*/
      map(
        ([breakpoints, index, total]) => {
          let maxCount = 5;
          // console.log("BREAKPOINTS BROTHER", breakpoints);
          if (!breakpoints || breakpoints?.length === 0) {
            // specific case where we will show only counter and prev/next buttons
            return [];
          }
          if (breakpoints.includes('big')) {
            maxCount = 9;
          } else if (breakpoints.includes('compact')) {
            maxCount = 7;
          } else if (breakpoints.includes('small')) {
            maxCount = 5;
          }
          return createDynamicButtons(Math.ceil(total / this.take), maxCount, index + 1);
        })
    )
  }
}


