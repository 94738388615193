import {Inject, Injectable, Optional} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ComponentType, Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {CookieConsentDialogComponent} from "./cookie-consent-dialog/cookie-consent-dialog.component";
import {take} from "rxjs/operators";
import {COOKIE_CONSENT_CONFIG, CookieConsentConfig} from "./types/config";
import {DynamicComponentRegistryService} from "@echo-nx/shared/ng/feature/common";
import {CookieConsentDialog} from "./types/cookie-consent-dialog";
import {Cookie, cookieStorageArray} from "./types/cookie";
import {GoogleTagManagerService} from "@echo-nx/shared/ng/feature/gtm";

@Injectable()
export class CookieConsentManager {
  private overlayRef?: OverlayRef;
  private readonly overlayConfig!: OverlayConfig;

  private readonly cookieName!: string;
  private _consentCookie: BehaviorSubject<Cookie | undefined>;
  public consentCookie$: Observable<Cookie | undefined>;

  constructor(
    private overlay: Overlay,
    @Inject(COOKIE_CONSENT_CONFIG) private cookieConsentConfig: CookieConsentConfig,
    private componentRegistry: DynamicComponentRegistryService,
    @Optional() private gtmService: GoogleTagManagerService) {
    // create default overlay config. later improvements: can use cookieConsentConfig to pass additional positions
    this.overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      positionStrategy: this.overlay.position()
        .global()
        .bottom()
        .left('0')
        .right('0')
    })
    this.cookieName = this.cookieConsentConfig.cookieName ?? 'epx-cookies-consent';

    const consentCookie = this.getCookie();
    this._consentCookie = new BehaviorSubject<Cookie | undefined>(undefined);
    this.consentCookie$ = this._consentCookie.asObservable();

    if (!consentCookie) {
      this.openDialog();
    }
  }

  async saveCookie(cookie: Cookie){
    this._consentCookie.next(cookie);
    this.overlayRef?.detach();
    this.setCookie(cookie);
    if(this.gtmService){
      return this.sendConsentsToGtm(cookie);
    }
  }

  async allowAll() {
    const allTrueCookie = cookieStorageArray.reduce((acc: Cookie, curr: keyof Cookie) => {
      acc[curr] = true;
      return acc;
    }, {} as Cookie);
    return this.saveCookie(allTrueCookie);
  }

  denyAll() {
    const allFalseCookie = cookieStorageArray.reduce((acc: Cookie, curr: keyof Cookie) => {
      acc[curr] = false;
      return acc;
    }, {} as Cookie);
    // necessary cookie
    allFalseCookie.functionality_storage = true;
    return this.saveCookie(allFalseCookie);
  }


  async sendConsentsToGtm(cookie: Cookie) {
    //convert our Cookie to structure GTM wants
    /*
      {
        ad_storage: 'denied',
        analytics_storage: 'granted'
      }
     */
    const consentObject = (Object.keys(cookie) as Array<keyof Cookie>).reduce((acc: any, curr: keyof Cookie) => {
      acc[curr] = cookie[curr] ? 'granted' : 'denied';
      return acc;
    }, {} as any);

    //this.gtmService.pushTag()
    return this.gtmService?.pushTag({event:'gtm.update_consent',consents: consentObject});
  }

  public getCookie(): Cookie | null {

    let result = document.cookie.match(new RegExp(this.cookieName + '=([^;]+)'));
    console.log('cookie at startup',result);
    result && (result = JSON.parse(result[1]));
    return result as Cookie;

  }

  public setCookie(cookie: Cookie) {
    if(!cookie){
      return;
    }
    const strValue: string = JSON.stringify(cookie);

    const {expiryDays, domain, secure, sameSite} = this.cookieConsentConfig;

    const expDate = new Date()
    const expHours = (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
    expDate.setHours(expDate.getHours() + expHours)

    document.cookie = `${this.cookieName}=${strValue};path=/;expires=${expDate.toUTCString()};domain=${domain ?? ''};secure=${secure ?? ''};sameSite=${sameSite ?? 'Lax'}`;
  }

  public openDialog() {
    this.overlayRef = this.overlay.create(this.overlayConfig);

    const {dynamicComponentKey} = this.cookieConsentConfig;
    const componentType: ComponentType<CookieConsentDialog> | undefined = dynamicComponentKey ? this.componentRegistry.get(dynamicComponentKey) : CookieConsentDialogComponent;

    if (componentType) {
      const component = this.overlayRef.attach(new ComponentPortal(componentType));
      if (!component.instance?.consentChanged) {
        throw new Error('Cookie consent dialog must implement CookieConsentDialog interface!')
      }

      component.instance.consentChanged?.pipe(
        take(1)
      ).subscribe(res => {
        this.saveCookie(res);
      });
    } else {
      throw new Error('Cookie consent dialog component could not be spawned. Did you register it in component registry?')
    }
  }

}
