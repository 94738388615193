import {Injectable} from '@angular/core';
import {v4 as uuid_v4} from 'uuid';
import {Gallery, GalleryRef} from "ng-gallery";

@Injectable()
export class LightboxGroupService {
  public galleryRef!: GalleryRef;
  public galleryId = uuid_v4();


  constructor(private gallery: Gallery) {
    this.galleryRef = this.gallery.ref(this.galleryId, {
      loop: false,
      imageSize: 'contain',
      nav: true,
      thumb: false,
      counter: false,
    });
  }
}
