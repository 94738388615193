import gql from 'graphql-tag';

export const readAllProductTypes = gql`
  query ReadAllProductTypes($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $filter: String, $includeNotPublished: Boolean) {
    response: readAllProductTypes(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      filter: $filter,
      includeNotPublished: $includeNotPublished,
    ) {
      total
      page
      take
      hasMore
      items {
        _id
        language
        languageGroupId
        description
        publishedAt
        image{
          _id
          url
          description
          type
          categories {
            _id
            name
          }
        }
        name
        commodityType {
          _id
          price
          articleName
        }
      }
    }
  }
`;
