import gql from 'graphql-tag';

export const readSelectedDistributionPlace = gql`
  query ReadSelectedDistributionPlace($ids: [String!]!) {
    response: readSelectedDistribution(
      ids: $ids
    ) {
      categories {
        _id
        name
      }
      _id
      language
      languageGroupId
      createdAt
      name
      openingHours
      priority
      contact {

          _id
          name
          firstName
          lastName
          telephones
          emails
          websites
          name
          ico
          dic
          dataBox
          tradeRegisterText
          companyName
          bankInfo{
            bankName
            iban
            swift
            bankNumber
            bankCode
          }
          address{
            city
            province
            state
            zipcode
            street
          }

      }
    }
  }
`;

