export interface IPaginatedResponse<TItem = any> {
  items?: TItem[];
  total?: number;
  page?: number;
  take?: number;
  hasMore?: boolean;
}

export interface IPKApolloResult<T> {
  response: T
}

export interface IPaginatedLanguageGroup<TItem = any> {
  _id: string;
  docs: TItem[];
}
