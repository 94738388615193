import {Injectable, InjectionToken} from '@angular/core';
import {Apollo, MutationResult} from "apollo-angular";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {
  addPageSettings,
  IMarlenkaOwner,
  IMPageSettings, MarlenkaPageIdentifier, MarlenkaRouteData,
  readAllPageSettings,
  readPageSettingsByIdentifier, readPageSettingsLanguageGroups
} from "@echo-nx/marlenka/common";
import {
  FetchAllArgs,
  ICategory,
  IEntityService,
  IPaginatedLanguageGroup,
  IPaginatedResponse,
  IPKApolloResult
} from "@echo-nx/shared/common";



@Injectable()
export class BasePageSettingsService implements IEntityService<IMPageSettings> {

  constructor(protected apollo: Apollo) {
  }

  fetchAll(args: FetchAllArgs): Observable<IPaginatedResponse<IMPageSettings>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<IMPageSettings>>>({
      query: readAllPageSettings,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchLanguageGroups(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<IMPageSettings>> {
    return this.apollo.query<IPKApolloResult<IPaginatedLanguageGroup<IMPageSettings>>>({
      query: readPageSettingsLanguageGroups,
      variables: args
    }).pipe(
      map(result => result.data.response)
    );
  }

  fetchSelected(ids: string[]): Observable<IMPageSettings[]> {
    throw Error('NOT IMPLEMENTED :(');

  }

  fetchSingle(id: string): Observable<IMPageSettings> {
    throw Error('NOT IMPLEMENTED :(');
    /*return this.fetchAll({
      filter: JSON.stringify({
        _id: id
      })
    })
      .pipe(
        map(result => result?.items?.[0]),
        switchMap(({identifier}) => this.getPageSettingsByIdentifier(identifier, this.mrdService.currentLanguage))
      )
        filter(Boolean),
        switchMap(({identifier, language}) => this.getPageSettingsByIdentifier(identifier, language))
      )*/
  }

  executeMutation(mutation: any, vars: any) {
    throw Error('NOT IMPLEMENTED :(');
  }

  fetchCategories(): Observable<ICategory<IMarlenkaOwner>[]> {
    return of([]);

  }

  delete(ids: string[]): Observable<any> {
    throw Error('NOT IMPLEMENTED :(');
  }

  save(pageSettings: IMPageSettings[]): Observable<MutationResult<IPKApolloResult<IMPageSettings[]>>> {
    // parse input - need to stringify text
    const parsedInput = pageSettings.map(pageSetting => ({
      ...pageSetting,
      texts: JSON.stringify(pageSetting.texts)
    }));

    return this.apollo.mutate<IPKApolloResult<IMPageSettings[]>>({
      mutation: addPageSettings,
      variables: {input: parsedInput}
    });
  }

  public getPageSettingsByIdentifier<T>(identifier: MarlenkaPageIdentifier, language: string): Observable<IMPageSettings<T>> {
    return this.apollo.query<IPKApolloResult<IMPageSettings<T>>>({
      query: readPageSettingsByIdentifier,
      variables:
        {
          language,
          identifier
        }
    }).pipe(
      map(res => res.data.response)
    )
  }

  getType(): string {
    return '';
  }

}

export const MPAGESETTINGS_SERVICE_TOKEN = new InjectionToken<IEntityService<IMPageSettings>>('MPAGESETTINGS_SERVICE_TOKEN');
