import {AfterViewInit, Component, Injector, OnDestroy} from '@angular/core';
import {MarlenkaBasePageComponent} from '../marlenka-base-page/marlenka-base-page.component';
import {Observable, switchMap} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../services/navigation.service';
import {CakesExclusiveService} from '../../services/cakes-exclusive.service';
import {ICategory} from "@echo-nx/shared/common";
import {IMarlenkaOwner, IMSubnavigationItem} from "@echo-nx/marlenka/common";
import {CakesExclusivePageTexts} from "@echo-nx/marlenka/common";


@Component({
  selector: 'marlenka-cakes-exclusive-page',
  templateUrl: './cakes-exclusive-page.component.html',
  styleUrls: ['./cakes-exclusive-page.component.scss']
})
export class CakesExclusivePageComponent extends MarlenkaBasePageComponent<CakesExclusivePageTexts> implements AfterViewInit, OnDestroy {

  public categories$!: Observable<ICategory<IMarlenkaOwner>[]>;

  constructor(injector: Injector,
              private cakesExclusiveService: CakesExclusiveService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private navigationService: NavigationService) {
    super(injector);
  }

  ngAfterViewInit() {
    this.categories$ = this.mrdService.currentLanguage$.pipe(switchMap((lang => this.cakesExclusiveService.fetchCategories(lang).pipe(
      takeUntil(this.isDestroyed$),
      tap((categories) => {
        this.navigationService.setSubnavigationItems(
          categories.map((category => ({
            link: this.router.createUrlTree([`./${category.languageGroupId}`], {relativeTo: this.activeRoute}).toString(),
            title: category.name
          }) as IMSubnavigationItem))
        );
      })
    ))));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.navigationService.setSubnavigationItems(undefined);
  }

}
