import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntityPickerFieldComponent} from './entity-picker-field.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  CheckPipesModule,
  DynamicComponentRegistryService,
  FunctionPipeModule, ResolveKeyPipeModule
} from "@echo-nx/shared/ng/feature/common";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {PortalModule} from "@angular/cdk/portal";
import {MatChipsModule} from "@angular/material/chips";
import {MatDialogModule} from "@angular/material/dialog";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SharedNgFeatureSortableGridModule} from "@echo-nx/shared/ng/feature/sortable-grid";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    EntityPickerFieldComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FlexModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        SharedNgFeatureSortableGridModule,
        CheckPipesModule,
        ResolveKeyPipeModule,
        FunctionPipeModule,
        MatInputModule,
        MatButtonModule,
        PortalModule,
        MatChipsModule,
        MatDialogModule,
        DragDropModule,
        TranslateModule
    ]
})
export class EntityPickerFieldModule {
  constructor(private componentRegistry: DynamicComponentRegistryService) {
    this.componentRegistry.add('EntityPickerFieldComponent', EntityPickerFieldComponent);
  }
}
