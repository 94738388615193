import {Directive, Input, OnInit} from '@angular/core';
import {LightboxGroupService} from "../services/lightbox-group.service";

@Directive({
  selector: '[echoNxLightboxGalleryGroup]',
  providers: [LightboxGroupService]
})
export class LightboxGroupDirective implements OnInit {

  @Input()
  withThumbnails = false;

  // this thing provides the gallery for given group
  constructor(public galleryGroupService: LightboxGroupService) {
  }

  ngOnInit() {
    this.galleryGroupService.galleryRef.setConfig({thumb: this.withThumbnails})
  }

  public clearGroup() {
    this.galleryGroupService.galleryRef.reset();
  }
}
