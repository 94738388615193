import {BaseDocumentType} from "@echo-nx/shared/common";
import {IMDialogCommodity} from "./dialog";

export interface IDiscountCoupon extends BaseDocumentType {
  // type: 'ticket' | 'product' | string;
  commodity?: IMDialogCommodity;
  code: string;
  price: number;
  name: string;
  personCount: number;
  expires_at: Date;
  issuer: 'Marlenka' | 'Slevomat' | 'BeskydyCard' | string; // slevomat, beskydy?
}


export interface CouponValidationResult {
  error: boolean;
  message?: string;
  data?: IDiscountCoupon;
}
