import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'echo-nx-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss']
})
export class ArticlePreviewComponent {

  @Input()
  public imgSrc!: string;

  @Input()
  public subtitle!: string;

  @Input()
  public title!: string;

  @Input()
  public routeTo?: string;

  @Input()
  public flexSettings: FlexSettings;

  @Input()
  @HostBinding('class')
  public panelClass?:string;

  constructor() {
    this.flexSettings = {
      isColumn: false,
      reverse: false
    };
  }

}


export interface ArticleButtons {
  title: string;
  url?: string;
}

export interface FlexSettings {
  isColumn: boolean;
  reverse: boolean;
}
