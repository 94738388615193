<ng-container *ngIf="(pageSettings$ | async)?.texts as texts">
  <marlenka-jumbotron
    [title]="texts.jumbotron.title"
    [subtitle]="
      texts.jumbotron.subtitle
    "
    [topWave]="true"
    background="url('assets/distribution.png')"
    id="distribution-jumbotron"
  >
    <div class="wrapper container small my-4">
      <div class="distribution-grid">
        <a
          class="col"
          *ngFor="let dPlace of distributionPlaces$ | async"
          [routerLink]="dPlace.languageGroupId"
        >
          <img [src]="dPlace.featuredImage.url" alt="" />
          <div class="title">
            {{ dPlace.name
            }}<span
              ><img src="../../../assets/arrow_right.svg" class="icon"
            /></span>
          </div>
        </a>
      </div>
    </div>
  </marlenka-jumbotron>
</ng-container>
