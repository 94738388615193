import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IMedium, INavigationItem} from "@echo-nx/shared/common";

@Component({
  selector: 'echo-nx-nav-mobile',
  templateUrl: './nav-mobile.component.html',
  styleUrls: ['./nav-mobile.component.scss']
})
export class NavMobileComponent {

  @Output()
  itemClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  navClosed: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  navigation: INavigationItem;

  @Input()
  primaryColor: string;

  @Input()
  bgColor = 'white';

  @Input()
  textColor = 'black';

  @Input()
  logo: IMedium;
}
