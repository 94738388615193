import { Component } from '@angular/core';
import {BaseSliderComponent} from "../base/base-slider.component";
import {state, style, transition, trigger} from "@angular/animations";
import {fadeIn, fadeOut, scaleIn, scaleOut, slide} from "@echo-nx/shared/ng/feature/animations";

@Component({
  selector: 'echo-nx-slider-variant-a',
  templateUrl: './slider-variant-a.component.html',
  styleUrls: ['./slider-variant-a.component.scss'],
  animations: [
    trigger('sliderAnimator', [
      state("none", style({opacity: 0})),
      transition("* => fade", fadeIn),
      transition("fade => *", fadeOut),
      transition("* => slide", slide),
      transition("slide => *", slide),
      transition("* => scale", scaleIn),
      transition("scale => *", scaleOut),
    ]),
  ]
})
export class SliderVariantAComponent extends BaseSliderComponent {

  animationStyle: "slide" | "fade" | "scale" = "fade";
}
