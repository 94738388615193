import gql from "graphql-tag";

export const deletePartnerStore = gql`
  mutation deleteMPartnerStore($input: [String!]!){
    response: deletePartnerStore(ids: $input){
      userMessage
      description
      error
    }
  }
`;
