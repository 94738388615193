import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionPipe',
  pure: true
})
export class FunctionPipe implements PipeTransform {

  transform(func: (...args: any[]) => any, ...args: any[]): any {
    return func(...args);
  }

}
