export enum MarlenkaPageIdentifier {
  // concrete
  HOMEPAGE = 'HOMEPAGE',
  ABOUTPAGE = 'ABOUTPAGE',
  PRODUCTS = 'PRODUCTS',
  PRODUCTS_DETAIL = 'PRODUCTS_DETAIL',
  CAKES_EXCLUSIVE = 'CAKES_EXCLUSIVE',
  CAKES_EXCLUSIVE_DETAIL = 'CAKES_EXCLUSIVE_DETAIL',
  EXCURSION = 'EXCURSION',
  CAREER = 'CAREER',
  DISTRIBUTION = 'DISTRIBUTION',
  CONTACT = 'CONTACT',
  ARTICLES = 'ARTICLES',
  ARTICLES_REVIEWS = 'ARTICLES_REVIEWS',
  AWARDS = 'AWARDS',
  CERTIFICATES = 'CERTIFICATES',
  VIDEOS = 'VIDEOS',
  CREATE = 'CREATE',
  CREATE_RESERVATIONANDVOUCHER = 'CREATE_RESERVATIONANDVOUCHER',
  VIRTUAL_TOUR = 'VIRTUAL_TOUR',
  // shared
  COMMON = 'COMMON',
}
