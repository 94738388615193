import {Component, Input} from '@angular/core';

@Component({
  selector: 'marlenka-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent {

  @Input()
  public title!: string;

  @Input()
  public subtitle?: string;

  @Input()
  public wrapClass?: string;


  @Input()
  public background?: string; // #fff, image, whatever. todo maybe sanitize with DomSanitizer necessary

  @Input()
  public backgroundPosition?: string; // for some text-fixes - vertical

  @Input()
  public textColor?: string;// color of title and subtitle

  @Input()
  public withDivider = true; // if title have underline;

  @Input()
  public dividerColor?: string; // divider color

  @Input()
  public dividerWave = false; // if title underline is wave

  @Input()
  public topArrowFragment?: string;

  @Input()
  public bottomArrowFragment?: string;

  @Input()
  public minHeight?: string; // min-height applied if there is bg image

  @Input()
  public topWave: boolean = false; // svg wave on top of the Jumbotron

}
