import gql from 'graphql-tag';
import {
  LAYOUT_FRAGMENT
} from '../fragments';

export const readSelectedTimelinesWithScreens = gql`
  query ReadSelectedTimelines($ids: [String!]){
    response: readSelectedKioskTimeline(ids: $ids){
      _id
      name
      screens{
        timeout
        screen{
          _id
          name
          owner{
            _id
          }
          slug
          background{
            color
            image{
              _id
              type
              url
              thumbnailUrl
              mime
            }
            size
            position
            origin
            repeat
            clip
            attachment
          }
          zones{
            background {
              color
              image{
                url
              }
            }
            display
            flexDirection
            justifyContent
            alignItems
            justifySelf
            alignSelf
            gridColumnStart
            gridColumnEnd
            gridRowStart
            gridRowEnd
            layouts{
              ... LayoutUnionFragment
            }
          }
        }
        transitionName
      }
      owner{
        _id
        name
      }
    }
  }
  ${LAYOUT_FRAGMENT}
`;
