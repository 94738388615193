<echo-nx-table-item-list
  [fields]="fields"
  [items]="items | async"
  [selectable]="false"
>
  <ng-template echoNxTableKey="delete-cell" let-item>
    <button mat-icon-button (click)="remove.emit(item)">
      <mat-icon style="color: #AB0E0E">delete_forever</mat-icon>
    </button>
  </ng-template>

</echo-nx-table-item-list>
