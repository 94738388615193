import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarVariantAComponent} from "./calendar-variant-a.component";
import {DateModule, FirstDayOfMonthModule} from "@echo-nx/shared/ng/feature/common";
import {PrefixModule} from "../../prefix/prefix.module";


@NgModule({
  declarations: [
    CalendarVariantAComponent
  ],
  imports: [
    CommonModule,
    FirstDayOfMonthModule,
    DateModule,
    PrefixModule
  ],
  exports: [
    CalendarVariantAComponent
  ]
})
export class CalendarVariantAModule {
}
