// NEW STUFF:
import {IOwnedEntity} from "./i-owned-entity";
import {FetchAllArgs} from "./i-fetch-all-args";
import {Observable} from "rxjs";
import {IPaginatedLanguageGroup, IPaginatedResponse, IPKApolloResult} from "./i-paginated-response";
import {ICategory} from "./i-category";
import {IMutationResponse, MutationResult} from './network';



export interface IEntityService<E extends IOwnedEntity<any> | Record<string, any> = any> {
  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<E>>;

  fetchLanguageGroups?(args?: FetchAllArgs): Observable<IPaginatedLanguageGroup<E>>;

  fetchSingle(id: string): Observable<E>;

  fetchSelected(ids: string[]): Observable<E[]>;

  fetchCategories(currentLanguage?: string, owner?: string): Observable<ICategory[]>;

  getType(): string;

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>>;

  save(entities: E[]): Observable<MutationResult<IPKApolloResult<E[]>>>;

  executeMutation(mutation: any, vars: any): any;
}
