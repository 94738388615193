import gql from "graphql-tag";

export const deleteEvent = gql`
  mutation deleteEvent($input: [String!]!){
    response: deleteEvent(ids: $input){
      userMessage
      description
      error
    }
  }
`;
