import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseItemListData, IBaseItemListDesign,} from "../../structural";
import {ComponentVariant} from "./component-variants";


export type SearchResultWidgetSettings = {
  variant?: ComponentVariant;
  itemsClass?: string;
  itemTo?: string; // route for item click
  itemListData?: IBaseItemListData;
  itemListDesign?: IBaseItemListDesign;
  emptyIcon?: string;
} & BaseWidgetSettings;
