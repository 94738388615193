import {IKioskOwner} from './kiosk-owner';
import {IOwnedEntity} from "../i-owned-entity";
import {IAddress} from "../i-owned-contact";
import {IKioskTimeline} from "./timeline";
import {BaseTimestamps, MongoID} from "../base-document-types";
import {IBackgroundObject, IKioskScreen} from "../structural";
import {IBaseRole} from "../i-role";


export interface IKiosk<O extends IKioskOwner = any, H extends IKioskScreen | string = any, T extends IKioskTimeline<O> | string = any>
  extends IOwnedEntity<O> {
  name?: string;
  description?: string;

  isPwaOnline?: boolean; // not in DB, from WSS
  isPythonOnline?: boolean; // not in DB, from WSS

  pythonUniqueId?: string;
  pwaUniqueId?: string;

  pin?: string; // autogenerated after register -> 8char PIN
  hashedPassword?: string; //autogenerated after claim

  location?: IAddress;
  screenWidthPx?: number;
  screenHeightPx?: number;
  macAddress?: string;
  timeSchedules?: IKioskTimeSchedule[];
  homescreen?: H;

  timeline?: T;
  timelineTimeout?: number;
  idleTimeout?: number;

  browserSettings?: IKioskBrowserSettings;

  screenshot?: IKioskScreenshot;
  role?: IBaseRole | string;
}

export interface IKioskBrowserSettings{
  themeProps?: IKioskBrowserThemeProps;
  boundaries?: IKioskBrowserBoundaries;

  whitelist?: string[];
  blacklist?: string[];
  corsModifierBlacklist?: string[]; //Do not modify CORS headers on these domains
}

export interface IKioskBrowserThemeProps {
  primaryColor?: string;
  secondaryColor?: string;
  accentColor?: string;

  primaryTextColor?: string;
  secondaryTextColor?: string;
  accentTextColor?: string;

  background?: IBackgroundObject;
}

export interface IKioskBrowserBoundaries {
  useAccessibility?: boolean;
  barHeight?: number;
  accessibilityOffsetTop: number;
  accessibilityOffsetBottom: number;
  normalOffsetTop: number;
  normalOffsetBottom: number;
}

export interface IKioskHWInfo {
  memoryTotal?: number;
  memoryAvailable?: number;
  storageTotal?: number;
  storageAvailable?: number;
  wifiSignalDBM?: number;
  wifiSignalLevel?: number;
  wifiName?: string;
  ipAddr?: string;
  screenWidthPx: number;
  screenHeightPx: number;
  macAddress?: string;
  connectionStatus: string;
}

export interface IKioskTimeSchedule {
  timeOn: string;
  timeOff: string;
  weekday: number;
}

export interface IKioskScreenshot extends BaseTimestamps, MongoID {
  url: string;
  thumbnailUrl?: string;
}

export interface ISynchronizerOption {
  storeName: string,
  filter: string,
}

export interface IQueryParam {
  key: string,
  value: string
}
