import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {PortalModule} from '@angular/cdk/portal';
import {MatRadioModule} from '@angular/material/radio';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApolloModule} from 'apollo-angular';
import {ArticlePreviewComponent} from './components/article/article-preview/article-preview.component';
import {JumbotronComponent} from './components/jumbotron/jumbotron.component';
import {ArticleHeaderComponent} from './components/article/article-header/article-header.component';
import {RoutingModule} from './modules/routing/routing.module';
import {environment} from '../environments/environment';
import {MenuGridBannerComponent} from './components/menu-grid-banner/menu-grid-banner.component';
import {FooterComponent} from './components/footer/footer.component';
import {
  HomePageComponent,
  DebugPageComponent,
  ReservationResultPageComponent,
  MarlenkaBasePageComponent,
} from './pages';
import {MarlenkaRouterLinkPipe} from './pipes/marlenka-router-link.pipe';
import {LanguageSelectorComponent} from './components/ui/language-selector/language-selector.component';
import {AboutPageComponent} from './pages/about-page/about-page.component';
import {ProductsPageComponent} from './pages/products-page/products-page.component';
import {DistributionPageComponent} from './pages/distribution-page/distribution-page.component';
import {CareerPageComponent} from './pages/career-page/career-page.component';
import {ContactPageComponent} from './pages/contact-page/contact-page.component';
import {ExcursionPageComponent} from './pages/excursion-page/excursion-page.component';
import {DistributionDetailPageComponent} from './pages/distribution-page/distribution-detail-page/distribution-detail-page.component';
import {ArticlesPageComponent} from './pages/articles-page/articles-page.component';
import {ArticleDetailPageComponent} from './pages/articles-page/article-detail-page/article-detail-page.component';
import {ProductCategoryDetailComponent} from './pages/products-page/product-category-detail/product-category-detail.component';
import {ProductDetailComponent} from './pages/products-page/product-detail/product-detail.component';
import {ContactDetailComponent} from './components/contact-detail/contact-detail.component';
import {ReservationEditPageComponent} from './pages/reservation/reservation-edit-page/reservation-edit-page.component';
import {ReadMoreComponent} from './components/read-more/read-more.component';
import {SelectPurchaseTypePageComponent} from './pages/reservation/reservation-create-page/select-purchase-type-page.component';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {PageNotFoundComponent} from './pages/404/page-not-found.component';
import {ReservationStepperPageComponent} from './pages/reservation/reservation-create-page/reservation-stepper-page/reservation-stepper-page.component';
import {VoucherStepperPageComponent} from './pages/reservation/reservation-create-page/voucher-stepper-page/voucher-stepper-page.component';
import {CakesExclusivePageComponent} from './pages/cakes-exclusive-page/cakes-exclusive-page.component';
import {CakesExclusiveCategoryDetailComponent} from './pages/cakes-exclusive-page/cakes-exclusive-category-detail/cakes-exclusive-category-detail.component';
import {CakesExclusiveService} from './services/cakes-exclusive.service';
import {VirtualTourPageComponent} from './pages/virtual-tour-page/virtual-tour-page.component';
import {MatSelectModule} from '@angular/material/select';
import {VirtualTourPointDetailComponent} from './components/virtual-tour/virtual-tour-point-detail/virtual-tour-point-detail.component';
import {
  BASE_VIRTUAL_TOUR_SCENE_SERVICE_TOKEN, BaseGalleryService,
  BaseVirtualTourSceneService,
  SharedNgDataAccessModule,
} from '@echo-nx/shared/ng/data-access';
import {
  BaseDistributionService,
  BasePartnerStoreService,
  MarlenkaTimelineService, BaseVoucherTypeService, BasePageSettingsService,
} from '@echo-nx/marlenka/ng/data-access';
import {
  AddressPipeModule, createUrlSettings, PluralizeModule,
  ScrollableService, URL_SETTINGS_TOKEN,
} from '@echo-nx/shared/ng/feature/common';
import {ItemListComponent} from './components/item-lists/item-list/item-list.component';
import {MarlenkaReservationModule} from "@echo-nx/marlenka/ng/feature-reservation";
import {SharedNgUiGenericModule} from "@echo-nx/shared/ng/ui/generic";
import {SharedNgFeatureCommonModule} from "@echo-nx/shared/ng/feature/common";
import {SharedNgUiSmartModule} from "@echo-nx/shared/ng/ui/smart";
import {FlexLayoutModule} from "@angular/flex-layout";
import {SharedNgFeatureLanguageModule} from "@echo-nx/shared/ng/feature/language";
import {MarlenkaUiModule} from "@echo-nx/marlenka/ng/ui";
import {SharedNgFeatureLightboxGalleryModule} from "@echo-nx/shared/ng/feature/lightbox-gallery";
import {SharedNgFeatureCookiesModule} from "@echo-nx/shared/ng/feature/cookies";
import {MarlenkaCookieConsentModule} from "./components/cookie-consent/cookie-consent.module";
import { ArticlesReviewsPageComponent } from './pages/articles-reviews-page/articles-reviews-page.component';
import {SharedNgFeatureSsrModule} from "@echo-nx/shared/ng/feature/ssr";
import {GoogleTagManagerModule} from "@echo-nx/shared/ng/feature/gtm";
import { PageSettingService } from './services/page-setting.service';
import {MarlenkaSsrTokenInterceptor} from "./services/marlenka-ssr-token-interceptor.service";

@NgModule({
    declarations: [
        AppComponent,
        VirtualTourPageComponent,
        ArticlePreviewComponent,
        JumbotronComponent,
        ArticleHeaderComponent,
        HomePageComponent,
        DebugPageComponent,
        ReservationResultPageComponent,
        ReservationStepperPageComponent,
        VoucherStepperPageComponent,
        MenuGridBannerComponent,
        FooterComponent,
        MarlenkaBasePageComponent,
        MarlenkaRouterLinkPipe,
        AboutPageComponent,
        ProductsPageComponent,
        DistributionPageComponent,
        CareerPageComponent,
        ContactPageComponent,
        ExcursionPageComponent,
        DistributionDetailPageComponent,
        ArticlesPageComponent,
        ArticleDetailPageComponent,
        ProductCategoryDetailComponent,
        ProductDetailComponent,
        ContactDetailComponent,
        ReservationEditPageComponent,
        ReadMoreComponent,
        SelectPurchaseTypePageComponent,
        LanguageSelectorComponent,
        PageNotFoundComponent,
        CakesExclusivePageComponent,
        CakesExclusiveCategoryDetailComponent,
        VirtualTourPointDetailComponent,
        ItemListComponent,
        ArticlesReviewsPageComponent
    ],
    imports: [
        //SmartLanguageSelectorModule,
        SharedNgFeatureLanguageModule,
        SharedNgDataAccessModule,
        BrowserModule,
        ApolloModule,
        BrowserAnimationsModule,
        RoutingModule,
        MatButtonModule,
        MatIconModule,
        PortalModule,
        MatRadioModule,
        CdkStepperModule,
        MatRippleModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        FormsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        HttpClientModule,
        MatSnackBarModule,
        RouterModule,
        // EchoNxLightboxModule,
        AddressPipeModule,
        SharedNgFeatureCommonModule,
        SharedNgUiSmartModule,
        MarlenkaReservationModule,
        SharedNgUiGenericModule,
        SharedNgFeatureSsrModule,
        FroalaViewModule,
        MatButtonModule,
        MatIconModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        MatSelectModule,
        MatDialogModule,
        FlexLayoutModule,
        MarlenkaUiModule,
        PluralizeModule,

        SharedNgFeatureLightboxGalleryModule,
        GoogleTagManagerModule.forRoot({
            id: environment.gTagId,
        }),
        MarlenkaCookieConsentModule,
        SharedNgFeatureCookiesModule.forRoot({
            // gdprUrl: 'https://marlenka.cz/assets/zasady_ochrany_osobnich_udaju.pdf',
            dynamicComponentKey: 'MarlenkaCookieConsent',
        })
    ],
    providers: [
        MarlenkaTimelineService,
        BaseVoucherTypeService,
        CakesExclusiveService,
        BaseDistributionService,
        BasePartnerStoreService,
        BaseGalleryService,
        ScrollableService,
        {
            provide: URL_SETTINGS_TOKEN,
            useFactory: createUrlSettings({apiSettings: environment.apiSettings}),
        },
        {
            provide: BASE_VIRTUAL_TOUR_SCENE_SERVICE_TOKEN,
            useClass: BaseVirtualTourSceneService,
        },
        {
            provide: BasePageSettingsService,
            useClass: PageSettingService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MarlenkaSsrTokenInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {

    this.matIconRegistry.addSvgIcon(
      'family_2_2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/family_2_2.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'family_2_1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/family_2_1.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'family_1_3',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/family_1_3.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'family_3_1',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/family_3_1.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'marlenka_family_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/marlenka_family_icon.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'marlenka_kid_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/marlenka_kid_icon.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'marlenka_handicap_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/marlenka_handicap_icon.svg'
      )
    );
  }
}
