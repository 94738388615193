<div class="flex">
  <div class="text">{{text}}</div>
  <button *ngIf="!hideButton"
          mat-stroked-button
          id="snack-ok-button"
          (click)="snackBar.dismiss()">
    {{buttonText}}
  </button>
</div>

