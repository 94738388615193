import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavMobileComponent} from "./nav-mobile.component";
import {NavMobileLevelComponent} from "./nav-mobile-level/nav-mobile-level.component";
import {PrefixModule} from "../../prefix/prefix.module";
import {CdkAccordionModule} from "@angular/cdk/accordion";
import {NavActiveChildrenCountModule} from "../nav-active-children-count/nav-active-children-count.module";

@NgModule({
  declarations: [
    NavMobileComponent,
    NavMobileLevelComponent
  ],
  imports: [
    NavActiveChildrenCountModule,
    CommonModule,
    PrefixModule,
    CdkAccordionModule
  ],
  exports: [
    NavMobileComponent
  ]
})
export class NavMobileModule {

}
