<div class="flex flex-col justify-around items-center py-4 px-8 col-span-2 items-stretch">
  <span class="text-2xl font-bold border-b-8 p-2 px-8 text-center"
        [ngClass]="[startIcon?.bgColor | prefix:'border']">
    {{title}}
  </span>
  <div class="flex items-center justify-center text-3xl p-2 mt-2">
    <i [ngClass]="[startIcon?.icon, startIcon?.color | prefix:'text']"></i>
  </div>
</div>

<!-- VALUE + SUFFIX -->
<div class="flex flex-col flex-1 justify-center py-4 px-8 col-span-3">
  <div class="flex space-x-2 justify-center">
    <span class="text-5xl font-bold h-full">{{value}}</span>
    <span class="text-xl font-light h-full" style="vertical-align: super">{{suffix}}</span>
  </div>
  <span *ngIf="description" class="text-xs">{{description}}</span>
</div>
