import {BaseWidgetSettings} from "./base-settings.type";
import {IBaseTextData, IBaseTextDesign} from "../../structural";
import {ComponentVariant} from "./component-variants";

// for screen name
export type ScreenNameSettings = {
  text?: string;
  size?: string;
  color: string;
} & BaseWidgetSettings;

export type TextWidgetSettings = {
  variant?: ComponentVariant,
  textData: IBaseTextData,
  textDesign: IBaseTextDesign
} & BaseWidgetSettings;
