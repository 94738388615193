import {Component, Injector, OnInit} from '@angular/core';
import {CakeService} from "../../../services/cake.service";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {switchMap, takeUntil, tap} from "rxjs/operators";
import {IMProduct, ProductsPageTexts} from "@echo-nx/marlenka/common";
import {MarlenkaBasePageComponent} from "../../marlenka-base-page/marlenka-base-page.component";

@Component({
  selector: 'marlenka-product-category-detail',
  templateUrl: './product-category-detail.component.html',
  styleUrls: ['./product-category-detail.component.scss']
})
export class ProductCategoryDetailComponent extends MarlenkaBasePageComponent<ProductsPageTexts> implements OnInit {
  public products$: Observable<IMProduct[]>;

  constructor(injector: Injector, private cakeService: CakeService, private activeRoute: ActivatedRoute) {
    super(injector);

    this.products$ = this.activeRoute.params.pipe(
      takeUntil(this.isDestroyed$),
      switchMap(({categoryId}) => {
        return this.cakeService.fetchAllForLanguageGroupCategory(categoryId);
      }),
      tap(e => console.log('eee',e))
    )
  }

  ngOnInit(): void {
    return;
  }

}
