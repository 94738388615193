import {IMeta} from "./i-meta";
import {IGeolocationCoordinates} from "./i-geolocation-coordinates";
import {IMedium} from "./i-medium";
import {IBaseOwner} from "./i-base-owner";
import {ICategorizedEntity, IOwnedEntity, IPublishableOwnedEntity} from "./i-owned-entity";
import {ICategory} from "./i-category";

export interface IPOIItem<M = any, O extends IBaseOwner = any, C extends ICategory<O> | string = any> extends IMeta<M>, ICategorizedEntity<C>, IPublishableOwnedEntity<O> {
  name: string;
  description?: string;
  content: string;
  location: IGeolocationCoordinates;
  media?: IMedium<O>[] | string[];
  featuredImage?: IMedium<O> | string;
}
