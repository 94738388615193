export function resolveKey(key: string[], row: Record<string, any>) {
  return key.reduce((acc: Record<string, any>, current: string) => {
    if (acc) {
      return acc[current];
    }
  }, row);
}


export function resolveKeyOrShowDefault(key: string[], row: Record<string, any>, defaultValue?: string) {
  const result = resolveKey(key, row);
  if (!result && defaultValue) {
    return defaultValue;
  } else {
    return result;
  }
}

export const getAllergensNameNumber = (item: any) => {
  return `${item['number']}: ${item['text']}`;
}
