import {Component, Input} from '@angular/core';

@Component({
  selector: 'marlenka-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: ['./article-header.component.scss']
})
export class ArticleHeaderComponent {

  @Input()
  public imgSrc!: string;

  @Input()
  public subtitle!: string;

  @Input()
  public title!: string;

  @Input()
  public content!: string;

}
