import {IRole} from './i-role';
import { IBaseOwner } from './i-base-owner';
export interface IAuthToken<T extends IBaseOwner | string = any> {
  _id?: string;
  email?: string;
  ownerId?: string;
  userId?: string;
  homepageId?: string;
  mainNavigationId?: string;
  role?: IRole<T>;
  exp?: number;
  gtmTag?: string;
  domains?: string[];
  entityTypeSlugMapping?: Record<string, string>;
}
