import * as moment from "moment";
import StartOf = moment.unitOfTime.StartOf;

export const getDaysInMonth = (month: number, year: number) => 32 - new Date(year, month, 32).getDate();

export const getFirstDayOfMonth = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  // if first day of month is sunday shift it cause normal people start with monday
  return firstDay === 0 ? 7 : firstDay;
};

export const isSameDate = (date1: Date, date2: Date, unit: StartOf) => moment(date1).isSame(moment(date2), unit);

export const formatDate = (date: Date, format = 'DD.MM.YYYY HH:mm') => moment(date).format(format);

export const getWeekdayName = (weekday: number): string => {
  if (weekday === 1) {
    return 'Pondělí';
  } else if (weekday === 2) {
    return 'Úterý';
  } else if (weekday === 3) {
    return 'Středa';
  } else if (weekday === 4) {
    return 'Čtvrtek';
  } else if (weekday === 5) {
    return 'Pátek';
  } else if (weekday === 6) {
    return 'Sobota';
  } else if (weekday === 7) {
    return 'Neděle';
  } else {
    return `Neznámý den s indexem${weekday}`
  }
};
