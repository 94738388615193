import {Component, HostBinding, Input} from '@angular/core';
import {IBaseButtonData, IEpxColorful, IEpxIcon} from "@echo-nx/shared/common";
import {IButtonThemeRsdDesign} from "./i-button-theme-rsd.settings";


@Component({
  selector: 'echo-nx-button-theme-rsd',
  templateUrl: './button-theme-rsd.component.html',
  styleUrls: ['./button-theme-rsd.component.scss']
})
export class ButtonThemeRsdComponent implements IButtonThemeRsdDesign, IBaseButtonData {

  @Input() title: string;

  @Input() description?: string;

  @Input() textColor = 'white';

  @Input() prominent = false;

  @Input() bgColor = 'primary-500';

  @Input() startIcon?: IEpxIcon;

  @Input() endIcon?: IEpxIcon;

  @Input() descriptionColorful?: IEpxColorful;

  @Input() decorate = false;

  @Input() rounded = false;

  @Input() fluid;

  //todo make dis into type plz
  @Input() titleAlign: 'text-left' | 'text-center' | 'text-right' = 'text-left';

  @HostBinding('class') get classList(): any {
    return {
      'flex-1': this.fluid === 'horizontal' || this.fluid === 'both',
    };
  }

  @Input()
  set design({ prominent,
    descriptionColorful,
               rounded,
               textColor,
               bgColor,
               startIcon,
               endIcon,
               titleAlign,
               fluid}: IButtonThemeRsdDesign) {
    this.fluid = fluid;
    this.rounded = rounded;
    this.prominent = prominent ?? this.prominent;
    this.textColor = textColor ?? this.textColor;
    this.descriptionColorful = descriptionColorful;
    this.bgColor = bgColor ?? this.bgColor;
    this.startIcon = startIcon;
    this.endIcon = endIcon;
    this.titleAlign = titleAlign ?? this.titleAlign;
  }

  @Input()
  set data({
             title,
             description,
           }: IBaseButtonData) {
    this.title = title;
    this.description = description;
  }

  //this including inject will break storybook

}
