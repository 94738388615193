import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {formatFileSize, getWeekdayName, ICompanyContact, IContact} from "@echo-nx/shared/common";
import {EchoNxFormatPipeProps} from "../../types";
import {formatPrice} from "../../utils/common";

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: any, settings?: EchoNxFormatPipeProps, entity?: any): string {
    if (settings) {
      const {method, transform, format} = settings;
      const transformedValue = transform ? transform(value, entity) : value;
      switch (method) {
        case 'date':
          return moment(transformedValue).format(format || 'DD.MM.YYYY HH:mm');
        case 'money':
          return formatPrice(transformedValue, format);
        case "mongoId":
          return this.shortenMongoId(transformedValue);
        case 'contact':
          return this.formatContact(transformedValue);
        case 'localeString':
          return value.toLocaleString();
        case 'weekday':
          return getWeekdayName(transformedValue);
        case "filesize":
          return formatFileSize(transformedValue, format);
        default:
          return transformedValue;
      }
    } else {
      console.error('Format pipe used without settings');
      return value;
    }
  }

  shortenMongoId(value: string, length = 5) {
    if (value.length > length) {
      return value.substr(value.length - length, value.length);
    }
    return value;
  }

  formatContact(contact: IContact & ICompanyContact): string {
    const {name, ico, firstName, lastName} = contact || {};
    if (firstName || lastName) {
      return `${firstName ?? ''} ${lastName ?? ''}`;
    }

    if (name || ico) {
      return `${name} ${name ? `(IČO: ${ico})` : `IČO ${ico}`}`
    }

    return 'Neznámý'
  }
}
