import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IsArrayPipe} from "./is-array.pipe";
import {IsImageMediumPipe} from "./is-image-medium.pipe";
import {StartsWithPipe} from "./starts-with.pipe";
import {HasKeyPipe} from "./has-key.pipe";



@NgModule({
  declarations: [IsArrayPipe, IsImageMediumPipe, StartsWithPipe, HasKeyPipe],
  imports: [
    CommonModule
  ],
  exports: [IsArrayPipe, IsImageMediumPipe, StartsWithPipe, HasKeyPipe],
})
export class CheckPipesModule { }
