import {Injectable, InjectionToken} from "@angular/core";
import {
  EkioskEntityTypes,
  FetchAllArgs,
  ICategory,
  IEntityService,
  INavigationItem,
  IMutationResponse,
  IPaginatedResponse,
  IPKApolloResult, IBaseRole
} from "@echo-nx/shared/common";
import {Observable, of} from "rxjs";
import {Apollo, MutationResult} from "apollo-angular";
import {map} from "rxjs/operators";
import {readAllCategoriesOfEntity, readAllNavigations, readSelectedNavigations} from "../queries";
import {addNavigation} from "../mutations/navigation";


@Injectable()
export class BaseNavigationService implements IEntityService<INavigationItem> {

  constructor(protected apollo: Apollo) {}

  getType(): string {
    return EkioskEntityTypes.Navigation;
  }

  delete(ids: string[]): Observable<MutationResult<IPKApolloResult<IMutationResponse>>> {
    throw Error('NOT IMPLEMENTED :(');
  }

  executeMutation(mutation: any, variables: any) {
    return this.apollo.mutate({
      mutation,
      variables
    });
  }

  fetchAll(args?: FetchAllArgs): Observable<IPaginatedResponse<INavigationItem>> {
    return this.apollo.query<IPKApolloResult<IPaginatedResponse<INavigationItem>>>({
      query: readAllNavigations,
      variables: args
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchCategories(currentLanguage?: string, owner?: string): Observable<ICategory[]> {
    return this.apollo.query<IPKApolloResult<ICategory<any>[]>>({
      query: readAllCategoriesOfEntity,
      variables: {
        //WE NEED SCREEN CATEGORIES, Navigation itself does not have any (as of 08/2022), this is intended
        type: EkioskEntityTypes.Screen,
        //language: currentLanguage
      }
    }).pipe(
      map(result => result.data.response)
    );
  }



  fetchSelected(ids: string[]): Observable<INavigationItem[]> {
    return this.apollo.query<IPKApolloResult<INavigationItem[]>>({
      query: readSelectedNavigations,
      variables: {
        ids
      }
    })
      .pipe(
        map(result => result.data.response)
      );
  }

  fetchSingle(id: string): Observable<INavigationItem> {
    return this.fetchSelected([id]).pipe(
      map(res => res[0])
    )
  }

  save(entities: INavigationItem[]): Observable<MutationResult<IPKApolloResult<INavigationItem[]>>> {

    return this.apollo.mutate<IPKApolloResult<INavigationItem[]>>({
      mutation: addNavigation,
      variables: {input: entities}
    });
  }

}

export const BASE_NAVIGATION_SERVICE_TOKEN = new InjectionToken<IEntityService<INavigationItem>>('BASE_NAVIGATION_SERVICE_TOKEN');
