export const isNumeric = (possibleNumber: any) => {
    if (typeof possibleNumber === 'number') {
      return true;
    }
    if (typeof possibleNumber === 'string') {
      if (possibleNumber.length === 0) {
        // conversion of empty string to number is 0, we need to check it first
        return false;
      } else if (isNaN(Number(possibleNumber))) {
        return false;
      }
      return true;
    }
    return false;
  };