<mat-card class="rounded-xl overflow-hidden">
  <div class="header bg-accent" [style.background]="color">
    <mat-card-title>{{settings.nazev}}<span style="color: red" *ngIf="settings.povinnost">*</span> </mat-card-title>
    <div *ngIf="!settings.hideAddButton">
      <ng-container *ngIf="settings.iconAddButton">
        <button mat-icon-button class="button" (click)="createItem()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!settings.iconAddButton">
        <button mat-raised-button class="button" color="primary" (click)="createItem()">
          + Nový řádek
        </button>
      </ng-container>

      <!-- if it has Expandable option and if have at least one item -->
      <button *ngIf="settings.expandable" mat-button (click)="expanded = !expanded">
        <mat-icon>{{expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content
    echoNxRepeaterComponentTree
    [entityFormService]="entityFormService"
    [repeaterControlName]="settings.formControlName"
  >
    <div class="rows" [style.display]="expanded ? '' : 'none'" [class.divide-y-2]="settings.withDivider">
      <ng-container *ngIf="formControl">

        <!-- radky pomoci gridu-->
        <div class="fx-row" *ngFor="let row of formControl.controls; let rowIndex = index" >
          <div
            *ngFor="let field of settings.rowDefinition;"
            [ngClass]="field.name === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
            [formGroup]="getFormGroupForRow(rowIndex)"
            [style.grid-row-start]="field.y ? field.y + 1 : undefined"
            [style.grid-column-start]="field.x ? field.x + 1 : undefined"
            [style.grid-column-end]="field.x ? field.x + 1 + field.cols : undefined"
            [style.grid-column]="'span ' + field.cols">
            <ng-template
              (attached)="onPortalAttached(field.settings.formControlName, rowIndex, $event)"
              [cdkPortalOutlet]="field | dynamicComponent"
            >
            </ng-template>
          </div>

          <!-- delete button -->
          <button
            *ngIf="!settings.hideAddButton"
            mat-icon-button
            class="delete-button"
            (click)="deleteItem(rowIndex)"
            [style.grid-row-start]="0"
            [style.grid-column-start]="13">
            <mat-icon>delete</mat-icon>
          </button>

        </div>
      </ng-container>

    </div>
  </mat-card-content>

</mat-card>

