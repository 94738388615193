import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PluckPipe} from "./pluck.pipe";



@NgModule({
  declarations: [PluckPipe],
  imports: [
    CommonModule
  ],
  exports: [
    PluckPipe
  ]
})
export class PluckPipeModule { }
