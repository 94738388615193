import {IEntityDefinition} from "../../interfaces";
import {BASE_MEDIUM_SERVICE_TOKEN} from "@echo-nx/shared/ng/data-access";
import {IMedium} from "@echo-nx/shared/common";
import {
  IEntityPickerFieldSettings,
  IGroupFieldSettings,
  ISelectFieldSettings,
  ITextFieldSettings
} from "../../modules/form-fields";
import {Validators} from "@angular/forms";
import {of} from "rxjs";
import {routeToEntityDefinition} from "../entity-definition-helpers";

export const getImageWidgetEntityDefinition = (): IEntityDefinition[] => {
  return [
    {
      name: 'PkInputGroupFieldComponent',
      settings: {
        nazev: 'Nastavení',
        formControlName: 'settings',
        withoutCard: true,
        groupDefinition: [
          {
            name: 'EntityPickerFieldComponent',
            settings: {
              nazev: 'Hlavní obrázek',
              povinnost: true,
              formControlName: 'image',
              preview: {
                style: 'media',
                keys: {
                  name: ['name'],
                  url: ['url']
                }
              },
              dialogSettings: {
                title: 'Média',
                entityServiceData: {
                  token: BASE_MEDIUM_SERVICE_TOKEN
                },
                selectOnItemClick: true,
                viewType: 'grid',
                gridItemDefinition: {
                  mediaUrl: (entity: IMedium) => {
                    const {type, url, thumbnailUrl} = entity;
                    if (type === 'Image') {
                      return thumbnailUrl ?? url ?? 'assets/images/placeholders/jpeg-placeholder.png';
                    } else if (type === 'Document') {
                      return 'assets/images/placeholders/doc-placeholder.png';
                    } else if (type === 'Archive') {
                      return 'assets/images/placeholders/zip-placeholder.png';
                    } else if (type === 'Video') {
                      return 'assets/images/placeholders/video-placeholder.png';
                    } else {
                      return 'assets/images/placeholders/generic-placeholder.png';
                    }
                  },
                  title: ['name'],
                  subtitle: ['description'],
                  date: ['createdAt'],
                }
              },
              //transformFn: transformSingularId
            } as IEntityPickerFieldSettings,

            data: null,
            validators: [Validators.required],
            cols: 12,
          },
          {
            name: 'PkSelectFieldComponent',
            settings: {
              nazev: 'Škálování',
              defaultValue: 'cover',
              formControlName: 'objectFit'
            } as ISelectFieldSettings,
            data: of([
              {
                displayValue: 'Vyplnit celou plochu a zachovat poměr stran (cover)',
                _id: 'cover'
              },
              {
                displayValue: 'Vyplnit celou plochu (fill)',
                _id: 'fill'
              },
              {
                displayValue: 'Vyplnit dle nejdelší strany (contain)',
                _id: 'contain'
              },
              {
                displayValue: 'Žádné (none)',
                _id: 'none'
              },
            ]),
            validators: [],
            cols: 12,
          },
        ]
      } as IGroupFieldSettings,
      data: null,
      validators: [],
      cols: 12,
    },
    routeToEntityDefinition,

  ];
}
