export interface IColumnDefinition<T = any> {
  key: string[] | (keyof T)[];
  title: string;
  sortable?: boolean;
  align: 'left' | 'center' | 'right';
  shrink?: boolean;
  fxHide?: {
    sm: boolean;
    md: boolean;
    lg: boolean;
  };
  showInGrid?: 'picture' | 'title' | 'subtitle' | 'select' | 'icon' | 'color' | 'category';
  nullValue?: string; // value to show if column is null
  settings?: any; // TODO CMS-REWORK PkStatusCellProps | FormatPipeProps | PkFilterProps | PkPluckProps;
  type?: 'string' | 'label' | 'date' | 'date-time' | 'select' | 'status' | 'format' | 'filter' | 'map' | 'template';
  width?: string;
  gridOnly?: boolean;
}
