import {Sort} from "@angular/material/sort";

export enum FilterNames {
  SORT_FILTER_NAME = 'sort',
  PAGINATION_FILTER_NAME = 'pagination',
  SEARCH_FILTER_NAME = 'search',
  EXTERNAL_FILTER_NAME = 'filters',
  VIEWTYPE_FILTER_NAME = 'viewtype'
}


export type FilterFormValue = {
  [FilterNames.SORT_FILTER_NAME]: Sort;
  [FilterNames.PAGINATION_FILTER_NAME]: MaterialPaginatorGroup;
  [FilterNames.SEARCH_FILTER_NAME]: string;
  [FilterNames.EXTERNAL_FILTER_NAME]: Record<string, any>;
}

export type MaterialPaginatorGroup = {
  pageSize: number;
  pageIndex: number;
}

export const getSortValue = (filters: FilterFormValue): Sort => {
  return filters[FilterNames.SORT_FILTER_NAME] as Sort;
}

export const getPaginationValue = (filters: FilterFormValue): MaterialPaginatorGroup => {
  return filters[FilterNames.PAGINATION_FILTER_NAME];
}

export const getSearchValue = (filters: FilterFormValue): string | undefined => {
  return filters[FilterNames.SEARCH_FILTER_NAME];
}

export const getFilterValue = (filters: FilterFormValue): any => {
  return filters[FilterNames.EXTERNAL_FILTER_NAME];
}

export const getCategoryValue = (filters: FilterFormValue): string[] | undefined => {
  const external = filters[FilterNames.EXTERNAL_FILTER_NAME];
  return external['category']?.length > 0 ? external['category'] : undefined;
}

