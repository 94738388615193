import { Component, OnInit } from '@angular/core';
import {TextComponent} from "../text.component";

@Component({
  selector: 'echo-nx-text-variant-b',
  templateUrl: './text-variant-b.component.html',
  styleUrls: ['./text-variant-b.component.scss']
})
export class TextVariantBComponent extends TextComponent {

}
